export default {
    'Portfolio-Boston': {
      'Troy Boston': {
        Leads: [
          '257',
          '257',
          '243',
          '243',
          '243',
          '243',
          '243',
          '229',
          '229',
          '243',
          '257',
          '271',
          '286',
          '500',
          '476',
          '452',
          '429',
          '405',
          '381',
          '357',
          '357',
          '333',
          '310',
          '286',
          '286',
          '262',
          '262',
          '238',
          '238',
          '214',
          '214',
          '190',
          '190',
          '190',
          '190',
          '190',
          '100',
          '100',
          '100',
          '86',
          '86',
          '86',
          '86',
          '86',
          '86',
          '86',
          '86',
          '71',
          '71',
          '69',
          '69',
          '69',
          '69',
          '82',
          '96',
          '82',
          '82',
          '82',
          '69',
          '69',
        ],
        Showings: [
          '59',
          '59',
          '56',
          '56',
          '56',
          '56',
          '56',
          '53',
          '53',
          '56',
          '59',
          '62',
          '66',
          '115',
          '110',
          '104',
          '99',
          '61',
          '57',
          '54',
          '54',
          '50',
          '46',
          '43',
          '43',
          '39',
          '39',
          '36',
          '36',
          '32',
          '32',
          '29',
          '29',
          '29',
          '29',
          '29',
          '15',
          '15',
          '15',
          '13',
          '13',
          '21',
          '21',
          '21',
          '21',
          '21',
          '21',
          '18',
          '18',
          '17',
          '17',
          '17',
          '19',
          '23',
          '27',
          '23',
          '23',
          '23',
          '19',
          '19',
        ],
        'App. Requested': [
          '12',
          '12',
          '11',
          '11',
          '11',
          '11',
          '11',
          '11',
          '11',
          '11',
          '12',
          '12',
          '13',
          '23',
          '22',
          '21',
          '20',
          '9',
          '9',
          '8',
          '8',
          '8',
          '7',
          '7',
          '7',
          '6',
          '9',
          '8',
          '8',
          '7',
          '7',
          '7',
          '7',
          '7',
          '7',
          '7',
          '3',
          '3',
          '3',
          '3',
          '4',
          '4',
          '4',
          '4',
          '4',
          '4',
          '4',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '4',
          '4',
          '4',
          '4',
          '4',
          '3',
          '3',
        ],
        Approved: [
          '5',
          '5',
          '5',
          '5',
          '5',
          '5',
          '5',
          '5',
          '5',
          '5',
          '5',
          '5',
          '6',
          '10',
          '10',
          '9',
          '9',
          '8',
          '8',
          '7',
          '7',
          '7',
          '6',
          '6',
          '6',
          '5',
          '8',
          '7',
          '7',
          '6',
          '6',
          '6',
          '6',
          '6',
          '6',
          '6',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '3',
          '3',
          '3',
          '3',
          '3',
          '2',
          '2',
        ],
        'Lease velocity': [
          '32',
          '32',
          '32',
          '32',
          '25',
          '25',
          '32',
          '25',
          '25',
          '25',
          '28',
          '29',
          '25',
          '45',
          '40',
          '32',
          '30',
          '31',
          '32',
          '33',
          '34',
          '35',
          '36',
          '37',
          '38',
          '39',
          '40',
          '40',
          '40',
          '25',
          '25',
          '32',
          '30',
          '28',
          '28',
          '28',
          '28',
          '28',
          '28',
          '28',
          '28',
          '29',
          '29',
          '29',
          '29',
          '30',
          '30',
          '30',
          '31',
          '31',
          '31',
          '30',
          '30',
          '30',
          '29',
          '29',
          '29',
          '29',
          '28',
          '28',
        ],
        'Available Units': [
          '10',
          '10',
          '10',
          '10',
          '10',
          '10',
          '10',
          '9',
          '9',
          '10',
          '10',
          '11',
          '12',
          '12',
          '12',
          '11',
          '10',
          '10',
          '9',
          '9',
          '9',
          '8',
          '7',
          '7',
          '7',
          '6',
          '8',
          '8',
          '8',
          '7',
          '7',
          '6',
          '6',
          '6',
          '6',
          '6',
          '5',
          '4',
          '4',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '4',
          '3',
          '3',
          '3',
          '3',
          '3',
        ],
        'Vacancy (%)': [
          '2.86',
          '2.86',
          '2.86',
          '2.86',
          '2.86',
          '2.86',
          '2.86',
          '2.57',
          '2.57',
          '2.86',
          '2.86',
          '3.14',
          '3.43',
          '3.43',
          '3.43',
          '3.14',
          '2.86',
          '2.86',
          '2.57',
          '2.57',
          '2.57',
          '2.29',
          '2.00',
          '2.00',
          '2.00',
          '1.71',
          '2.29',
          '2.29',
          '2.29',
          '2.00',
          '2.00',
          '1.71',
          '1.71',
          '1.71',
          '1.71',
          '1.71',
          '1.43',
          '1.14',
          '1.14',
          '0.86',
          '0.86',
          '0.86',
          '0.86',
          '0.86',
          '0.86',
          '0.86',
          '0.86',
          '0.86',
          '0.86',
          '0.86',
          '0.86',
          '0.86',
          '0.86',
          '0.86',
          '1.14',
          '0.86',
          '0.86',
          '0.86',
          '0.86',
          '0.86',
        ],
        'Budgeted Vacancy': [
          '1.71',
          '1.71',
          '1.71',
          '1.71',
          '1.71',
          '1.71',
          '1.71',
          '1.54',
          '1.54',
          '1.71',
          '1.71',
          '1.89',
          '2.74',
          '2.74',
          '2.74',
          '2.51',
          '2.29',
          '2.29',
          '2.06',
          '2.06',
          '2.06',
          '1.83',
          '1.60',
          '1.60',
          '1.60',
          '1.37',
          '1.83',
          '1.83',
          '1.83',
          '1.60',
          '1.60',
          '1.37',
          '1.37',
          '1.37',
          '1.37',
          '1.37',
          '1.14',
          '0.91',
          '0.91',
          '0.69',
          '0.69',
          '0.69',
          '0.69',
          '0.69',
          '0.69',
          '0.94',
          '0.94',
          '0.94',
          '0.94',
          '0.94',
          '0.94',
          '0.94',
          '0.94',
          '0.69',
          '0.91',
          '0.69',
          '0.69',
          '0.69',
          '0.69',
          '0.69',
        ],
        'Contacts Made': [
          '206',
          '206',
          '194',
          '194',
          '194',
          '194',
          '194',
          '183',
          '183',
          '194',
          '206',
          '217',
          '229',
          '350',
          '333',
          '317',
          '300',
          '283',
          '267',
          '250',
          '250',
          '233',
          '217',
          '200',
          '200',
          '183',
          '183',
          '167',
          '167',
          '150',
          '150',
          '133',
          '133',
          '171',
          '171',
          '171',
          '90',
          '90',
          '90',
          '77',
          '77',
          '77',
          '77',
          '77',
          '77',
          '77',
          '77',
          '64',
          '64',
          '62',
          '62',
          '62',
          '62',
          '74',
          '87',
          '74',
          '74',
          '74',
          '62',
          '62',
        ],
        'No shows': [
          '8',
          '8',
          '8',
          '8',
          '8',
          '8',
          '8',
          '7',
          '7',
          '8',
          '8',
          '9',
          '9',
          '17',
          '16',
          '15',
          '14',
          '9',
          '8',
          '8',
          '8',
          '7',
          '6',
          '6',
          '6',
          '5',
          '5',
          '5',
          '5',
          '4',
          '4',
          '4',
          '4',
          '4',
          '4',
          '4',
          '2',
          '2',
          '2',
          '1',
          '1',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '3',
          '4',
          '3',
          '3',
          '3',
          '2',
          '2',
        ],
        '# of units': [
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
        ],
        'Renewal Rate (%)': [
          '60',
          '63',
          '66',
          '67',
          '67',
          '68',
          '69',
          '70',
          '70',
          '71',
          '70',
          '70',
          '69',
          '68',
          '67',
          '67',
          '66',
          '65',
          '65',
          '64',
          '63',
          '65',
          '67',
          '69',
          '71',
          '74',
          '76',
          '78',
          '78',
          '78',
          '78',
          '78',
          '78',
          '79',
          '79',
          '79',
          '79',
          '79',
          '79',
          '79',
          '79',
          '79',
          '79',
          '79',
          '79',
          '80',
          '80',
          '80',
          '80',
          '80',
          '80',
          '80',
          '80',
          '80',
          '80',
          '80',
          '80',
          '80',
          '81',
          '81',
        ],
        'Net Eff Rent per unit': [
          '3256.00',
          '3272.28',
          '3288.64',
          '3305.08',
          '3321.61',
          '3338.22',
          '3354.91',
          '3371.68',
          '3388.54',
          '3405.48',
          '3422.51',
          '3439.62',
          '3456.82',
          '3474.11',
          '3491.48',
          '3508.93',
          '3505.43',
          '3501.92',
          '3498.42',
          '3494.92',
          '3491.43',
          '3487.93',
          '3484.45',
          '3480.96',
          '3477.48',
          '3474.00',
          '3470.53',
          '3467.06',
          '3463.59',
          '3460.13',
          '3456.67',
          '3453.21',
          '3449.76',
          '3446.31',
          '3442.86',
          '3439.42',
          '3435.98',
          '3432.54',
          '3429.11',
          '3425.68',
          '3422.26',
          '3418.83',
          '3415.42',
          '3412.00',
          '3415.41',
          '3418.83',
          '3422.25',
          '3425.67',
          '3435.95',
          '3446.25',
          '3456.59',
          '3466.96',
          '3477.36',
          '3487.79',
          '3498.26',
          '3508.75',
          '3519.28',
          '3529.84',
          '3540.43',
          '3551.05',
        ],
        'Conversion rate': [
          '1.9',
          '1.9',
          '2.1',
          '2.1',
          '2.1',
          '2.1',
          '2.1',
          '2.2',
          '2.2',
          '2.1',
          '1.9',
          '1.8',
          '2.1',
          '2.0',
          '2.1',
          '2.0',
          '2.1',
          '2.0',
          '2.1',
          '2.0',
          '2.0',
          '2.1',
          '1.9',
          '2.1',
          '2.1',
          '1.9',
          '3.1',
          '2.9',
          '2.9',
          '2.8',
          '2.8',
          '3.2',
          '3.2',
          '3.2',
          '3.2',
          '3.2',
          '3.0',
          '3.0',
          '3.0',
          '3.5',
          '3.5',
          '3.5',
          '3.5',
          '3.5',
          '3.5',
          '3.5',
          '3.5',
          '2.8',
          '2.8',
          '2.9',
          '2.9',
          '2.9',
          '2.9',
          '3.6',
          '3.1',
          '3.6',
          '3.6',
          '3.6',
          '2.9',
          '2.9',
        ],
        'Lost reasons': {
          'Competition has better price': [
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '4',
            '4',
            '4',
            '5',
            '5',
            '5',
            '5',
            '5',
            '7',
            '7',
            '7',
            '8',
            '8',
            '8',
            '8',
            '8',
            '7',
            '6',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '4',
            '3',
            '2',
            '1',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
          ],
          'Location doesn\u2019t work': [
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
          ],
          'Amenities are lacking': [
            '5',
            '4',
            '3',
            '2',
            '1',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
          ],
          'Bad resident historty/credit': [
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
          ],
          'Unit in poor condition': [
            '0',
            '0',
            '1',
            '2',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
          ],
          'Change of plans': [
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
          ],
          Unresponsive: [
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
          ],
        },
      },
      'Parkway Apartments': {
        Leads: [
          '357',
          '357',
          '357',
          '357',
          '343',
          '343',
          '343',
          '343',
          '329',
          '357',
          '371',
          '386',
          '414',
          '429',
          '400',
          '386',
          '371',
          '343',
          '329',
          '314',
          '300',
          '286',
          '271',
          '257',
          '243',
          '229',
          '214',
          '214',
          '200',
          '186',
          '186',
          '171',
          '171',
          '157',
          '157',
          '157',
          '157',
          '143',
          '143',
          '129',
          '129',
          '129',
          '129',
          '129',
          '114',
          '114',
          '114',
          '114',
          '100',
          '100',
          '100',
          '100',
          '114',
          '129',
          '143',
          '129',
          '129',
          '114',
          '114',
          '100',
        ],
        Showings: [
          '82',
          '82',
          '82',
          '82',
          '79',
          '79',
          '79',
          '79',
          '76',
          '82',
          '85',
          '89',
          '95',
          '99',
          '92',
          '89',
          '85',
          '79',
          '76',
          '72',
          '69',
          '66',
          '62',
          '59',
          '56',
          '53',
          '49',
          '49',
          '46',
          '43',
          '43',
          '39',
          '39',
          '36',
          '36',
          '36',
          '36',
          '33',
          '33',
          '30',
          '30',
          '30',
          '30',
          '30',
          '26',
          '26',
          '26',
          '26',
          '23',
          '23',
          '23',
          '23',
          '26',
          '26',
          '29',
          '26',
          '26',
          '23',
          '23',
          '20',
        ],
        'App. Requested': [
          '16',
          '16',
          '16',
          '16',
          '16',
          '16',
          '16',
          '16',
          '15',
          '16',
          '17',
          '18',
          '19',
          '20',
          '18',
          '18',
          '17',
          '16',
          '15',
          '14',
          '14',
          '13',
          '12',
          '12',
          '11',
          '11',
          '10',
          '10',
          '9',
          '9',
          '9',
          '8',
          '8',
          '7',
          '7',
          '7',
          '7',
          '7',
          '7',
          '6',
          '6',
          '6',
          '6',
          '6',
          '5',
          '5',
          '5',
          '5',
          '5',
          '5',
          '5',
          '5',
          '5',
          '5',
          '6',
          '5',
          '5',
          '5',
          '5',
          '4',
        ],
        Approved: [
          '7',
          '7',
          '7',
          '7',
          '7',
          '7',
          '7',
          '7',
          '7',
          '7',
          '7',
          '8',
          '8',
          '9',
          '8',
          '8',
          '7',
          '7',
          '7',
          '6',
          '6',
          '6',
          '5',
          '5',
          '5',
          '5',
          '4',
          '4',
          '4',
          '4',
          '4',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '3',
          '3',
          '3',
          '3',
          '2',
          '2',
          '2',
        ],
        'Lease velocity': [
          '32',
          '32',
          '32',
          '32',
          '25',
          '25',
          '32',
          '25',
          '25',
          '25',
          '28',
          '29',
          '25',
          '45',
          '40',
          '32',
          '30',
          '31',
          '32',
          '33',
          '34',
          '35',
          '36',
          '37',
          '38',
          '39',
          '40',
          '40',
          '40',
          '25',
          '25',
          '32',
          '30',
          '28',
          '28',
          '28',
          '28',
          '28',
          '28',
          '28',
          '28',
          '29',
          '29',
          '29',
          '29',
          '30',
          '30',
          '30',
          '31',
          '31',
          '31',
          '30',
          '30',
          '30',
          '29',
          '29',
          '29',
          '29',
          '28',
          '28',
        ],
        'Available Units': [
          '15',
          '15',
          '15',
          '15',
          '14',
          '14',
          '14',
          '14',
          '13',
          '15',
          '15',
          '16',
          '17',
          '18',
          '16',
          '16',
          '15',
          '14',
          '13',
          '13',
          '12',
          '12',
          '11',
          '10',
          '10',
          '9',
          '9',
          '9',
          '8',
          '7',
          '7',
          '7',
          '7',
          '6',
          '6',
          '6',
          '6',
          '6',
          '6',
          '5',
          '5',
          '5',
          '5',
          '5',
          '4',
          '4',
          '4',
          '4',
          '4',
          '4',
          '4',
          '4',
          '4',
          '5',
          '6',
          '5',
          '5',
          '4',
          '4',
          '4',
        ],
        'Vacancy (%)': [
          '3.57',
          '3.57',
          '3.57',
          '3.57',
          '3.33',
          '3.33',
          '3.33',
          '3.33',
          '3.10',
          '3.57',
          '3.57',
          '3.81',
          '4.05',
          '4.29',
          '3.81',
          '3.81',
          '3.57',
          '3.33',
          '3.10',
          '3.10',
          '2.86',
          '2.86',
          '2.62',
          '2.38',
          '2.38',
          '2.14',
          '2.14',
          '2.14',
          '1.90',
          '1.67',
          '1.67',
          '1.67',
          '1.67',
          '1.43',
          '1.43',
          '1.43',
          '1.43',
          '1.43',
          '1.43',
          '1.19',
          '1.19',
          '1.19',
          '1.19',
          '1.19',
          '0.95',
          '0.95',
          '0.95',
          '0.95',
          '0.95',
          '0.95',
          '0.95',
          '0.95',
          '0.95',
          '1.19',
          '1.43',
          '1.19',
          '1.19',
          '0.95',
          '0.95',
          '0.95',
        ],
        'Budgeted Vacancy': [
          '2.14',
          '2.14',
          '2.14',
          '2.14',
          '2.00',
          '2.00',
          '2.00',
          '2.00',
          '1.86',
          '2.14',
          '2.14',
          '2.29',
          '2.43',
          '2.57',
          '2.29',
          '2.29',
          '2.14',
          '2.00',
          '1.86',
          '1.86',
          '1.71',
          '1.71',
          '1.57',
          '1.43',
          '1.43',
          '1.29',
          '1.29',
          '1.29',
          '1.14',
          '1.00',
          '1.00',
          '1.00',
          '1.00',
          '0.86',
          '0.86',
          '0.86',
          '0.86',
          '0.86',
          '0.86',
          '0.71',
          '0.71',
          '0.71',
          '0.71',
          '0.71',
          '0.57',
          '0.57',
          '0.57',
          '0.57',
          '0.57',
          '0.57',
          '0.57',
          '0.57',
          '0.57',
          '0.71',
          '0.86',
          '0.71',
          '0.71',
          '0.57',
          '0.57',
          '0.57',
        ],
        'Contacts Made': [
          '286',
          '286',
          '286',
          '286',
          '274',
          '274',
          '274',
          '274',
          '263',
          '286',
          '297',
          '309',
          '331',
          '343',
          '320',
          '309',
          '297',
          '274',
          '263',
          '251',
          '240',
          '229',
          '217',
          '206',
          '194',
          '183',
          '171',
          '171',
          '160',
          '149',
          '149',
          '137',
          '137',
          '126',
          '126',
          '126',
          '126',
          '114',
          '114',
          '103',
          '103',
          '103',
          '103',
          '103',
          '91',
          '91',
          '91',
          '91',
          '80',
          '80',
          '80',
          '80',
          '91',
          '103',
          '114',
          '103',
          '103',
          '91',
          '91',
          '80',
        ],
        'No shows': [
          '12',
          '12',
          '12',
          '12',
          '11',
          '11',
          '11',
          '11',
          '11',
          '12',
          '12',
          '13',
          '14',
          '14',
          '13',
          '13',
          '12',
          '11',
          '11',
          '10',
          '10',
          '9',
          '9',
          '8',
          '8',
          '7',
          '7',
          '7',
          '6',
          '6',
          '6',
          '5',
          '5',
          '5',
          '5',
          '5',
          '5',
          '4',
          '4',
          '4',
          '4',
          '4',
          '4',
          '4',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '4',
          '3',
          '3',
          '3',
          '3',
          '3',
        ],
        '# of units': [
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
        ],
        'Renewal Rate (%)': [
          '72',
          '76',
          '79',
          '80',
          '81',
          '82',
          '83',
          '83',
          '84',
          '85',
          '84',
          '83',
          '83',
          '82',
          '81',
          '80',
          '79',
          '79',
          '78',
          '77',
          '76',
          '78',
          '81',
          '83',
          '86',
          '88',
          '91',
          '94',
          '94',
          '94',
          '94',
          '94',
          '94',
          '94',
          '94',
          '94',
          '95',
          '95',
          '95',
          '95',
          '95',
          '95',
          '95',
          '95',
          '95',
          '95',
          '96',
          '96',
          '96',
          '96',
          '96',
          '96',
          '96',
          '96',
          '96',
          '96',
          '96',
          '97',
          '97',
          '97',
        ],
        'Net Eff Rent per unit': [
          '3256.00',
          '3257.00',
          '3258.00',
          '3259.00',
          '3260.00',
          '3261.00',
          '3262.00',
          '3263.00',
          '3264.00',
          '3265.00',
          '3266.00',
          '3267.00',
          '3268.00',
          '3269.00',
          '3270.00',
          '3271.00',
          '3272.00',
          '3273.00',
          '3274.00',
          '3275.00',
          '3276.00',
          '3277.00',
          '3278.00',
          '3279.00',
          '3280.00',
          '3281.00',
          '3282.00',
          '3283.00',
          '3284.00',
          '3285.00',
          '3286.00',
          '3287.00',
          '3288.00',
          '3289.00',
          '3290.00',
          '3291.00',
          '3292.00',
          '3293.00',
          '3294.00',
          '3295.00',
          '3296.00',
          '3297.00',
          '3298.00',
          '3299.00',
          '3300.00',
          '3301.00',
          '3302.00',
          '3303.00',
          '3304.00',
          '3305.00',
          '3306.00',
          '3307.00',
          '3308.00',
          '3309.00',
          '3310.00',
          '3311.00',
          '3312.00',
          '3313.00',
          '3314.00',
          '3315.00',
        ],
        'Conversion rate': [
          '2.0',
          '2.0',
          '2.0',
          '2.0',
          '2.0',
          '2.0',
          '2.0',
          '2.0',
          '2.1',
          '2.0',
          '1.9',
          '2.1',
          '1.9',
          '2.1',
          '2.0',
          '2.1',
          '1.9',
          '2.0',
          '2.1',
          '1.9',
          '2.0',
          '2.1',
          '1.8',
          '1.9',
          '2.1',
          '2.2',
          '1.9',
          '1.9',
          '2.0',
          '2.2',
          '2.2',
          '1.8',
          '1.8',
          '1.9',
          '1.9',
          '1.9',
          '1.9',
          '2.1',
          '2.1',
          '2.3',
          '2.3',
          '2.3',
          '2.3',
          '2.3',
          '1.8',
          '1.8',
          '1.8',
          '1.8',
          '2.0',
          '2.0',
          '2.0',
          '2.0',
          '1.8',
          '2.3',
          '2.1',
          '2.3',
          '2.3',
          '1.8',
          '1.8',
          '2.0',
        ],
        'Lost reasons': {
          'Competition has better price': [
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
          ],
          'Location doesn\u2019t work': [
            '1',
            '2',
            '1',
            '3',
            '3',
            '3',
            '1',
            '2',
            '1',
            '3',
            '2',
            '1',
            '2',
            '1',
            '3',
            '1',
            '2',
            '3',
            '3',
            '3',
            '1',
            '2',
            '1',
            '2',
            '3',
            '2',
            '3',
            '1',
            '3',
            '3',
            '1',
            '1',
            '2',
            '2',
            '2',
            '3',
            '3',
            '3',
            '2',
            '3',
            '2',
            '1',
            '2',
            '3',
            '1',
            '3',
            '2',
            '1',
            '3',
            '1',
            '2',
            '3',
            '3',
            '3',
            '3',
            '2',
            '3',
            '3',
            '2',
            '2',
          ],
          'Amenities are lacking': [
            '2',
            '1',
            '3',
            '1',
            '1',
            '2',
            '2',
            '1',
            '1',
            '1',
            '2',
            '2',
            '1',
            '3',
            '1',
            '3',
            '1',
            '3',
            '3',
            '1',
            '2',
            '3',
            '3',
            '3',
            '3',
            '1',
            '3',
            '3',
            '2',
            '1',
            '1',
            '1',
            '3',
            '1',
            '2',
            '3',
            '3',
            '2',
            '3',
            '1',
            '2',
            '2',
            '1',
            '2',
            '3',
            '3',
            '2',
            '2',
            '3',
            '2',
            '3',
            '2',
            '3',
            '1',
            '3',
            '1',
            '2',
            '1',
            '2',
            '2',
          ],
          'Bad resident historty/credit': [
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
          ],
          'Unit in poor condition': [
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
          ],
          'Change of plans': [
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
          ],
          Unresponsive: [
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
          ],
        },
      },
    },
    'Portfolio-Toronto': {
      'Bridge Condos': {
        Leads: [
          '143',
          '143',
          '143',
          '143',
          '143',
          '143',
          '143',
          '143',
          '129',
          '129',
          '114',
          '114',
          '125',
          '167',
          '167',
          '143',
          '143',
          '214',
          '179',
          '214',
          '214',
          '214',
          '250',
          '250',
          '167',
          '190',
          '190',
          '214',
          '214',
          '214',
          '214',
          '214',
          '214',
          '214',
          '214',
          '238',
          '143',
          '143',
          '143',
          '143',
          '143',
          '143',
          '143',
          '129',
          '129',
          '129',
          '129',
          '114',
          '100',
          '82',
          '82',
          '69',
          '69',
          '55',
          '55',
          '41',
          '41',
          '41',
          '27',
          '27',
        ],
        Showings: [
          '33',
          '33',
          '33',
          '33',
          '33',
          '33',
          '33',
          '33',
          '30',
          '30',
          '26',
          '26',
          '29',
          '38',
          '38',
          '33',
          '33',
          '32',
          '27',
          '32',
          '32',
          '32',
          '38',
          '38',
          '25',
          '29',
          '29',
          '32',
          '32',
          '32',
          '32',
          '32',
          '32',
          '32',
          '32',
          '36',
          '21',
          '21',
          '21',
          '21',
          '21',
          '36',
          '36',
          '32',
          '32',
          '32',
          '32',
          '29',
          '25',
          '21',
          '21',
          '17',
          '19',
          '15',
          '15',
          '12',
          '12',
          '12',
          '8',
          '8',
        ],
        'App. Requested': [
          '7',
          '7',
          '7',
          '7',
          '7',
          '7',
          '7',
          '7',
          '6',
          '6',
          '5',
          '5',
          '6',
          '8',
          '8',
          '7',
          '7',
          '4',
          '4',
          '4',
          '4',
          '2',
          '3',
          '3',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '8',
          '4',
          '4',
          '4',
          '4',
          '5',
          '5',
          '5',
          '5',
          '5',
          '5',
          '5',
          '4',
          '4',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '1',
          '1',
          '1',
          '1',
          '1',
        ],
        Approved: [
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '2',
          '2',
          '3',
          '3',
          '3',
          '3',
          '3',
          '4',
          '4',
          '4',
          '4',
          '2',
          '3',
          '3',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '7',
          '4',
          '4',
          '4',
          '4',
          '4',
          '4',
          '4',
          '4',
          '4',
          '4',
          '4',
          '3',
          '3',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '1',
          '1',
          '1',
          '1',
          '1',
        ],
        'Lease velocity': [
          '42',
          '25',
          '15',
          '43',
          '35',
          '35',
          '36',
          '35',
          '30',
          '27',
          '32',
          '26',
          '34',
          '33',
          '18',
          '42',
          '31',
          '20',
          '28',
          '44',
          '26',
          '41',
          '19',
          '39',
          '32',
          '16',
          '33',
          '35',
          '24',
          '43',
          '42',
          '41',
          '22',
          '41',
          '15',
          '29',
          '17',
          '26',
          '41',
          '44',
          '16',
          '21',
          '31',
          '35',
          '45',
          '35',
          '38',
          '15',
          '31',
          '16',
          '32',
          '17',
          '18',
          '45',
          '18',
          '25',
          '25',
          '18',
          '25',
          '36',
        ],
        'Available Units': [
          '7',
          '7',
          '7',
          '7',
          '7',
          '7',
          '7',
          '7',
          '6',
          '6',
          '6',
          '6',
          '5',
          '5',
          '5',
          '4',
          '4',
          '4',
          '3',
          '4',
          '4',
          '4',
          '5',
          '5',
          '5',
          '6',
          '6',
          '6',
          '6',
          '6',
          '6',
          '6',
          '6',
          '6',
          '6',
          '8',
          '8',
          '6',
          '6',
          '6',
          '6',
          '6',
          '6',
          '5',
          '5',
          '5',
          '5',
          '4',
          '4',
          '3',
          '3',
          '3',
          '3',
          '2',
          '2',
          '1',
          '1',
          '1',
          '1',
          '1',
        ],
        'Vacancy (%)': [
          '3.11',
          '3.11',
          '3.11',
          '3.11',
          '3.11',
          '3.11',
          '3.11',
          '3.11',
          '2.67',
          '2.67',
          '2.67',
          '2.67',
          '2.22',
          '2.22',
          '2.22',
          '1.78',
          '1.78',
          '1.78',
          '1.33',
          '1.78',
          '1.78',
          '1.78',
          '2.22',
          '2.22',
          '2.22',
          '2.67',
          '2.67',
          '2.67',
          '2.67',
          '2.67',
          '2.67',
          '2.67',
          '2.67',
          '2.67',
          '2.67',
          '3.56',
          '3.56',
          '2.67',
          '2.67',
          '2.67',
          '2.67',
          '2.67',
          '2.67',
          '2.22',
          '2.22',
          '2.22',
          '2.22',
          '1.78',
          '1.78',
          '1.33',
          '1.33',
          '1.33',
          '1.33',
          '0.89',
          '0.89',
          '0.44',
          '0.44',
          '0.44',
          '0.44',
          '0.44',
        ],
        'Budgeted Vacancy': [
          '1.24',
          '1.24',
          '1.24',
          '1.24',
          '1.24',
          '1.24',
          '1.24',
          '1.24',
          '1.07',
          '1.07',
          '1.07',
          '1.07',
          '0.89',
          '0.89',
          '0.89',
          '0.71',
          '1.96',
          '1.96',
          '1.47',
          '1.96',
          '1.96',
          '1.96',
          '2.44',
          '2.44',
          '2.44',
          '2.93',
          '2.93',
          '2.93',
          '2.93',
          '2.93',
          '2.93',
          '2.93',
          '2.93',
          '2.13',
          '2.13',
          '2.84',
          '2.84',
          '2.13',
          '2.13',
          '2.13',
          '2.13',
          '2.13',
          '1.60',
          '1.33',
          '1.33',
          '1.33',
          '1.33',
          '1.07',
          '1.07',
          '1.47',
          '1.47',
          '1.47',
          '1.47',
          '0.98',
          '0.98',
          '0.49',
          '0.49',
          '0.49',
          '0.49',
          '0.49',
        ],
        'Contacts Made': [
          '114',
          '114',
          '114',
          '114',
          '114',
          '114',
          '114',
          '114',
          '103',
          '103',
          '91',
          '91',
          '100',
          '117',
          '117',
          '100',
          '100',
          '150',
          '125',
          '150',
          '150',
          '150',
          '175',
          '175',
          '117',
          '133',
          '133',
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '193',
          '193',
          '214',
          '129',
          '129',
          '129',
          '129',
          '129',
          '129',
          '129',
          '116',
          '116',
          '116',
          '116',
          '103',
          '90',
          '74',
          '74',
          '62',
          '62',
          '49',
          '49',
          '37',
          '37',
          '37',
          '25',
          '25',
        ],
        'No shows': [
          '4',
          '4',
          '4',
          '4',
          '4',
          '4',
          '4',
          '4',
          '4',
          '4',
          '3',
          '3',
          '4',
          '5',
          '5',
          '4',
          '4',
          '4',
          '4',
          '8',
          '8',
          '8',
          '9',
          '9',
          '6',
          '7',
          '7',
          '8',
          '8',
          '8',
          '8',
          '8',
          '8',
          '8',
          '8',
          '8',
          '5',
          '5',
          '5',
          '5',
          '3',
          '5',
          '5',
          '4',
          '4',
          '4',
          '4',
          '4',
          '3',
          '3',
          '3',
          '2',
          '2',
          '2',
          '2',
          '1',
          '1',
          '1',
          '1',
          '1',
        ],
        '# of units': [
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
        ],
        'Renewal Rate (%)': [
          '65',
          '68',
          '72',
          '72',
          '73',
          '74',
          '75',
          '75',
          '76',
          '77',
          '76',
          '75',
          '75',
          '74',
          '73',
          '72',
          '72',
          '71',
          '70',
          '69',
          '69',
          '68',
          '67',
          '67',
          '66',
          '65',
          '65',
          '64',
          '63',
          '64',
          '65',
          '65',
          '66',
          '67',
          '67',
          '68',
          '69',
          '69',
          '70',
          '71',
          '72',
          '72',
          '73',
          '74',
          '74',
          '75',
          '76',
          '77',
          '77',
          '78',
          '79',
          '81',
          '82',
          '84',
          '86',
          '87',
          '89',
          '91',
          '93',
          '96',
        ],
        'Net Eff Rent per unit': [
          '2375',
          '2386.88',
          '2398.81',
          '2410.8',
          '2422.86',
          '2434.97',
          '2447.15',
          '2459.38',
          '2471.68',
          '2484.04',
          '2496.46',
          '2508.94',
          '2521.48',
          '2534.09',
          '2546.76',
          '2559.5',
          '2556.94',
          '2554.38',
          '2551.83',
          '2549.27',
          '2546.72',
          '2544.18',
          '2541.63',
          '2539.09',
          '2536.55',
          '2534.02',
          '2531.48',
          '2528.95',
          '2526.42',
          '2523.9',
          '2521.37',
          '2518.85',
          '2516.33',
          '2513.82',
          '2511.3',
          '2508.79',
          '2506.28',
          '2503.77',
          '2501.27',
          '2498.77',
          '2496.27',
          '2493.77',
          '2491.28',
          '2488.79',
          '2491.28',
          '2493.77',
          '2496.26',
          '2498.76',
          '2506.26',
          '2513.77',
          '2521.32',
          '2528.88',
          '2536.47',
          '2544.08',
          '2551.71',
          '2559.36',
          '2567.04',
          '2574.74',
          '2582.47',
          '2590.21',
        ],
        'Conversion rate': [
          '2.10',
          '2.10',
          '2.10',
          '2.10',
          '2.10',
          '2.10',
          '2.10',
          '2.10',
          '2.30',
          '2.30',
          '1.80',
          '1.80',
          '2.40',
          '1.80',
          '1.80',
          '2.10',
          '2.10',
          '1.90',
          '2.20',
          '1.90',
          '1.90',
          '0.90',
          '1.20',
          '1.20',
          '1.20',
          '1.10',
          '1.10',
          '0.90',
          '0.90',
          '0.90',
          '0.90',
          '0.90',
          '0.90',
          '0.90',
          '0.90',
          '2.90',
          '2.80',
          '2.80',
          '2.80',
          '2.80',
          '2.80',
          '2.80',
          '2.80',
          '3.10',
          '3.10',
          '3.10',
          '3.10',
          '2.60',
          '3.00',
          '2.40',
          '2.40',
          '2.90',
          '2.90',
          '3.60',
          '3.60',
          '2.40',
          '2.40',
          '2.40',
          '3.60',
          '3.60',
        ],
        'Lost reasons': {
          'Competition has better price': [
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '4',
            '4',
            '4',
            '5',
            '5',
            '5',
            '5',
            '5',
            '7',
            '7',
            '7',
            '8',
            '8',
            '8',
            '8',
            '8',
            '7',
            '6',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '4',
            '3',
            '2',
            '1',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
          ],
          'Location doesn\u2019t work': [
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
          ],
          'Amenities are lacking': [
            '5',
            '4',
            '3',
            '2',
            '1',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
          ],
          'Bad resident historty/credit': [
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '2',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
          ],
          'Unit in poor condition': [
            '0',
            '0',
            '1',
            '2',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
          ],
          'Change of plans': [
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
          ],
          Unresponsive: [
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
            '5',
          ],
        },
      },
      'Liberty Towers': {
        Leads: [
          '257',
          '257',
          '257',
          '257',
          '243',
          '243',
          '243',
          '243',
          '229',
          '214',
          '214',
          '200',
          '186',
          '171',
          '171',
          '157',
          '157',
          '143',
          '143',
          '143',
          '157',
          '157',
          '171',
          '171',
          '186',
          '200',
          '200',
          '214',
          '229',
          '229',
          '229',
          '229',
          '229',
          '243',
          '243',
          '243',
          '243',
          '243',
          '243',
          '257',
          '257',
          '257',
          '243',
          '243',
          '229',
          '229',
          '229',
          '200',
          '186',
          '157',
          '143',
          '129',
          '114',
          '100',
          '100',
          '86',
          '71',
          '71',
          '57',
          '57',
        ],
        Showings: [
          '59',
          '59',
          '59',
          '59',
          '56',
          '56',
          '56',
          '56',
          '53',
          '49',
          '49',
          '46',
          '43',
          '39',
          '39',
          '36',
          '36',
          '33',
          '33',
          '33',
          '36',
          '36',
          '39',
          '39',
          '43',
          '46',
          '46',
          '49',
          '53',
          '53',
          '53',
          '53',
          '53',
          '56',
          '56',
          '56',
          '56',
          '56',
          '56',
          '59',
          '59',
          '59',
          '56',
          '56',
          '53',
          '53',
          '53',
          '46',
          '43',
          '36',
          '33',
          '30',
          '26',
          '23',
          '23',
          '20',
          '16',
          '16',
          '13',
          '13',
        ],
        'App. Requested': [
          '12',
          '12',
          '12',
          '12',
          '11',
          '11',
          '11',
          '11',
          '11',
          '10',
          '10',
          '9',
          '9',
          '8',
          '8',
          '7',
          '7',
          '7',
          '7',
          '7',
          '7',
          '7',
          '8',
          '8',
          '9',
          '9',
          '9',
          '10',
          '11',
          '11',
          '11',
          '11',
          '11',
          '11',
          '11',
          '11',
          '11',
          '11',
          '11',
          '12',
          '12',
          '12',
          '11',
          '11',
          '11',
          '11',
          '11',
          '9',
          '9',
          '7',
          '7',
          '6',
          '5',
          '5',
          '5',
          '4',
          '3',
          '3',
          '3',
          '3',
        ],
        Approved: [
          '5',
          '5',
          '5',
          '5',
          '5',
          '5',
          '5',
          '5',
          '5',
          '4',
          '4',
          '4',
          '4',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '4',
          '4',
          '4',
          '4',
          '5',
          '5',
          '5',
          '5',
          '5',
          '5',
          '5',
          '5',
          '5',
          '5',
          '5',
          '5',
          '5',
          '5',
          '5',
          '5',
          '5',
          '5',
          '5',
          '4',
          '4',
          '3',
          '3',
          '3',
          '2',
          '2',
          '2',
          '2',
          '1',
          '1',
          '1',
          '1',
        ],
        'Lease velocity': [
          '31',
          '29',
          '42',
          '35',
          '28',
          '15',
          '35',
          '15',
          '25',
          '38',
          '38',
          '26',
          '16',
          '34',
          '41',
          '19',
          '16',
          '34',
          '37',
          '29',
          '38',
          '17',
          '15',
          '16',
          '26',
          '40',
          '15',
          '40',
          '43',
          '32',
          '40',
          '43',
          '42',
          '22',
          '42',
          '39',
          '31',
          '22',
          '18',
          '25',
          '41',
          '27',
          '15',
          '37',
          '39',
          '25',
          '27',
          '19',
          '35',
          '35',
          '40',
          '35',
          '25',
          '31',
          '23',
          '20',
          '17',
          '42',
          '18',
          '20',
        ],
        'Available Units': [
          '11',
          '11',
          '11',
          '11',
          '11',
          '11',
          '11',
          '11',
          '10',
          '9',
          '9',
          '9',
          '8',
          '7',
          '7',
          '7',
          '7',
          '6',
          '6',
          '6',
          '6',
          '6',
          '7',
          '7',
          '7',
          '8',
          '8',
          '9',
          '9',
          '9',
          '9',
          '9',
          '9',
          '10',
          '10',
          '10',
          '10',
          '10',
          '10',
          '10',
          '10',
          '10',
          '10',
          '10',
          '9',
          '9',
          '9',
          '8',
          '7',
          '6',
          '6',
          '5',
          '4',
          '4',
          '4',
          '3',
          '3',
          '3',
          '2',
          '2',
        ],
        'Vacancy (%)': [
          '3.33',
          '3.33',
          '3.33',
          '3.33',
          '3.33',
          '3.33',
          '3.33',
          '3.33',
          '3.03',
          '2.73',
          '2.73',
          '2.73',
          '2.42',
          '2.12',
          '2.12',
          '2.12',
          '2.12',
          '1.82',
          '1.82',
          '1.82',
          '1.82',
          '1.82',
          '2.12',
          '2.12',
          '2.12',
          '2.42',
          '2.42',
          '2.73',
          '2.73',
          '2.73',
          '2.73',
          '2.73',
          '2.73',
          '3.03',
          '3.03',
          '3.03',
          '3.03',
          '3.03',
          '3.03',
          '3.03',
          '3.03',
          '3.03',
          '3.03',
          '3.03',
          '2.73',
          '2.73',
          '2.73',
          '2.42',
          '2.12',
          '1.82',
          '1.82',
          '1.52',
          '1.21',
          '1.21',
          '1.21',
          '0.91',
          '0.91',
          '0.91',
          '0.61',
          '0.61',
        ],
        'Budgeted Vacancy': [
          '1.67',
          '1.67',
          '1.67',
          '1.67',
          '1.67',
          '1.67',
          '1.67',
          '1.67',
          '1.52',
          '1.36',
          '1.36',
          '1.36',
          '1.21',
          '1.06',
          '1.06',
          '1.27',
          '2.76',
          '2.36',
          '2.73',
          '2.73',
          '2.73',
          '2.73',
          '3.18',
          '3.18',
          '3.18',
          '3.15',
          '3.15',
          '3.55',
          '2.86',
          '2.86',
          '1.64',
          '1.64',
          '1.64',
          '1.82',
          '1.82',
          '1.82',
          '1.82',
          '1.82',
          '1.82',
          '1.82',
          '1.82',
          '1.82',
          '1.82',
          '1.82',
          '1.64',
          '1.64',
          '1.64',
          '1.45',
          '1.27',
          '1.09',
          '1.09',
          '0.91',
          '0.73',
          '0.73',
          '0.73',
          '0.55',
          '0.55',
          '0.55',
          '0.36',
          '0.36',
        ],
        'Contacts Made': [
          '206',
          '206',
          '206',
          '206',
          '194',
          '194',
          '194',
          '194',
          '183',
          '171',
          '171',
          '160',
          '149',
          '137',
          '137',
          '126',
          '126',
          '114',
          '114',
          '114',
          '126',
          '126',
          '137',
          '137',
          '149',
          '160',
          '160',
          '171',
          '183',
          '183',
          '183',
          '183',
          '183',
          '194',
          '194',
          '194',
          '194',
          '194',
          '194',
          '206',
          '206',
          '206',
          '194',
          '194',
          '183',
          '183',
          '183',
          '160',
          '149',
          '110',
          '100',
          '90',
          '80',
          '70',
          '70',
          '60',
          '50',
          '50',
          '40',
          '40',
        ],
        'No shows': [
          '8',
          '8',
          '8',
          '8',
          '8',
          '8',
          '8',
          '8',
          '7',
          '7',
          '7',
          '6',
          '6',
          '5',
          '5',
          '5',
          '5',
          '4',
          '4',
          '4',
          '5',
          '5',
          '5',
          '5',
          '6',
          '6',
          '6',
          '7',
          '13',
          '13',
          '13',
          '13',
          '13',
          '13',
          '13',
          '13',
          '13',
          '8',
          '8',
          '8',
          '8',
          '8',
          '8',
          '8',
          '7',
          '7',
          '7',
          '6',
          '6',
          '5',
          '4',
          '4',
          '3',
          '3',
          '3',
          '2',
          '2',
          '2',
          '1',
          '1',
        ],
        '# of units': [
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
        ],
        'Renewal Rate (%)': [
          '65',
          '68',
          '72',
          '72',
          '73',
          '74',
          '75',
          '75',
          '76',
          '77',
          '76',
          '75',
          '75',
          '74',
          '73',
          '72',
          '72',
          '71',
          '70',
          '69',
          '69',
          '68',
          '67',
          '67',
          '66',
          '65',
          '65',
          '64',
          '63',
          '64',
          '65',
          '65',
          '66',
          '67',
          '67',
          '68',
          '69',
          '69',
          '70',
          '71',
          '72',
          '72',
          '73',
          '74',
          '74',
          '75',
          '76',
          '77',
          '77',
          '78',
          '79',
          '81',
          '82',
          '83',
          '84',
          '85',
          '86',
          '86',
          '87',
          '88',
        ],
        'Net Eff Rent per unit': [
          '2155',
          '2165.78',
          '2176.6',
          '2187.49',
          '2198.42',
          '2209.42',
          '2220.46',
          '2231.57',
          '2242.72',
          '2253.94',
          '2265.21',
          '2276.53',
          '2287.92',
          '2299.36',
          '2310.85',
          '2322.41',
          '2320.08',
          '2317.76',
          '2315.45',
          '2313.13',
          '2310.82',
          '2308.51',
          '2306.2',
          '2303.89',
          '2301.59',
          '2299.29',
          '2296.99',
          '2294.69',
          '2292.4',
          '2290.1',
          '2287.81',
          '2285.53',
          '2283.24',
          '2280.96',
          '2278.68',
          '2276.4',
          '2274.12',
          '2271.85',
          '2269.57',
          '2267.3',
          '2265.04',
          '2262.77',
          '2260.51',
          '2258.25',
          '2260.51',
          '2262.77',
          '2265.03',
          '2267.3',
          '2274.1',
          '2280.92',
          '2287.76',
          '2294.63',
          '2301.51',
          '2308.41',
          '2315.34',
          '2322.29',
          '2329.25',
          '2336.24',
          '2343.25',
          '2350.28',
        ],
        'Conversion rate': [
          '1.90',
          '1.90',
          '1.90',
          '1.90',
          '2.10',
          '2.10',
          '2.10',
          '2.10',
          '2.20',
          '1.90',
          '1.90',
          '2.00',
          '2.20',
          '1.80',
          '1.80',
          '1.90',
          '1.90',
          '2.10',
          '2.10',
          '2.10',
          '1.90',
          '1.90',
          '1.80',
          '1.80',
          '2.20',
          '2.00',
          '2.00',
          '1.90',
          '2.20',
          '2.20',
          '2.20',
          '2.20',
          '2.20',
          '2.10',
          '2.10',
          '2.10',
          '2.10',
          '2.10',
          '2.10',
          '1.90',
          '1.90',
          '1.90',
          '2.10',
          '2.10',
          '2.20',
          '2.20',
          '2.20',
          '2.00',
          '2.20',
          '1.90',
          '2.10',
          '2.30',
          '1.80',
          '2.00',
          '2.00',
          '2.30',
          '1.40',
          '1.40',
          '1.80',
          '1.80',
        ],
        'Lost reasons': {
          'Competition has better price': [
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
          ],
          'Location doesn\u2019t work': [
            '3',
            '2',
            '3',
            '2',
            '2',
            '2',
            '1',
            '3',
            '1',
            '1',
            '3',
            '1',
            '1',
            '2',
            '3',
            '3',
            '3',
            '3',
            '2',
            '3',
            '3',
            '3',
            '2',
            '3',
            '2',
            '2',
            '1',
            '3',
            '2',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '3',
            '3',
            '2',
            '2',
            '2',
            '2',
            '3',
            '1',
            '1',
            '2',
            '1',
            '3',
            '3',
            '1',
            '1',
            '2',
            '1',
            '1',
            '3',
            '2',
            '3',
            '1',
            '3',
            '1',
          ],
          'Amenities are lacking': [
            '3',
            '1',
            '1',
            '2',
            '1',
            '3',
            '3',
            '3',
            '1',
            '2',
            '1',
            '3',
            '3',
            '3',
            '1',
            '3',
            '1',
            '3',
            '2',
            '1',
            '2',
            '2',
            '1',
            '2',
            '1',
            '2',
            '2',
            '2',
            '1',
            '1',
            '3',
            '2',
            '2',
            '3',
            '2',
            '3',
            '1',
            '3',
            '3',
            '2',
            '2',
            '2',
            '2',
            '1',
            '3',
            '3',
            '3',
            '3',
            '1',
            '3',
            '2',
            '2',
            '1',
            '1',
            '2',
            '1',
            '3',
            '2',
            '3',
            '3',
          ],
          'Bad resident historty/credit': [
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
          ],
          'Unit in poor condition': [
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
          ],
          'Change of plans': [
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
          ],
          Unresponsive: [
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
            '3',
          ],
        },
      },
    },
  };
  