import React, { useState } from 'react';
import {
  Alert,
  Modal,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  SelectPicker,
  CheckPicker,
  Input,
  InputNumber,
  DatePicker,
  Button,
} from 'rsuite';
import { format } from 'date-fns';
import { addDays, isBefore } from 'date-fns'; // Import date-fns functions
import { UserApi } from 'services/api/user';
import Editor from 'containers/List-Property/steps/EditorCus';
import Ajv from 'ajv';
import addFormats from 'ajv-formats';

// Initialize AJV
const ajv = new Ajv.default({ allErrors: true });
addFormats(ajv);

const CampaignModal = ({ show, onClose }) => {
  const [formErrors, setFormErrors] = useState({});
  const [targetAudience, setTargetAudience] = useState([]);
  const [loading, setLoading] = useState(false);
  const [campaignData, setCampaignData] = useState({
    campaign_type: null,
    start_date: null,
    end_date: null,
    form_type: 'External',
    // form_id: null,
    url: null,
    message: null,
    title: null,
    cta: null,
    audience_type: null,
    audience: [],
    manager: null,
  });

  const validate = () => {
    const validateSchema = ajv.compile({
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        campaign_type: {
          type: 'string',
        },
        // Add minLength property to other fields that should not be empty
        start_date: {
          type: 'string',
          format: 'date',
        },
        end_date: {
          type: 'string',
          format: 'date-time',
        },
        url: {
          anyOf: [
            { type: 'string', format: 'uri' },
            { type: 'null' }, // Allow null for "url"
          ],
        },
        message: {
          type: 'string',
        },
        title: {
          type: 'string',
        },
        cta: {
          type: 'string',
        },
        audience_type: {
          type: 'string',
        },
        audience: {
          type: 'array',
          items: {
            type: 'string',
          },
        },
        manager: {
          type: 'string',
        },
      },
      required: ['campaign_type', 'start_date', 'end_date', 'message', 'title', 'audience_type', 'manager'],
    });

    // Rest of your validation logic remains the same

    const valid = validateSchema(campaignData);
    if (!valid) {
      const errors = validateSchema.errors;
      console.log(errors);
      setFormErrors(errors);
      setLoading(false);
      return false;
    }
    return true;
  };

  // Function to handle changes in the "Audience Type" dropdown
  const handleAudienceTypeChange = value => {
    if (value === 'Organisations' || value === 'Users') {
      UserApi.getCampaignUsers(value).then(res => {
        console.log(res.data);
        setTargetAudience(res.data);
      });
    }
    setCampaignData({
      ...campaignData,
      audience_type: value,
      audience: [],
    }); // Clear the audience when changing the audience type
  };

  // Function to handle changes in the "Features" dropdown
  const handleAudienceChange = value => {
    setCampaignData({ ...campaignData, audience: [value] });
  };

  const handleFieldChange = (name, value) => {
    if (name === 'start_date') {
      value = format(value, 'yyyy-MM-dd');
    }
    if (name === 'end_date') {
      value = format(value, 'yyyy-MM-dd');
      value = `${value}T23:59:59`;
    }
    setCampaignData({ ...campaignData, [name]: value });
  };

  const handleSave = () => {
    setLoading(true);
    if (validate()) {
      UserApi.createCampaign(campaignData).then(res => {
        Alert.success('Success! New campaign has been created.');
        console.log(res.data);
        setLoading(false);
        onClose();
      });
    }
  };

  const renderFormErrors = () => {
    return formErrors.map((error, index) => (
      <p key={index} className="error-message text-red-500 bg-red-100 p-2 rounded-md">
        {error.instancePath.split('/').pop()}: {error.message}
      </p>
    ));
  };

  return (
    <Modal show={show} onHide={onClose} backdrop={false}>
      <Modal.Header>
        <Modal.Title>Create Campaign</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <FormGroup>
            <ControlLabel>Campaign Type</ControlLabel>
            <SelectPicker
              name="campaign_type"
              data={[
                { label: 'Banner', value: 'Banner' },
                { label: 'Dropdown', value: 'Dropdown' },
                { label: 'Popup', value: 'Popup' },
                { label: 'Email Campaign', value: 'Email' },
              ]}
              style={{ width: 224 }}
              placeholder="Select a Campaign Format"
              labelKey="label"
              valueKey="value"
              onChange={value => handleFieldChange('campaign_type', value)}
            />
          </FormGroup>

          <FormGroup>
            <ControlLabel>Audience Type</ControlLabel>
            <SelectPicker
              name="audience_type"
              data={[
                { label: 'All Landlords', value: 'All' },
                { label: 'Specific Landlords', value: 'Users' },
                { label: 'By Organisations', value: 'Organisations' },
                { label: 'By Features', value: 'Features' },
              ]}
              style={{ width: 224 }}
              placeholder="Select an Audience"
              labelKey="label"
              valueKey="value"
              onChange={handleAudienceTypeChange}
            />
          </FormGroup>

          {campaignData.audience_type === 'Features' && (
            <FormGroup>
              <ControlLabel>Select a Feature</ControlLabel>
              <SelectPicker
                name="selectedFeature"
                data={[
                  'engage',
                  'convert',
                  'dashboard',
                  'analytics-plus',
                  'sage+',
                  'properties',
                  'application-module',
                  'price',
                  'tickets',
                ].map(feature => ({
                  label: feature,
                  value: feature,
                }))}
                style={{ width: 224 }}
                placeholder="Select a Feature"
                onChange={handleAudienceChange}
                multiple={true}
              />
            </FormGroup>
          )}

          {campaignData.audience_type && ['Organisations', 'Users'].includes(campaignData.audience_type) && (
            <FormGroup>
              <ControlLabel>Select Accounts</ControlLabel>
              <CheckPicker
                name="selectedFeature"
                data={targetAudience}
                value={campaignData.audience}
                style={{ width: 320 }}
                placeholder="Select Target Accounts"
                onChange={value => setCampaignData({ ...campaignData, audience: value }) && console.log(value)}
                multiple={true}
              />
            </FormGroup>
          )}

          <FormGroup>
            <ControlLabel>Start Date</ControlLabel>
            <FormControl
              size="sm"
              oneTap
              name="start_date"
              style={{ width: '300px' }}
              format="YYYY-MM-DD"
              accepter={DatePicker}
              disabledDate={date => isBefore(date, addDays(new Date(), -1))}
              onChange={value => handleFieldChange('start_date', value)}></FormControl>
          </FormGroup>
          <FormGroup>
            <ControlLabel>End Date</ControlLabel>
            <FormControl
              size="sm"
              oneTap
              name="end_date"
              style={{ width: '300px' }}
              format="YYYY-MM-DD"
              accepter={DatePicker}
              disabledDate={date => isBefore(date, addDays(new Date(), 0))}
              onChange={value => handleFieldChange('end_date', value)}></FormControl>
          </FormGroup>
          {/* use this section to connect internal and external forms in the future */}
          {/* <FormGroup>
            <ControlLabel>Form Type</ControlLabel>
            <SelectPicker
              name="form_type"
              data={[
                { label: 'Internal Form', value: 'Internal' },
                { label: 'External Form', value: 'External' },
                { label: 'No Form', value: 'None' },
              ]}
              style={{ width: 224 }}
              placeholder="Select a form type"
              labelKey="label"
              valueKey="value"
              onChange={value => handleFieldChange('form_type', value)}
            />
          </FormGroup>
          {campaignData.form_type === 'Internal' && (
            <FormGroup>
              <ControlLabel>Form ID</ControlLabel>
              <Input name="form_id" onChange={value => handleFieldChange('form_id', value)} />
            </FormGroup>
          )} */}
          {campaignData.form_type === 'External' && campaignData.campaign_type === 'Popup' && (
            <FormGroup>
              <ControlLabel>External Form Link</ControlLabel>
              <Input name="url" onChange={value => handleFieldChange('url', value)} />
            </FormGroup>
          )}

          <FormGroup>
            <ControlLabel>Campaign Manager</ControlLabel>
            <SelectPicker
              name="manager"
              searchable={true}
              data={[
                { label: 'Gaurav', value: 'Gaurav' },
                { label: 'Mike', value: 'Mike' },
                { label: 'Kinda', value: 'Kinda' },
                { label: 'Arunabh', value: 'Arunabh' },
                { label: 'Maithili', value: 'Maithili' },
                { label: 'Leon', value: 'Leon' },
                { label: 'Dev Team', value: 'Dev Team' },
              ]}
              style={{ width: 224 }}
              placeholder="Select your name"
              labelKey="label"
              valueKey="value"
              onChange={value => handleFieldChange('manager', value)}
            />
          </FormGroup>

          <FormGroup>
            <ControlLabel>Title</ControlLabel>
            <Input name="title" onChange={value => handleFieldChange('title', value)} />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Button text</ControlLabel>
            <Input name="cta" onChange={value => handleFieldChange('cta', value)} />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Campaign Message</ControlLabel>
            <Editor currentValue={campaignData.message} onChange={value => handleFieldChange('message', value)} />
          </FormGroup>
          {Object.keys(formErrors).length > 0 && (
            <>
              <div className="error-container">{renderFormErrors()}</div>
              <button
                onClick={() => setFormErrors({})}
                className="bg-red-300  text-white font-semibold  px-3 py-2 mt-2 rounded">
                {' '}
                hide errors{' '}
              </button>
            </>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} appearance="primary">
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CampaignModal;
