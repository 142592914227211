import { Container, FlexboxGrid,Button } from 'rsuite';
import {
  ErrorBoundary as RollbarErrorBoundary
} from '@rollbar/react';
import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';



export default function DefaultErrorBoundary({ children,errorMessage, errorDescription, buttonLabel, onButtonClicked, imageUrl, failedOperation, customClassname, showDefaultErrorImage}) {
    function ErrorFallback({error, resetErrorBoundary}) {
        return (<div className={`flex flex-col max-w-xl w-full justify-center mx-auto items-center ${customClassname}`}>
        {(imageUrl || showDefaultErrorImage) && <img src={imageUrl?imageUrl:"/static/images/Oops! 404 Error with a broken robot-cuate.png" } className="max-w-xs "/>}
        <h1 className="text-xl my-8 font-bold">{errorMessage ?errorMessage :"Something went wrong."}</h1>
        <p>{errorDescription ?errorDescription :(failedOperation?`There was a problem ${failedOperation}. Please try again!`:'${error? error.toString():""}')}</p>
        {(onButtonClicked ||  buttonLabel || failedOperation) && (<div className="my-8"><Button appearance="primary" onClick={onButtonClicked ? onButtonClicked:() =>{window.location.reload(false)}}>{buttonLabel || `Retry ${failedOperation}`}</Button></div>)}
        </div>)
    }


    return (<RollbarErrorBoundary fallbackUI={ErrorFallback}  extra={(error, info) => info.componentStack.includes('Experimental') ? { experiment: true } : {} }>
    {children}
    </RollbarErrorBoundary>)
}


