import React from 'react';
import {
  Form,
  FormControl,
  Panel,
  DatePicker,
  FormGroup,
  Radio,
  RadioGroup,
  Schema,
  HelpBlock,
  Grid,
  InputGroup,
  Row,
  Col,
} from 'rsuite';
import styled from '@emotion/styled';

export const Model = Schema.Model({
  weekDayData: Schema.Types.ObjectType(),
  weekDayToBed: Schema.Types.ObjectType(),
  weekEndData: Schema.Types.ObjectType(),
  weekEndToBed: Schema.Types.ObjectType(),
  takeShowerOn: Schema.Types.StringType().isRequired('This field is required'),
  bothered: Schema.Types.StringType().isRequired('This field is required'),
});
const Step2Form = ({ roomieProfileData, setroomieProfileData, formErrors, setFormErrors, shadow, space }) => {
  const FormTagline = styled('h4')`
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 30px;
  `;

  // HANDLE TIME FOR WEEK DAY ... WAKE UP AND TO BED

  const handleWeekDayWakeUpFrom = data => {
    setroomieProfileData({
      ...roomieProfileData,
      weekDayData: { ...roomieProfileData.weekDayData, from: data.toLocaleString() },
    });
  };
  const handleWeekDayWakeUpTo = data => {
    setroomieProfileData({
      ...roomieProfileData,

      weekDayData: { ...roomieProfileData.weekDayData, to: data.toLocaleString() },
    });
  };

  const handleWeekDayToBedFrom = data => {
    setroomieProfileData({
      ...roomieProfileData,
      weekDayToBed: { ...roomieProfileData.weekDayToBed, from: data.toLocaleString() },
    });
  };
  const handleWeekDayToBedTo = data => {
    setroomieProfileData({
      ...roomieProfileData,

      weekDayToBed: { ...roomieProfileData.weekDayToBed, to: data.toLocaleString() },
    });
  };

  // HANDLE TIME FOR WEEK END ... WAKE UP AND TO BED

  const handleWeekEndWakeUpFrom = data => {
    setroomieProfileData({
      ...roomieProfileData,
      weekEndData: { ...roomieProfileData.weekEndData, from: data.toLocaleString() },
    });
  };
  const handleWeekEndWakeUpTo = data => {
    setroomieProfileData({
      ...roomieProfileData,

      weekEndData: { ...roomieProfileData.weekEndData, to: data.toLocaleString() },
    });
  };

  const handleWeekEndToBedFrom = data => {
    setroomieProfileData({
      ...roomieProfileData,
      weekEndToBed: { ...roomieProfileData.weekEndToBed, from: data.toLocaleString() },
    });
  };
  const handleWeekEndToBedTo = data => {
    setroomieProfileData({
      ...roomieProfileData,

      weekEndToBed: { ...roomieProfileData.weekEndToBed, to: data.toLocaleString() },
    });
  };

  return (
    <>
      {space ? (
        <FormTagline>
          On a <span className="text-decoration-underline">weekday</span> when do you typically...
        </FormTagline>
      ) : (
        <FormTagline className="mb-0">
          On a <span className="text-decoration-underline">weekday</span> when do you typically...
        </FormTagline>
      )}

      <Form
        checkTrigger="change"
        formValue={roomieProfileData}
        onCheck={setFormErrors}
        formError={formErrors}
        onChange={setroomieProfileData}
        model={Model}
        fluid>
        <Panel shaded={shadow ? false : true} style={{ overflow: 'inherit' }}>
          <FormGroup>
            <Grid fluid className="my-4">
              <Row className="show-grid d-flex align-items-center ">
                <Col
                  componentClass={Col}
                  //  md={customSize ? 5 : 4}
                  md={4}>
                  <HelpBlock>Wake Up?</HelpBlock>
                </Col>

                <Col md={17} mdOffset={2}>
                  <InputGroup className="w-100">
                    <DatePicker
                      oneTap
                      style={{ width: '50%' }}
                      onChange={value => {
                        handleWeekDayWakeUpFrom(value);
                      }}
                      format=" HH:mm:ss"
                      block
                      value={
                        roomieProfileData?.weekDayData?.from &&
                        new Date(roomieProfileData?.weekDayData?.from.toString())
                      }
                      showMeridian
                      appearance="default"
                    />
                    <InputGroup.Addon>-</InputGroup.Addon>
                    <DatePicker
                      oneTap
                      onChange={value => {
                        handleWeekDayWakeUpTo(value);
                      }}
                      style={{ width: '50%' }}
                      value={
                        roomieProfileData?.weekDayData?.to && new Date(roomieProfileData?.weekDayData?.to.toString())
                      }
                      format=" HH:mm:ss"
                      block
                      showMeridian
                      appearance="default"
                    />
                  </InputGroup>
                </Col>
              </Row>
              <Row className="show-grid d-flex align-items-center mt-5 pt-3 ">
                <Col md={4}>
                  <HelpBlock>Go to bed?</HelpBlock>
                </Col>

                <Col md={17} mdOffset={2}>
                  <InputGroup className="w-100">
                    <DatePicker
                      oneTap
                      onChange={value => {
                        handleWeekDayToBedFrom(value);
                      }}
                      style={{ width: '50%' }}
                      format=" HH:mm:ss"
                      value={
                        roomieProfileData?.weekDayToBed?.from &&
                        new Date(roomieProfileData?.weekDayToBed?.from.toString())
                      }
                      block
                      showMeridian
                      appearance="default"
                    />
                    <InputGroup.Addon>-</InputGroup.Addon>
                    <DatePicker
                      oneTap
                      onChange={value => {
                        handleWeekDayToBedTo(value);
                      }}
                      style={{ width: '50%' }}
                      value={
                        roomieProfileData?.weekDayToBed?.to && new Date(roomieProfileData?.weekDayToBed?.to.toString())
                      }
                      format=" HH:mm:ss"
                      block
                      showMeridian
                      appearance="default"
                    />
                  </InputGroup>
                </Col>
              </Row>
            </Grid>
          </FormGroup>
        </Panel>

        <FormTagline className="mt-4 mb-0">
          On a <span className="text-decoration-underline">weekend </span> when do you typically...
        </FormTagline>
        <Panel className="mt-3" shaded={shadow ? false : true} style={{ overflow: 'inherit' }}>
          <FormGroup>
            <Grid fluid className="my-4">
              <Row className="show-grid d-flex align-items-center ">
                <Col md={4}>
                  <HelpBlock>Wake Up?</HelpBlock>
                </Col>

                <Col md={17} mdOffset={2}>
                  <InputGroup className="w-100">
                    <DatePicker
                      oneTap
                      onChange={value => {
                        handleWeekEndWakeUpFrom(value);
                      }}
                      style={{ width: '50%' }}
                      format=" HH:mm:ss"
                      block
                      value={
                        roomieProfileData?.weekEndData?.from &&
                        new Date(roomieProfileData?.weekEndData?.from.toString())
                      }
                      showMeridian
                      appearance="default"
                    />
                    <InputGroup.Addon>-</InputGroup.Addon>
                    <DatePicker
                      oneTap
                      onChange={value => {
                        handleWeekEndWakeUpTo(value);
                      }}
                      style={{ width: '50%' }}
                      format=" HH:mm:ss"
                      value={
                        roomieProfileData?.weekEndData?.to && new Date(roomieProfileData?.weekEndData?.to.toString())
                      }
                      block
                      showMeridian
                      appearance="default"
                    />
                  </InputGroup>
                </Col>
              </Row>
              <Row className="show-grid mt-5 pt-3 d-flex align-items-center ">
                <Col md={4}>
                  <HelpBlock>Go to bed?</HelpBlock>
                </Col>

                <Col md={17} mdOffset={2}>
                  <InputGroup className="w-100">
                    <DatePicker
                      oneTap
                      onChange={value => {
                        handleWeekEndToBedFrom(value);
                      }}
                      style={{ width: '50%' }}
                      value={
                        roomieProfileData?.weekEndToBed?.from &&
                        new Date(roomieProfileData?.weekEndToBed?.from.toString())
                      }
                      format=" HH:mm:ss"
                      block
                      showMeridian
                      appearance="default"
                    />
                    <InputGroup.Addon>-</InputGroup.Addon>
                    <DatePicker
                      oneTap
                      onChange={value => {
                        handleWeekEndToBedTo(value);
                      }}
                      style={{ width: '50%' }}
                      value={
                        roomieProfileData?.weekEndToBed?.to && new Date(roomieProfileData?.weekEndToBed?.to.toString())
                      }
                      format=" HH:mm:ss"
                      block
                      showMeridian
                      appearance="default"
                    />
                  </InputGroup>
                </Col>
              </Row>
            </Grid>
          </FormGroup>
        </Panel>

        <FormTagline className="mt-4 mb-0">When do you typically shower?</FormTagline>
        <Panel className="mt-3" shaded={shadow ? false : true} style={{ overflow: 'inherit' }}>
          <Grid fluid>
            <Row className="show-grid  ">
              <Col md={24}>
                <FormGroup controlId="radioList">
                  <FormControl
                    inline
                    name="takeShowerOn"
                    accepter={RadioGroup}
                    onChange={val => setroomieProfileData({ ...roomieProfileData, takeShowerOn: val })}>
                    <Radio value="Mornings">Mornings</Radio>
                    <Radio value="Nights">Nights</Radio>
                    <Radio value="Both">Both</Radio>
                  </FormControl>
                </FormGroup>
              </Col>
            </Row>
          </Grid>
        </Panel>

        <FormTagline className="mt-4 mb-0">
          Would you be bothered if a roomie was quietly playing TV while you were falling asleep?
        </FormTagline>
        <Panel className="mt-3" shaded={shadow ? false : true} style={{ overflow: 'inherit' }}>
          <Grid fluid>
            <Row className="show-grid  ">
              <Col md={24}>
                <FormGroup controlId="radioList">
                  <FormControl
                    inline
                    name="bothered"
                    accepter={RadioGroup}
                    onChange={val => setroomieProfileData({ ...roomieProfileData, bothered: val })}>
                    <Radio value="Yes">Yes</Radio>
                    <Radio value="No">No</Radio>
                  </FormControl>
                </FormGroup>
              </Col>
            </Row>
          </Grid>
        </Panel>
      </Form>
    </>
  );
};

export default Step2Form;
