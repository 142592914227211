export default {
  'Portfolio-Boston': {
    'Troy Boston': {
      Zumper: {
        Leads: [
          '51',
          '50',
          '47',
          '46',
          '45',
          '44',
          '43',
          '40',
          '39',
          '40',
          '42',
          '43',
          '45',
          '77',
          '72',
          '67',
          '64',
          '61',
          '58',
          '55',
          '55',
          '52',
          '49',
          '46',
          '46',
          '43',
          '43',
          '40',
          '41',
          '38',
          '39',
          '36',
          '37',
          '38',
          '39',
          '40',
          '22',
          '22',
          '23',
          '20',
          '20',
          '20',
          '21',
          '21',
          '21',
          '21',
          '20',
          '16',
          '16',
          '15',
          '15',
          '15',
          '14',
          '17',
          '19',
          '16',
          '16',
          '16',
          '13',
          '12',
        ],
        'Marketing spend': [
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '350',
          '550',
          '550',
          '550',
          '550',
          '550',
          '550',
          '550',
          '550',
          '350',
          '350',
          '350',
          '350',
          '300',
          '250',
          '250',
          '250',
          '250',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '200',
          '200',
          '200',
          '200',
          '200',
          '200',
          '200',
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
        ],
        'Lease signed': [
          '1',
          '1',
          '0',
          '0',
          '0',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
        ],
        'Conversion rate': [
          '2',
          '2',
          '0',
          '0',
          '0',
          '2',
          '2',
          '3',
          '3',
          '2',
          '2',
          '2',
          '2',
          '1',
          '1',
          '1',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '3',
          '2',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '2',
          '5',
          '4',
          '4',
          '5',
          '5',
          '5',
          '5',
          '5',
          '5',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
        ],
        'TAC/channel': [
          '300',
          '300',
          '',
          '',
          '',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '350',
          '550',
          '550',
          '550',
          '550',
          '550',
          '550',
          '550',
          '550',
          '350',
          '350',
          '350',
          '350',
          '300',
          '250',
          '250',
          '250',
          '250',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '200',
          '200',
          '200',
          '200',
          '200',
          '200',
          '200',
          '150',
          '150',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
        ],
      },
      Padmapper: {
        Leads: [
          '41',
          '40',
          '37',
          '37',
          '36',
          '35',
          '34',
          '32',
          '31',
          '32',
          '34',
          '35',
          '36',
          '62',
          '57',
          '53',
          '51',
          '49',
          '46',
          '44',
          '44',
          '42',
          '39',
          '37',
          '37',
          '34',
          '35',
          '32',
          '33',
          '30',
          '31',
          '28',
          '28',
          '29',
          '29',
          '30',
          '16',
          '16',
          '17',
          '14',
          '15',
          '15',
          '15',
          '15',
          '15',
          '15',
          '15',
          '12',
          '12',
          '11',
          '11',
          '11',
          '10',
          '12',
          '14',
          '12',
          '11',
          '11',
          '9',
          '9',
        ],
        'Marketing spend': [
          '250',
          '250',
          '250',
          '250',
          '250',
          '250',
          '250',
          '250',
          '250',
          '250',
          '250',
          '250',
          '250',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '250',
          '250',
          '250',
          '250',
          '250',
          '250',
          '150',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
        ],
        'Lease signed': [
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
        ],
        'Conversion rate': [
          '2',
          '2',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '4',
          '4',
          '3',
          '3',
          '3',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
        ],
        'TAC/channel': [
          '250',
          '250',
          '250',
          '250',
          '250',
          '250',
          '250',
          '250',
          '250',
          '250',
          '250',
          '250',
          '250',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '250',
          '250',
          '250',
          '250',
          '250',
          '250',
          '150',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
        ],
      },
      Zillow: {
        Leads: [
          '31',
          '31',
          '29',
          '28',
          '28',
          '28',
          '27',
          '26',
          '25',
          '27',
          '28',
          '29',
          '30',
          '53',
          '50',
          '47',
          '46',
          '44',
          '43',
          '41',
          '43',
          '41',
          '39',
          '37',
          '38',
          '36',
          '37',
          '34',
          '35',
          '32',
          '32',
          '29',
          '29',
          '29',
          '29',
          '30',
          '16',
          '16',
          '16',
          '14',
          '15',
          '15',
          '16',
          '16',
          '16',
          '16',
          '16',
          '13',
          '13',
          '12',
          '12',
          '11',
          '11',
          '13',
          '15',
          '13',
          '12',
          '12',
          '10',
          '10',
        ],
        'Marketing spend': [
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '200',
          '200',
          '200',
          '200',
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
        ],
        'Lease signed': [
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '0',
          '0',
          '0',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '2',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '0',
          '0',
          '0',
          '0',
          '1',
          '1',
          '1',
          '0',
          '0',
          '0',
          '0',
        ],
        'Conversion rate': [
          '3',
          '3',
          '4',
          '4',
          '4',
          '4',
          '4',
          '4',
          '4',
          '4',
          '0',
          '0',
          '0',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '3',
          '3',
          '3',
          '3',
          '5',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '6',
          '6',
          '6',
          '7',
          '7',
          '7',
          '6',
          '6',
          '6',
          '6',
          '6',
          '8',
          '8',
          '0',
          '0',
          '0',
          '0',
          '8',
          '7',
          '8',
          '0',
          '0',
          '0',
          '0',
        ],
        'TAC/channel': [
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '',
          '',
          '',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '200',
          '100',
          '200',
          '200',
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '50',
          '50',
          '',
          '',
          '',
          '',
          '50',
          '50',
          '50',
          '',
          '',
          '',
          '',
        ],
      },
      Facebook: {
        Leads: [
          '39',
          '41',
          '40',
          '43',
          '46',
          '49',
          '53',
          '53',
          '57',
          '62',
          '68',
          '74',
          '80',
          '144',
          '141',
          '138',
          '127',
          '116',
          '106',
          '97',
          '94',
          '85',
          '76',
          '68',
          '66',
          '59',
          '57',
          '50',
          '50',
          '44',
          '44',
          '39',
          '38',
          '38',
          '38',
          '37',
          '19',
          '19',
          '19',
          '16',
          '15',
          '15',
          '14',
          '14',
          '14',
          '14',
          '14',
          '12',
          '13',
          '13',
          '13',
          '13',
          '14',
          '17',
          '20',
          '18',
          '19',
          '20',
          '17',
          '18',
        ],
        'Marketing spend': [
          '200',
          '200',
          '200',
          '200',
          '200',
          '200',
          '200',
          '200',
          '200',
          '200',
          '200',
          '200',
          '200',
          '200',
          '200',
          '200',
          '200',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '200',
          '200',
          '200',
          '200',
          '200',
          '200',
          '200',
          '200',
          '150',
          '150',
          '150',
          '150',
          '150',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
        ],
        'Lease signed': [
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '2',
          '2',
          '2',
          '3',
          '3',
          '3',
          '3',
          '3',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '1',
          '1',
          '2',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '0',
          '0',
          '0',
          '0',
          '1',
          '1',
          '1',
          '0',
          '0',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '2',
          '1',
          '2',
          '2',
          '1',
          '1',
        ],
        'Conversion rate': [
          '3',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '3',
          '3',
          '3',
          '2',
          '2',
          '2',
          '2',
          '3',
          '2',
          '2',
          '2',
          '2',
          '3',
          '3',
          '3',
          '2',
          '2',
          '4',
          '2',
          '2',
          '2',
          '3',
          '3',
          '3',
          '3',
          '3',
          '0',
          '0',
          '0',
          '0',
          '7',
          '7',
          '7',
          '0',
          '0',
          '7',
          '7',
          '8',
          '8',
          '8',
          '8',
          '7',
          '7',
          '6',
          '10',
          '6',
          '11',
          '10',
          '6',
          '5',
        ],
        'TAC/channel': [
          '200',
          '200',
          '200',
          '200',
          '200',
          '200',
          '200',
          '200',
          '200',
          '200',
          '100',
          '100',
          '100',
          '67',
          '67',
          '67',
          '67',
          '100',
          '150',
          '150',
          '150',
          '150',
          '150',
          '100',
          '100',
          '200',
          '200',
          '100',
          '200',
          '200',
          '200',
          '150',
          '150',
          '150',
          '150',
          '150',
          '',
          '',
          '',
          '',
          '100',
          '100',
          '100',
          '',
          '',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '50',
          '100',
          '50',
          '50',
          '100',
          '100',
        ],
      },
      'Appartments.com': {
        Leads: [
          '80',
          '79',
          '74',
          '73',
          '72',
          '72',
          '71',
          '66',
          '65',
          '69',
          '72',
          '75',
          '79',
          '136',
          '128',
          '121',
          '115',
          '110',
          '105',
          '99',
          '100',
          '94',
          '88',
          '82',
          '83',
          '77',
          '78',
          '72',
          '68',
          '58',
          '55',
          '47',
          '44',
          '42',
          '40',
          '38',
          '20',
          '20',
          '19',
          '16',
          '16',
          '16',
          '16',
          '16',
          '16',
          '16',
          '16',
          '13',
          '14',
          '13',
          '13',
          '13',
          '14',
          '16',
          '19',
          '17',
          '17',
          '17',
          '14',
          '15',
        ],
        'Marketing spend': [
          '680',
          '680',
          '680',
          '680',
          '680',
          '680',
          '680',
          '680',
          '680',
          '680',
          '680',
          '680',
          '680',
          '680',
          '680',
          '680',
          '680',
          '680',
          '680',
          '680',
          '680',
          '680',
          '680',
          '680',
          '680',
          '680',
          '680',
          '680',
          '500',
          '500',
          '400',
          '350',
          '350',
          '350',
          '350',
          '350',
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
        ],
        'Lease signed': [
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '1',
          '2',
          '1',
          '1',
          '1',
          '1',
          '1',
          '2',
          '2',
          '2',
          '2',
          '2',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
        ],
        'Conversion rate': [
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '2',
          '2',
          '1',
          '1',
          '1',
          '1',
          '1',
          '2',
          '2',
          '2',
          '2',
          '2',
          '1',
          '2',
          '1',
          '1',
          '1',
          '1',
          '1',
          '3',
          '3',
          '3',
          '3',
          '4',
          '2',
          '2',
          '2',
          '3',
          '3',
          '5',
          '5',
          '5',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
        ],
        'TAC/channel': [
          '680',
          '680',
          '680',
          '680',
          '680',
          '680',
          '680',
          '680',
          '680',
          '680',
          '680',
          '680',
          '680',
          '340',
          '340',
          '340',
          '340',
          '340',
          '340',
          '680',
          '340',
          '680',
          '680',
          '680',
          '680',
          '680',
          '340',
          '340',
          '250',
          '250',
          '200',
          '350',
          '350',
          '350',
          '350',
          '350',
          '150',
          '150',
          '150',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
        ],
      },
      'Direct (website)': {
        Leads: [
          '15',
          '16',
          '16',
          '16',
          '16',
          '15',
          '14',
          '13',
          '11',
          '12',
          '14',
          '15',
          '16',
          '29',
          '28',
          '27',
          '26',
          '24',
          '23',
          '21',
          '21',
          '19',
          '17',
          '15',
          '15',
          '13',
          '12',
          '10',
          '12',
          '12',
          '14',
          '13',
          '14',
          '15',
          '15',
          '15',
          '7',
          '7',
          '6',
          '5',
          '5',
          '5',
          '4',
          '4',
          '4',
          '4',
          '5',
          '4',
          '5',
          '5',
          '5',
          '5',
          '6',
          '7',
          '8',
          '7',
          '7',
          '7',
          '5',
          '5',
        ],
        'Marketing spend': [
          '220',
          '220',
          '120',
          '120',
          '120',
          '120',
          '120',
          '20',
          '20',
          '120',
          '220',
          '320',
          '370',
          '1470',
          '1303',
          '1137',
          '970',
          '703',
          '537',
          '370',
          '370',
          '403',
          '237',
          '170',
          '170',
          '153',
          '203',
          '37',
          '217',
          '150',
          '150',
          '83',
          '83',
          '83',
          '83',
          '183',
          '100',
          '100',
          '100',
          '0',
          '0',
          '0',
          '0',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '31',
          '31',
          '31',
          '31',
          '127',
          '223',
          '127',
          '127',
          '127',
          '31',
          '31',
        ],
        'Lease signed': [
          '0',
          '0',
          '1',
          '1',
          '1',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '1',
          '2',
          '2',
          '1',
          '1',
          '0',
          '1',
          '1',
          '0',
          '1',
          '0',
          '0',
          '0',
          '0',
          '1',
          '0',
          '1',
          '0',
          '0',
          '1',
          '1',
          '1',
          '1',
          '1',
          '0',
          '0',
          '0',
          '1',
          '0',
          '0',
          '0',
          '1',
          '1',
          '1',
          '1',
          '0',
          '0',
          '1',
          '1',
          '1',
          '1',
          '1',
          '0',
          '1',
          '1',
          '1',
          '1',
          '1',
        ],
        'Conversion rate': [
          '0',
          '0',
          '6',
          '6',
          '6',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '6',
          '7',
          '7',
          '4',
          '4',
          '0',
          '4',
          '5',
          '0',
          '5',
          '0',
          '0',
          '0',
          '0',
          '9',
          '0',
          '8',
          '0',
          '0',
          '8',
          '7',
          '7',
          '7',
          '7',
          '0',
          '0',
          '0',
          '20',
          '0',
          '0',
          '0',
          '24',
          '26',
          '23',
          '21',
          '0',
          '0',
          '21',
          '20',
          '19',
          '18',
          '15',
          '0',
          '14',
          '15',
          '15',
          '20',
          '21',
        ],
        'TAC/channel': [
          '',
          '',
          '120',
          '120',
          '120',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '370',
          '735',
          '652',
          '1,137',
          '970',
          '',
          '537',
          '370',
          '',
          '403',
          '',
          '',
          '',
          '',
          '203',
          '',
          '217',
          '',
          '',
          '83',
          '83',
          '83',
          '83',
          '183',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '50',
          '50',
          '50',
          '50',
          '',
          '',
          '31',
          '31',
          '31',
          '31',
          '127',
          '',
          '127',
          '127',
          '127',
          '31',
          '31',
        ],
      },
      'Upcoming Vacancy (i) - (next 60 days)': [
        '18',
        '18',
        '17',
        '17',
        '17',
        '17',
        '17',
        '16',
        '16',
        '17',
        '18',
        '19',
        '20',
        '21',
        '20',
        '19',
        '18',
        '17',
        '16',
        '15',
        '15',
        '14',
        '13',
        '12',
        '12',
        '11',
        '11',
        '10',
        '10',
        '9',
        '9',
        '8',
        '8',
        '8',
        '8',
        '8',
        '7',
        '7',
        '7',
        '6',
        '6',
        '6',
        '6',
        '6',
        '6',
        '6',
        '6',
        '5',
        '5',
        '5',
        '5',
        '5',
        '5',
        '6',
        '7',
        '6',
        '6',
        '6',
        '5',
        '5',
      ],
      'Upcoming vacancy (%)': [
        '5.00',
        '5.00',
        '4.95',
        '4.90',
        '4.85',
        '4.80',
        '4.75',
        '4.71',
        '4.66',
        '4.89',
        '5.14',
        '5.39',
        '5.66',
        '5.95',
        '5.65',
        '5.37',
        '5.10',
        '4.84',
        '4.60',
        '4.37',
        '4.15',
        '3.95',
        '3.75',
        '3.56',
        '3.38',
        '3.21',
        '3.05',
        '2.90',
        '2.76',
        '2.62',
        '2.49',
        '2.36',
        '2.36',
        '2.24',
        '2.22',
        '2.22',
        '2.11',
        '2.01',
        '1.91',
        '1.81',
        '1.81',
        '1.81',
        '1.72',
        '1.70',
        '1.69',
        '1.67',
        '1.58',
        '1.51',
        '1.43',
        '1.36',
        '1.29',
        '1.42',
        '1.56',
        '1.72',
        '1.89',
        '1.80',
        '1.71',
        '1.62',
        '1.54',
        '1.46',
      ],
      Leads: [
        '257',
        '257',
        '243',
        '243',
        '243',
        '243',
        '243',
        '229',
        '229',
        '243',
        '257',
        '271',
        '286',
        '500',
        '476',
        '452',
        '429',
        '405',
        '381',
        '357',
        '357',
        '333',
        '310',
        '286',
        '286',
        '262',
        '262',
        '238',
        '238',
        '214',
        '214',
        '190',
        '190',
        '190',
        '190',
        '190',
        '100',
        '100',
        '100',
        '86',
        '86',
        '86',
        '86',
        '86',
        '86',
        '86',
        '86',
        '71',
        '71',
        '69',
        '69',
        '69',
        '69',
        '82',
        '96',
        '82',
        '82',
        '82',
        '69',
        '69',
      ],
      'Marketing spend': [
        '1800',
        '1800',
        '1700',
        '1700',
        '1700',
        '1700',
        '1700',
        '1600',
        '1600',
        '1700',
        '1800',
        '1900',
        '2000',
        '3500',
        '3333',
        '3167',
        '3000',
        '2833',
        '2667',
        '2500',
        '2500',
        '2333',
        '2167',
        '2000',
        '2000',
        '1833',
        '1833',
        '1667',
        '1667',
        '1500',
        '1500',
        '1333',
        '1333',
        '1333',
        '1333',
        '1333',
        '700',
        '700',
        '700',
        '600',
        '600',
        '600',
        '600',
        '600',
        '600',
        '600',
        '600',
        '500',
        '500',
        '481',
        '481',
        '481',
        '481',
        '577',
        '673',
        '577',
        '577',
        '577',
        '481',
        '481',
      ],
      'Tenants acquired (lease signed)': [
        '5',
        '5',
        '5',
        '5',
        '5',
        '5',
        '5',
        '5',
        '5',
        '5',
        '5',
        '5',
        '6',
        '10',
        '10',
        '9',
        '9',
        '8',
        '8',
        '7',
        '7',
        '7',
        '6',
        '6',
        '6',
        '5',
        '8',
        '7',
        '7',
        '6',
        '6',
        '6',
        '6',
        '6',
        '6',
        '6',
        '3',
        '3',
        '3',
        '3',
        '3',
        '3',
        '3',
        '3',
        '3',
        '3',
        '3',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '3',
        '3',
        '3',
        '3',
        '3',
        '2',
        '2',
      ],
      'Marketing TAC': [
        '360',
        '360',
        '340',
        '340',
        '340',
        '340',
        '340',
        '320',
        '320',
        '340',
        '360',
        '380',
        '333',
        '350',
        '333',
        '352',
        '333',
        '354',
        '333',
        '357',
        '357',
        '333',
        '361',
        '333',
        '333',
        '367',
        '229',
        '238',
        '238',
        '250',
        '250',
        '222',
        '222',
        '222',
        '222',
        '222',
        '233',
        '233',
        '233',
        '200',
        '200',
        '200',
        '200',
        '200',
        '200',
        '200',
        '200',
        '250',
        '250',
        '240',
        '240',
        '240',
        '240',
        '192',
        '224',
        '192',
        '192',
        '192',
        '240',
        '240',
      ],
    },
    'Parkway Apartments': {
      Zumper: {
        Leads: [
          '71',
          '70',
          '69',
          '67',
          '63',
          '62',
          '61',
          '60',
          '56',
          '60',
          '61',
          '62',
          '65',
          '66',
          '60',
          '57',
          '55',
          '52',
          '50',
          '48',
          '47',
          '45',
          '43',
          '41',
          '39',
          '37',
          '35',
          '36',
          '34',
          '33',
          '34',
          '32',
          '33',
          '31',
          '32',
          '33',
          '34',
          '32',
          '33',
          '30',
          '30',
          '31',
          '31',
          '31',
          '28',
          '27',
          '27',
          '26',
          '23',
          '22',
          '22',
          '21',
          '24',
          '26',
          '29',
          '25',
          '25',
          '21',
          '21',
          '18',
        ],
        'Marketing spend': [
          '450',
          '450',
          '450',
          '450',
          '450',
          '450',
          '450',
          '450',
          '450',
          '450',
          '450',
          '450',
          '450',
          '450',
          '660',
          '660',
          '660',
          '500',
          '500',
          '400',
          '400',
          '400',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '400',
          '400',
          '400',
          '400',
          '360',
          '360',
          '240',
          '240',
          '240',
          '240',
          '240',
          '240',
          '240',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
        ],
        'Lease signed': [
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
        ],
        'Conversion rate': [
          '1',
          '1',
          '1',
          '1',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
        ],
        'TAC/channel': [
          '450',
          '450',
          '450',
          '450',
          '450',
          '450',
          '450',
          '450',
          '450',
          '450',
          '450',
          '450',
          '450',
          '450',
          '660',
          '660',
          '660',
          '500',
          '500',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
        ],
      },
      Padmapper: {
        Leads: [
          '57',
          '56',
          '55',
          '54',
          '51',
          '50',
          '49',
          '48',
          '45',
          '48',
          '49',
          '49',
          '52',
          '53',
          '48',
          '46',
          '44',
          '41',
          '40',
          '39',
          '37',
          '36',
          '34',
          '33',
          '31',
          '30',
          '28',
          '29',
          '27',
          '26',
          '27',
          '25',
          '25',
          '24',
          '24',
          '25',
          '25',
          '23',
          '24',
          '22',
          '22',
          '22',
          '22',
          '23',
          '20',
          '20',
          '19',
          '19',
          '16',
          '16',
          '16',
          '15',
          '17',
          '19',
          '21',
          '18',
          '18',
          '16',
          '15',
          '13',
        ],
        'Marketing spend': [
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '360',
          '360',
          '360',
          '360',
          '360',
          '360',
          '360',
          '360',
          '360',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '200',
          '200',
          '200',
          '180',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
        ],
        'Lease signed': [
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '0',
          '0',
          '0',
          '0',
          '0',
          '1',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
        ],
        'Conversion rate': [
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '4',
          '3',
          '4',
          '4',
          '4',
          '0',
          '0',
          '0',
          '0',
          '0',
          '4',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
        ],
        'TAC/channel': [
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '360',
          '360',
          '360',
          '360',
          '360',
          '360',
          '360',
          '360',
          '360',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '',
          '',
          '',
          '',
          '',
          '60',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
        ],
      },
      Zillow: {
        Leads: [
          '43',
          '42',
          '42',
          '42',
          '40',
          '39',
          '39',
          '38',
          '36',
          '39',
          '40',
          '41',
          '44',
          '45',
          '42',
          '40',
          '39',
          '38',
          '37',
          '37',
          '36',
          '35',
          '34',
          '34',
          '33',
          '32',
          '31',
          '31',
          '29',
          '27',
          '28',
          '26',
          '26',
          '24',
          '24',
          '25',
          '25',
          '23',
          '23',
          '21',
          '22',
          '23',
          '23',
          '24',
          '22',
          '22',
          '21',
          '21',
          '18',
          '17',
          '17',
          '17',
          '19',
          '21',
          '22',
          '20',
          '19',
          '17',
          '17',
          '14',
        ],
        'Marketing spend': [
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '360',
          '360',
          '360',
          '360',
          '360',
          '240',
          '240',
          '240',
          '240',
          '240',
          '240',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
        ],
        'Lease signed': [
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '0',
          '0',
          '0',
          '0',
          '0',
          '1',
          '1',
          '0',
          '0',
          '0',
          '0',
          '0',
        ],
        'Conversion rate': [
          '2',
          '2',
          '2',
          '2',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '2',
          '2',
          '2',
          '2',
          '2',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '4',
          '4',
          '4',
          '4',
          '4',
          '4',
          '4',
          '4',
          '4',
          '4',
          '5',
          '5',
          '4',
          '4',
          '4',
          '5',
          '5',
          '5',
          '5',
          '0',
          '0',
          '0',
          '0',
          '0',
          '5',
          '4',
          '0',
          '0',
          '0',
          '0',
          '0',
        ],
        'TAC/channel': [
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '360',
          '360',
          '360',
          '360',
          '360',
          '240',
          '240',
          '240',
          '240',
          '240',
          '240',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '60',
          '',
          '',
          '',
          '',
          '',
          '60',
          '60',
          '',
          '',
          '',
          '',
          '',
        ],
      },
      Facebook: {
        Leads: [
          '54',
          '56',
          '59',
          '63',
          '65',
          '69',
          '74',
          '80',
          '82',
          '91',
          '98',
          '105',
          '116',
          '123',
          '119',
          '118',
          '110',
          '98',
          '92',
          '85',
          '79',
          '73',
          '67',
          '62',
          '56',
          '51',
          '47',
          '45',
          '42',
          '39',
          '38',
          '35',
          '35',
          '31',
          '31',
          '31',
          '30',
          '27',
          '27',
          '24',
          '23',
          '22',
          '22',
          '21',
          '18',
          '19',
          '19',
          '20',
          '18',
          '18',
          '19',
          '19',
          '23',
          '26',
          '30',
          '28',
          '30',
          '28',
          '29',
          '27',
        ],
        'Marketing spend': [
          '240',
          '240',
          '240',
          '240',
          '240',
          '240',
          '240',
          '240',
          '240',
          '240',
          '240',
          '240',
          '240',
          '240',
          '240',
          '240',
          '240',
          '360',
          '360',
          '360',
          '360',
          '260',
          '260',
          '240',
          '200',
          '200',
          '200',
          '200',
          '200',
          '200',
          '200',
          '120',
          '120',
          '120',
          '180',
          '180',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
        ],
        'Lease signed': [
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
        ],
        'Conversion rate': [
          '2',
          '2',
          '2',
          '2',
          '2',
          '1',
          '1',
          '3',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '3',
          '1',
          '1',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '4',
          '4',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '4',
          '3',
          '4',
          '3',
          '4',
          '3',
          '4',
        ],
        'TAC/channel': [
          '240',
          '240',
          '240',
          '240',
          '240',
          '240',
          '240',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '180',
          '180',
          '180',
          '180',
          '260',
          '260',
          '240',
          '200',
          '200',
          '200',
          '200',
          '200',
          '200',
          '200',
          '120',
          '120',
          '120',
          '180',
          '180',
          '120',
          '120',
          '120',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
        ],
      },
      'Appartments.com': {
        Leads: [
          '111',
          '110',
          '109',
          '107',
          '102',
          '101',
          '100',
          '99',
          '94',
          '101',
          '104',
          '107',
          '114',
          '117',
          '108',
          '103',
          '100',
          '93',
          '90',
          '87',
          '84',
          '81',
          '78',
          '74',
          '71',
          '67',
          '64',
          '64',
          '57',
          '50',
          '48',
          '42',
          '40',
          '35',
          '33',
          '31',
          '31',
          '28',
          '28',
          '25',
          '24',
          '24',
          '24',
          '24',
          '21',
          '21',
          '21',
          '21',
          '19',
          '19',
          '19',
          '20',
          '23',
          '26',
          '29',
          '26',
          '26',
          '24',
          '24',
          '21',
        ],
        'Marketing spend': [
          '816',
          '816',
          '816',
          '816',
          '816',
          '816',
          '816',
          '816',
          '816',
          '816',
          '816',
          '816',
          '816',
          '816',
          '816',
          '816',
          '816',
          '816',
          '816',
          '700',
          '700',
          '700',
          '700',
          '700',
          '400',
          '400',
          '400',
          '400',
          '400',
          '200',
          '200',
          '200',
          '200',
          '200',
          '200',
          '200',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
        ],
        'Lease signed': [
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '1',
          '1',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
        ],
        'Conversion rate': [
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '1',
          '1',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '3',
          '3',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
        ],
        'TAC/channel': [
          '408',
          '408',
          '408',
          '408',
          '408',
          '408',
          '408',
          '816',
          '816',
          '408',
          '408',
          '408',
          '408',
          '408',
          '408',
          '408',
          '408',
          '816',
          '816',
          '700',
          '700',
          '700',
          '700',
          '700',
          '400',
          '400',
          '400',
          '400',
          '400',
          '200',
          '200',
          '200',
          '200',
          '200',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
        ],
      },
      'Direct (website)': {
        Leads: [
          '21',
          '23',
          '24',
          '24',
          '22',
          '22',
          '20',
          '19',
          '16',
          '18',
          '20',
          '21',
          '24',
          '25',
          '24',
          '23',
          '22',
          '21',
          '20',
          '19',
          '18',
          '16',
          '15',
          '14',
          '12',
          '11',
          '10',
          '9',
          '10',
          '11',
          '12',
          '12',
          '13',
          '12',
          '12',
          '13',
          '12',
          '9',
          '8',
          '7',
          '7',
          '7',
          '7',
          '6',
          '5',
          '6',
          '6',
          '7',
          '7',
          '7',
          '7',
          '8',
          '9',
          '11',
          '12',
          '11',
          '11',
          '9',
          '9',
          '7',
        ],
        'Marketing spend': [
          '514',
          '514',
          '514',
          '514',
          '414',
          '414',
          '414',
          '414',
          '314',
          '514',
          '614',
          '714',
          '914',
          '774',
          '364',
          '264',
          '164',
          '4',
          '24',
          '140',
          '40',
          '40',
          '100',
          '20',
          '320',
          '220',
          '120',
          '120',
          '20',
          '120',
          '80',
          '60',
          '160',
          '60',
          '40',
          '60',
          '380',
          '280',
          '280',
          '180',
          '180',
          '180',
          '180',
          '240',
          '140',
          '140',
          '140',
          '260',
          '160',
          '160',
          '160',
          '160',
          '260',
          '360',
          '460',
          '360',
          '360',
          '260',
          '260',
          '160',
        ],
        'Lease signed': [
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '0',
          '0',
          '1',
          '1',
          '2',
          '1',
          '1',
          '0',
          '1',
          '1',
          '1',
          '1',
          '2',
          '1',
          '1',
          '1',
          '1',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '1',
          '1',
          '0',
          '1',
          '1',
          '2',
          '2',
          '2',
          '2',
          '2',
          '1',
          '1',
          '1',
          '1',
          '2',
          '2',
          '2',
          '2',
          '2',
          '1',
          '1',
          '2',
          '2',
          '1',
          '1',
          '1',
        ],
        'Conversion rate': [
          '5',
          '4',
          '4',
          '4',
          '4',
          '5',
          '5',
          '5',
          '6',
          '0',
          '0',
          '5',
          '4',
          '8',
          '4',
          '4',
          '0',
          '5',
          '5',
          '5',
          '6',
          '12',
          '7',
          '7',
          '8',
          '9',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '8',
          '8',
          '0',
          '11',
          '12',
          '27',
          '28',
          '29',
          '30',
          '32',
          '19',
          '17',
          '15',
          '14',
          '30',
          '28',
          '27',
          '26',
          '22',
          '9',
          '8',
          '18',
          '19',
          '11',
          '12',
          '14',
        ],
        'TAC/channel': [
          '514',
          '514',
          '514',
          '514',
          '414',
          '414',
          '414',
          '414',
          '314',
          '',
          '',
          '714',
          '914',
          '387',
          '364',
          '264',
          '',
          '4',
          '24',
          '140',
          '40',
          '20',
          '100',
          '20',
          '320',
          '220',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '40',
          '60',
          '',
          '280',
          '280',
          '90',
          '90',
          '90',
          '90',
          '120',
          '140',
          '140',
          '140',
          '260',
          '80',
          '80',
          '80',
          '80',
          '130',
          '360',
          '460',
          '180',
          '180',
          '260',
          '260',
          '160',
        ],
      },
      'Upcoming Vacancy (i) - (next 60 days)': [
        '25',
        '25',
        '25',
        '25',
        '24',
        '24',
        '24',
        '24',
        '23',
        '25',
        '26',
        '27',
        '29',
        '30',
        '28',
        '27',
        '26',
        '24',
        '23',
        '22',
        '21',
        '20',
        '19',
        '18',
        '17',
        '16',
        '15',
        '15',
        '14',
        '13',
        '13',
        '12',
        '12',
        '11',
        '11',
        '11',
        '11',
        '10',
        '10',
        '9',
        '9',
        '9',
        '9',
        '9',
        '8',
        '8',
        '8',
        '8',
        '7',
        '7',
        '7',
        '7',
        '8',
        '9',
        '10',
        '9',
        '9',
        '8',
        '8',
        '7',
      ],
      'Upcoming vacancy (%)': [
        '6.00',
        '6.00',
        '5.94',
        '5.88',
        '5.82',
        '5.76',
        '5.71',
        '5.65',
        '5.59',
        '5.87',
        '6.17',
        '6.47',
        '6.80',
        '7.14',
        '6.78',
        '6.44',
        '6.12',
        '5.81',
        '5.52',
        '5.25',
        '4.98',
        '4.74',
        '4.50',
        '4.27',
        '4.06',
        '3.86',
        '3.66',
        '3.48',
        '3.31',
        '3.14',
        '2.98',
        '2.84',
        '2.84',
        '2.69',
        '2.67',
        '2.67',
        '2.53',
        '2.41',
        '2.29',
        '2.17',
        '2.17',
        '2.17',
        '2.06',
        '2.04',
        '2.02',
        '2.00',
        '1.90',
        '1.81',
        '1.72',
        '1.63',
        '1.55',
        '1.70',
        '1.87',
        '2.06',
        '2.27',
        '2.15',
        '2.05',
        '1.94',
        '1.85',
        '1.75',
      ],
      Leads: [
        '357',
        '357',
        '357',
        '357',
        '343',
        '343',
        '343',
        '343',
        '329',
        '357',
        '371',
        '386',
        '414',
        '429',
        '400',
        '386',
        '371',
        '343',
        '329',
        '314',
        '300',
        '286',
        '271',
        '257',
        '243',
        '229',
        '214',
        '214',
        '200',
        '186',
        '186',
        '171',
        '171',
        '157',
        '157',
        '157',
        '157',
        '143',
        '143',
        '129',
        '129',
        '129',
        '129',
        '129',
        '114',
        '114',
        '114',
        '114',
        '100',
        '100',
        '100',
        '100',
        '114',
        '129',
        '143',
        '129',
        '129',
        '114',
        '114',
        '100',
      ],
      'Marketing spend': [
        '2500',
        '2500',
        '2500',
        '2500',
        '2400',
        '2400',
        '2400',
        '2400',
        '2300',
        '2500',
        '2600',
        '2700',
        '2900',
        '3000',
        '2800',
        '2700',
        '2600',
        '2400',
        '2300',
        '2200',
        '2100',
        '2000',
        '1900',
        '1800',
        '1700',
        '1600',
        '1500',
        '1500',
        '1400',
        '1300',
        '1300',
        '1200',
        '1200',
        '1100',
        '1100',
        '1100',
        '1100',
        '1000',
        '1000',
        '900',
        '900',
        '900',
        '900',
        '900',
        '800',
        '800',
        '800',
        '800',
        '700',
        '700',
        '700',
        '700',
        '800',
        '900',
        '1000',
        '900',
        '900',
        '800',
        '800',
        '700',
      ],
      'Tenants acquired (lease signed)': [
        '7',
        '7',
        '7',
        '7',
        '7',
        '7',
        '7',
        '7',
        '7',
        '7',
        '7',
        '8',
        '8',
        '9',
        '8',
        '8',
        '7',
        '7',
        '7',
        '6',
        '6',
        '6',
        '5',
        '5',
        '5',
        '5',
        '4',
        '4',
        '4',
        '4',
        '4',
        '3',
        '3',
        '3',
        '3',
        '3',
        '3',
        '3',
        '3',
        '3',
        '3',
        '3',
        '3',
        '3',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '3',
        '3',
        '3',
        '3',
        '2',
        '2',
        '2',
      ],
      'Marketing TAC': [
        '357',
        '357',
        '357',
        '357',
        '343',
        '343',
        '343',
        '343',
        '329',
        '357',
        '371',
        '338',
        '363',
        '333',
        '350',
        '338',
        '371',
        '343',
        '329',
        '367',
        '350',
        '333',
        '380',
        '360',
        '340',
        '320',
        '375',
        '375',
        '350',
        '325',
        '325',
        '400',
        '400',
        '367',
        '367',
        '367',
        '367',
        '333',
        '333',
        '300',
        '300',
        '300',
        '300',
        '300',
        '400',
        '400',
        '400',
        '400',
        '350',
        '350',
        '350',
        '350',
        '400',
        '300',
        '333',
        '300',
        '300',
        '400',
        '400',
        '350',
      ],
    },
  },
  'Portfolio-Toronto': {
    'Bridge Condos': {
      Zumper: {
        Leads: [
          '50',
          '49',
          '48',
          '47',
          '46',
          '45',
          '44',
          '43',
          '38',
          '38',
          '33',
          '32',
          '34',
          '45',
          '44',
          '37',
          '37',
          '57',
          '48',
          '58',
          '58',
          '59',
          '69',
          '70',
          '47',
          '54',
          '55',
          '62',
          '64',
          '66',
          '68',
          '70',
          '72',
          '75',
          '77',
          '88',
          '54',
          '55',
          '55',
          '56',
          '56',
          '57',
          '58',
          '52',
          '53',
          '52',
          '51',
          '44',
          '38',
          '31',
          '30',
          '25',
          '24',
          '19',
          '18',
          '14',
          '13',
          '13',
          '9',
          '8',
        ],
        'Marketing spend': [
          '450',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '200',
          '500',
          '500',
          '500',
          '500',
          '500',
          '400',
          '550',
          '550',
          '550',
          '550',
          '550',
          '450',
          '550',
          '550',
          '550',
          '550',
          '550',
          '550',
          '550',
          '550',
          '550',
          '550',
          '550',
          '300',
          '300',
          '200',
          '350',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '100',
          '100',
          '100',
          '100',
        ],
        'Lease signed': [
          '1',
          '0',
          '0',
          '0',
          '0',
          '1',
          '1',
          '1',
          '0',
          '0',
          '0',
          '0',
          '0',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '0',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
        ],
        'Conversion rate': [
          '2',
          '0',
          '0',
          '0',
          '0',
          '2',
          '2',
          '2',
          '0',
          '0',
          '0',
          '0',
          '0',
          '2',
          '2',
          '3',
          '3',
          '2',
          '2',
          '2',
          '2',
          '2',
          '1',
          '1',
          '0',
          '2',
          '2',
          '2',
          '2',
          '2',
          '1',
          '1',
          '1',
          '1',
          '1',
          '2',
          '4',
          '4',
          '4',
          '4',
          '4',
          '4',
          '3',
          '4',
          '4',
          '2',
          '2',
          '2',
          '3',
          '3',
          '3',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
        ],
        'TAC/channel': [
          '450',
          '',
          '',
          '',
          '',
          '300',
          '300',
          '300',
          '',
          '',
          '',
          '',
          '',
          '500',
          '500',
          '500',
          '500',
          '500',
          '400',
          '550',
          '550',
          '550',
          '550',
          '550',
          '',
          '550',
          '550',
          '550',
          '550',
          '550',
          '550',
          '550',
          '550',
          '550',
          '550',
          '275',
          '150',
          '150',
          '100',
          '175',
          '150',
          '150',
          '150',
          '150',
          '150',
          '300',
          '300',
          '300',
          '150',
          '150',
          '150',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
        ],
      },
      Padmapper: {
        Leads: [
          '37',
          '36',
          '36',
          '35',
          '34',
          '34',
          '33',
          '32',
          '28',
          '28',
          '24',
          '24',
          '26',
          '33',
          '33',
          '27',
          '28',
          '42',
          '35',
          '43',
          '43',
          '44',
          '51',
          '52',
          '35',
          '40',
          '41',
          '47',
          '48',
          '49',
          '50',
          '51',
          '52',
          '53',
          '54',
          '61',
          '37',
          '37',
          '37',
          '37',
          '37',
          '38',
          '38',
          '35',
          '35',
          '34',
          '34',
          '29',
          '25',
          '20',
          '20',
          '16',
          '16',
          '12',
          '12',
          '9',
          '9',
          '9',
          '6',
          '6',
        ],
        'Marketing spend': [
          '200',
          '250',
          '250',
          '250',
          '250',
          '250',
          '250',
          '250',
          '250',
          '250',
          '250',
          '250',
          '250',
          '250',
          '250',
          '250',
          '250',
          '250',
          '250',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '180',
          '180',
          '120',
          '210',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '90',
          '90',
          '90',
          '90',
          '90',
          '90',
          '30',
          '30',
          '30',
          '30',
          '30',
          '30',
        ],
        'Lease signed': [
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '1',
          '1',
          '1',
          '1',
          '0',
          '1',
          '1',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '1',
          '0',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
        ],
        'Conversion rate': [
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '4',
          '4',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '2',
          '3',
          '2',
          '2',
          '0',
          '2',
          '2',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '2',
          '0',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
        ],
        'TAC/channel': [
          '200',
          '250',
          '250',
          '250',
          '250',
          '250',
          '250',
          '250',
          '250',
          '250',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '250',
          '250',
          '300',
          '300',
          '',
          '300',
          '300',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '300',
          '',
          '180',
          '120',
          '210',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
        ],
      },
      Zillow: {
        Leads: [
          '4',
          '4',
          '4',
          '4',
          '4',
          '4',
          '4',
          '4',
          '4',
          '4',
          '3',
          '3',
          '3',
          '4',
          '4',
          '4',
          '4',
          '6',
          '5',
          '6',
          '6',
          '7',
          '8',
          '8',
          '6',
          '7',
          '7',
          '8',
          '8',
          '8',
          '8',
          '8',
          '8',
          '8',
          '8',
          '9',
          '6',
          '6',
          '6',
          '6',
          '6',
          '6',
          '6',
          '6',
          '6',
          '6',
          '6',
          '5',
          '4',
          '4',
          '3',
          '3',
          '3',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
        ],
        'Marketing spend': [
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
        ],
        'Lease signed': [
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
        ],
        'Conversion rate': [
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
        ],
        'TAC/channel': [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
        ],
      },
      Facebook: {
        Leads: [
          '29',
          '30',
          '32',
          '34',
          '36',
          '39',
          '41',
          '44',
          '43',
          '44',
          '40',
          '41',
          '47',
          '64',
          '66',
          '58',
          '56',
          '82',
          '66',
          '77',
          '75',
          '73',
          '82',
          '80',
          '52',
          '57',
          '55',
          '61',
          '60',
          '59',
          '59',
          '58',
          '58',
          '57',
          '56',
          '62',
          '37',
          '36',
          '36',
          '35',
          '34',
          '33',
          '32',
          '28',
          '27',
          '27',
          '28',
          '26',
          '23',
          '20',
          '20',
          '17',
          '18',
          '15',
          '15',
          '12',
          '12',
          '13',
          '9',
          '10',
        ],
        'Marketing spend': [
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '450',
          '450',
          '450',
          '450',
          '450',
          '450',
          '450',
          '250',
          '250',
          '250',
          '250',
          '300',
          '300',
          '450',
          '450',
          '450',
          '450',
          '450',
          '450',
          '200',
          '300',
          '450',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '50',
          '50',
          '20',
          '20',
          '20',
          '20',
          '20',
          '20',
        ],
        'Lease signed': [
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '2',
          '2',
          '1',
          '1',
          '2',
          '1',
          '2',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '0',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '0',
          '1',
        ],
        'Conversion rate': [
          '4',
          '3',
          '3',
          '3',
          '3',
          '3',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '3',
          '3',
          '2',
          '2',
          '2',
          '2',
          '3',
          '1',
          '1',
          '1',
          '1',
          '2',
          '2',
          '0',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '3',
          '4',
          '4',
          '4',
          '4',
          '4',
          '4',
          '5',
          '5',
          '6',
          '6',
          '7',
          '7',
          '8',
          '8',
          '8',
          '0',
          '10',
        ],
        'TAC/channel': [
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '150',
          '75',
          '75',
          '150',
          '150',
          '225',
          '450',
          '225',
          '450',
          '450',
          '450',
          '450',
          '250',
          '250',
          '',
          '250',
          '300',
          '300',
          '450',
          '450',
          '450',
          '450',
          '450',
          '450',
          '200',
          '300',
          '450',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '50',
          '50',
          '20',
          '20',
          '20',
          '20',
          '',
          '20',
        ],
      },
      'Appartments.com': {
        Leads: [
          '7',
          '7',
          '7',
          '7',
          '7',
          '7',
          '7',
          '7',
          '6',
          '6',
          '5',
          '5',
          '6',
          '7',
          '7',
          '6',
          '6',
          '9',
          '8',
          '10',
          '10',
          '10',
          '12',
          '12',
          '8',
          '9',
          '9',
          '10',
          '10',
          '9',
          '9',
          '8',
          '8',
          '8',
          '7',
          '8',
          '5',
          '5',
          '4',
          '4',
          '4',
          '4',
          '4',
          '4',
          '4',
          '4',
          '4',
          '3',
          '3',
          '3',
          '3',
          '2',
          '2',
          '2',
          '2',
          '1',
          '1',
          '1',
          '1',
          '1',
        ],
        'Marketing spend': [
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '50',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '100',
          '20',
          '20',
          '20',
          '20',
          '20',
          '20',
        ],
        'Lease signed': [
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
        ],
        'Conversion rate': [
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
        ],
        'TAC/channel': [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
        ],
      },
      'Direct (website)': {
        Leads: [
          '16',
          '16',
          '16',
          '16',
          '15',
          '15',
          '14',
          '12',
          '10',
          '10',
          '9',
          '9',
          '10',
          '13',
          '13',
          '11',
          '11',
          '18',
          '16',
          '21',
          '22',
          '23',
          '28',
          '28',
          '20',
          '23',
          '23',
          '26',
          '25',
          '23',
          '21',
          '19',
          '17',
          '14',
          '12',
          '10',
          '4',
          '4',
          '4',
          '4',
          '5',
          '5',
          '5',
          '4',
          '4',
          '5',
          '6',
          '7',
          '6',
          '6',
          '6',
          '6',
          '6',
          '7',
          '7',
          '6',
          '5',
          '5',
          '3',
          '3',
        ],
        'Marketing spend': [
          '100',
          '200',
          '200',
          '200',
          '200',
          '200',
          '200',
          '200',
          '100',
          '100',
          '0',
          '0',
          '175',
          '167',
          '167',
          '0',
          '0',
          '200',
          '50',
          '100',
          '100',
          '100',
          '350',
          '350',
          '67',
          '133',
          '133',
          '300',
          '250',
          '250',
          '100',
          '100',
          '100',
          '100',
          '100',
          '217',
          '170',
          '70',
          '80',
          '190',
          '270',
          '270',
          '270',
          '170',
          '170',
          '170',
          '170',
          '70',
          '210',
          '87',
          '87',
          '-9',
          '41',
          '-5',
          '165',
          '68',
          '118',
          '118',
          '22',
          '22',
        ],
        'Lease signed': [
          '0',
          '1',
          '1',
          '1',
          '1',
          '0',
          '0',
          '0',
          '1',
          '1',
          '1',
          '1',
          '2',
          '0',
          '0',
          '1',
          '1',
          '0',
          '1',
          '0',
          '1',
          '0',
          '0',
          '0',
          '1',
          '0',
          '1',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '3',
          '1',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '1',
          '1',
          '1',
          '1',
          '0',
          '0',
          '1',
          '1',
          '1',
          '1',
          '0',
          '0',
          '0',
          '1',
          '0',
        ],
        'Conversion rate': [
          '0',
          '6',
          '6',
          '6',
          '6',
          '0',
          '0',
          '0',
          '10',
          '10',
          '11',
          '11',
          '21',
          '0',
          '0',
          '9',
          '9',
          '0',
          '6',
          '0',
          '5',
          '0',
          '0',
          '0',
          '5',
          '0',
          '4',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '29',
          '24',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '18',
          '16',
          '15',
          '16',
          '0',
          '0',
          '18',
          '17',
          '14',
          '14',
          '0',
          '0',
          '0',
          '30',
          '0',
        ],
        'TAC/channel': [
          '',
          '200',
          '200',
          '200',
          '200',
          '',
          '',
          '',
          '100',
          '100',
          '-',
          '-',
          '88',
          '',
          '',
          '-',
          '-',
          '',
          '50',
          '',
          '100',
          '',
          '',
          '',
          '67',
          '',
          '133',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '72',
          '170',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '170',
          '170',
          '70',
          '210',
          '',
          '',
          '-9',
          '41',
          '-5',
          '165',
          '',
          '',
          '',
          '22',
          '',
        ],
      },
      'Upcoming Vacancy (i) - (next 60 days)': [
        '10',
        '10',
        '10',
        '10',
        '10',
        '10',
        '10',
        '10',
        '9',
        '9',
        '8',
        '8',
        '7',
        '7',
        '7',
        '6',
        '6',
        '6',
        '5',
        '6',
        '6',
        '6',
        '7',
        '7',
        '7',
        '8',
        '8',
        '9',
        '9',
        '9',
        '9',
        '9',
        '9',
        '9',
        '9',
        '10',
        '10',
        '10',
        '10',
        '10',
        '10',
        '10',
        '10',
        '9',
        '9',
        '9',
        '9',
        '8',
        '7',
        '6',
        '6',
        '5',
        '5',
        '4',
        '4',
        '3',
        '3',
        '3',
        '2',
        '2',
      ],
      'Upcoming vacancy (%)': [
        '4.55',
        '4.55',
        '4.50',
        '4.46',
        '4.41',
        '4.37',
        '4.33',
        '4.28',
        '4.07',
        '3.87',
        '3.67',
        '3.49',
        '3.31',
        '3.15',
        '2.99',
        '2.84',
        '2.70',
        '2.56',
        '2.44',
        '2.56',
        '2.69',
        '2.82',
        '2.96',
        '3.11',
        '3.27',
        '3.43',
        '3.60',
        '3.78',
        '3.97',
        '4.01',
        '4.05',
        '4.09',
        '4.13',
        '4.17',
        '4.21',
        '4.26',
        '4.30',
        '4.34',
        '4.38',
        '4.43',
        '4.47',
        '4.47',
        '4.25',
        '4.21',
        '4.16',
        '4.12',
        '3.92',
        '3.52',
        '3.17',
        '2.86',
        '2.57',
        '2.31',
        '2.08',
        '1.87',
        '1.69',
        '1.52',
        '1.37',
        '1.23',
        '1.11',
        '1.00',
      ],
      Leads: [
        '143',
        '143',
        '143',
        '143',
        '143',
        '143',
        '143',
        '143',
        '129',
        '129',
        '114',
        '114',
        '125',
        '167',
        '167',
        '143',
        '143',
        '214',
        '179',
        '214',
        '214',
        '214',
        '250',
        '250',
        '167',
        '190',
        '190',
        '214',
        '214',
        '214',
        '214',
        '214',
        '214',
        '214',
        '214',
        '238',
        '143',
        '143',
        '143',
        '143',
        '143',
        '143',
        '143',
        '129',
        '129',
        '129',
        '129',
        '114',
        '100',
        '82',
        '82',
        '69',
        '69',
        '55',
        '55',
        '41',
        '41',
        '41',
        '27',
        '27',
      ],
      'Marketing spend': [
        '1000',
        '1000',
        '1000',
        '1000',
        '1000',
        '1000',
        '1000',
        '1000',
        '900',
        '900',
        '800',
        '800',
        '875',
        '1167',
        '1167',
        '1000',
        '1000',
        '1500',
        '1250',
        '1500',
        '1500',
        '1500',
        '1750',
        '1750',
        '1167',
        '1333',
        '1333',
        '1500',
        '1500',
        '1500',
        '1500',
        '1500',
        '1500',
        '1500',
        '1500',
        '1667',
        '1000',
        '1000',
        '1000',
        '1000',
        '1000',
        '1000',
        '1000',
        '900',
        '900',
        '900',
        '900',
        '800',
        '700',
        '577',
        '577',
        '481',
        '481',
        '385',
        '385',
        '288',
        '288',
        '288',
        '192',
        '192',
      ],
      'Tenants acquired (lease signed)': [
        '3',
        '3',
        '3',
        '3',
        '3',
        '3',
        '3',
        '3',
        '3',
        '3',
        '2',
        '2',
        '3',
        '3',
        '3',
        '3',
        '3',
        '4',
        '4',
        '4',
        '4',
        '2',
        '3',
        '3',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '7',
        '4',
        '4',
        '4',
        '4',
        '4',
        '4',
        '4',
        '4',
        '4',
        '4',
        '4',
        '3',
        '3',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '1',
        '1',
        '1',
        '1',
        '1',
      ],
      'Marketing TAC': [
        '333',
        '333',
        '333',
        '333',
        '333',
        '333',
        '333',
        '333',
        '300',
        '300',
        '400',
        '400',
        '292',
        '389',
        '389',
        '333',
        '333',
        '375',
        '313',
        '375',
        '375',
        '750',
        '583',
        '583',
        '583',
        '667',
        '667',
        '750',
        '750',
        '750',
        '750',
        '750',
        '750',
        '750',
        '750',
        '238',
        '250',
        '250',
        '250',
        '250',
        '250',
        '250',
        '250',
        '225',
        '225',
        '225',
        '225',
        '267',
        '233',
        '288',
        '288',
        '240',
        '240',
        '192',
        '192',
        '288',
        '288',
        '288',
        '192',
        '192',
      ],
    },
    'Liberty Towers': {
      Zumper: {
        Leads: [
          '90',
          '88',
          '86',
          '85',
          '78',
          '77',
          '75',
          '74',
          '68',
          '63',
          '61',
          '56',
          '51',
          '46',
          '45',
          '41',
          '41',
          '38',
          '38',
          '38',
          '43',
          '43',
          '48',
          '48',
          '53',
          '57',
          '58',
          '62',
          '69',
          '71',
          '73',
          '75',
          '77',
          '84',
          '87',
          '90',
          '92',
          '93',
          '94',
          '101',
          '102',
          '103',
          '98',
          '99',
          '94',
          '92',
          '90',
          '77',
          '70',
          '58',
          '52',
          '46',
          '40',
          '34',
          '34',
          '28',
          '23',
          '23',
          '18',
          '17',
        ],
        'Marketing spend': [
          '540',
          '360',
          '360',
          '360',
          '360',
          '360',
          '360',
          '360',
          '360',
          '360',
          '360',
          '360',
          '240',
          '600',
          '600',
          '600',
          '600',
          '400',
          '400',
          '400',
          '400',
          '400',
          '400',
          '400',
          '400',
          '400',
          '400',
          '400',
          '400',
          '400',
          '400',
          '400',
          '400',
          '400',
          '400',
          '400',
          '360',
          '360',
          '240',
          '420',
          '360',
          '360',
          '360',
          '360',
          '360',
          '360',
          '360',
          '360',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '120',
          '120',
          '120',
          '120',
        ],
        'Lease signed': [
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
        ],
        'Conversion rate': [
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '2',
          '2',
          '2',
          '2',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '2',
          '2',
          '2',
          '2',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '2',
          '2',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
        ],
        'TAC/channel': [
          '540',
          '360',
          '360',
          '360',
          '360',
          '360',
          '360',
          '360',
          '360',
          '360',
          '360',
          '360',
          '240',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '400',
          '400',
          '400',
          '400',
          '400',
          '400',
          '400',
          '400',
          '400',
          '400',
          '400',
          '400',
          '360',
          '360',
          '240',
          '420',
          '360',
          '360',
          '360',
          '360',
          '360',
          '360',
          '360',
          '360',
          '180',
          '180',
          '180',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
        ],
      },
      Padmapper: {
        Leads: [
          '67',
          '66',
          '64',
          '63',
          '58',
          '57',
          '56',
          '55',
          '51',
          '46',
          '46',
          '42',
          '38',
          '34',
          '34',
          '30',
          '30',
          '28',
          '28',
          '29',
          '32',
          '32',
          '35',
          '36',
          '39',
          '42',
          '43',
          '47',
          '51',
          '52',
          '53',
          '54',
          '55',
          '60',
          '61',
          '62',
          '63',
          '63',
          '63',
          '67',
          '67',
          '68',
          '65',
          '65',
          '62',
          '61',
          '60',
          '51',
          '47',
          '39',
          '34',
          '30',
          '26',
          '23',
          '22',
          '19',
          '15',
          '15',
          '12',
          '11',
        ],
        'Marketing spend': [
          '240',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '200',
          '200',
          '200',
          '200',
          '200',
          '200',
          '200',
          '200',
          '200',
          '200',
          '200',
          '360',
          '360',
          '360',
          '360',
          '360',
          '360',
          '360',
          '360',
          '360',
          '450',
          '450',
          '450',
          '450',
          '450',
          '450',
          '450',
          '450',
          '450',
          '450',
          '450',
          '450',
          '450',
          '108',
          '108',
          '108',
          '108',
          '108',
          '108',
          '36',
          '36',
          '36',
          '36',
          '36',
          '36',
        ],
        'Lease signed': [
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
        ],
        'Conversion rate': [
          '1',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '3',
          '3',
          '3',
          '3',
          '3',
          '4',
          '4',
          '4',
          '3',
          '3',
          '3',
          '3',
          '3',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '1',
          '1',
          '1',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '3',
          '3',
          '3',
          '4',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
        ],
        'TAC/channel': [
          '240',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '300',
          '200',
          '200',
          '200',
          '200',
          '200',
          '200',
          '200',
          '200',
          '200',
          '200',
          '200',
          '360',
          '360',
          '360',
          '360',
          '360',
          '360',
          '360',
          '360',
          '360',
          '450',
          '450',
          '450',
          '450',
          '450',
          '450',
          '450',
          '450',
          '450',
          '450',
          '450',
          '450',
          '450',
          '108',
          '108',
          '108',
          '108',
          '108',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
        ],
      },
      Zillow: {
        Leads: [
          '8',
          '8',
          '8',
          '7',
          '7',
          '7',
          '7',
          '7',
          '6',
          '6',
          '6',
          '5',
          '5',
          '5',
          '4',
          '4',
          '4',
          '4',
          '4',
          '4',
          '5',
          '5',
          '5',
          '6',
          '6',
          '7',
          '7',
          '8',
          '8',
          '8',
          '8',
          '9',
          '9',
          '9',
          '9',
          '9',
          '10',
          '9',
          '10',
          '10',
          '11',
          '11',
          '11',
          '11',
          '11',
          '11',
          '10',
          '9',
          '8',
          '7',
          '6',
          '5',
          '5',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
        ],
        'Marketing spend': [
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
        ],
        'Lease signed': [
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
        ],
        'Conversion rate': [
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
        ],
        'TAC/channel': [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
        ],
      },
      Facebook: {
        Leads: [
          '51',
          '54',
          '57',
          '61',
          '61',
          '66',
          '70',
          '75',
          '76',
          '73',
          '75',
          '72',
          '69',
          '66',
          '68',
          '64',
          '62',
          '55',
          '53',
          '51',
          '55',
          '53',
          '56',
          '55',
          '57',
          '60',
          '58',
          '61',
          '64',
          '63',
          '63',
          '62',
          '61',
          '65',
          '64',
          '63',
          '63',
          '62',
          '61',
          '63',
          '61',
          '59',
          '54',
          '53',
          '47',
          '49',
          '50',
          '45',
          '43',
          '38',
          '35',
          '33',
          '30',
          '27',
          '28',
          '24',
          '21',
          '22',
          '19',
          '20',
        ],
        'Marketing spend': [
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '300',
          '300',
          '300',
          '360',
          '360',
          '540',
          '540',
          '540',
          '540',
          '540',
          '540',
          '240',
          '360',
          '540',
          '540',
          '540',
          '540',
          '540',
          '540',
          '540',
          '120',
          '120',
          '120',
          '540',
          '120',
          '120',
          '120',
          '60',
          '60',
          '24',
          '24',
          '24',
          '24',
          '24',
          '24',
        ],
        'Lease signed': [
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '2',
          '2',
          '1',
          '2',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '0',
          '0',
          '0',
          '0',
          '0',
        ],
        'Conversion rate': [
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '1',
          '3',
          '3',
          '1',
          '3',
          '1',
          '1',
          '2',
          '1',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '3',
          '3',
          '3',
          '3',
          '4',
          '4',
          '0',
          '0',
          '0',
          '0',
          '0',
        ],
        'TAC/channel': [
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '90',
          '90',
          '180',
          '90',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '180',
          '300',
          '300',
          '300',
          '360',
          '360',
          '540',
          '540',
          '540',
          '540',
          '540',
          '540',
          '240',
          '360',
          '540',
          '540',
          '540',
          '540',
          '540',
          '540',
          '540',
          '120',
          '120',
          '120',
          '540',
          '120',
          '120',
          '120',
          '60',
          '60',
          '24',
          '',
          '',
          '',
          '',
          '',
        ],
      },
      'Appartments.com': {
        Leads: [
          '13',
          '13',
          '13',
          '12',
          '12',
          '12',
          '11',
          '11',
          '11',
          '10',
          '10',
          '9',
          '8',
          '8',
          '7',
          '7',
          '7',
          '6',
          '6',
          '6',
          '7',
          '7',
          '8',
          '8',
          '9',
          '10',
          '10',
          '10',
          '11',
          '10',
          '9',
          '9',
          '9',
          '9',
          '8',
          '8',
          '8',
          '8',
          '8',
          '8',
          '8',
          '8',
          '7',
          '7',
          '7',
          '7',
          '7',
          '6',
          '6',
          '5',
          '4',
          '4',
          '4',
          '3',
          '3',
          '3',
          '2',
          '2',
          '2',
          '2',
        ],
        'Marketing spend': [
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '60',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '120',
          '24',
          '24',
          '24',
          '24',
          '24',
          '24',
        ],
        'Lease signed': [
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
        ],
        'Conversion rate': [
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
          '0',
        ],
        'TAC/channel': [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
        ],
      },
      'Direct (website)': {
        Leads: [
          '28',
          '29',
          '30',
          '29',
          '26',
          '25',
          '23',
          '21',
          '17',
          '17',
          '17',
          '16',
          '14',
          '13',
          '13',
          '12',
          '13',
          '12',
          '13',
          '14',
          '16',
          '17',
          '19',
          '20',
          '22',
          '24',
          '25',
          '26',
          '26',
          '24',
          '22',
          '20',
          '18',
          '16',
          '13',
          '10',
          '7',
          '7',
          '7',
          '8',
          '8',
          '8',
          '8',
          '8',
          '8',
          '10',
          '11',
          '11',
          '12',
          '11',
          '11',
          '10',
          '10',
          '13',
          '13',
          '12',
          '9',
          '9',
          '7',
          '7',
        ],
        'Marketing spend': [
          '720',
          '840',
          '840',
          '840',
          '740',
          '740',
          '740',
          '740',
          '640',
          '540',
          '540',
          '440',
          '460',
          '0',
          '0',
          '0',
          '0',
          '100',
          '100',
          '100',
          '200',
          '200',
          '300',
          '300',
          '400',
          '380',
          '220',
          '320',
          '360',
          '360',
          '180',
          '180',
          '180',
          '280',
          '280',
          '130',
          '470',
          '350',
          '290',
          '210',
          '270',
          '270',
          '170',
          '170',
          '70',
          '490',
          '490',
          '290',
          '292',
          '512',
          '412',
          '312',
          '272',
          '232',
          '436',
          '336',
          '296',
          '296',
          '196',
          '196',
        ],
        'Lease signed': [
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '1',
          '1',
          '1',
          '0',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '2',
          '1',
          '1',
          '0',
          '0',
          '1',
          '0',
          '1',
          '1',
          '2',
          '1',
          '1',
          '1',
          '1',
        ],
        'Conversion rate': [
          '7',
          '7',
          '7',
          '7',
          '8',
          '8',
          '9',
          '5',
          '6',
          '6',
          '0',
          '6',
          '7',
          '8',
          '8',
          '9',
          '8',
          '8',
          '8',
          '7',
          '6',
          '6',
          '5',
          '5',
          '5',
          '4',
          '4',
          '4',
          '8',
          '8',
          '9',
          '10',
          '11',
          '12',
          '15',
          '19',
          '28',
          '28',
          '30',
          '25',
          '24',
          '24',
          '26',
          '27',
          '26',
          '21',
          '18',
          '9',
          '8',
          '0',
          '0',
          '10',
          '0',
          '8',
          '8',
          '17',
          '11',
          '11',
          '14',
          '15',
        ],
        'TAC/channel': [
          '360',
          '420',
          '420',
          '420',
          '370',
          '370',
          '370',
          '740',
          '640',
          '540',
          '',
          '440',
          '460',
          '-',
          '-',
          '-',
          '-',
          '100',
          '100',
          '100',
          '200',
          '200',
          '300',
          '300',
          '400',
          '380',
          '220',
          '320',
          '180',
          '180',
          '90',
          '90',
          '90',
          '140',
          '140',
          '65',
          '235',
          '175',
          '145',
          '105',
          '135',
          '135',
          '85',
          '85',
          '35',
          '245',
          '245',
          '290',
          '292',
          '',
          '',
          '312',
          '',
          '232',
          '436',
          '168',
          '296',
          '296',
          '196',
          '196',
        ],
      },
      'Upcoming Vacancy (i) - (next 60 days)': [
        '18',
        '18',
        '18',
        '18',
        '17',
        '17',
        '17',
        '17',
        '16',
        '15',
        '15',
        '14',
        '13',
        '12',
        '12',
        '11',
        '11',
        '10',
        '10',
        '10',
        '11',
        '11',
        '12',
        '12',
        '13',
        '14',
        '14',
        '15',
        '16',
        '16',
        '16',
        '16',
        '16',
        '17',
        '17',
        '17',
        '17',
        '17',
        '17',
        '18',
        '18',
        '18',
        '17',
        '17',
        '16',
        '16',
        '16',
        '14',
        '13',
        '11',
        '10',
        '9',
        '8',
        '7',
        '7',
        '6',
        '5',
        '5',
        '4',
        '4',
      ],
      'Upcoming vacancy (%)': [
        '5.46',
        '5.46',
        '5.41',
        '5.35',
        '5.30',
        '5.24',
        '5.19',
        '5.14',
        '4.88',
        '4.64',
        '4.41',
        '4.19',
        '3.98',
        '3.78',
        '3.59',
        '3.41',
        '3.24',
        '3.08',
        '2.92',
        '3.07',
        '3.22',
        '3.38',
        '3.55',
        '3.73',
        '3.92',
        '4.11',
        '4.32',
        '4.54',
        '4.76',
        '4.81',
        '4.86',
        '4.91',
        '4.96',
        '5.01',
        '5.06',
        '5.11',
        '5.16',
        '5.21',
        '5.26',
        '5.31',
        '5.37',
        '5.37',
        '5.10',
        '5.05',
        '5.00',
        '4.95',
        '4.70',
        '4.23',
        '3.81',
        '3.43',
        '3.08',
        '2.78',
        '2.50',
        '2.25',
        '2.02',
        '1.82',
        '1.64',
        '1.47',
        '1.33',
        '1.19',
      ],
      Leads: [
        '257',
        '257',
        '257',
        '257',
        '243',
        '243',
        '243',
        '243',
        '229',
        '214',
        '214',
        '200',
        '186',
        '171',
        '171',
        '157',
        '157',
        '143',
        '143',
        '143',
        '157',
        '157',
        '171',
        '171',
        '186',
        '200',
        '200',
        '214',
        '229',
        '229',
        '229',
        '229',
        '229',
        '243',
        '243',
        '243',
        '243',
        '243',
        '243',
        '257',
        '257',
        '257',
        '243',
        '243',
        '229',
        '229',
        '229',
        '200',
        '186',
        '157',
        '143',
        '129',
        '114',
        '100',
        '100',
        '86',
        '71',
        '71',
        '57',
        '57',
      ],
      'Marketing spend': [
        '1800',
        '1800',
        '1800',
        '1800',
        '1700',
        '1700',
        '1700',
        '1700',
        '1600',
        '1500',
        '1500',
        '1400',
        '1300',
        '1200',
        '1200',
        '1100',
        '1100',
        '1000',
        '1000',
        '1000',
        '1100',
        '1100',
        '1200',
        '1200',
        '1300',
        '1400',
        '1400',
        '1500',
        '1600',
        '1600',
        '1600',
        '1600',
        '1600',
        '1700',
        '1700',
        '1700',
        '1700',
        '1700',
        '1700',
        '1800',
        '1800',
        '1800',
        '1700',
        '1700',
        '1600',
        '1600',
        '1600',
        '1400',
        '1300',
        '1100',
        '1000',
        '900',
        '800',
        '700',
        '700',
        '600',
        '500',
        '500',
        '400',
        '400',
      ],
      'Tenants acquired (lease signed)': [
        '5',
        '5',
        '5',
        '5',
        '5',
        '5',
        '5',
        '5',
        '5',
        '4',
        '4',
        '4',
        '4',
        '3',
        '3',
        '3',
        '3',
        '3',
        '3',
        '3',
        '3',
        '3',
        '3',
        '3',
        '4',
        '4',
        '4',
        '4',
        '5',
        '5',
        '5',
        '5',
        '5',
        '5',
        '5',
        '5',
        '5',
        '5',
        '5',
        '5',
        '5',
        '5',
        '5',
        '5',
        '5',
        '5',
        '5',
        '4',
        '4',
        '3',
        '3',
        '3',
        '2',
        '2',
        '2',
        '2',
        '1',
        '1',
        '1',
        '1',
      ],
      'Marketing TAC': [
        '360',
        '360',
        '360',
        '360',
        '340',
        '340',
        '340',
        '340',
        '320',
        '375',
        '375',
        '350',
        '325',
        '400',
        '400',
        '367',
        '367',
        '333',
        '333',
        '333',
        '367',
        '367',
        '400',
        '400',
        '325',
        '350',
        '350',
        '375',
        '320',
        '320',
        '320',
        '320',
        '320',
        '340',
        '340',
        '340',
        '340',
        '340',
        '340',
        '360',
        '360',
        '360',
        '340',
        '340',
        '320',
        '320',
        '320',
        '350',
        '325',
        '367',
        '333',
        '300',
        '400',
        '350',
        '350',
        '300',
        '500',
        '500',
        '400',
        '400',
      ],
    },
  },
};
