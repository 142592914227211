import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Grid, Row, Col } from 'rsuite';
import { ControlLabel, Panel, Button, Icon } from 'rsuite';
import Step1Form from './StepsForms/Step1Form';
import Step2Form from './StepsForms/Step2Form';
import Step3Form from './StepsForms/Step3Form';
import Step4Form from './StepsForms/Step4Form';
import Step5Form from './StepsForms/Step5Form';
import Step6Form from './StepsForms/Step6Form';
import Step7Form from './StepsForms/Step7Form';
import Step8Form from './StepsForms/Step8Form';
import Step9Form from './StepsForms/Step9Form';

const Step10 = ({ roomieProfileData, setroomieProfileData, formErrors, setFormErrors, isModel }) => {
  const [width, setWidth] = useState(null);

  const [isStepEdit, setIsStepEdit] = useState({
    step1: true,
    step2: true,
    step3: true,
    step4: true,
    step5: true,
    step6: true,
    step7: true,
    step8: true,
    step9: true,
  });

  const [isEditBtnShow, setIsEditBtnShow] = useState({
    editBtnStep1: true,
    editBtnStep2: true,
    editBtnStep3: true,
    editBtnStep4: true,
    editBtnStep5: true,
    editBtnStep6: true,
    editBtnStep7: true,
    editBtnStep8: true,
    editBtnStep9: true,
  });

  const FormTitle = styled('h3')`
    font-size: 24px;
    font-weight: 600;
  `;
  const FormTagline = styled('h4')`
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 30px;
  `;

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <>
      <FormTitle>Your Work</FormTitle>
      <Panel shaded style={{ overflow: 'inherit' }}>
        <div className="position-relative">
          <div className="position-absolute top-0 end-0">
            {isEditBtnShow.editBtnStep1 ? (
              <Button
                appearance="link"
                className="p-0"
                onClick={() => {
                  setIsStepEdit({ ...isStepEdit, step1: false });
                  setIsEditBtnShow({ ...isEditBtnShow, editBtnStep1: false });
                }}>
                <Icon icon="edit2" className="p-1" />
                Edit
              </Button>
            ) : null}
          </div>
          {isStepEdit.step1 ? (
            isModel ? (
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={20} sm={20} md={20}>
                    <Row className={width > 480 ? 'd-flex align-items-center' : ''}>
                      <Col xs={24} sm={12} md={12}>
                        <ControlLabel className="mb-0">Title / Program : </ControlLabel>
                      </Col>
                      <Col xs={24} sm={12} md={12}>
                        <div className="  ">
                          {' '}
                          <p className="m-0 h6 ">
                            {roomieProfileData ? (roomieProfileData.title ? roomieProfileData.title : null) : ' '}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={20} sm={20} md={20}>
                    <Row className={width > 480 ? 'd-flex align-items-center' : ''}>
                      <Col xs={24} sm={12} md={12}>
                        <ControlLabel className="mb-0">Company / School : </ControlLabel>
                      </Col>
                      <Col xs={24} sm={12} md={12}>
                        <div className=" ">
                          {' '}
                          <p className="m-0 h6">
                            {roomieProfileData ? (roomieProfileData.company ? roomieProfileData.company : null) : ' '}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Grid>
            ) : (
              <div>
                <Row className="show-grid">
                  <Col xs={20} sm={20} md={20}>
                    <div className="d-flex flex-column flex-sm-row align-items-sm-center">
                      <ControlLabel className="mb-0">Title / Program : </ControlLabel>
                      <div className=" ms-sm-3 mb-1">
                        {' '}
                        <p className="m-0 h6 ">
                          {roomieProfileData ? (roomieProfileData.title ? roomieProfileData.title : null) : ' '}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col xs={20} sm={20} md={20}>
                    <div className="d-flex flex-column flex-sm-row align-items-sm-center">
                      <ControlLabel className="mb-0">Company / School : </ControlLabel>
                      <div className=" ms-sm-3 mb-1">
                        {' '}
                        <p className="m-0 h6">
                          {roomieProfileData ? (roomieProfileData.company ? roomieProfileData.company : null) : ' '}
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            )
          ) : (
            <div>
              <Step1Form
                shadow={true}
                roomieProfileData={roomieProfileData}
                setroomieProfileData={setroomieProfileData}
                formErrors={formErrors}
                setFormErrors={setFormErrors}
              />
              <Button
                className="mt-2 "
                size="sm"
                style={{ width: '100px' }}
                onClick={() => {
                  setIsStepEdit({ ...isStepEdit, step1: true });
                  setIsEditBtnShow({ ...isEditBtnShow, editBtnStep1: true });
                }}
                appearance="primary">
                Continue
              </Button>
            </div>
          )}
        </div>
      </Panel>

      {/* STEP 2  */}

      <FormTitle className="mt-4">Your schedule</FormTitle>
      <Panel shaded style={{ overflow: 'inherit' }}>
        <div className="position-relative">
          {isStepEdit.step2 ? (
            <div>
              <div className="position-absolute top-0 end-0">
                {isEditBtnShow.editBtnStep2 ? (
                  <Button
                    appearance="link"
                    className="p-0"
                    onClick={() => {
                      setIsStepEdit({ ...isStepEdit, step2: false });
                      setIsEditBtnShow({ ...isEditBtnShow, editBtnStep2: false });
                    }}>
                    <Icon icon="edit2" className="p-1" />
                    Edit
                  </Button>
                ) : null}
              </div>
              {isModel ? (
                <div>
                  <Row className="show-grid">
                    <Col xs={20} sm={20} md={20}>
                      <FormTagline className="mb-2">On a weekday when do you typically...</FormTagline>
                      <div className="d-flex flex-column flex-sm-row align-items-sm-center">
                        <ControlLabel className="mb-0">Wake Up : </ControlLabel>
                        <div className="">
                          <p className="m-0 h6 ">
                            {roomieProfileData?.weekDayData
                              ? roomieProfileData.weekDayData.from.slice(10, 21) +
                                ' - ' +
                                roomieProfileData.weekDayData.to.slice(10, 21)
                              : null}
                          </p>
                        </div>
                      </div>
                      <div className="d-flex flex-column flex-sm-row align-items-sm-center">
                        <ControlLabel className="mb-0">Go to bed : </ControlLabel>
                        <div className="">
                          <p className="m-0 h6 ">
                            {roomieProfileData?.weekDayToBed
                              ? roomieProfileData.weekDayToBed.from.slice(10, 21) +
                                ' - ' +
                                roomieProfileData.weekDayToBed.to.slice(10, 21)
                              : null}
                          </p>
                        </div>
                      </div>
                      <FormTagline className="mt-2 mb-2">On a weekend when do you typically...</FormTagline>
                      <div className="d-flex flex-column flex-sm-row align-items-sm-center">
                        <ControlLabel className="mb-0">Wake Up : </ControlLabel>
                        <div className="">
                          <p className="m-0 h6 ">
                            {roomieProfileData?.weekEndData
                              ? roomieProfileData.weekEndData.from.slice(10, 21) +
                                ' - ' +
                                roomieProfileData.weekEndData.to.slice(10, 21)
                              : null}
                          </p>
                        </div>
                      </div>
                      <div className="d-flex flex-column flex-sm-row align-items-sm-center">
                        <ControlLabel className="mb-0">Go to bed : </ControlLabel>
                        <div className=" ">
                          <p className="m-0 h6 ">
                            {roomieProfileData?.weekEndToBed
                              ? roomieProfileData.weekEndToBed.from.slice(10, 21) +
                                ' - ' +
                                roomieProfileData.weekEndToBed.to.slice(10, 21)
                              : null}
                          </p>
                        </div>
                      </div>
                      <FormTagline className="mb-2 mt-2">When do you typically shower?</FormTagline>
                      <div className=" mb-1">
                        <ul>
                          <li className="m-0 h6 ">
                            {roomieProfileData
                              ? roomieProfileData.takeShowerOn
                                ? roomieProfileData.takeShowerOn
                                : null
                              : ' '}
                          </li>
                        </ul>
                      </div>
                      <FormTagline className="mb-2 mt-2">
                        Would you be bothered if a roomie was quietly playing TV while you were falling asleep?
                      </FormTagline>
                      <div className=" mb-1">
                        <ul>
                          <li className="m-0 h6 ">
                            {roomieProfileData ? (roomieProfileData.bothered ? roomieProfileData.bothered : null) : ' '}
                          </li>
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </div>
              ) : (
                <div>
                  <Row className="show-grid">
                    <Col xs={20} sm={20} md={20}>
                      <FormTagline className="mb-2">On a weekday when do you typically...</FormTagline>
                      <div className="d-flex align-items-center">
                        <ControlLabel className="mb-0">Wake Up : </ControlLabel>
                        <div className=" ms-3 mb-1">
                          <p className="m-0 h6 ">
                            {roomieProfileData?.weekDayData
                              ? roomieProfileData.weekDayData.from.slice(10, 21) +
                                ' - ' +
                                roomieProfileData.weekDayData.to.slice(10, 21)
                              : null}
                          </p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <ControlLabel className="mb-0">Go to bed : </ControlLabel>
                        <div className=" ms-3 mb-1">
                          <p className="m-0 h6 ">
                            {roomieProfileData?.weekDayToBed
                              ? roomieProfileData.weekDayToBed.from.slice(10, 21) +
                                ' - ' +
                                roomieProfileData.weekDayToBed.to.slice(10, 21)
                              : null}
                          </p>
                        </div>
                      </div>
                      <FormTagline className="mt-2 mb-2">On a weekend when do you typically...</FormTagline>
                      <div className="d-flex align-items-center">
                        <ControlLabel className="mb-0">Wake Up : </ControlLabel>
                        <div className=" ms-3 mb-1">
                          <p className="m-0 h6 ">
                            {roomieProfileData?.weekEndData
                              ? roomieProfileData.weekEndData.from.slice(10, 21) +
                                ' - ' +
                                roomieProfileData.weekEndData.to.slice(10, 21)
                              : null}
                          </p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <ControlLabel className="mb-0">Go to bed : </ControlLabel>
                        <div className=" ms-3 mb-1">
                          <p className="m-0 h6 ">
                            {roomieProfileData?.weekEndToBed
                              ? roomieProfileData.weekEndToBed.from.slice(10, 21) +
                                ' - ' +
                                roomieProfileData.weekEndToBed.to.slice(10, 21)
                              : null}
                          </p>
                        </div>
                      </div>
                      <FormTagline className="mb-2 mt-2">When do you typically shower?</FormTagline>
                      <div className=" mb-1">
                        <ul>
                          <li className="m-0 h6 ">
                            {roomieProfileData
                              ? roomieProfileData.takeShowerOn
                                ? roomieProfileData.takeShowerOn
                                : null
                              : ' '}
                          </li>
                        </ul>
                      </div>
                      <FormTagline className="mb-2 mt-2">
                        Would you be bothered if a roomie was quietly playing TV while you were falling asleep?
                      </FormTagline>
                      <div className=" mb-1">
                        <ul>
                          <li className="m-0 h6 ">
                            {roomieProfileData ? (roomieProfileData.bothered ? roomieProfileData.bothered : null) : ' '}
                          </li>
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
            </div>
          ) : (
            <div>
              <Step2Form
                shadow={true}
                roomieProfileData={roomieProfileData}
                setroomieProfileData={setroomieProfileData}
                formErrors={formErrors}
                setFormErrors={setFormErrors}
              />
              <Button
                className="mt-2 "
                size="sm"
                style={{ width: '100px' }}
                onClick={() => {
                  setIsStepEdit({ ...isStepEdit, step2: true });
                  setIsEditBtnShow({ ...isEditBtnShow, editBtnStep2: true });
                }}
                appearance="primary">
                Continue
              </Button>
            </div>
          )}
        </div>
      </Panel>

      {/* STEP 3  */}

      <FormTitle className="mt-4">Weekend Fun</FormTitle>
      <Panel shaded style={{ overflow: 'inherit' }}>
        <div className="position-relative">
          {isStepEdit.step3 ? (
            <div>
              <div className="position-absolute top-0 end-0">
                {isEditBtnShow.editBtnStep3 ? (
                  <Button
                    appearance="link"
                    className="p-0"
                    onClick={() => {
                      setIsStepEdit({ ...isStepEdit, step3: false });
                      setIsEditBtnShow({ ...isEditBtnShow, editBtnStep3: false });
                    }}>
                    <Icon icon="edit2" className="p-1" />
                    Edit
                  </Button>
                ) : null}
              </div>

              <div>
                <Row className="show-grid">
                  <Col xs={20} sm={20} md={20}>
                    <FormTagline className="mt-2 mb-2">Which is Your First choice?</FormTagline>
                    <div className=" mb-1">
                      <ul>
                        <li className="m-0 h6 ">
                          {roomieProfileData
                            ? roomieProfileData.firstChoice
                              ? roomieProfileData.firstChoice
                              : null
                            : ' '}
                        </li>
                      </ul>
                    </div>

                    <FormTagline className="mb-2 mt-2">Second choice?</FormTagline>
                    <div className=" mb-1">
                      <ul>
                        <li className="m-0 h6 ">
                          {roomieProfileData
                            ? roomieProfileData.secondChoice
                              ? roomieProfileData.secondChoice
                              : null
                            : ' '}
                        </li>
                      </ul>
                    </div>
                    <FormTagline className="mb-2 mt-2">Third choice?</FormTagline>
                    <div className=" mb-1">
                      <ul>
                        <li className="m-0 h6 ">
                          {roomieProfileData
                            ? roomieProfileData.thirdChoice
                              ? roomieProfileData.thirdChoice
                              : null
                            : ' '}
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          ) : (
            <div>
              <Step3Form
                shadow={true}
                roomieProfileData={roomieProfileData}
                setroomieProfileData={setroomieProfileData}
                formErrors={formErrors}
                setFormErrors={setFormErrors}
              />
              <Button
                className="mt-2 "
                size="sm"
                style={{ width: '100px' }}
                onClick={() => {
                  setIsStepEdit({ ...isStepEdit, step3: true });
                  setIsEditBtnShow({ ...isEditBtnShow, editBtnStep3: true });
                }}
                appearance="primary">
                Continue
              </Button>
            </div>
          )}
        </div>
      </Panel>

      {/* STEP 4  */}

      <FormTitle className="mt-4">Keeping clean</FormTitle>
      <Panel shaded style={{ overflow: 'inherit' }}>
        <div className="position-relative">
          {isStepEdit.step4 ? (
            <div>
              <div className="position-absolute top-0 end-0">
                {isEditBtnShow.editBtnStep3 ? (
                  <Button
                    appearance="link"
                    className="p-0"
                    onClick={() => {
                      setIsStepEdit({ ...isStepEdit, step4: false });
                      setIsEditBtnShow({ ...isEditBtnShow, editBtnStep4: false });
                    }}>
                    <Icon icon="edit2" className="p-1" />
                    Edit
                  </Button>
                ) : null}
              </div>
              <div>
                <Row className="show-grid">
                  <Col xs={20} sm={20} md={20}>
                    <FormTagline className="mt-2 mb-2">How long do you leave dishes in the sink?.</FormTagline>
                    <div className=" mb-1">
                      <ul>
                        <li className="m-0 h6 ">
                          {roomieProfileData
                            ? roomieProfileData.dishesInSink
                              ? roomieProfileData.dishesInSink
                              : null
                            : ' '}
                        </li>
                      </ul>
                    </div>

                    <FormTagline className="mb-2 mt-2">Do dirty clothes go on the floor/furniture?</FormTagline>
                    <div className=" mb-1">
                      <ul>
                        <li className="m-0 h6 ">
                          {roomieProfileData
                            ? roomieProfileData.dirtyClothes
                              ? roomieProfileData.dirtyClothes
                              : null
                            : ' '}
                        </li>
                      </ul>
                    </div>
                    <FormTagline className="mb-2 mt-2">
                      If you cook, how long do you leave crumbs and spills?
                    </FormTagline>
                    <div className=" mb-1">
                      <ul>
                        <li className="m-0 h6 ">
                          {roomieProfileData ? (roomieProfileData.crumbs ? roomieProfileData.crumbs : null) : ' '}
                        </li>
                      </ul>
                    </div>
                    <FormTagline className="mb-2 mt-2">How often do you empty garbage bins?</FormTagline>
                    <div className=" mb-1">
                      <ul>
                        <li className="m-0 h6 ">
                          {roomieProfileData
                            ? roomieProfileData.garbageBins
                              ? roomieProfileData.garbageBins
                              : null
                            : ' '}
                        </li>
                      </ul>
                    </div>
                    <FormTagline className="mb-2 mt-2">Do you ever have food go bad in the fridge?</FormTagline>
                    <div className=" mb-1">
                      <ul>
                        <li className="m-0 h6 ">
                          {roomieProfileData
                            ? roomieProfileData.foodInFridge
                              ? roomieProfileData.foodInFridge
                              : null
                            : ' '}
                        </li>
                      </ul>
                    </div>
                    <FormTagline className="mb-2 mt-2">
                      How much mess from roommates are you okay with in shared spaces?
                    </FormTagline>
                    <div className=" mb-1">
                      <ul>
                        <li className="m-0 h6 ">
                          {roomieProfileData
                            ? roomieProfileData.messFromRoommates
                              ? roomieProfileData.messFromRoommates
                              : null
                            : ' '}
                        </li>
                      </ul>
                    </div>
                    <FormTagline className="mb-2 mt-2">
                      What would you expect from a roomie who&apos;s messier than you?
                    </FormTagline>
                    <div className=" mb-1">
                      <ul>
                        <li className="m-0 h6 ">
                          {roomieProfileData
                            ? roomieProfileData.expectFromARoomie
                              ? roomieProfileData.expectFromARoomie
                              : null
                            : ' '}
                        </li>
                      </ul>
                    </div>
                    <FormTagline className="mb-2 mt-2">
                      Would you live with a roomie who expects you to be cleaner?
                    </FormTagline>
                    <div className=" mb-1">
                      <ul>
                        <li className="m-0 h6 ">
                          {roomieProfileData ? (roomieProfileData.isCleaner ? roomieProfileData.isCleaner : null) : ' '}
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          ) : (
            <div>
              <Step4Form
                shadow={true}
                roomieProfileData={roomieProfileData}
                setroomieProfileData={setroomieProfileData}
                formErrors={formErrors}
                setFormErrors={setFormErrors}
              />
              <Button
                className="mt-2 "
                size="sm"
                style={{ width: '100px' }}
                onClick={() => {
                  setIsStepEdit({ ...isStepEdit, step4: true });
                  setIsEditBtnShow({ ...isEditBtnShow, editBtnStep4: true });
                }}
                appearance="primary">
                Continue
              </Button>
            </div>
          )}
        </div>
      </Panel>

      {/* STEP 5  */}

      <FormTitle className="mt-4">Cooking</FormTitle>
      <Panel shaded style={{ overflow: 'inherit' }}>
        <div className="position-relative">
          {isStepEdit.step5 ? (
            <div>
              <div className="position-absolute top-0 end-0">
                {isEditBtnShow.editBtnStep5 ? (
                  <Button
                    appearance="link"
                    className="p-0"
                    onClick={() => {
                      setIsStepEdit({ ...isStepEdit, step5: false });
                      setIsEditBtnShow({ ...isEditBtnShow, editBtnStep5: false });
                    }}>
                    <Icon icon="edit2" className="p-1" />
                    Edit
                  </Button>
                ) : null}
              </div>
              <div>
                <Row className="show-grid">
                  <Col xs={20} sm={20} md={20}>
                    <FormTagline className="mt-2 mb-2">How often do you cook at home?</FormTagline>

                    <div className=" mb-1">
                      <ul>
                        <li className="m-0 h6 ">
                          {roomieProfileData
                            ? roomieProfileData.cookAtHome
                              ? roomieProfileData.cookAtHome
                              : null
                            : ' '}
                        </li>
                      </ul>
                    </div>

                    <FormTagline className="mb-2 mt-2">What are your favourite foods?</FormTagline>
                    <div className=" mb-1">
                      <ul>
                        {roomieProfileData?.favouritFoods && (
                          <>
                            {roomieProfileData.favouritFoods.map((item, index) => {
                              return (
                                <li className="m-0 h6 " key={index}>
                                  {item}
                                </li>
                              );
                            })}
                          </>
                        )}
                      </ul>
                    </div>
                    <FormTagline className="mb-2 mt-2">
                      Would you be bothered if your roomies regularly cooked food with strong smells?
                    </FormTagline>
                    <div className=" mb-1">
                      <ul>
                        <li className="m-0 h6 ">
                          {roomieProfileData
                            ? roomieProfileData.isSmellBothered
                              ? roomieProfileData.isSmellBothered
                              : null
                            : ' '}
                        </li>
                      </ul>
                    </div>
                    <FormTagline className="mb-2 mt-2">Any dietary restrictions?</FormTagline>
                    <div className=" mb-1">
                      <ul>
                        {roomieProfileData?.dietaryRestrictions && (
                          <>
                            {roomieProfileData.dietaryRestrictions.map((item, index) => {
                              return (
                                <li className="m-0 h6 " key={index}>
                                  {item}
                                </li>
                              );
                            })}
                          </>
                        )}
                      </ul>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          ) : (
            <div>
              {' '}
              <Step5Form
                chkBoxShadow={true}
                shadow={true}
                roomieProfileData={roomieProfileData}
                setroomieProfileData={setroomieProfileData}
                formErrors={formErrors}
                setFormErrors={setFormErrors}
              />
              <Button
                className="mt-2 "
                size="sm"
                style={{ width: '100px' }}
                onClick={() => {
                  setIsStepEdit({ ...isStepEdit, step5: true });
                  setIsEditBtnShow({ ...isEditBtnShow, editBtnStep5: true });
                }}
                appearance="primary">
                Continue
              </Button>
            </div>
          )}
        </div>
      </Panel>

      {/* STEP 6  */}

      <FormTitle className="mt-4">Smoking</FormTitle>
      <Panel shaded style={{ overflow: 'inherit' }}>
        <div className="position-relative">
          {isStepEdit.step6 ? (
            <div>
              <div className="position-absolute top-0 end-0">
                {isEditBtnShow.editBtnStep6 ? (
                  <Button
                    appearance="link"
                    className="p-0"
                    onClick={() => {
                      setIsStepEdit({ ...isStepEdit, step6: false });
                      setIsEditBtnShow({ ...isEditBtnShow, editBtnStep6: false });
                    }}>
                    <Icon icon="edit2" className="p-1" />
                    Edit
                  </Button>
                ) : null}
              </div>
              <Row className="show-grid">
                <Col xs={20} sm={20} md={20}>
                  <div>
                    <FormTagline className="mb-2">Do you smoke at home...</FormTagline>
                    <div className="d-flex align-items-center">
                      <ControlLabel className="mb-0">Nicotine / Tobacco? : </ControlLabel>
                      <div className=" ms-3 mb-1">
                        <p className="m-0 h6 ">
                          {roomieProfileData
                            ? roomieProfileData.youTakeTobacco
                              ? roomieProfileData.youTakeTobacco
                              : null
                            : ' '}
                        </p>
                      </div>
                    </div>

                    <div className="d-flex align-items-center">
                      <ControlLabel className="mb-0">Cannabis? : </ControlLabel>
                      <div className=" ms-3 mb-1">
                        <p className="m-0 h6 ">
                          {roomieProfileData
                            ? roomieProfileData.youTakeTobacco
                              ? roomieProfileData.youTakeTobacco
                              : null
                            : ' '}
                        </p>
                      </div>
                    </div>
                    <FormTagline className="mt-2 mb-2">Is it okay if your roomies smoke at home...</FormTagline>
                    <div className="d-flex align-items-center">
                      <ControlLabel className="mb-0">Nicotine / Tobacco? : </ControlLabel>
                      <div className=" ms-3 mb-1">
                        <p className="m-0 h6 ">
                          {roomieProfileData
                            ? roomieProfileData.roomiesTakeTobacco
                              ? roomieProfileData.roomiesTakeTobacco
                              : null
                            : ' '}
                        </p>
                      </div>
                    </div>

                    <div className="d-flex align-items-center">
                      <ControlLabel className="mb-0">Cannabis? : </ControlLabel>
                      <div className=" ms-3 mb-1">
                        <p className="m-0 h6 ">
                          {roomieProfileData
                            ? roomieProfileData.roomiesTakeCannabis
                              ? roomieProfileData.roomiesTakeCannabis
                              : null
                            : ' '}
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            <div>
              <Step6Form
                shadow={true}
                roomieProfileData={roomieProfileData}
                setroomieProfileData={setroomieProfileData}
                formErrors={formErrors}
                setFormErrors={setFormErrors}
              />
              <Button
                className="mt-2 "
                size="sm"
                style={{ width: '100px' }}
                onClick={() => {
                  setIsStepEdit({ ...isStepEdit, step6: true });
                  setIsEditBtnShow({ ...isEditBtnShow, editBtnStep6: true });
                }}
                appearance="primary">
                Continue
              </Button>
            </div>
          )}
        </div>
      </Panel>

      {/* STEP 7  */}

      <FormTitle className="mt-4">Guests</FormTitle>
      <Panel shaded style={{ overflow: 'inherit' }}>
        <div className="position-relative">
          {isStepEdit.step7 ? (
            <div>
              <div className="position-absolute top-0 end-0">
                {isEditBtnShow.editBtnStep6 ? (
                  <Button
                    appearance="link"
                    className="p-0"
                    onClick={() => {
                      setIsStepEdit({ ...isStepEdit, step7: false });
                      setIsEditBtnShow({ ...isEditBtnShow, editBtnStep7: false });
                    }}>
                    <Icon icon="edit2" className="p-1" />
                    Edit
                  </Button>
                ) : null}
              </div>
              <div>
                <Row className="show-grid">
                  <Col xs={20} sm={20} md={20}>
                    <FormTagline className="mb-2">I would like a home that allows me to have...</FormTagline>
                    <div className="d-flex align-items-center">
                      <ControlLabel className="mb-0">1-2 guests... : </ControlLabel>
                      <div className=" ms-3 mb-1">
                        <p className="m-0 h6 ">
                          {roomieProfileData ? (roomieProfileData.twoGuests ? roomieProfileData.twoGuests : null) : ' '}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <ControlLabel className="mb-0">3+ guests... : </ControlLabel>
                      <div className=" ms-3 mb-1">
                        <p className="m-0 h6 ">
                          {roomieProfileData
                            ? roomieProfileData.threePlusGuests
                              ? roomieProfileData.threePlusGuests
                              : null
                            : ' '}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <ControlLabel className="mb-0">Overnight guests... : </ControlLabel>
                      <div className=" ms-3 mb-1">
                        <p className="m-0 h6 ">
                          {roomieProfileData
                            ? roomieProfileData.overnightGuests
                              ? roomieProfileData.overnightGuests
                              : null
                            : ' '}
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          ) : (
            <div>
              <Step7Form
                shadow={true}
                roomieProfileData={roomieProfileData}
                setroomieProfileData={setroomieProfileData}
                formErrors={formErrors}
                setFormErrors={setFormErrors}
              />
              <Button
                className="mt-2 "
                size="sm"
                style={{ width: '100px' }}
                onClick={() => {
                  setIsStepEdit({ ...isStepEdit, step7: true });
                  setIsEditBtnShow({ ...isEditBtnShow, editBtnStep7: true });
                }}
                appearance="primary">
                Continue
              </Button>
            </div>
          )}
        </div>
      </Panel>

      {/* STEP 8  */}

      <FormTitle className="mt-4">Roomies</FormTitle>
      <Panel shaded style={{ overflow: 'inherit' }}>
        <div className="position-relative">
          {isStepEdit.step8 ? (
            <div>
              <div className="position-absolute top-0 end-0">
                {isEditBtnShow.editBtnStep8 ? (
                  <Button
                    appearance="link"
                    className="p-0"
                    onClick={() => {
                      setIsStepEdit({ ...isStepEdit, step8: false });
                      setIsEditBtnShow({ ...isEditBtnShow, editBtnStep8: false });
                    }}>
                    <Icon icon="edit2" className="p-1" />
                    Edit
                  </Button>
                ) : null}
              </div>
              <Row className="show-grid">
                <Col xs={20} sm={20} md={20}>
                  <div>
                    <FormTagline className="mt-2 mb-2">Would you like to cook for each other?</FormTagline>

                    <div className=" mb-1">
                      <ul>
                        <li className="m-0 h6 ">
                          {roomieProfileData
                            ? roomieProfileData.cookForOthers
                              ? roomieProfileData.cookForOthers
                              : null
                            : ' '}
                        </li>
                      </ul>
                    </div>

                    <FormTagline className="mb-2 mt-2">Would you like to eat together?</FormTagline>
                    <div className=" mb-1">
                      <ul>
                        <li className="m-0 h6 ">
                          {roomieProfileData
                            ? roomieProfileData.eatTogethers
                              ? roomieProfileData.eatTogethers
                              : null
                            : ' '}
                        </li>
                      </ul>
                    </div>
                    <FormTagline className="mb-2 mt-2">Would you like to hangout together?</FormTagline>
                    <div className=" mb-1">
                      <ul>
                        <li className="m-0 h6 ">
                          {roomieProfileData
                            ? roomieProfileData.hangoutTogether
                              ? roomieProfileData.hangoutTogether
                              : null
                            : ' '}
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            <div>
              <Step8Form
                shadow={true}
                roomieProfileData={roomieProfileData}
                setroomieProfileData={setroomieProfileData}
                formErrors={formErrors}
                setFormErrors={setFormErrors}
              />
              <Button
                className="mt-2 "
                size="sm"
                style={{ width: '100px' }}
                onClick={() => {
                  setIsStepEdit({ ...isStepEdit, step8: true });
                  setIsEditBtnShow({ ...isEditBtnShow, editBtnStep8: true });
                }}
                appearance="primary">
                Continue
              </Button>
            </div>
          )}
        </div>
      </Panel>

      {/* STEP 9 */}

      <FormTitle className="mt-4">About you</FormTitle>
      <Panel shaded style={{ overflow: 'inherit' }}>
        <div className="position-relative">
          {isStepEdit.step9 ? (
            <div>
              <div className="position-absolute top-0 end-0">
                {isEditBtnShow.editBtnStep9 ? (
                  <Button
                    appearance="link"
                    className="p-0"
                    onClick={() => {
                      setIsStepEdit({ ...isStepEdit, step9: false });
                      setIsEditBtnShow({ ...isEditBtnShow, editBtnStep9: false });
                    }}>
                    <Icon icon="edit2" className="p-1" />
                    Edit
                  </Button>
                ) : null}
              </div>
              <Row className="show-grid">
                <Col xs={20} sm={20} md={20}>
                  <div>
                    <FormTagline className="mb-2">Do you have a preference?</FormTagline>
                    <div className="d-flex align-items-center">
                      <ControlLabel className="mb-0">Your gender : </ControlLabel>
                      <div className=" ms-3 mb-1">
                        <p className="m-0 h6 ">
                          {roomieProfileData
                            ? roomieProfileData.yourGender
                              ? roomieProfileData.yourGender
                              : null
                            : ' '}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <ControlLabel className="mb-0">All Genders : </ControlLabel>
                      <div className=" ms-3 mb-1">
                        <p className="m-0 h6 ">
                          {roomieProfileData
                            ? roomieProfileData.allGenders
                              ? roomieProfileData.allGenders
                              : null
                            : ' '}
                        </p>
                      </div>
                    </div>
                    <FormTagline className="mb-2 mt-2">What are your interests / hobbies / passions?</FormTagline>
                    <div className=" mb-1">
                      <ul>
                        {roomieProfileData?.yourInterests && (
                          <>
                            {roomieProfileData.yourInterests.map((item, index) => {
                              return (
                                <li className="m-0 h6 " key={index}>
                                  {item}
                                </li>
                              );
                            })}
                          </>
                        )}
                      </ul>
                    </div>
                    <FormTagline className="mb-2 mt-2">From 1-5, how extroverted are you?</FormTagline>
                    <div className=" mb-1">
                      <ul>
                        <li className="m-0 h6 ">
                          {roomieProfileData
                            ? roomieProfileData.extrovertedYouAre
                              ? roomieProfileData.extrovertedYouAre
                              : null
                            : ' '}
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            <div>
              <Step9Form
                chkBoxShadow={true}
                shadow={true}
                roomieProfileData={roomieProfileData}
                setroomieProfileData={setroomieProfileData}
                formErrors={formErrors}
                setFormErrors={setFormErrors}
              />
              <Button
                className="mt-2 "
                size="sm"
                style={{ width: '100px' }}
                onClick={() => {
                  setIsStepEdit({ ...isStepEdit, step9: true });
                  setIsEditBtnShow({ ...isEditBtnShow, editBtnStep9: true });
                }}
                appearance="primary">
                Continue
              </Button>
            </div>
          )}
        </div>
      </Panel>
    </>
  );
};

export default Step10;
// foodInFridge;
