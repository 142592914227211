// ------------------------ constants that won't require calling ---------------------------------- //

// dates in excel sheet
export const dates = [
  '5-Jul-21',
  '12-Jul-21',
  '19-Jul-21',
  '26-Jul-21',
  '2-Aug-21',
  '9-Aug-21',
  '16-Aug-21',
  '23-Aug-21',
  '30-Aug-21',
  '6-Sep-21',
  '13-Sep-21',
  '20-Sep-21',
  '27-Sep-21',
  '4-Oct-21',
  '11-Oct-21',
  '18-Oct-21',
  '25-Oct-21',
  '1-Nov-21',
  '8-Nov-21',
  '15-Nov-21',
  '22-Nov-21',
  '29-Nov-21',
  '6-Dec-21',
  '13-Dec-21',
  '20-Dec-21',
  '27-Dec-21',
  '3-Jan-22',
  '10-Jan-22',
  '17-Jan-22',
  '24-Jan-22',
  '31-Jan-22',
  '7-Feb-22',
  '14-Feb-22',
  '21-Feb-22',
  '28-Feb-22',
  '7-Mar-22',
  '14-Mar-22',
  '21-Mar-22',
  '28-Mar-22',
  '4-Apr-22',
  '11-Apr-22',
  '18-Apr-22',
  '25-Apr-22',
  '2-May-22',
  '9-May-22',
  '16-May-22',
  '23-May-22',
  '30-May-22',
  '6-Jun-22',
  '13-Jun-22',
  '20-Jun-22',
  '27-Jun-22',
  '4-Jul-22',
  '11-Jul-22',
  '18-Jul-22',
  '25-Jul-22',
  '1-Aug-22',
  '8-Aug-22',
  '15-Aug-22',
  '22-Aug-22',
];

// months in a year
export const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

// quarters in a calender year (not fiscal)
export const quarters = [
  ['Jan', 'Feb', 'Mar'],
  ['Apr', 'May', 'Jun'],
  ['Jul', 'Aug', 'Sep'],
  ['Oct', 'Nov', 'Dec'],
];

export const Qs = ['Q1', 'Q2', 'Q3', 'Q4'];
// self calling function that converts dates from excel into object with properties:
// {
//    month,
//    year,
//    quarter,
// }
export const useValues = (() => {
  const findQuarter = month => {
    if (quarters[0].includes(month)) {
      return 'Q1';
    }
    if (quarters[1].includes(month)) {
      return 'Q2';
    }
    if (quarters[2].includes(month)) {
      return 'Q3';
    }
    if (quarters[3].includes(month)) {
      return 'Q4';
    }
  };
  const dateBreakdown = date => {
    const breakdown = date.split('-');
    //   const day = breakdown[0];
    const month = breakdown[1];
    const year = breakdown[2];
    const quarter = findQuarter(month);
    return {
      month,
      year,
      quarter,
    };
  };

  let dateYears = [];
  let dateMonths = [];
  let dateQuarters = [];
  for (let x in dates) {
    let currentDate = dates[x];
    let date = dateBreakdown(currentDate);
    dateYears.push(date.year);
    dateMonths.push(date.month);
    dateQuarters.push(date.quarter);
  }
  return {
    Years: dateYears,
    Months: dateMonths,
    DateQuarters: dateQuarters,
  };
})();

// years in the current data set
export const years = [...new Set(useValues.Years)];

// self calling funtion that sets the range of values associated with each month, quarter, and year
export const valuesRanges = (() => {
  let valueRanges = {};
  for (let y in years) {
    const year = years[y];
    !valueRanges[year] && (valueRanges[year] = {});
    !valueRanges[year][year] && (valueRanges[year][year] = {});
    valueRanges[year][year] = {
      start: useValues.Years.indexOf(year),
      end: useValues.Years.lastIndexOf(year),
    };
    for (let j in months) {
      const month = months[j];
      !valueRanges[year][month] && (valueRanges[year][month] = {});
      const yearRange = useValues.Months.slice(valueRanges[year][year].start, valueRanges[year][year].end + 1);
      const monthIndex = yearRange.indexOf(month);
      const monthLastIndex = yearRange.lastIndexOf(month);
      valueRanges[year][month] = {
        start: monthIndex > -1 ? valueRanges[year][year].start + monthIndex : -1,
        end:
          monthIndex === -1
            ? -1
            : monthLastIndex === -1
            ? valueRanges[year][year].end
            : valueRanges[year][year].start + monthLastIndex,
      };
    }
    for (let i in quarters) {
      const quarter = quarters[i];
      const Q = `Q${Number(i) + 1}`;
      !valueRanges[year][Q] && (valueRanges[year][Q] = {});
      const yearRange = useValues.Months.slice(valueRanges[year][year].start, valueRanges[year][year].end + 1);
      const quarterIndex = yearRange.indexOf(quarter[0]);
      const quarterLastIndex = yearRange.lastIndexOf(quarter[2]);
      valueRanges[year][Q] = {
        start: quarterIndex > -1 ? valueRanges[year][year].start + quarterIndex : -1,
        end:
          quarterIndex === -1
            ? -1
            : quarterLastIndex === -1
            ? valueRanges[year][year].end
            : valueRanges[year][year].start + quarterLastIndex,
      };
    }
  }
  return valueRanges;
})();

//---------------------------- Functions That Need To Be Called ------------------------------------//

// need to be passed data unique to the page
export const dropDownOptions = fileData => {
  let filter = {
    portfolios: [],
    buildings: [],
  };
  let Portfolio = '';
  let Building = '';
  //console.log(fileData)
  for (const [portfolio, buildingData] of Object.entries(fileData)) {
    filter.portfolios.push(portfolio);
    filter[portfolio] = [];
    !Portfolio && (Portfolio = portfolio);
    for (const [buildingName, buildingData] of Object.entries(fileData[portfolio])) {
      !Building && (Building = buildingName);
      filter[portfolio].push(buildingName);
      filter.buildings.push(buildingName);
    }
  }

  for (const [path, pathValue] of Object.entries(fileData[Portfolio][Building])) {
    !filter.paths && (filter.paths = []);
    filter.paths.push(path);
  }
  return { ...filter };
};

// size must be length of dates array
// if this fails the array or fileData (Data.js) has an error.
// recommended fast fix is just to pass
export const splitIntoRanges = (array, size = dates.length) => {
  const evenSplit = [];
  while (array.length > 0) {
    const evenRange = array.splice(0, size);
    evenSplit.push(evenRange);
  }
  return evenSplit;
};

// graphs require x y value. you can perform math on the values
// you will receive an object with child objects as years
// then each year will have children of key value pairs for months and quarters
export const valuesGraph = results => {
  const valuesForRange = (data, resultsWithValues) => {
    for (let x in valuesRanges) {
      !resultsWithValues[x] && (resultsWithValues[x] = {});
      for (let y in valuesRanges[x]) {
        if (!years.includes(y)) {
          if (valuesRanges[x][y].start > -1 && valuesRanges[x][y].end > -1) {
            !resultsWithValues[x][y] && (resultsWithValues[x][y] = []);
            resultsWithValues[x][y] &&
              (resultsWithValues[x][y] = [
                ...resultsWithValues[x][y],
                ...data.slice(valuesRanges[x][y].start, valuesRanges[x][y].end + 1),
              ]);
          }
        } else {
          !resultsWithValues[x][y] && (resultsWithValues[x][y] = []);
          resultsWithValues[x][y] &&
            (resultsWithValues[x][y] = [
              ...resultsWithValues[x][y],
              ...data.slice(valuesRanges[x][y].start, valuesRanges[x][y].end + 1),
            ]);
        }
      }
    }
    return resultsWithValues;
  };
  let resultsWithValues = {};
  // valuesForRange(results[0], resultsWithValues);

  for (let x in results) {
    resultsWithValues = valuesForRange(results[x], resultsWithValues);
  }

  return resultsWithValues;
};

// projected values are often given at a cell level
// this would be the value at multiple buildings
export const valueAtWeek = (data, week) => {
  const currentWeek = dates.indexOf(week);
  let resultsProjected = [];
  for (let x in data) {
    resultsProjected.push(data[x][currentWeek]);
  }
  return resultsProjected;
};

// IF PAST 4 WEEK
// across multiple buildings
export const valueWeeksRange = (data, week, weeks) => {
  const currentWeek = dates.indexOf(findWeekString(week));

  let results4week = [];
  for (let x in data) {
    // If startingIndex < 0, set it to 0
    const startingIndex = Math.max(currentWeek - weeks, 0);
    results4week.push(data[x].slice(startingIndex, currentWeek));
  }
  return results4week;
};

export const getSumAndAverage = array => {
  const sum = array.reduce((a, b) => {
    let y = b.startsWith('(') ? b.replace('(', '-').replace(')', '') : b;
    return Number(a) + Number(y);
  }, 0);
  const avg = sum / array.length || 0;
  return {
    sum: Number(sum.toFixed(2)),
    avg: Number(avg.toFixed(2)),
  };
};

export const getArray = (fetch, fileData) => {
  const loopBuildings = (fetch, dataSet, response) => {
    const buildings = Array.from(fetch.buildings);
    if (buildings.length === 1) {
      fetch.path.forEach(x => {
        // Continue only if building selected is part of current portfolio (dataset)
        if (dataSet[buildings[0]]) {
          if (Array.isArray(dataSet[buildings[0]][x])) {
            response[x]
              ? (response[x] = [...response[x], ...dataSet[buildings[0]][x]])
              : (response[x] = [...dataSet[buildings[0]][x]]);
          } else {
            !response[x] && (response[x] = {});
            for (const [key, value] of Object.entries(dataSet[buildings[0]][x])) {
              response[x][key] ? (response[x][key] = [...response[x][key], ...value]) : (response[x][key] = [...value]);
            }
          }
        }
      });
      // this only works for arrays you need objects to copy code below
      return response;
    }
    for (const [buildingName, data] of Object.entries(dataSet)) {
      if (buildings.length === 0 || buildings.includes(buildingName)) {
        fetch.path.forEach(x => {
          if (Array.isArray(data[x])) {
            response[x] ? (response[x] = [...response[x], ...data[x]]) : (response[x] = [...data[x]]);
          } else {
            !response[x] && (response[x] = {});

            for (const [key, value] of Object.entries(data[x])) {
              response[x][key] ? (response[x][key] = [...response[x][key], ...value]) : (response[x][key] = [...value]);
            }
          }
        });
      }
    }
    return response;
  };

  let dateSet = fileData;
  let response = {};
  const portfolios = fetch.portfolios;
  if (portfolios.length === 1) {
    return loopBuildings(fetch, dateSet[portfolios[0]], response);
  }
  for (const [portfolio, buildingData] of Object.entries(fileData)) {
    // Fetch all data if both portfolio and building is NOT selected
    // Otherwise, fetch building data that matches portfolio selected
    if (portfolios.length === 0 || portfolios.includes(portfolio)) {
      response = loopBuildings(fetch, buildingData, response);
    }
  }

  return response;
};

export const weeklyAverageSum = (uniqueBuildings, method) => {
  let weeksstuff = [];
  for (let x in uniqueBuildings[0]) {
    for (let y in uniqueBuildings) {
      !weeksstuff[x] && (weeksstuff[x] = []);
      weeksstuff[x].push(uniqueBuildings[y][x]);
    }
  }
  let final = weeksstuff.map(x => (method === 'sum' ? getSumAndAverage(x).sum : getSumAndAverage(x).avg));
  return final;
};
export const lastNumberWeeks = (fileData, fetchData, week, weeks, method) => {
  const results = getArray(fetchData, fileData);
  let allGraphData = {};
  for (let x in results) {
    allGraphData[x] = {};
    let forGraph = [];
    if (Array.isArray(results[x])) {
      const resultsByBuilding = splitIntoRanges(results[x]);
      if (weeks < 1) {
        forGraph =
          method === 'sum'
            ? getSumAndAverage(valueAtWeek(resultsByBuilding, week)).sum
            : getSumAndAverage(valueAtWeek(resultsByBuilding, week)).avg;
      } else {
        const uniqueBuildings = valueWeeksRange(resultsByBuilding, week, weeks);
        forGraph = weeklyAverageSum(uniqueBuildings, method);
      }
      allGraphData[x] = forGraph;
    } else {
      for (let y in results[x]) {
        const resultsByBuilding = splitIntoRanges(results[x][y]);
        if (weeks < 1) {
          forGraph =
            method === 'sum'
              ? getSumAndAverage(valueAtWeek(resultsByBuilding, week)).sum
              : getSumAndAverage(valueAtWeek(resultsByBuilding, week)).avg;
        } else {
          const uniqueBuildings = valueWeeksRange(resultsByBuilding, week, weeks);
          forGraph = weeklyAverageSum(uniqueBuildings, method);
        }
        allGraphData[x][y] = forGraph;
      }
    }
  }
  return allGraphData;
};

export const weeklyByPeriod = (fileData, fetchData) => {
  const results = getArray(fetchData, fileData);
  let allGraphData = {};
  for (let x in results) {
    allGraphData[x] = {};
    if (Array.isArray(results[x])) {
      const resultsByBuilding = splitIntoRanges(results[x]);
      const forGraph = valuesGraph(resultsByBuilding);
      allGraphData[x] = forGraph;
    } else {
      for (let y in results[x]) {
        const resultsByBuilding = splitIntoRanges(results[x][y]);
        const forGraph = valuesGraph(resultsByBuilding);
        allGraphData[x][y] = forGraph;
      }
    }
  }
  return allGraphData;
};

// unique to each card fetch Data is an object with the following properties:
// {
//   portfolios: [],
//   buildings: [],
//   path: [], // required
// }
export const analyticsMaker = (fileData, fetchData) => {
  const results = getArray(fetchData, fileData);
  let allGraphData = {};
  for (let x in results) {
    allGraphData[x] = {};
    if (Array.isArray(results[x])) {
      const resultsByBuilding = splitIntoRanges(results[x]);
      const forGraph = valuesGraph(resultsByBuilding);
      allGraphData[x] = forGraph;
    } else {
      for (let y in results[x]) {
        const resultsByBuilding = splitIntoRanges(results[x][y]);
        const forGraph = valuesGraph(resultsByBuilding);
        allGraphData[x][y] = forGraph;
      }
    }
  }
  let computedGraphData = {};
  for (let x in allGraphData) {
    computedGraphData[x] = {};
    for (let y in allGraphData[x]) {
      computedGraphData[x][y] = {};
      for (let z in allGraphData[x][y]) {
        computedGraphData[x][y][z] = {};
        if (Array.isArray(allGraphData[x][y][z])) {
          computedGraphData[x][y][z] = getSumAndAverage(allGraphData[x][y][z]);
        } else {
          for (let i in allGraphData[x][y][z]) {
            computedGraphData[x][y][z][i] = getSumAndAverage(allGraphData[x][y][z][i]);
          }
        }
      }
    }
  }
  return computedGraphData;
};

export const SectionData = (results, method, section) => {
  let array = [];
  for (let i in results) {
    if (months.includes(i) && section !== 'Q') {
      method === 'sum' ? array.push({ x: i, y: results[i].sum }) : array.push({ x: i, y: results[i].avg });
    }
    if (Qs.includes(i) && section === 'Q') {
      method === 'sum' ? array.push({ x: i, y: results[i].sum }) : array.push({ x: i, y: results[i].avg });
    }
  }
  return array;
};

// Checks if dateToValidate is within 7 days of weekStartDate
// weekStartDate = hardcoded date retrieved from dates
// dateToValidate = endDate of dateRangePicker
const isDatePartOfThisWeek = (dateToValidate, weekStartDate) => {
  const sevenDaysInMilliseconds = 7 * 24 * 60 * 60 * 1000;
  return (
    weekStartDate.getTime() <= dateToValidate.getTime() &&
    weekStartDate.getTime() + sevenDaysInMilliseconds > dateToValidate.getTime()
  );
};

export const findWeekString = week => {
  let foundWeek = '';
  for (let x in dates) {
    if (isDatePartOfThisWeek(new Date(week), new Date(dates[x]))) {
      foundWeek = dates[x];
      break;
    }
  }
  return foundWeek;
};

export const getPreviousWeekDate = weekDate => {
  const sevenDaysInMilliseconds = 7 * 24 * 60 * 60 * 1000;
  return new Date(weekDate.getTime() - sevenDaysInMilliseconds);
};

const getNextWeekDate = weekDate => {
  const sevenDaysInMilliseconds = 7 * 24 * 60 * 60 * 1000;
  return new Date(weekDate.getTime() + sevenDaysInMilliseconds);
};

const findMatchingWeekIndexInPeriod = (dateToFind, periodStartDate, periodEndDate) => {
  let indexFound = false;
  let endOfPeriodReached = false;
  let periodStartBeingEvaluated = periodStartDate;
  let weekIndex = 0;
  while (!indexFound && !endOfPeriodReached) {
    if (isDatePartOfThisWeek(periodStartBeingEvaluated, dateToFind)) {
      indexFound = true;
    } else {
      weekIndex = weekIndex + 1;
      periodStartBeingEvaluated = getNextWeekDate(periodStartBeingEvaluated);
      endOfPeriodReached = periodStartBeingEvaluated.getTime() > periodEndDate.getTime();
    }
  }
  return indexFound ? weekIndex : -1;
};

const getNumberOfWeeksInPeriod = (periodStartDate, periodEndDate) => {
  let endOfPeriodReached = false;
  let periodStartBeingEvaluated = periodStartDate;
  let numberOfWeeks = 0;
  while (!endOfPeriodReached) {
    numberOfWeeks = numberOfWeeks + 1;
    periodStartBeingEvaluated = getNextWeekDate(periodStartBeingEvaluated);
    endOfPeriodReached = periodStartBeingEvaluated.getTime() > periodEndDate.getTime();
  }
  return numberOfWeeks;
};

const getWeekDataAtIndex = (fileData, portfolio, building, path, index) => {
  const portfolioData = fileData[portfolio];
  const buildingData = portfolioData ? portfolioData[building] : null;
  let pathData = null;
  if (buildingData) {
    const pathArray = path.split(' ');
    pathData = buildingData;
    for (const path in pathArray) {
      if (pathData) {
        pathData = pathData[path];
      }
    }
  }
  if (pathData && pathData.length > index) {
    return pathData[index];
  }
  return null;
};

const getSliceWeekDataAtIndex = (fileData, portfolio, building, path, index, numberOfWeeks) => {
  const portfolioData = fileData[portfolio];
  const buildingData = portfolioData ? portfolioData[building] : null;
  let pathData = null;
  let dataToReturn = null;
  if (buildingData) {
    const pathArray = path.split(' ');
    pathData = buildingData;
    for (const path in pathArray) {
      if (pathData) {
        pathData = pathData[pathArray[path]];
      }
    }
  }
  if (pathData && pathData.length > index) {
    if (numberOfWeeks > 0) {
      dataToReturn = pathData.slice(index, index + numberOfWeeks);
      if (index + numberOfWeeks > pathData.length) {
        const numberOfNullValuesToAdd = index + numberOfWeeks - pathData.length;
        dataToReturn = dataToReturn.concat(new Array(numberOfNullValuesToAdd).fill(null));
      }
    } else {
      if (index + 1 + numberOfWeeks < 0) {
        const numberOfNullValuesToAdd = Math.abs(index + 1 + numberOfWeeks);
        dataToReturn = new Array(numberOfNullValuesToAdd).fill(null).concat(pathData.slice(0, index + 1));
      } else {
        dataToReturn = pathData.slice(index + numberOfWeeks + 1, index + 1);
      }
    }
  }
  return dataToReturn;
};

export const getWeeksDataForGraph = (
  fileData,
  dataFirstStartDateString,
  dataLastStartDateString,
  portfolio,
  building,
  path,
  resultStartDateString,
  numberOfWeeks
) => {
  const dataFirstStartDate = new Date(dataFirstStartDateString);
  const dataLastStartDate = new Date(dataLastStartDateString);
  const resultStartDate = new Date(resultStartDateString);
  const dataToReturn = [];
  if (
    resultStartDate.getTime() >= dataFirstStartDate.getTime() &&
    resultStartDate.getTime() <= dataLastStartDate.getTime()
  ) {
    //requested data is in range
    const resultStartMatchingIndex = findMatchingWeekIndexInPeriod(
      resultStartDate,
      dataFirstStartDate,
      dataLastStartDate
    );
    if (resultStartMatchingIndex >= 0) {
      return getSliceWeekDataAtIndex(fileData, portfolio, building, path, resultStartMatchingIndex, numberOfWeeks);
    } else {
      //index is out of range
    }
  }
  return null;
};

export const getWeeksCalculatedDataForGraph = (
  fileData,
  dataFirstStartDateString,
  dataLastStartDateString,
  portfolio,
  building,
  path1,
  path2,
  resultStartDateString,
  numberOfWeeks,
  calculationFunction
) => {
  const data1 = getWeeksDataForGraph(
    fileData,
    dataFirstStartDateString,
    dataLastStartDateString,
    portfolio,
    building,
    path1,
    resultStartDateString,
    numberOfWeeks
  );
  const data2 = getWeeksDataForGraph(
    fileData,
    dataFirstStartDateString,
    dataLastStartDateString,
    portfolio,
    building,
    path2,
    resultStartDateString,
    numberOfWeeks
  );
  const dataToReturn = [];
  if (data1 && data2 && data1.length === data2.length) {
    for (let i = 0; i < data1.length; i++) {
      dataToReturn.push(calculationFunction(data1[i], data2[i]));
    }
  }
  return dataToReturn;
};

export const getWeeksRatioDataForGraph = (
  fileData,
  dataFirstStartDateString,
  dataLastStartDateString,
  portfolio,
  building,
  path1,
  path2,
  resultStartDateString,
  numberOfWeeks
) => {
  const calculationFunction = (d1, d2) => {
    return d1 && d2 ? d1 / d2 : 0;
  };
  return getWeeksCalculatedDataForGraph(
    fileData,
    dataFirstStartDateString,
    dataLastStartDateString,
    portfolio,
    building,
    path1,
    path2,
    resultStartDateString,
    numberOfWeeks,
    calculationFunction
  );
};
