import {
  Alert,
  Button,
  Col,
  Container,
  Drawer,
  FlexboxGrid,
  HelpBlock,
  Icon,
  IconButton,
  List,
  Nav,
  Input,
  Panel,
  PanelGroup,
  Placeholder,
  Popover,
  SelectPicker,
  Divider,
  Tooltip,
  Table,
  Column,
  HeaderCell,
  Cell,
  Affix,
  Loader,
  Whisper,
} from 'rsuite';
import React, { useEffect, useState, useRef, useContext } from 'react';
import { get, isEmpty, startCase, sum, truncate, union } from 'lodash';
import PropTypes from 'prop-types';
import { GptApi } from 'services/api/gpt';
import { useUser } from 'contexts/UserContext';
import { useErrorTracker } from 'utils/use-error-tracker';
import DefaultErrorBoundary from 'components/DefaultErrorBoundary';
import FeedbackComponent from 'components/ChatFeedback';
import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons';
import SendIcon from '@rsuite/icons/Send';
import ShieldIcon from '@rsuite/icons/Shield';
import HelpOutlineIcon from '@rsuite/icons/HelpOutline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EditIcon from '@rsuite/icons/Edit';
import CloseIcon from '@rsuite/icons/Close';
import ReloadIcon from '@rsuite/icons/Reload';
import ArrowLeftLineIcon from '@rsuite/icons/ArrowLeftLine';
import ArrowRightLineIcon from '@rsuite/icons/ArrowRightLine';
import useWindowSize from 'utils/detect-windows-size';
import { faWandMagicSparkles, faClipboard, faSparkles } from '@fortawesome/free-solid-svg-icons';
import pageIdsToContext from './data';
import { jsonToCSV } from 'react-papaparse';
import Styles from './styles.js';
import Typewriter from 'typewriter-effect';
import { TableAccessContext } from 'contexts/TableAccessContext';
import * as htmlToImage from 'html-to-image';
import { UserApi } from 'services/index.js';
import { DashboardContext } from 'contexts/LayoutDashboardContext';
import { TableauDashboardContext } from 'contexts/TableauDashboardContext';
import { getTableauUrl } from 'utils/getTableauUrl';

function DynamicTable({ data }) {
  const { Column, HeaderCell, Cell } = Table;
  const [sortColumn, setSortColumn] = useState('');
  const [sortType, setSortType] = useState('');

  function handleSortColumn(sortColumn, sortType) {
    setSortColumn(sortColumn);
    setSortType(sortType);
  }

  // create a sorted copy of the data array based on the current sort order
  const sortedData = [...data].sort((a, b) => {
    if (sortColumn) {
      const aValue = a[sortColumn];
      const bValue = b[sortColumn];

      if (typeof aValue === 'string') {
        return aValue.localeCompare(bValue);
      }

      if (aValue < bValue) {
        return -1;
      }

      if (aValue > bValue) {
        return 1;
      }
    }

    return 0;
  });

  const generateCsvFile = () => {
    const element = document.createElement('a');
    const file = new Blob([jsonToCSV(sortedData)], { type: 'text/csv;charset=utf-8' });
    element.href = URL.createObjectURL(file);
    element.download = 'data-' + new Date().getTime() + '.csv';
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  return (
    <div>
      <div className="p-4 flex justify-center border border-gray-400 rounded-lg">
        <div className="w-full max-w-screen-lg mx-auto">
          <Table
            data={sortedData}
            sortColumn={sortColumn}
            sortType={sortType}
            autoHeight={true}
            onSortColumn={handleSortColumn}>
            {data &&
              data.length > 0 &&
              Object.keys(data[0]).map(key => (
                <Column key={key} sortable resizable>
                  <HeaderCell>{key}</HeaderCell>
                  <Cell dataKey={key} />
                </Column>
              ))}
          </Table>
        </div>
      </div>
      <div className="py-2 bg-[#feebc1] flex  flex-row justify-end">
        <Button appearance="primary" onClick={generateCsvFile}>
          Download
        </Button>
      </div>
    </div>
  );
}

const QuestionWidget = ({ questionAnswer, currentUser }) => {
  return (
    <section className="questionWrapper  px-2 py-4 flex flex-row items-center">
      <FontAwesomeIcon icon={faWandMagicSparkles} className="mr-2" />
      <div className="question font-bold   text-base flex-auto  ">{questionAnswer.question}</div>
    </section>
  );
};

const AnswerFeedbackComponent = ({ questionAnswer, currentUser }) => {
  const removeTags = str => {
    if (str === null || str === '') return false;
    else str = str.toString();
    return str.replace(/(<([^>]+)>)/gi, '');
  };
  const copyAnswerToClipboard = () => {
    if (questionAnswer.summary) {
      navigator.clipboard.writeText(removeTags(questionAnswer.summary));
      Alert.success('Answer copied to clipboard');
    }
  };
  if (!questionAnswer.hasError && !questionAnswer.isLoading) {
    return (
      <div className="questionFeedback flex flex-row justify-end">
        <Whisper placement="top" trigger="hover" speaker={<Tooltip>Copy to clipboard</Tooltip>}>
          <Button onClick={copyAnswerToClipboard} className={`px-[0.2rem] pb-0 pt-[0.2rem]`}>
            <FontAwesomeIcon size={'sm'} icon={faClipboard} className={` cursor-pointer  p-2 `} />
          </Button>
        </Whisper>
        <FeedbackComponent
          user={currentUser}
          questionId={questionAnswer.questionId}
          noOutline={true}
          smallSize={true}
          monochrome={true}
        />
      </div>
    );
  } else {
    return <></>;
  }
};
const AnswerWidget = ({ questionAnswer, currentUser }) => {
  const [showData, setShowData] = useState(false);
  return (
    <section className={`py-2 w-full px-2  ${questionAnswer.isLoading ? '' : 'bg-[#feebc1]'}`}>
      {!questionAnswer.hasError && questionAnswer.isLoading && <Placeholder.Paragraph rows={4} active={true} />}
      {!questionAnswer.hasError && !questionAnswer.isLoading && (
        <div className="flex flex-row">
          <div className="answerWidget w-full">
            {questionAnswer.summary && (
              <>
                {' '}
                <Typewriter
                  options={{ delay: 10, cursor: '' }}
                  onInit={typewriter => {
                    typewriter.typeString(questionAnswer.summary).start();
                  }}
                />
                {questionAnswer.answer?.length > 1 && (
                  <button
                    className="btn bg-[#ffb900] rounded-lg px-3 py-2 mb-4 text-white"
                    onClick={() => setShowData(!showData)}>
                    {!showData ? 'Show Data' : 'Hide Data'}
                  </button>
                )}
              </>
            )}
            {questionAnswer?.answer?.length > 1 && (!questionAnswer.summary || showData) && (
              <div className="bg-white">
                <DynamicTable data={questionAnswer.answer} />
              </div>
            )}
          </div>
        </div>
      )}
      {questionAnswer.hasError && (
        <div className={`flex flex-col max-w-xl w-full justify-center mx-auto items-center `}>
          <img src={'/static/images/Oops! 404 Error with a broken robot-cuate.png'} className="max-w-xs " />
          <h1 className="text-lg my-8 font-bold">
            Something went retrieving your answer. Error:{questionAnswer.error}
          </h1>
        </div>
      )}
    </section>
  );
};

const QuestionAnswerWidget = ({ questionAnswer, currentUser }) => {
  return (
    <section className="my-2 flex flex-col questionAnswer">
      <div>
        <QuestionWidget questionAnswer={questionAnswer} currentUser={currentUser} />
        <AnswerWidget questionAnswer={questionAnswer} currentUser={currentUser} />
      </div>
      <div className="bg-[#feebc1]">
        <AnswerFeedbackComponent questionAnswer={questionAnswer} currentUser={currentUser} />
      </div>
    </section>
  );
};

export function ChatAssistantPopover({ ...props }) {
  const [pageId, setPageId] = useState(null);
  const [openChat, setOpenChat] = useState(true);
  const [currentTab, setCurrentTab] = useState('chat');
  const [lastAskedQuestion, setLastAskedQuestion] = useState(null);
  const [questionAsked, setQuestionAsked] = useState(null);
  const [fetchedAnswer, setFetchedAnswer] = useState(null);
  const [summary, setSummary] = useState('');
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [settingSearchKeyword, setSettingSearchKeyword] = useState();
  const questionInputRef = useRef();
  const [question, setQuestion] = useState('');
  const { user: currentUser } = useUser();
  const [loading, setLoading] = useState(false);
  const [errorCaught, setErrorCaught] = useState(false);
  const [showData, setShowData] = useState(false);
  const [historyArray, setHistoryArray] = useState([]);
  const [historyLoading, setHistoryLoading] = useState([]);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [questionId, setQuestionId] = useState(null);
  const [showChatOnboarding, setShowChatOnboarding] = useState(true);
  const [questionAnswers, setQuestionAnswers] = useState([]);
  const [suggestedQuestions, setSuggestedQuestions] = useState([]);
  const chatContainerRef = useRef();
  const width = useWindowSize()[0];
  const windowHeight = useWindowSize()[1];
  const containerRef = props.containerRef;
  const height = props.height;
  const maxHeight = props.maxHeight;
  const tableAccessContext = useContext(TableAccessContext);
  const suggestionQuestionScrollContainterRef = useRef();
  const { dashboardState, dashboardDispatch } = useContext(DashboardContext);
  const {
    tableauDashboardState: { allDashboards, currentDashboard, dashboardTabIndex, showTableau, userSelected, token },
  } = useContext(TableauDashboardContext);

  const isDevProdUser = ()=>{
    return currentUser?.email === 'dev.prod.soulrooms@gmail.com';
  }


  const delay = ms => new Promise(res => setTimeout(res, ms));

  useEffect(() => {
    setPageId(props.pageId || 'sage+');
  }, [props.pageId]);

  const scrollToLastQuestion = () => {
    if (chatContainerRef.current) {
      const arrayOfQuestionAnswerElements = chatContainerRef.current.querySelectorAll('.questionAnswer');
      if (arrayOfQuestionAnswerElements.length > 0) {
        const lastQuestionAnswerElement = arrayOfQuestionAnswerElements[arrayOfQuestionAnswerElements.length - 1];
        lastQuestionAnswerElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      }
    }
  };

  useEffect(() => {
    scrollToLastQuestion();
  }, [questionAnswers.length]);

  const processResponse = res => {
    if (res && res.data && res.data.completions && res.data.completions) {
      const keys = Object.keys(res.data.completions);
      for (let i = 0; i < keys.length; i++) {
        // If return object in res.data.completions[keys[i]], we return a default message
        if (res.data.completions[keys[i]] && !res.data.completions[keys[i]].result && !res.data.completions[keys[i]].summary) {
          return {
            answer: [{ data: null }],
            questionId: '',
            summary: 'Sorry, we are unable to provide a response to this question at the moment.',
          };
        }

        if (res.data.completions[keys[i]] && (res.data.completions[keys[i]].result || res.data.completions[keys[i]].summary)) {
          if (res.data.completions[keys[i]].result?.rows?.length > 0) {
            return {
              answer: res.data.completions[keys[i]].result.rows,
              questionId: res.data.completions[keys[i]].questionId,
              summary: res.data.completions[keys[i]].summary?.data,
            };
          } else {
            if (res.data.completions[keys[i]].summary?.data) {
              setSummary(res.data.completions[keys[i]].summary?.data);
              setQuestionId(res.data.completions[keys[i]].questionId);
              setSuggestedQuestions(res.data.completions[keys[i]].suggestionQuestions);
            }
          }
          // if the array is empty, we return {data: null} so that the frontend does not crash
          return {
            answer: [{ data: null }],
            questionId: res.data.completions[keys[i]].questionId,
            summary: res.data.completions[keys[i]].summary?.data,
          };
        }
      }
    }
    return null;
  };

  const getScreenCapture = async ()=>{
    try{
      const html = document.querySelector('.rs-content.db-content');
      if(html){
        const canvas  = await htmlToImage.toCanvas(html);
        const base64Image = canvas.toDataURL('image/png');
        console.log("base64Image",base64Image)
        return base64Image;
      }
      return null;
    }catch(error){
      console.error(error);
      return null;
    }
  }

  const getDashboardUrl = async ()=>{
    return showTableau? getTableauDashboardUrl():getLookerDashboardUrl();
  }

  const getLookerDashboardUrl = async ()=>{
    const lastDisplayedDashboardId = dashboardState.DashboardTab?.lastDisplayedDashboardId;
    const lastUrlParamsUsed = dashboardState.DashboardTab?.lastUrlParamsUsed;
    if(lastDisplayedDashboardId ){
      const res = await UserApi.getLookerSSOUrl({ url: `/embed/dashboards/${lastDisplayedDashboardId}${lastUrlParamsUsed}` })
      return res.data;
    }else{
      return null;
    }
    
  }

  const getTableauDashboardUrl = async ()=>{
    const res = await UserApi.getTableauJWT();
    const token  = res.data;
    const userId = currentUser.id;
    const organisationId = currentUser.organisationId;
    const baseUrl = getTableauUrl(allDashboards, currentDashboard.value, dashboardTabIndex);
    const urlParams =  new URLSearchParams({ baseUrl, token, organisationId , userId}).toString();
    return `${window.location.origin}/ghost-tableau?${urlParams}`;
  }

  const isQuestionAboutExecutiveSummary = (question)=>{
    return question.toLowerCase().includes('executive summary');
  }

  const onAskQuestionButtonClicked = async aQuestion => {
    if (questionInputRef.current.value === '' && !aQuestion && !questionAsked) {
      return;
    }
    const questionToAsk = aQuestion ? aQuestion : questionAsked ? questionAsked : questionInputRef.current.value;
    const questionToAskIndex = questionAnswers.length;
    setQuestionAnswers(currentQuestionAnswers => {
      return [...currentQuestionAnswers, { question: questionToAsk, isLoading: true }];
    });
    setQuestionAsked('');

    const checkSimilarityOfString = (s1, s2) => {
      let longer = s1;
      let shorter = s2;
      if (s1.length < s2.length) {
        longer = s2;
        shorter = s1;
      }
      let longerLength = longer.length;
      if (longerLength == 0) {
        return 1.0;
      }
      return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength);
    };

    const editDistance = (s1, s2) => {
      s1 = s1.toLowerCase();
      s2 = s2.toLowerCase();

      let costs = new Array();
      for (let i = 0; i <= s1.length; i++) {
        let lastValue = i;
        for (let j = 0; j <= s2.length; j++) {
          if (i == 0) costs[j] = j;
          else {
            if (j > 0) {
              let newValue = costs[j - 1];
              if (s1.charAt(i - 1) != s2.charAt(j - 1))
                newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
              costs[j - 1] = lastValue;
              lastValue = newValue;
            }
          }
        }
        if (i > 0) costs[s2.length] = lastValue;
      }
      return costs[s2.length];
    };

    const searchForSimilarQuestion = () => {
      let similaritiesByPercent = '';
      for (const key in pageIdsToContext[pageId].questionsAndAnswers) {
        if (checkSimilarityOfString(questionToAsk, key) >= 0.8) {
          // greater than or equal to 80%
          similaritiesByPercent = key;
        }
      }
      return similaritiesByPercent;
    };

    // DEMO CODE
    if (isDevProdUser() && searchForSimilarQuestion()) {
      await delay(3000);
      setQuestionAnswers(currentQuestionAnswers => {
        return currentQuestionAnswers.map((questionAnswer, i) => {
          if (i === questionToAskIndex) {
            return {
              questionId: '',
              answer: [{ data: null }],
              summary: pageIdsToContext[pageId].questionsAndAnswers[searchForSimilarQuestion()],
              question: questionToAsk,
              isLoading: false,
            };
          } else {
            return questionAnswer;
          }
        });
      });
    } else {
      // END DEMO CODE

      try {
        currentUser.tables = tableAccessContext?.state?.data?.tables;
        const isDashboardPageLoaded = document.location.pathname === "/dashboard";
        const doesQuestionIsAboutExecutiveSummary = isQuestionAboutExecutiveSummary(questionToAsk);
        const screenCaptureBase64 = doesQuestionIsAboutExecutiveSummary &&  !isDashboardPageLoaded?await getScreenCapture(): null;
        const urlToSummarize = doesQuestionIsAboutExecutiveSummary &&  isDashboardPageLoaded? await getDashboardUrl(): null;
        const res = await GptApi.runModels(questionToAsk, currentUser, screenCaptureBase64, urlToSummarize);
        const answerInfo = processResponse(res);

        if (!answerInfo) {
          throw 'Something went wrong!';
        }
        setQuestionAnswers(currentQuestionAnswers => {
          return currentQuestionAnswers.map((questionAnswer, i) => {
            if (i === questionToAskIndex) {
              return { ...answerInfo, question: questionToAsk, isLoading: false };
            } else {
              return questionAnswer;
            }
          });
        });
      } catch (error) {
        setQuestionAnswers(currentQuestionAnswers => {
          return currentQuestionAnswers.map((questionAnswer, i) => {
            if (i === questionToAskIndex) {
              return { ...questionAnswer, isLoading: false, hasError: true, error };
            } else {
              return questionAnswer;
            }
          });
        });
      }
    }
  };

  const getHistory = async () => {
    try {
      setHistoryLoading(true);
      const { data } = await GptApi.getHistory(currentUser);
      setHistoryLoading(false);
      // console.log(response);
      if (!data.result) setHistoryArray([]);
      // sort data.result by "createdAt" in descending order
      data.result.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

      setHistoryArray(data.result);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleKeyUp = event => {
    if (event.key === 'Enter') {
      // Get and set ref value
      onAskQuestionButtonClicked();
    }
  };

  useEffect(() => {
    getHistory();
  }, [fetchedAnswer]);

  const extractResponse = res => {
    if (res && res.data && res.data.completions && res.data.completions) {
      const keys = Object.keys(res.data.completions);
      for (let i = 0; i < keys.length; i++) {
        if (res.data.completions[keys[i]] && res.data.completions[keys[i]].result) {
          if (res.data.completions[keys[i]].result.length > 0) {
            setSummary(res.data.completions[keys[i]].summary?.data);
            setQuestionId(res.data.completions[keys[i]].questionId);
            setSuggestedQuestions(res.data.completions[keys[i]].suggestionQuestions);
            return res.data.completions[keys[i]].result;
          } else {
            if (res.data.completions[keys[i]].summary?.data) {
              setSummary(res.data.completions[keys[i]].summary?.data);
              setQuestionId(res.data.completions[keys[i]].questionId);
              setSuggestedQuestions(res.data.completions[keys[i]].suggestionQuestions);
            }
          }
          // if the array is empty, we return {data: null} so that the frontend does not crash
          return [{ data: null }];
        }
      }
    }
    return null;
  };

  const AnswerSection = () => {
    return (
      <section>
        <div className="font-semibold italic text-stone-800 text-xl my-4"> Asked: {question}</div>

        {summary && (
          <>
            {' '}
            <div className=" text-gray-900 font-semibold mb-4 bg-amber-200 p-4 rounded-lg"> {summary}</div>
            {fetchedAnswer.length > 1 && (
              <button
                className="btn bg-[#ffb900] rounded-lg px-3 py-2 mb-4 text-white"
                onClick={() => setShowData(!showData)}>
                {!showData ? 'Show Data' : 'Hide Data'}
              </button>
            )}
          </>
        )}
        <div className="feedback-test">
          <FeedbackComponent user={currentUser} questionId={questionId} />
        </div>
        {fetchedAnswer.length > 1 && (!summary || showData) && <DynamicTable data={fetchedAnswer} />}
      </section>
    );
  };

  const SuggestedQuestionsSection = () =>{
    const SCROLL_VALUE = 300;
    const scrollQuestionSuggestionContainerRight = ()=>{
      if(suggestionQuestionScrollContainterRef && suggestionQuestionScrollContainterRef.current){
        if(!suggestionQuestionScrollContainterRef.current.scrollLeft){
          suggestionQuestionScrollContainterRef.current.scrollLeft  = 0;
        }
        
          suggestionQuestionScrollContainterRef.current.scrollLeft = suggestionQuestionScrollContainterRef.current.scrollLeft + SCROLL_VALUE;
        
      }
    }

    const scrollQuestionSuggestionContainerLeft = ()=>{
      if(suggestionQuestionScrollContainterRef && suggestionQuestionScrollContainterRef.current){
        if(!suggestionQuestionScrollContainterRef.current.scrollLeft){
          suggestionQuestionScrollContainterRef.current.scrollLeft = 0;
        }

        if(suggestionQuestionScrollContainterRef.current.scrollLeft - SCROLL_VALUE < 0){
          suggestionQuestionScrollContainterRef.current.scrollLeft = 0;
        }else{
          suggestionQuestionScrollContainterRef.current.scrollLeft = suggestionQuestionScrollContainterRef.current.scrollLeft - SCROLL_VALUE;
        }
      }
    }
    return (<>
    <div className='flex flex-row w-full pb-4'>
    <IconButton icon={<ArrowLeftLineIcon/>} color="yellow" onClick={scrollQuestionSuggestionContainerLeft}  className="mr-4" circle size="md" appearance="ghost"  style={{flex: "0 0 auto"}} />
    <div className='flex flex-row flex-auto overflow-auto' ref={suggestionQuestionScrollContainterRef} style={{width: "calc(100% - 72px)", scrollBehavior: "smooth"}}>
      {suggestedQuestions.map((q, i)=>{
        return(<Button className='mr-2' key={i} onClick={()=>{onAskQuestionButtonClicked(q)}} appearance="ghost">{q}</Button>)
      })}
    </div>
    <IconButton icon={<ArrowRightLineIcon/>} color="yellow" onClick={scrollQuestionSuggestionContainerRight} className="ml-4" circle size="md" appearance="ghost"    style={{flex: "0 0 auto"}} />
    </div>
    </>)
  }

  const HistorySection = () => {
    return (
      <>
        <FlexboxGrid justify="space-around" style={{ padding: '0 10px' }}>
          {/* <FlexboxGrid.Item as={Col} colspan={24} xs={24} sm={24} md={12} lg={12}>
            <SearchInput
              onChange={k => {
                setSettingSearchKeyword(k);
              }}
              placeholder="Search..."
            />
          </FlexboxGrid.Item> */}
          {historyLoading && (
            <div className="h-72">
              <Loader center speed="slow" size="lg" />
            </div>
          )}

          {!historyLoading &&
            historyArray &&
            historyArray.map(history => {
              return (
                <Panel
                  shaded
                  bodyFill
                  className="shadow-sm w-full border-gray-100 border hover:shadow-xl m-[10px] overflow-visible"
                  key={history.id}
                  expanded={false}
                  onClick={() => {
                    setCurrentQuestion(history);
                  }}>
                  <FlexboxGrid className="m-6">
                    <FlexboxGrid.Item componentClass={Col} xs={18} sm={18} md={18} lg={18}>
                      <div style={{ textAlign: 'left' }} className="flex flex-col gap-1">
                        <div>{`${history.question}`}</div>
                      </div>
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item componentClass={Col} xs={4} sm={4} md={4} lg={4}>
                      {currentQuestion?.id === history.id && (
                        <div
                          className="bg bg-grey-100 py-1 px-3 rounded-lg font-bold text-center hover:bg-gray-200"
                          onClick={e => {
                            e.stopPropagation();

                            setCurrentQuestion(null);
                          }}>
                          close
                        </div>
                      )}
                    </FlexboxGrid.Item>
                  </FlexboxGrid>
                  <div
                    className="m-6 px-2"
                    onClick={e => {
                      e.stopPropagation();
                    }}>
                    {currentQuestion?.id === history.id && (
                      <div>
                        <div> Answer </div>
                        <div className="flex">
                          <div className="w-full">
                            {(Array.isArray(history.results?.rows)) &&
                            <DynamicTable data={history.results?.rows} />
                            }
                            {(Array.isArray(history.results))  &&
                            <DynamicTable data={history.results} />
                            }
                            {(typeof history.results === "string")  &&
                            <span>{history.results}</span>
                            }
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </Panel>
              );
            })}
        </FlexboxGrid>
      </>
    );
  };

  // const handleQuestionChange = debounce(text => {
  //   setQuestion(text);
  // }, 300);

  const ShowError = () => {
    return (
      <div className={`flex flex-col max-w-xl w-full justify-center mx-auto items-center `}>
        <img src={'/static/images/Oops! 404 Error with a broken robot-cuate.png'} className="max-w-xs " />
        <h1 className="text-lg my-8 font-bold">Something went wrong retrieving your answer</h1>
      </div>
    );
  };

  const renderPromptExmaples = (pageId = props.pageId || 'sage+') => {
    const promptExamples = isDevProdUser() &&
      pageIdsToContext[pageId] &&
      pageIdsToContext[pageId].questionsAndAnswers &&
      Object.keys(pageIdsToContext[pageId].questionsAndAnswers).length > 0
        ? Object.keys(pageIdsToContext[pageId].questionsAndAnswers)
        : [
            'What are the top 2 reasons I lost a lead?',
            'How many showings had I had in the last month?',
            'How many buildings do I have?',
          ];
    return promptExamples.map((d, i) => {
      return (
        <div className="prompt_example my-2" key={i}>
          <Button
            key={i}
            appearance="ghost"
            onClick={() => {
              onAskQuestionButtonClicked(d);
            }}
            className="text-black border-black whitespace-normal text-left bg-[#FFBD2B]  hover:text-black hover:bg-[#e0ac00] hover:border-black">
            <SendIcon className="text-xl mr-2" />
            {d}
          </Button>
        </div>
      );
    });
  };

  const renderInfoChatItem = ({ icon, title, details }) => {
    return (
      <div className="chat_info_intro_item flex flex-row my-8">
        <div className="icon_container px-4 w-20 text-xl items-center flex flex-col" style={{ flex: '0 0 auto' }}>
          {icon}
        </div>

        <div>
          <div className="font-bold">{title}</div>
          <div>{details}</div>
        </div>
      </div>
    );
  };

  const ChatOnboarding = () => {
    return (
      <div className="" style={{}}>
        <section
          className="top_section py-8 px-4 rounded-t-xl"
         >
          <div className="intro_message text-base">
            <span className="colorful_message font-bold">Welcome to Sage+</span> Now, you can ask anything about your
            portfolio on Leni.
          </div>

          <div className="try_out_message opacity-30 my-2 text-xs">
            <span className="inline mr-4">Try it out</span>
            <img className="w-8 mt-2 inline rotate-[230deg]" src={'/static/icons/drawn-arrow.png'} />
          </div>

          <div className="prompt_examples">{renderPromptExmaples()}</div>
        </section>

        <section className="bottom_section py-4 pr-8 rounded-b-xl" >
          {renderInfoChatItem({
            icon: <ShieldIcon className="text-xl" />,
            title: 'Privacy first integration with LLMs',
            details:
              'Sage+ combines what Leni has captured with the power of LLMs Only your question and text from relevant moments is sent to LLMs.',
          })}
          {renderInfoChatItem({
            icon: <HelpOutlineIcon className="text-xl" />,
            title: 'Great questions get great answers',
            details: 'Sage+ works well when you provide specific detailed questions.',
          })}
          {renderInfoChatItem({
            icon: <EditIcon className="text-xl" />,
            title: 'Share your feedback',
            details: 'Please share your feedback as you use Sage+ so we can keep improving it. ',
          })}
        </section>
      </div>
    );
  };

  const HistoryTabContent = () => (
    <div className="w-full py-2">
      <HistorySection />
    </div>
  );

  return (
    <>
      <div className="h-full relative" style={{height: height?height+"px": "auto"}}>
        <div className="flex flex-col w-full items-center h-full relative overflow-auto">
          <Affix container={containerRef}><Nav
            appearance="subtle"
            onSelect={val => {
              setCurrentTab(val);
            }}
            style={{flex: "0 0 auto"}}
            activeKey={currentTab}>
            <Nav.Item style={{ marginLeft: '10px', marginRight: '10px' }} eventKey="chat">
              <span className={`font-bold ` + (currentTab === 'chat' ? `text-[#ffb900]` : ` `)}>Chat</span>
            </Nav.Item>
            <Nav.Item style={{ marginLeft: '10px', marginRight: '10px' }} eventKey="history">
              <span className={`font-bold ` + (currentTab === 'history' ? `text-[#ffb900]` : ` `)}>History</span>
            </Nav.Item>
          </Nav>
          </Affix>
          {/*<ChatTabContent show={currentTab === 'chat'} key={"arm"} /> */}
          <div
          style={{flex: "1 1 calc(100% - 200px)"}}
            className={`relative  w-full h-full my-4 flex justify-start flex-col overflow-auto bg-white rounded-md border-solid border border-[#FFBD2B] ${
              currentTab === 'chat' ? '' : 'hidden'
            }`}
            ref={chatContainerRef}>
            {/* <div class="flex flex-row overflow-auto border-2 border-gray-400 rounded-lg p-4 h-52"> */}
            {loading && (
              <div className="h-72">
                <Loader center speed="slow" size="lg" />
              </div>
            )}
            {!loading &&
              !errorCaught &&
              !fetchedAnswer &&
              showChatOnboarding &&
              (!questionAnswers || questionAnswers.length === 0) && (
                <div className="h-full ">
                  <ChatOnboarding />
                </div>
              )}
            {!loading &&
              !errorCaught &&
              questionAnswers &&
              questionAnswers.length > 0 &&
              questionAnswers.map((qa, i) => {
                return <QuestionAnswerWidget key={i} currentUser={currentUser} questionAnswer={qa} />;
              })}

          </div>

          {currentTab !== 'history' && <div className={`bottom-0 relative  w-full ${maxHeight> 600 ? 'pb-10 md:pl-6 md:pr-20 mt-10 mb-4':''} md:pb-0  flex flex-col z-10 drop-shadow-lg`} style={{flex: "0 0 auto"}}>
           {suggestedQuestions && suggestedQuestions.length > 0 && <div className={`${suggestedQuestions && suggestedQuestions.length > 0?'my-2':''} w-full overflow-scroll`}><SuggestedQuestionsSection/></div>}
            <div className={`bottom-0 relative  w-full ${maxHeight> 600 ? 'pb-10 mb-10 md:pl-6 md:pr-20 mt-10 mb-4':''} md:pb-2  flex flex-row z-10`} style={{flex: "0 0 auto"}}>
            <Input
              className=' flex-auto p-2 text-base sm:text-sm'
              type='text'
              ref={questionInputRef}
              placeholder='Ask a question about your data...'
              onKeyUp={handleKeyUp}
              onChange={v => {
                setQuestionAsked(v);
              }}
              value={questionAsked}
            />
            <Button
              appearance='primary'
              disabled={loading || !questionAsked}
              className='mx-2 '
              style={{ flex: '0 0 auto' }}
              onClick={() => onAskQuestionButtonClicked()}>
              {loading ? (
                <div className='flex flex-row items-center'>
                  <Loader className='mr-2' />
                  <div>Answering...</div>
                </div>
              ) : (
                <div className='flex flex-row items-center text-base sm:text-sm'>
                  <SendIcon className='mr-2 text-lg' />
                  <p className='hidden sm:block'>Ask Question</p>
                  <p className='block sm:hidden'>Ask</p>
                </div>
              )}
            </Button>
            </div>
          </div>}


          {currentTab === 'history' && <HistoryTabContent />}
        </div>
        <Styles />
      </div>
    </>
  );
}

ChatAssistantPopover.propTypes = {
  showcasesData: PropTypes.object,
  getShowcases: PropTypes.func,
};

export default ChatAssistantPopover;
