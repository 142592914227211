import React from 'react';
import Styles from './styles.js'
const HeadingSection = ({ title, heading, bgcolor, subHd, spacing }) => {
  return (
    <div>
      {bgcolor == 'black' ? (
        <div
          className={
            spacing
              ? ' p-0 pt-12 px-0 text-white '
              : ' p-0 pt-4 px-0  text-white '
          }
          style={{ backgroundColor: 'rgb(22, 22, 22)' }}>
          {heading !== undefined ? (
            <div className="lg:container mx-auto  px-6  lg:px-3 2xl:px-2">
              <div className="">
                <div className="">
                  <p
                    className=" uppercase font-weight fs-4 size "
                    style={{ fontFamily: 'Fjalla One', color: '#F7F7F7' }}>
                    {title}
                  </p>
                  <div className=" bg-white mt-2 mb-4" style={{ padding: '0.5px' }}></div>
                </div>
                <div className="flex justify-center">
                  <div className="col-lg-10 md:col-11  pt-4 mt-1  ">
                    <h6
                      className="text-center responsiveT"
                      style={{
                        fontFamily: 'josefin Sans',
                        color: '#fdbd00',
                        fontWeight: '400',
                      }}>
                      {heading}
                    </h6>
                  </div>
                </div>
              </div>
              <p className="fs-14-16 font-normal text-center" style={{ color: '#a9a9a9', fontFamily: 'josefin Sans' }}>
                {subHd}
              </p>
            </div>
          ) : (
            <div className="lg:container px-6  lg:px-3 2xl:px-5 mx-auto ">
              <div className="">
                <div className="   pb-6">
                  <p
                    className="pb-1 mb-4 text-2xl uppercase fontWeight fs-4 size"
                    style={{ fontFamily: 'Fjalla One', color: '#F7F7F7' }}>
                    {title}
                  </p>
                  <div className=" bg-white mt-2  " style={{ padding: '0.5px' }}></div>

                  {/* <hr className="mb-4 mt-3 " style={{ borderTop: '1px solid white' }}></hr> */}
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="lg:container  mx-auto px-6 lg:px-3 xl:px-4 2xl:px-5 text-white mt-0 " style={{ backgroundColor: '#ffffff' }}>
          {heading !== undefined ? (
            <div className="lg:container pt-4  px-6  lg:px-2 2xl:px-5  ">
              <div className="row  row-custom md:px-1 lg:px-0 xl:px-1 2xl:px-0 ">
                <div className="col-12  ">
                  <p
                    className="ok text-uppercase fontWeight fs-4 size"
                    style={{ fontFamily: 'Fjalla One', color: '#000000' }}>
                    {title}
                  </p>
                  <hr className="mb-4 mt-3 text-dark"></hr>
                </div>
                <div className="flex justify-center">
                  <div className="col-10 py-4 ">
                    <h6
                      className="text-center responsiveT "
                      style={{
                        fontFamily: 'josefin Sans',
                        color: '#ba8c00',
                      }}>
                      {heading}
                    </h6>
                  </div>
                </div>
              </div>
              <p className="fs-14-16 font-normal text-center" style={{ color: '#595959', fontFamily: 'josefin Sans' }}>
                {subHd}
              </p>
            </div>
          ) : (
            <div
              className={
                spacing
                  ? 'lg:container mx-auto    '
                  : 'lg:container mx-auto  '
              }>
              <div className="   ">
                <div className="pt-12">
                  <p
                    className="pb-1 mb-4 text-2xl uppercase fontWeight fs-4 size"
                    style={{ fontFamily: 'Fjalla One', color: '#000000' }}>
                    {title}
                  </p>
                  <hr className="mb-6 mt-4 text-dark"></hr>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      <Styles/>
    </div>
  );
};

export default HeadingSection;
