const pageIdstoContext = {
  dashboard: {
    questionsAndAnswers: {
      'Provide me an executive summary of my portfolio': `<b>Troy Boston</b> is situated in Boston and has a revenue of <b>$11,296,258. After accounting for expenses, which total </b>$4,828,626</b>,
the gross margin stands strong at <b>$6,467,632</b>. The building demonstrates a <b>good occupancy rate of 98.89%</b> when compared to the neighborhood occupancy.<br/><br/>
Meanwhile, <b>Parkway Apartments</b> is another building in Boston.
Generating a <b>revenue of $12,285,020</b> and incurring <b>expenses of $6,043,149</b>, the apartments achieve a NOI of <b>$6,241,871</b>.
The occupancy rate is slightly below that of Troy Boston, settling at <b>98.70%</b>.
<br/><br/>In Toronto, <b>Bridge Condos</b> has made its mark with the highest occupancy rate among the listed properties, a remarkable <b>99.14%</b>.
Financially, the condos have garnered a revenue of $4,539,450, and after deducting expenses <b>of $2,298,619, the NOI is ascertained at $2,240,831</b>.<br/><br/>
Lastly, <b>Liberty Towers</b>, also located in Toronto, is pulling in a revenue of <b>$6,821,198</b>. The expenses for this property amount to <b>$3,050,755</b>,
resulting in a net operating income of <b>$3,770,443</b>. The occupancy rate is competitive, noted at <b>98.87%</b>.<br/><br/>
In summary, while Troy Boston holds the lead in terms of NOI, Bridge Condos in Toronto stands out with the highest occupancy rate. Let me know if you need to know anything else.`,
      'How is the marketing performance this quarter?': `When evaluating lead generation, Zumper tops the list with <b>647 leads</b>,
followed closely by Facebook with <b>631 leads</b>. Our website generated the fewest leads, <b>totaling 221</b>.
Nevertheless, when considering conversion rates, the website has <b>11.95%</b>, whereas Facebook's rate stands at <b>4.25%</b>.
Zumper <b>recorded a 0% conversion rate</b>.<br/><br/>
Given the marketing investment for each channel, our website emerges as the top performer, even with its lower lead count.`,
      'Provide an update on Troy Boston occupancy': `Troy Boston currently holds a <b>99.0%</b> occupancy rate, with projections indicating a decline to <b>94.2% in the next 90 days</b>.
This is a shortfall from our target of <b>97.3%</b>, resulting in a -3.10% difference</b>. <br/><br/>
There <b>is a decrease in lease renewals</b>. In the last month, anticipated renewal rates have slipped by <b>5.0% to 43.1%</b>.
Feedback from move-out reasons points to pricing as a central concern. A minor price reduction might enhance renewal rates,
bringing us closer to our desired occupancy objectives.<br/><br/>`,
      'Identify revenue improvement opportunities': `Currently, your advertised rent in Toronto is priced <b>10% lower</b> than the recent average.
Impressively, your portfolio exhibits a <b>23% renewal growth</b>, coupled with a <b>2.10% average</b> conversion rate.
By strategically raising the rents while sustaining your present renewal rate, there's a potential to augment your earnings by an extra <b>$17,784</b>. <br/><br/>
Consider increasing the rent by <b>$25 for units</b> that are presently available.
Furthermore, you have an edge in the Annex neighborhood, where your vacancy rate is notably lower than the local market standard.
Capitalizing on this position can bolster your annual revenues by an additional <b>$3,000</b>.
`,
    },
    relevant_tables: {},
    data: {},
  },
  properties: {
    questionsAndAnswers: {},
    relevant_tables: {},
    data: {},
  },
  bookings: {
    questionsAndAnswers: {},
    relevant_tables: {},
    data: {},
  },
  ticket: {
    questionsAndAnswers: {},
    relevant_tables: {},
    data: {},
  },
  'income-management': {
    questionsAndAnswers: {},
    relevant_tables: {},
    data: {},
  },
  price: {
    questionsAndAnswers: {},
    relevant_tables: {},
    data: {},
  },
  'sage+': {
    questionsAndAnswers: {
      'Provide me an executive summary of my portfolio': `<b>Troy Boston</b> is situated in Boston and has a revenue of <b>$11,296,258. After accounting for expenses, which total </b>$4,828,626</b>,
    the gross margin stands strong at <b>$6,467,632</b>. The building demonstrates a <b>good occupancy rate of 98.89%</b> when compared to the neighborhood occupancy.<br/><br/>
    Meanwhile, <b>Parkway Apartments</b> is another building in Boston.
    Generating a <b>revenue of $12,285,020</b> and incurring <b>expenses of $6,043,149</b>, the apartments achieve a NOI of <b>$6,241,871</b>.
    The occupancy rate is slightly below that of Troy Boston, settling at <b>98.70%</b>.
    <br/><br/>In Toronto, <b>Bridge Condos</b> has made its mark with the highest occupancy rate among the listed properties, a remarkable <b>99.14%</b>.
    Financially, the condos have garnered a revenue of $4,539,450, and after deducting expenses <b>of $2,298,619, the NOI is ascertained at $2,240,831</b>.<br/><br/>
    Lastly, <b>Liberty Towers</b>, also located in Toronto, is pulling in a revenue of <b>$6,821,198</b>. The expenses for this property amount to <b>$3,050,755</b>,
    resulting in a net operating income of <b>$3,770,443</b>. The occupancy rate is competitive, noted at <b>98.87%</b>.<br/><br/>
    In summary, while Troy Boston holds the lead in terms of NOI, Bridge Condos in Toronto stands out with the highest occupancy rate. Let me know if you need to know anything else.`,
      'How is the marketing performance this quarter?': `When evaluating lead generation, Zumper tops the list with <b>647 leads</b>,
    followed closely by Facebook with <b>631 leads</b>. Our website generated the fewest leads, <b>totaling 221</b>.
    Nevertheless, when considering conversion rates, the website has <b>11.95%</b>, whereas Facebook's rate stands at <b>4.25%</b>.
    Zumper <b>recorded a 0% conversion rate</b>.<br/><br/>
    Given the marketing investment for each channel, our website emerges as the top performer, even with its lower lead count.`,
      'Provide an update on Troy Boston occupancy': `Troy Boston currently holds a <b>99.0%</b> occupancy rate, with projections indicating a decline to <b>94.2% in the next 90 days</b>.
    This is a shortfall from our target of <b>97.3%</b>, resulting in a -3.10% difference</b>. <br/><br/>
    There <b>is a decrease in lease renewals</b>. In the last month, anticipated renewal rates have slipped by <b>5.0% to 43.1%</b>.
    Feedback from move-out reasons points to pricing as a central concern. A minor price reduction might enhance renewal rates,
    bringing us closer to our desired occupancy objectives.<br/><br/>`,
      'Identify revenue improvement opportunities': `Currently, your advertised rent in Toronto is priced <b>10% lower</b> than the recent average.
    Impressively, your portfolio exhibits a <b>23% renewal growth</b>, coupled with a <b>2.10% average</b> conversion rate.
    By strategically raising the rents while sustaining your present renewal rate, there's a potential to augment your earnings by an extra <b>$17,784</b>. <br/><br/>
    Consider increasing the rent by <b>$25 for units</b> that are presently available.
    Furthermore, you have an edge in the Annex neighborhood, where your vacancy rate is notably lower than the local market standard.
    Capitalizing on this position can bolster your annual revenues by an additional <b>$3,000</b>.
    `,
    },
    relevant_tables: {},
    data: {},
  },
  import: {
    questionsAndAnswers: {},
    relevant_tables: {},
    data: {},
  },
  reports: {
    questionsAndAnswers: {},
    relevant_tables: {},
    data: {},
  },
  integrations: {
    questionsAndAnswers: {},
    relevant_tables: {},
    data: {},
  },
  download: {
    questionsAndAnswers: {},
    relevant_tables: {},
    data: {},
  },
  transactions: {
    questionsAndAnswers: {},
    relevant_tables: {},
    data: {},
  },
  settings: {
    questionsAndAnswers: {},
    relevant_tables: {},
    data: {},
  },
};

export default pageIdstoContext;
