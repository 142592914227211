import styled from '@emotion/styled';
import { useUser } from 'contexts/UserContext';
import { pickBy } from 'lodash';
import { useEffect, useState } from 'react';
import { Alert, Button, Col, Grid, Icon, Row, Steps } from 'rsuite';
import { UserApi } from 'services';
import { Link } from 'utils/with-i18next';
import Step1 from './step1';
import Step10 from './step10';
import Step2 from './step2';
import Step3 from './step3';
import Step4 from './step4';
import Step5 from './step5';
import Step6 from './step6';
import Step7 from './step7';
import Step8 from './step8';
import Step9 from './step9';
import { Model as Step1Model } from './StepsForms/Step1Form';
import { Model as Step2Model } from './StepsForms/Step2Form';
import { Model as Step3Model } from './StepsForms/Step3Form';
import { Model as Step4Model } from './StepsForms/Step4Form';
import { Model as Step5Model } from './StepsForms/Step5Form';
import { Model as Step6Model } from './StepsForms/Step6Form';
import { Model as Step7Model } from './StepsForms/Step7Form';
import { Model as Step8Model } from './StepsForms/Step8Form';
import { Model as Step9Model } from './StepsForms/Step9Form';
import Styles from './styles.js';
import { useErrorTracker } from 'utils/use-error-tracker';

const RoomieProfileSteps = ({ mdsize, offsetsize, isModel, setClose }) => {
  const errorTracker = useErrorTracker();
  const [currentStep, setcurrentStep] = useState(0);
  const [formErrors, setFormErrors] = useState({});
  const [width, setWidth] = useState(null);
  const [loading, setloading] = useState(false);
  const [roomieProfileData, setroomieProfileData] = useState({});

  const { user: currentUser } = useUser({
    ...{ redirectTo: '/login', redirectIfFound: false },
  });
  console.log('');
  const steps = [
    { title: 'Work', description: 'Let’s start with your Work ', idx: 1 },
    { title: 'Schedule', description: 'Show us, don’t be shy', idx: 1 },
    { title: 'Weekend Fun', description: 'Your ideal Friday night', idx: 1 },
    { title: 'Cleanliness', description: 'Keeping clean', idx: 1 },
    { title: 'Cooking', description: 'Cooking at home', idx: 1 },
    { title: 'Smoking', description: 'Here is what we have to offer', idx: 1 },
    { title: 'Guests', description: 'Bringing guests home', idx: 1 },
    { title: 'Roomies', description: 'Ideal roomie relationship', idx: 1 },
    { title: 'About you', description: 'Last but not least', idx: 1 },
    { title: 'Review', description: 'Review your info', idx: 1 },
  ];

  const validateStep = () => {
    const validate = model => {
      const c = pickBy(model.check(roomieProfileData), v => v.hasError);
      Object.keys(c).forEach(k => {
        c[k] = c[k].errorMessage;
      });
      return c;
    };
    switch (currentStep) {
      case 0:
        return validate(Step1Model);
      case 1:
        return validate(Step2Model);
      case 2:
        return validate(Step3Model);
      case 3:
        return validate(Step4Model);
      case 4:
        return validate(Step5Model);
      case 5:
        return validate(Step6Model);
      case 6:
        return validate(Step7Model);
      case 7:
        return validate(Step8Model);
      case 8:
        return validate(Step9Model);

      default:
        return false;
    }
  };
  const styles = {
    normal: {
      width: '75%',
      display: 'inline-table',
      verticalAlign: 'top',
      cursor: 'pointer',
    },
    small: {
      marginBottom: '30px',
    },
  };
  const handleNextStep = e => {
    e.preventDefault();
    setloading(true);

    const failedChecks = validateStep();
    if (Object.keys(failedChecks).length > 0) {
      setFormErrors(failedChecks);
      setloading(false);

      return;
    }

    UserApi.createRoomieProfile({ ...roomieProfileData })
      .then(() => {
        currentStep < 8
          ? Alert.success('step ' + (currentStep + 1) + ' submitted')
          : Alert.success('Submitted succesfully');
      })
      .then(() => {
        setloading(false);
        let ns = currentStep + 1;
        setcurrentStep(ns);
      })
      .catch(() => {
        errorTracker.error('Create roomie profile error', { createRoomieProfileData: { ...roomieProfileData } });
        Alert.error('Something went wrong while creating roommate profile.', 5000);
        console.error(err);
        setloading(false);
      });
  };
  const handlePrevStep = e => {
    e.preventDefault();
    let ps = currentStep - 1;
    setcurrentStep(ps);
  };

  useEffect(() => {
    if (currentUser?.profile) {
      let profiledata = JSON.parse(currentUser.profile.data);

      if (profiledata) {
        setroomieProfileData(profiledata);
      }
    }
  }, [currentUser]);

  const Title = styled('h3')`
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 40px;
  `;
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const handleDemo = i => {
    // console.log(i, 'nextstep value');
    switch (i) {
      case 0:
        if (roomieProfileData?.title || roomieProfileData?.company) {
          setcurrentStep(i);
        }
        break;
      case 1:
        if (
          roomieProfileData?.weekDayData ||
          roomieProfileData?.weekDayToBed ||
          roomieProfileData?.weekEndData ||
          roomieProfileData?.weekEndToBed ||
          roomieProfileData?.takeShowerOn ||
          roomieProfileData?.bothered
        ) {
          setcurrentStep(i);
        }
        break;
      case 2:
        if (roomieProfileData?.firstChoice || roomieProfileData?.secondChoice || roomieProfileData?.thirdChoice) {
          setcurrentStep(i);
        }
        break;
      case 3:
        if (
          roomieProfileData?.dishesInSink ||
          roomieProfileData?.dirtyClothes ||
          roomieProfileData?.crumbs ||
          roomieProfileData?.garbageBins ||
          roomieProfileData?.foodInFridge ||
          roomieProfileData?.messFromRoommates ||
          roomieProfileData?.expectFromARoomie ||
          roomieProfileData?.isCleaner
        ) {
          setcurrentStep(i);
        }
        break;
      case 4:
        if (
          roomieProfileData?.cookAtHome ||
          roomieProfileData?.favouritFoods ||
          roomieProfileData?.dietaryRestrictions ||
          roomieProfileData?.isSmellBothered
        ) {
          setcurrentStep(i);
        }
        break;
      case 5:
        if (
          roomieProfileData?.youTakeTobacco ||
          roomieProfileData?.youTakeCannabis ||
          roomieProfileData?.roomiesTakeTobacco ||
          roomieProfileData?.roomiesTakeCannabis
        ) {
          setcurrentStep(i);
        }
        break;
      case 6:
        if (roomieProfileData?.twoGuests || roomieProfileData?.threePlusGuests || roomieProfileData?.overnightGuests) {
          setcurrentStep(i);
        }
        break;
      case 7:
        if (roomieProfileData?.cookForOthers || roomieProfileData?.eatTogethers || roomieProfileData?.hangoutTogether) {
          setcurrentStep(i);
        }
        break;
      case 8:
        if (
          roomieProfileData?.yourGender ||
          roomieProfileData?.allGenders ||
          roomieProfileData?.yourInterests ||
          roomieProfileData?.extrovertedYouAre
        ) {
          setcurrentStep(i);
        }
        break;
      case 9:
        if (
          roomieProfileData?.yourGender ||
          roomieProfileData?.allGenders ||
          roomieProfileData?.yourInterests ||
          roomieProfileData?.extrovertedYouAre
        ) {
          setcurrentStep(i);
        }
        break;
      default:
        console.log('default case');
      // setcurrentStep();
    }
  };
  return (
    <>
      <Grid fluid>
        <Row>
          <Col md={7}>
            <div className="show-col  ">
              <Title>We are excited already! But do tell us more</Title>
              <Steps
                className={width < 600 ? 'd-none' : 'steps-dots '}
                small
                current={currentStep}
                vertical={width > 992 ? true : false}
                style={width > 992 ? styles.normal : null}>
                {steps.map((s, idx) => {
                  const showPointer = idx < currentStep;
                  return (
                    <Steps.Item
                      small={'true'}
                      className="animated fadeIn"
                      onClick={() => (showPointer ? setcurrentStep(idx) : handleDemo(idx))}
                      key={idx}
                      title={width > 992 ? s.title : null}
                      description={width > 992 ? s.description : null}
                    />
                  );
                })}
              </Steps>
            </div>
          </Col>
          <Col md={mdsize ? 15 : 10} mdOffset={offsetsize ? 1 : 2}>
            <div className={width < 992 ? 'show-col mt-5' : 'show-col'}>
              {currentStep === 0 && (
                <Step1
                  formErrors={formErrors}
                  setFormErrors={setFormErrors}
                  roomieProfileData={roomieProfileData}
                  setroomieProfileData={setroomieProfileData}
                />
              )}
              {currentStep === 1 && (
                <Step2
                  customSize
                  formErrors={formErrors}
                  setFormErrors={setFormErrors}
                  roomieProfileData={roomieProfileData}
                  setroomieProfileData={setroomieProfileData}
                />
              )}
              {currentStep === 2 && (
                <Step3
                  formErrors={formErrors}
                  setFormErrors={setFormErrors}
                  roomieProfileData={roomieProfileData}
                  setroomieProfileData={setroomieProfileData}
                />
              )}
              {currentStep === 3 && (
                <Step4
                  formErrors={formErrors}
                  setFormErrors={setFormErrors}
                  roomieProfileData={roomieProfileData}
                  setroomieProfileData={setroomieProfileData}
                />
              )}
              {currentStep === 4 && (
                <Step5
                  formErrors={formErrors}
                  setFormErrors={setFormErrors}
                  roomieProfileData={roomieProfileData}
                  setroomieProfileData={setroomieProfileData}
                />
              )}
              {currentStep === 5 && (
                <Step6
                  formErrors={formErrors}
                  setFormErrors={setFormErrors}
                  roomieProfileData={roomieProfileData}
                  setroomieProfileData={setroomieProfileData}
                />
              )}
              {currentStep === 6 && (
                <Step7
                  formErrors={formErrors}
                  setFormErrors={setFormErrors}
                  roomieProfileData={roomieProfileData}
                  setroomieProfileData={setroomieProfileData}
                />
              )}
              {currentStep === 7 && (
                <Step8
                  formErrors={formErrors}
                  setFormErrors={setFormErrors}
                  roomieProfileData={roomieProfileData}
                  setroomieProfileData={setroomieProfileData}
                />
              )}
              {currentStep === 8 && (
                <Step9
                  formErrors={formErrors}
                  setFormErrors={setFormErrors}
                  roomieProfileData={roomieProfileData}
                  setroomieProfileData={setroomieProfileData}
                />
              )}
              {currentStep === 9 && (
                <Step10
                  isModel={isModel}
                  roomieProfileData={roomieProfileData}
                  setroomieProfileData={setroomieProfileData}
                  setcurrentStep={setcurrentStep}
                />
              )}

              <Row className="flex items-center">
                <Col xs={12}>
                  {currentStep == 0 ? (
                    <Button
                      appearance="link"
                      className="mt-2  p-0 position-relative flex items-center"
                      size="lg"
                      disabled
                      style={{ textDecoration: 'underline', marginLeft: '14px ' }}
                      onClick={handlePrevStep}>
                      <Icon
                        icon="angle-left mr-1"
                        className=" position-absolute "
                        style={{ top: '15px', left: '-14px', fontSize: '26px' }}
                      />
                      Back
                    </Button>
                  ) : (
                    <Button
                      appearance="link"
                      className="mt-2  p-0 position-relative flex items-center"
                      size="lg"
                      style={{ textDecoration: 'underline', marginLeft: '14px ' }}
                      onClick={handlePrevStep}>
                      <Icon
                        icon="angle-double-left"
                        className=" position-absolute "
                        style={{ top: '15px', left: '-14px', fontSize: '26px' }}
                      />
                      Back
                    </Button>
                  )}
                </Col>
                <Col xs={12} className="text-end flex justify-end items-center">
                  {currentStep == 9 ? (
                    isModel ? (
                      <Button
                        className="mt-4 py-2"
                        onClick={setClose}
                        size="xs"
                        style={{ width: '120px' }}
                        appearance="primary">
                        Done
                      </Button>
                    ) : (
                      <div>
                        {/* {Alert.success('Submitted succesfully')} */}
                        <Link href="/properties">
                          <Button className="mt-4 " size="xs" style={{ width: '120px' }} appearance="primary">
                            Go to Properties
                          </Button>
                        </Link>
                      </div>
                    )
                  ) : (
                    <>
                      {loading ? (
                        <Button
                          className="mt-4 "
                          size="xs"
                          style={{ width: '100px' }}
                          onClick={handleNextStep}
                          appearance="primary">
                          <Icon icon="spinner" pulse />
                        </Button>
                      ) : (
                        <Button
                          className="mt-4 py-2"
                          size="xs"
                          style={{ width: '100px' }}
                          onClick={handleNextStep}
                          appearance="primary">
                          Continue
                        </Button>
                      )}
                    </>
                  )}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Grid>
      <Styles />
    </>
  );
};

export default RoomieProfileSteps;
