import React from 'react';
import styled from '@emotion/styled';
import Step4Form from './StepsForms/Step4Form';

const Step4 = ({ roomieProfileData, setroomieProfileData, formErrors, setFormErrors }) => {
  const FormTitle = styled('h3')`
    font-size: 24px;
    font-weight: 600;
  `;

  return (
    <>
      <FormTitle>Keeping clean</FormTitle>

      <Step4Form
        space={true}
        roomieProfileData={roomieProfileData}
        setroomieProfileData={setroomieProfileData}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
      />
    </>
  );
};

export default Step4;
