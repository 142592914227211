import React from 'react';
import { Schema, FormControl, Panel, FormGroup, Radio, RadioGroup, Form } from 'rsuite';
import styled from '@emotion/styled';
import { Grid, Row, Col } from 'rsuite';
export const Model = Schema.Model({
  twoGuests: Schema.Types.StringType().isRequired('This field is required'),
  threePlusGuests: Schema.Types.StringType().isRequired('This field is required'),
  overnightGuests: Schema.Types.StringType().isRequired('This field is required'),
});
const Step7Form = ({ roomieProfileData, setroomieProfileData, formErrors, setFormErrors, shadow, space }) => {
  const FormTagline = styled('h4')`
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 30px;
  `;

  return (
    <div>
      {space ? (
        <FormTagline> I would like a home that allows me to have...</FormTagline>
      ) : (
        <FormTagline className="mb-0"> I would like a home that allows me to have...</FormTagline>
      )}

      <Form
        checkTrigger="change"
        formValue={roomieProfileData}
        onCheck={setFormErrors}
        formError={formErrors}
        onChange={setroomieProfileData}
        model={Model}
        fluid>
        <Panel className="mt-3" shaded={shadow ? false : true} style={{ overflow: 'inherit' }}>
          <Grid fluid>
            <Row className="show-grid  ">
              <FormTagline className="mb-2">1-2 guests...</FormTagline>
              <Col md={24}>
                <FormGroup controlId="radioList" className="mb-2">
                  <FormControl
                    inline
                    name="twoGuests"
                    accepter={RadioGroup}
                    onChange={val => setroomieProfileData({ ...roomieProfileData, twoGuests: val })}>
                    <Radio value="Rarely">Rarely</Radio>
                    <Radio value="1-2 times / month">1-2 times / month</Radio>
                    <Radio value="1-2 times / week">1-2 times / week</Radio>
                    <Radio value="Almost every day">Almost every day</Radio>
                  </FormControl>
                </FormGroup>
              </Col>
              <FormTagline className="mb-2">3+ guests...</FormTagline>
              <Col md={24}>
                <FormGroup controlId="radioList" className="mb-2">
                  <FormControl
                    inline
                    name="threePlusGuests"
                    accepter={RadioGroup}
                    onChange={val => setroomieProfileData({ ...roomieProfileData, threePlusGuests: val })}>
                    <Radio value="Rarely">Rarely</Radio>
                    <Radio value="1-2 times / month">1-2 times / month</Radio>
                    <Radio value="1-2 times / week">1-2 times / week</Radio>
                    <Radio value="Almost every day">Almost every day</Radio>
                  </FormControl>
                </FormGroup>
              </Col>
              <FormTagline className="mb-2">Overnight guests...</FormTagline>
              <Col md={24}>
                <FormGroup controlId="radioList" className="mb-2">
                  <FormControl
                    inline
                    name="overnightGuests"
                    accepter={RadioGroup}
                    onChange={val => setroomieProfileData({ ...roomieProfileData, overnightGuests: val })}>
                    <Radio value="Rarely">Rarely</Radio>
                    <Radio value="1-2 times / month">1-2 times / month</Radio>
                    <Radio value="1-2 times / week">1-2 times / week</Radio>
                    <Radio value="Almost every day">Almost every day</Radio>
                  </FormControl>
                </FormGroup>
              </Col>
            </Row>
          </Grid>
        </Panel>
      </Form>
    </div>
  );
};

export default Step7Form;
