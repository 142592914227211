import React, { useState, useEffect } from 'react'
// import { Editor } from "react-draft-wysiwyg";

import dynamic from "next/dynamic";
const Editor = dynamic(() => import("react-draft-wysiwyg").then((mod) => mod.Editor), {
    ssr: false,
});
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { EditorState, ContentState, convertToRaw } from "draft-js";

const EditorCus = ({ onChange,currentValue }) => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    useEffect(() => {
        if (currentValue) {
            let htmlToDraft = null;
            if (typeof window === "object") {
                htmlToDraft = require("html-to-draftjs").default;
            }
            const blocksFromHtml = htmlToDraft(currentValue);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            setEditorState(EditorState.createWithContent(contentState));
        }
    }, [])

    const convertEmptyParagraphInNull = function(htmlContent){
        if(htmlContent){
            const htmlContentWithNoSpaces =htmlContent.replaceAll("&nbsp;", "");
            const htmlContentWithoutEmptyParagrah = htmlContentWithNoSpaces.replaceAll("<p></p>\n","").trim();
            return htmlContentWithoutEmptyParagrah === ""?null:htmlContent
        }else{
            return htmlContent;
        }
    }

    const editorStateChanged = (newState) => {
        onChange(convertEmptyParagraphInNull(draftToHtml(convertToRaw(newState.getCurrentContent()))))
        setEditorState(newState)
    };
    return (
        <>
            <Editor
                editorState={editorState}
                wrapperClassName="custom-editor-wrapper"
                editorClassName="custom-editor"
                onEditorStateChange={editorStateChanged}
                stripPastedStyles={true} 
            />
            <style>
                {`
                    .custom-editor-wrapper {
                        border: 1px solid #e5e5eb;
                        border-radius: 8px;
                    }
                    .custom-editor {
                        min-height: 200px;
                        padding: 8px;
                    }
                `}
            </style>
        </>
    )
}
export default EditorCus;
