import React, { useState } from 'react';
import LinkToForm from 'components/Modals/LinkToForm';
import { UserApi } from 'services/api/user';

export default function DropDownNotifications({ notifications, setDropdownOpen, setUpdatedNotifications }) {
  const [seeAllNotifications, setSeeAllNotifications] = useState(false);
  const [activeNotification, setActiveNotification] = useState(null);
  const handleHide = id => {
    UserApi.updateNotifications({ hide: true }, id);
  };
  return (
    <div className="absolute top-full right-0 mt-2 z-50" style={{ width: '20rem' }}>
      <button
        className="bg-white shadow-md text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 dark:hover:bg-gray-600 dark:hover:text-white"
        onClick={() => setDropdownOpen(false)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="h-4 w-4">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
      <div className="bg-white rounded-md shadow-lg overflow-hidden">
        <div className="">
          {!seeAllNotifications &&
            notifications.map(
              dropdown =>
                !dropdown.hide && (
                  <div
                    key={dropdown.id}
                    id="banner"
                    tabIndex="-1"
                    onClick={() => {
                      handleHide(dropdown.nid);
                      setActiveNotification(dropdown);
                      setUpdatedNotifications();
                    }}
                    className="flex justify-between items-start py-3 px-4 border-b bg-white border-gray-200 cursor-pointer">
                    <div className={'property-details-description resident-description-wrapper'}>
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        {dropdown.title} - <span className="font-semibold">{dropdown.cta}</span>
                      </p>
                    </div>
                  </div>
                )
            )}
          {seeAllNotifications &&
            notifications.map(dropdown => (
              <div
                key={dropdown.id}
                id="banner"
                tabIndex="-1"
                onClick={() => setActiveNotification(dropdown)}
                className="flex justify-between items-start py-3 px-4 border-b bg-white border-gray-200 cursor-pointer">
                <div className={'property-details-description resident-description-wrapper'}>
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    {dropdown.title} - <span className="font-semibold">{dropdown.cta}</span>
                  </p>
                </div>
              </div>
            ))}
        </div>

        {!seeAllNotifications ? (
          <div
            onClick={() => setSeeAllNotifications(true)}
            className="block bg-[#ffc900] text-white text-center font-bold py-2 cursor-pointer">
            See all notifications
          </div>
        ) : (
          <div
            onClick={() => setSeeAllNotifications(false)}
            className="block bg-[#ffc900] text-white text-center font-bold py-2 cursor-pointer">
            Hide read notifications
          </div>
        )}
      </div>
      {activeNotification && (
        <LinkToForm
          show={true}
          onClose={() => {
            setActiveNotification();
            setUpdatedNotifications();
          }}
          title={activeNotification.title}
          description={activeNotification.message}
          buttonLabel={activeNotification.cta}
          buttonLink={activeNotification.url}
        />
      )}
    </div>
  );
}
