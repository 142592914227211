export default function Style(){
  return (
    <style>
      {`
       .roomModal{
    margin-left: 0 !important;
    margin-right:  0 !important;
}

/* // @media screen and  (max-width: 400px) {
//     .roomModalMobile {
//       width: 290px !important;
//     }
// } */

.roomModal .rs-modal-body{
    margin-top: 0 !important;
    padding-bottom: 0 !important;
}
        `}
    </style>
  );
}
