export default {
  'Portfolio-Boston': {
    'Troy Boston': {
      Notices: [
        '5',
        '5',
        '5',
        '5',
        '5',
        '5',
        '5',
        '4',
        '4',
        '5',
        '5',
        '6',
        '6',
        '2',
        '2',
        '2',
        '1',
        '2',
        '1',
        '2',
        '2',
        '1',
        '1',
        '1',
        '1',
        '1',
        '0',
        '1',
        '1',
        '1',
        '1',
        '0',
        '0',
        '0',
        '0',
        '0',
        '2',
        '1',
        '1',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '0',
        '1',
        '0',
        '0',
        '0',
        '1',
        '1',
      ],
      'Lease renewals': [
        '13',
        '14',
        '15',
        '15',
        '15',
        '16',
        '16',
        '13',
        '13',
        '17',
        '17',
        '20',
        '19',
        '6',
        '6',
        '6',
        '3',
        '6',
        '3',
        '6',
        '5',
        '3',
        '3',
        '3',
        '4',
        '4',
        '0',
        '5',
        '5',
        '5',
        '5',
        '0',
        '0',
        '0',
        '0',
        '0',
        '9',
        '5',
        '5',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '5',
        '5',
        '5',
        '5',
        '5',
        '5',
        '0',
        '5',
        '0',
        '0',
        '0',
        '5',
        '5',
      ],
      'Lease expiration exposure Next 120 days': [
        '80',
        '82',
        '79',
        '76',
        '77',
        '78',
        '87',
        '94',
        '80',
        '67',
        '49',
        '28',
        '27',
        '23',
        '23',
        '26',
        '22',
        '23',
        '19',
        '16',
        '17',
        '13',
        '14',
        '15',
        '16',
        '22',
        '16',
        '11',
        '5',
        '0',
        '0',
        '11',
        '17',
        '22',
        '22',
        '11',
        '5',
        '0',
        '0',
        '0',
        '0',
        '0',
        '5',
        '11',
        '17',
        '23',
        '23',
        '23',
        '17',
        '18',
        '12',
        '6',
        '6',
        '6',
        '12',
        '12',
        '12',
        '6',
        '0',
        '24',
      ],
      'Lease expiration exposure (%)': [
        '22.86',
        '23.43',
        '22.57',
        '21.71',
        '22.00',
        '22.29',
        '24.86',
        '26.86',
        '22.86',
        '19.14',
        '14.00',
        '8.00',
        '7.71',
        '6.57',
        '6.57',
        '7.43',
        '6.29',
        '6.57',
        '5.43',
        '4.57',
        '4.86',
        '3.71',
        '4.00',
        '4.29',
        '4.57',
        '6.29',
        '4.57',
        '3.14',
        '1.43',
        '0.00',
        '0.00',
        '3.14',
        '4.86',
        '6.29',
        '6.29',
        '3.14',
        '1.43',
        '0.00',
        '0.00',
        '0.00',
        '0.00',
        '0.00',
        '1.43',
        '3.14',
        '4.86',
        '6.57',
        '6.57',
        '6.57',
        '4.86',
        '5.14',
        '3.43',
        '1.71',
        '1.71',
        '1.71',
        '3.43',
        '3.43',
        '3.43',
        '1.71',
        '0.00',
        '6.86',
      ],
      '# of units': [
        '350',
        '350',
        '350',
        '350',
        '350',
        '350',
        '350',
        '350',
        '350',
        '350',
        '350',
        '350',
        '350',
        '350',
        '350',
        '350',
        '350',
        '350',
        '350',
        '350',
        '350',
        '350',
        '350',
        '350',
        '350',
        '350',
        '350',
        '350',
        '350',
        '350',
        '350',
        '350',
        '350',
        '350',
        '350',
        '350',
        '350',
        '350',
        '350',
        '350',
        '350',
        '350',
        '350',
        '350',
        '350',
        '350',
        '350',
        '350',
        '350',
        '350',
        '350',
        '350',
        '350',
        '350',
        '350',
        '350',
        '350',
        '350',
        '350',
        '350',
      ],
      'Work orders Raised': [
        '5',
        '11',
        '13',
        '10',
        '7',
        '5',
        '3',
        '8',
        '15',
        '5',
        '12',
        '6',
        '8',
        '14',
        '11',
        '12',
        '14',
        '8',
        '9',
        '15',
        '3',
        '5',
        '12',
        '14',
        '4',
        '4',
        '11',
        '14',
        '3',
        '15',
        '10',
        '8',
        '4',
        '10',
        '13',
        '13',
        '5',
        '14',
        '10',
        '14',
        '14',
        '10',
        '4',
        '3',
        '9',
        '15',
        '15',
        '7',
        '11',
        '15',
        '4',
        '14',
        '10',
        '3',
        '12',
        '3',
        '3',
        '10',
        '7',
        '3',
      ],
      'Total work orders pending': [
        '38',
        '55',
        '59',
        '49',
        '47',
        '64',
        '41',
        '59',
        '50',
        '26',
        '61',
        '34',
        '70',
        '51',
        '55',
        '28',
        '34',
        '67',
        '27',
        '30',
        '62',
        '67',
        '34',
        '59',
        '74',
        '36',
        '38',
        '35',
        '54',
        '49',
        '37',
        '29',
        '73',
        '48',
        '25',
        '51',
        '64',
        '55',
        '38',
        '67',
        '48',
        '29',
        '56',
        '71',
        '68',
        '34',
        '35',
        '28',
        '28',
        '46',
        '46',
        '46',
        '56',
        '71',
        '50',
        '47',
        '43',
        '54',
        '70',
        '52',
      ],
      'Rent Collection (%)': [
        '95',
        '94',
        '93',
        '92',
        '92',
        '92',
        '91',
        '92',
        '92',
        '92',
        '94',
        '94',
        '95',
        '95',
        '95',
        '95',
        '96',
        '96',
        '96',
        '96',
        '96',
        '96',
        '96',
        '96',
        '96',
        '96',
        '97',
        '97',
        '97',
        '97',
        '97',
        '97',
        '97',
        '97',
        '97',
        '97',
        '97',
        '98',
        '98',
        '98',
        '98',
        '98',
        '98',
        '98',
        '98',
        '98',
        '98',
        '98',
        '99',
        '99',
        '99',
        '99',
        '99',
        '99',
        '99',
        '99',
        '99',
        '99',
        '99',
        '99',
      ],
      'Delay (%)': [
        '3',
        '4',
        '3',
        '5',
        '5',
        '6',
        '6',
        '6',
        '6',
        '6',
        '5',
        '5',
        '4',
        '4',
        '4',
        '4',
        '4',
        '4',
        '4',
        '3',
        '3',
        '3',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
      ],
      'Deliquent (over 60 days delay)': [
        '2',
        '2',
        '4',
        '3',
        '3',
        '2',
        '3',
        '2',
        '2',
        '2',
        '1',
        '1',
        '1',
        '1',
        '0',
        '0',
        '0',
        '0',
        '1',
        '1',
        '1',
        '1',
        '1',
        '2',
        '2',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '0',
        '1',
        '0',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
      ],
      'Renewal Rate (%)': [
        '60',
        '63',
        '66',
        '67',
        '67',
        '68',
        '69',
        '70',
        '70',
        '71',
        '70',
        '70',
        '69',
        '68',
        '67',
        '67',
        '66',
        '65',
        '65',
        '64',
        '63',
        '65',
        '67',
        '69',
        '71',
        '74',
        '76',
        '78',
        '78',
        '78',
        '78',
        '78',
        '78',
        '79',
        '79',
        '79',
        '79',
        '79',
        '79',
        '79',
        '79',
        '79',
        '79',
        '79',
        '79',
        '80',
        '80',
        '80',
        '80',
        '80',
        '80',
        '80',
        '80',
        '80',
        '80',
        '80',
        '80',
        '80',
        '81',
        '81',
      ],
      'Avg. tenancy (in months)': [
        '24',
        '22',
        '22',
        '22',
        '22',
        '22',
        '21',
        '21',
        '21',
        '21',
        '21',
        '20',
        '20',
        '21',
        '21',
        '21',
        '21',
        '21',
        '21',
        '21',
        '21',
        '21',
        '21',
        '22',
        '22',
        '22',
        '22',
        '22',
        '22',
        '22',
        '22',
        '22',
        '23',
        '23',
        '23',
        '23',
        '23',
        '23',
        '23',
        '23',
        '23',
        '24',
        '24',
        '24',
        '24',
        '24',
        '24',
        '24',
        '24',
        '25',
        '25',
        '25',
        '25',
        '25',
        '25',
        '25',
        '25',
        '26',
        '26',
        '26',
      ],
      'NPS Score': [
        '65',
        '64',
        '63',
        '63',
        '63',
        '63',
        '63',
        '63',
        '63',
        '63',
        '63',
        '63',
        '63',
        '63',
        '63',
        '63',
        '63',
        '63',
        '63',
        '63',
        '63',
        '63',
        '63',
        '63',
        '63',
        '63',
        '63',
        '63',
        '63',
        '63',
        '63',
        '62',
        '61',
        '60',
        '59',
        '58',
        '58',
        '58',
        '58',
        '58',
        '58',
        '58',
        '57',
        '56',
        '55',
        '54',
        '53',
        '52',
        '53',
        '54',
        '55',
        '56',
        '57',
        '58',
        '59',
        '60',
        '61',
        '62',
        '63',
        '64',
      ],
    },
    'Parkway Apartments': {
      Notices: [
        '8',
        '8',
        '8',
        '8',
        '7',
        '7',
        '7',
        '7',
        '6',
        '8',
        '8',
        '8',
        '9',
        '9',
        '8',
        '8',
        '8',
        '7',
        '6',
        '7',
        '6',
        '6',
        '6',
        '5',
        '5',
        '4',
        '5',
        '5',
        '4',
        '3',
        '3',
        '4',
        '4',
        '3',
        '3',
        '3',
        '3',
        '3',
        '3',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '3',
        '2',
        '2',
        '2',
        '2',
        '2',
      ],
      'Lease renewals': [
        '29',
        '33',
        '39',
        '40',
        '37',
        '38',
        '40',
        '42',
        '38',
        '54',
        '51',
        '48',
        '52',
        '49',
        '42',
        '40',
        '39',
        '33',
        '27',
        '30',
        '25',
        '28',
        '31',
        '30',
        '35',
        '34',
        '55',
        '80',
        '65',
        '49',
        '50',
        '68',
        '69',
        '52',
        '53',
        '54',
        '55',
        '56',
        '57',
        '39',
        '40',
        '40',
        '41',
        '42',
        '43',
        '44',
        '45',
        '46',
        '47',
        '48',
        '49',
        '50',
        '51',
        '52',
        '81',
        '55',
        '57',
        '58',
        '60',
        '62',
      ],
      'Lease expiration exposure Next 120 days': [
        '184',
        '184',
        '185',
        '186',
        '202',
        '213',
        '220',
        '237',
        '234',
        '225',
        '217',
        '203',
        '184',
        '167',
        '156',
        '141',
        '135',
        '139',
        '137',
        '146',
        '150',
        '173',
        '223',
        '251',
        '265',
        '258',
        '245',
        '249',
        '252',
        '256',
        '241',
        '227',
        '230',
        '234',
        '218',
        '201',
        '184',
        '167',
        '170',
        '173',
        '177',
        '180',
        '184',
        '188',
        '192',
        '196',
        '200',
        '205',
        '210',
        '242',
        '248',
        '253',
        '259',
        '238',
        '244',
        '185',
        '125',
        '63',
        '0',
        '492',
      ],
      'Lease expiration exposure (%)': [
        '43.81',
        '43.81',
        '44.05',
        '44.29',
        '48.10',
        '50.71',
        '52.38',
        '56.43',
        '55.71',
        '53.57',
        '51.67',
        '48.33',
        '43.81',
        '39.76',
        '37.14',
        '33.57',
        '32.14',
        '33.10',
        '32.62',
        '34.76',
        '35.71',
        '41.19',
        '53.10',
        '59.76',
        '63.10',
        '61.43',
        '58.33',
        '59.29',
        '60.00',
        '60.95',
        '57.38',
        '54.05',
        '54.76',
        '55.71',
        '51.90',
        '47.86',
        '43.81',
        '39.76',
        '40.48',
        '41.19',
        '42.14',
        '42.86',
        '43.81',
        '44.76',
        '45.71',
        '46.67',
        '47.62',
        '48.81',
        '50.00',
        '57.62',
        '59.05',
        '60.24',
        '61.67',
        '56.67',
        '58.10',
        '44.05',
        '29.76',
        '15.00',
        '0.00',
        '117.14',
      ],
      '# of units': [
        '420',
        '420',
        '420',
        '420',
        '420',
        '420',
        '420',
        '420',
        '420',
        '420',
        '420',
        '420',
        '420',
        '420',
        '420',
        '420',
        '420',
        '420',
        '420',
        '420',
        '420',
        '420',
        '420',
        '420',
        '420',
        '420',
        '420',
        '420',
        '420',
        '420',
        '420',
        '420',
        '420',
        '420',
        '420',
        '420',
        '420',
        '420',
        '420',
        '420',
        '420',
        '420',
        '420',
        '420',
        '420',
        '420',
        '420',
        '420',
        '420',
        '420',
        '420',
        '420',
        '420',
        '420',
        '420',
        '420',
        '420',
        '420',
        '420',
        '420',
      ],
      'Work orders Raised': [
        '10',
        '8',
        '8',
        '3',
        '11',
        '8',
        '5',
        '9',
        '12',
        '4',
        '14',
        '15',
        '9',
        '13',
        '6',
        '15',
        '8',
        '7',
        '5',
        '6',
        '7',
        '10',
        '6',
        '13',
        '5',
        '5',
        '9',
        '8',
        '8',
        '3',
        '9',
        '14',
        '15',
        '7',
        '6',
        '7',
        '10',
        '12',
        '10',
        '15',
        '8',
        '6',
        '13',
        '11',
        '12',
        '8',
        '5',
        '12',
        '10',
        '7',
        '12',
        '9',
        '11',
        '6',
        '3',
        '4',
        '4',
        '12',
        '10',
        '14',
      ],
      'Total work orders pending': [
        '54',
        '38',
        '37',
        '33',
        '41',
        '73',
        '47',
        '30',
        '49',
        '28',
        '29',
        '30',
        '68',
        '73',
        '56',
        '30',
        '70',
        '52',
        '41',
        '36',
        '41',
        '38',
        '53',
        '72',
        '70',
        '60',
        '64',
        '32',
        '75',
        '72',
        '53',
        '70',
        '28',
        '33',
        '63',
        '58',
        '57',
        '71',
        '58',
        '26',
        '63',
        '40',
        '41',
        '25',
        '37',
        '29',
        '56',
        '44',
        '66',
        '42',
        '56',
        '29',
        '62',
        '71',
        '41',
        '69',
        '39',
        '67',
        '34',
        '72',
      ],
      'Rent Collection (%)': [
        '95',
        '94',
        '93',
        '92',
        '92',
        '92',
        '91',
        '92',
        '92',
        '92',
        '94',
        '94',
        '95',
        '95',
        '95',
        '95',
        '96',
        '96',
        '96',
        '96',
        '96',
        '96',
        '96',
        '96',
        '96',
        '96',
        '97',
        '97',
        '97',
        '97',
        '97',
        '97',
        '97',
        '97',
        '97',
        '97',
        '97',
        '98',
        '98',
        '98',
        '98',
        '98',
        '98',
        '98',
        '98',
        '98',
        '98',
        '98',
        '99',
        '99',
        '99',
        '99',
        '99',
        '99',
        '99',
        '99',
        '99',
        '99',
        '99',
        '99',
      ],
      'Delay (%)': [
        '3',
        '4',
        '3',
        '5',
        '5',
        '6',
        '6',
        '6',
        '6',
        '6',
        '5',
        '5',
        '4',
        '4',
        '4',
        '4',
        '4',
        '4',
        '4',
        '3',
        '3',
        '3',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
      ],
      'Deliquent (over 60 days delay)': [
        '2',
        '2',
        '4',
        '3',
        '3',
        '2',
        '3',
        '2',
        '2',
        '2',
        '1',
        '1',
        '1',
        '1',
        '0',
        '0',
        '0',
        '0',
        '1',
        '1',
        '1',
        '1',
        '1',
        '2',
        '2',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '0',
        '1',
        '0',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
      ],
      'Renewal Rate (%)': [
        '72',
        '76',
        '79',
        '80',
        '81',
        '82',
        '83',
        '83',
        '84',
        '85',
        '84',
        '83',
        '83',
        '82',
        '81',
        '80',
        '79',
        '79',
        '78',
        '77',
        '76',
        '78',
        '81',
        '83',
        '86',
        '88',
        '91',
        '94',
        '94',
        '94',
        '94',
        '94',
        '94',
        '94',
        '94',
        '94',
        '95',
        '95',
        '95',
        '95',
        '95',
        '95',
        '95',
        '95',
        '95',
        '95',
        '96',
        '96',
        '96',
        '96',
        '96',
        '96',
        '96',
        '96',
        '96',
        '96',
        '96',
        '97',
        '97',
        '97',
      ],
      'Avg. tenancy (in months)': [
        '12',
        '12',
        '12',
        '22',
        '31',
        '31',
        '36',
        '18',
        '14',
        '14',
        '28',
        '23',
        '26',
        '18',
        '32',
        '26',
        '29',
        '35',
        '24',
        '24',
        '16',
        '35',
        '30',
        '19',
        '17',
        '21',
        '25',
        '20',
        '20',
        '19',
        '17',
        '18',
        '30',
        '29',
        '16',
        '22',
        '23',
        '35',
        '33',
        '17',
        '21',
        '18',
        '14',
        '27',
        '25',
        '20',
        '28',
        '17',
        '34',
        '23',
        '28',
        '30',
        '36',
        '23',
        '29',
        '24',
        '33',
        '35',
        '17',
        '35',
      ],
      'NPS Score': [
        '71',
        '76',
        '50',
        '51',
        '66',
        '70',
        '56',
        '65',
        '53',
        '57',
        '70',
        '60',
        '58',
        '54',
        '52',
        '71',
        '69',
        '50',
        '75',
        '59',
        '52',
        '60',
        '59',
        '59',
        '66',
        '74',
        '71',
        '70',
        '63',
        '52',
        '63',
        '50',
        '71',
        '60',
        '52',
        '64',
        '56',
        '69',
        '58',
        '65',
        '67',
        '54',
        '72',
        '65',
        '65',
        '59',
        '59',
        '57',
        '51',
        '72',
        '62',
        '61',
        '51',
        '51',
        '76',
        '75',
        '53',
        '66',
        '57',
        '64',
      ],
    },
  },
  'Portfolio-Toronto': {
    'Bridge Condos': {
      Notices: [
        '4',
        '4',
        '4',
        '4',
        '4',
        '4',
        '4',
        '4',
        '3',
        '3',
        '4',
        '4',
        '2',
        '2',
        '2',
        '1',
        '1',
        '0',
        '-1',
        '0',
        '0',
        '2',
        '2',
        '2',
        '3',
        '4',
        '4',
        '4',
        '4',
        '4',
        '4',
        '4',
        '4',
        '4',
        '4',
        '1',
        '4',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
      ],
      'Lease renewals': [
        '11',
        '13',
        '14',
        '14',
        '15',
        '15',
        '16',
        '16',
        '13',
        '13',
        '17',
        '16',
        '8',
        '8',
        '7',
        '4',
        '4',
        '0',
        '-3',
        '0',
        '0',
        '6',
        '6',
        '6',
        '9',
        '12',
        '11',
        '11',
        '11',
        '11',
        '11',
        '12',
        '12',
        '12',
        '12',
        '3',
        '13',
        '7',
        '7',
        '7',
        '7',
        '7',
        '7',
        '4',
        '4',
        '4',
        '4',
        '4',
        '4',
        '5',
        '5',
        '5',
        '6',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
      ],
      'Lease expiration exposure Next 120 days': [
        '74',
        '76',
        '78',
        '74',
        '71',
        '72',
        '72',
        '66',
        '60',
        '49',
        '33',
        '28',
        '18',
        '4',
        '0',
        '-4',
        '3',
        '16',
        '24',
        '36',
        '43',
        '50',
        '57',
        '61',
        '60',
        '60',
        '61',
        '61',
        '62',
        '63',
        '52',
        '53',
        '45',
        '38',
        '42',
        '35',
        '35',
        '36',
        '32',
        '28',
        '24',
        '19',
        '20',
        '20',
        '21',
        '22',
        '22',
        '24',
        '18',
        '12',
        '6',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '6',
      ],
      'Lease expiration exposure (%)': [
        '32.89',
        '33.78',
        '34.67',
        '32.89',
        '31.56',
        '32.00',
        '32.00',
        '29.33',
        '26.67',
        '21.78',
        '14.67',
        '12.44',
        '8.00',
        '1.78',
        '0.00',
        '-1.78',
        '1.33',
        '7.11',
        '10.67',
        '16.00',
        '19.11',
        '22.22',
        '25.33',
        '27.11',
        '26.67',
        '26.67',
        '27.11',
        '27.11',
        '27.56',
        '28.00',
        '23.11',
        '23.56',
        '20.00',
        '16.89',
        '18.67',
        '15.56',
        '15.56',
        '16.00',
        '14.22',
        '12.44',
        '10.67',
        '8.44',
        '8.89',
        '8.89',
        '9.33',
        '9.78',
        '9.78',
        '10.67',
        '8.00',
        '5.33',
        '2.67',
        '0.00',
        '0.00',
        '0.00',
        '0.00',
        '0.00',
        '0.00',
        '0.00',
        '0.00',
        '2.67',
      ],
      '# of units': [
        '225',
        '225',
        '225',
        '225',
        '225',
        '225',
        '225',
        '225',
        '225',
        '225',
        '225',
        '225',
        '225',
        '225',
        '225',
        '225',
        '225',
        '225',
        '225',
        '225',
        '225',
        '225',
        '225',
        '225',
        '225',
        '225',
        '225',
        '225',
        '225',
        '225',
        '225',
        '225',
        '225',
        '225',
        '225',
        '225',
        '225',
        '225',
        '225',
        '225',
        '225',
        '225',
        '225',
        '225',
        '225',
        '225',
        '225',
        '225',
        '225',
        '225',
        '225',
        '225',
        '225',
        '225',
        '225',
        '225',
        '225',
        '225',
        '225',
        '225',
      ],
      'Work orders Raised': [
        '11',
        '4',
        '11',
        '6',
        '11',
        '9',
        '15',
        '5',
        '4',
        '6',
        '13',
        '15',
        '11',
        '4',
        '15',
        '12',
        '4',
        '12',
        '12',
        '7',
        '6',
        '14',
        '14',
        '7',
        '11',
        '10',
        '10',
        '13',
        '6',
        '14',
        '8',
        '6',
        '5',
        '4',
        '14',
        '11',
        '10',
        '13',
        '15',
        '13',
        '6',
        '11',
        '11',
        '13',
        '12',
        '5',
        '3',
        '12',
        '7',
        '3',
        '4',
        '7',
        '4',
        '10',
        '8',
        '13',
        '8',
        '14',
        '7',
        '5',
      ],
      'Total work orders pending': [
        '25',
        '57',
        '58',
        '40',
        '25',
        '26',
        '74',
        '26',
        '52',
        '75',
        '63',
        '28',
        '51',
        '51',
        '56',
        '37',
        '62',
        '62',
        '61',
        '55',
        '53',
        '73',
        '60',
        '67',
        '56',
        '28',
        '53',
        '28',
        '51',
        '66',
        '74',
        '50',
        '52',
        '38',
        '41',
        '42',
        '55',
        '69',
        '46',
        '62',
        '28',
        '30',
        '49',
        '54',
        '64',
        '45',
        '61',
        '74',
        '64',
        '32',
        '43',
        '52',
        '25',
        '51',
        '46',
        '31',
        '75',
        '55',
        '71',
        '53',
      ],
      'Rent Collection (%)': [
        '95',
        '94',
        '93',
        '92',
        '92',
        '92',
        '91',
        '92',
        '92',
        '92',
        '94',
        '94',
        '95',
        '95',
        '95',
        '95',
        '96',
        '96',
        '96',
        '96',
        '96',
        '96',
        '96',
        '96',
        '96',
        '96',
        '97',
        '97',
        '97',
        '97',
        '97',
        '97',
        '97',
        '97',
        '97',
        '97',
        '97',
        '98',
        '98',
        '98',
        '98',
        '98',
        '98',
        '98',
        '98',
        '98',
        '98',
        '98',
        '99',
        '99',
        '99',
        '99',
        '99',
        '99',
        '99',
        '99',
        '99',
        '99',
        '99',
        '99',
      ],
      'Delay (%)': [
        '3',
        '4',
        '3',
        '5',
        '5',
        '6',
        '6',
        '6',
        '6',
        '6',
        '5',
        '5',
        '4',
        '4',
        '4',
        '4',
        '4',
        '4',
        '4',
        '3',
        '3',
        '3',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
      ],
      'Deliquent (over 60 days delay)': [
        '2',
        '2',
        '4',
        '3',
        '3',
        '2',
        '3',
        '2',
        '2',
        '2',
        '1',
        '1',
        '1',
        '1',
        '0',
        '0',
        '0',
        '0',
        '1',
        '1',
        '1',
        '1',
        '1',
        '2',
        '2',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '0',
        '1',
        '0',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
      ],
      'Renewal Rate (%)': [
        '65',
        '68',
        '72',
        '72',
        '73',
        '74',
        '75',
        '75',
        '76',
        '77',
        '76',
        '75',
        '75',
        '74',
        '73',
        '72',
        '72',
        '71',
        '70',
        '69',
        '69',
        '68',
        '67',
        '67',
        '66',
        '65',
        '65',
        '64',
        '63',
        '64',
        '65',
        '65',
        '66',
        '67',
        '67',
        '68',
        '69',
        '69',
        '70',
        '71',
        '72',
        '72',
        '73',
        '74',
        '74',
        '75',
        '76',
        '77',
        '77',
        '78',
        '79',
        '81',
        '82',
        '84',
        '86',
        '87',
        '89',
        '91',
        '93',
        '96',
      ],
      'Avg. tenancy (in months)': [
        '33',
        '32',
        '29',
        '36',
        '21',
        '17',
        '30',
        '19',
        '24',
        '26',
        '13',
        '27',
        '35',
        '31',
        '34',
        '33',
        '21',
        '22',
        '34',
        '35',
        '13',
        '36',
        '34',
        '25',
        '28',
        '20',
        '26',
        '33',
        '28',
        '27',
        '28',
        '23',
        '22',
        '28',
        '33',
        '30',
        '22',
        '12',
        '20',
        '34',
        '17',
        '20',
        '25',
        '22',
        '17',
        '22',
        '17',
        '23',
        '13',
        '21',
        '32',
        '25',
        '33',
        '15',
        '17',
        '21',
        '23',
        '16',
        '30',
        '33',
      ],
      'NPS Score': [
        '63',
        '58',
        '73',
        '51',
        '57',
        '63',
        '73',
        '55',
        '63',
        '66',
        '57',
        '62',
        '55',
        '71',
        '53',
        '63',
        '71',
        '64',
        '69',
        '56',
        '68',
        '59',
        '63',
        '52',
        '51',
        '64',
        '76',
        '65',
        '56',
        '75',
        '68',
        '54',
        '73',
        '71',
        '52',
        '54',
        '60',
        '63',
        '60',
        '59',
        '68',
        '69',
        '74',
        '68',
        '63',
        '64',
        '65',
        '59',
        '51',
        '64',
        '76',
        '51',
        '55',
        '60',
        '54',
        '59',
        '75',
        '63',
        '63',
        '51',
      ],
    },
    'Liberty Towers': {
      Notices: [
        '6',
        '6',
        '6',
        '6',
        '6',
        '6',
        '6',
        '6',
        '5',
        '5',
        '5',
        '5',
        '4',
        '4',
        '4',
        '4',
        '4',
        '3',
        '3',
        '3',
        '3',
        '3',
        '4',
        '4',
        '3',
        '4',
        '4',
        '5',
        '4',
        '4',
        '4',
        '4',
        '4',
        '5',
        '5',
        '5',
        '5',
        '5',
        '5',
        '5',
        '5',
        '5',
        '5',
        '5',
        '4',
        '4',
        '4',
        '4',
        '3',
        '3',
        '3',
        '2',
        '2',
        '2',
        '2',
        '1',
        '2',
        '2',
        '1',
        '1',
      ],
      'Lease renewals': [
        '17',
        '19',
        '21',
        '22',
        '22',
        '23',
        '24',
        '24',
        '21',
        '22',
        '21',
        '20',
        '16',
        '15',
        '15',
        '14',
        '14',
        '10',
        '10',
        '10',
        '10',
        '9',
        '12',
        '12',
        '9',
        '12',
        '11',
        '14',
        '11',
        '11',
        '11',
        '12',
        '12',
        '15',
        '15',
        '16',
        '16',
        '16',
        '17',
        '17',
        '18',
        '18',
        '18',
        '19',
        '16',
        '16',
        '17',
        '17',
        '13',
        '14',
        '14',
        '10',
        '11',
        '12',
        '12',
        '7',
        '14',
        '15',
        '8',
        '8',
      ],
      'Lease expiration exposure Next 120 days': [
        '112',
        '114',
        '117',
        '114',
        '112',
        '108',
        '103',
        '97',
        '90',
        '83',
        '76',
        '74',
        '68',
        '62',
        '57',
        '51',
        '50',
        '54',
        '57',
        '56',
        '59',
        '58',
        '61',
        '64',
        '64',
        '64',
        '61',
        '61',
        '66',
        '71',
        '76',
        '81',
        '83',
        '84',
        '86',
        '87',
        '89',
        '91',
        '93',
        '90',
        '87',
        '84',
        '81',
        '78',
        '74',
        '71',
        '62',
        '59',
        '56',
        '53',
        '48',
        '51',
        '54',
        '48',
        '50',
        '34',
        '18',
        '9',
        '0',
        '99',
      ],
      'Lease expiration exposure (%)': [
        '33.94',
        '34.55',
        '35.45',
        '34.55',
        '33.94',
        '32.73',
        '31.21',
        '29.39',
        '27.27',
        '25.15',
        '23.03',
        '22.42',
        '20.61',
        '18.79',
        '17.27',
        '15.45',
        '15.15',
        '16.36',
        '17.27',
        '16.97',
        '17.88',
        '17.58',
        '18.48',
        '19.39',
        '19.39',
        '19.39',
        '18.48',
        '18.48',
        '20.00',
        '21.52',
        '23.03',
        '24.55',
        '25.15',
        '25.45',
        '26.06',
        '26.36',
        '26.97',
        '27.58',
        '28.18',
        '27.27',
        '26.36',
        '25.45',
        '24.55',
        '23.64',
        '22.42',
        '21.52',
        '18.79',
        '17.88',
        '16.97',
        '16.06',
        '14.55',
        '15.45',
        '16.36',
        '14.55',
        '15.15',
        '10.30',
        '5.45',
        '2.73',
        '0.00',
        '30.00',
      ],
      '# of units': [
        '330',
        '330',
        '330',
        '330',
        '330',
        '330',
        '330',
        '330',
        '330',
        '330',
        '330',
        '330',
        '330',
        '330',
        '330',
        '330',
        '330',
        '330',
        '330',
        '330',
        '330',
        '330',
        '330',
        '330',
        '330',
        '330',
        '330',
        '330',
        '330',
        '330',
        '330',
        '330',
        '330',
        '330',
        '330',
        '330',
        '330',
        '330',
        '330',
        '330',
        '330',
        '330',
        '330',
        '330',
        '330',
        '330',
        '330',
        '330',
        '330',
        '330',
        '330',
        '330',
        '330',
        '330',
        '330',
        '330',
        '330',
        '330',
        '330',
        '330',
      ],
      'Work orders Raised': [
        '10',
        '8',
        '6',
        '14',
        '6',
        '4',
        '6',
        '6',
        '6',
        '4',
        '15',
        '5',
        '14',
        '7',
        '3',
        '6',
        '6',
        '13',
        '9',
        '13',
        '13',
        '5',
        '4',
        '13',
        '14',
        '8',
        '8',
        '7',
        '15',
        '9',
        '12',
        '7',
        '8',
        '7',
        '8',
        '11',
        '10',
        '15',
        '9',
        '3',
        '5',
        '12',
        '9',
        '7',
        '11',
        '7',
        '6',
        '5',
        '4',
        '7',
        '4',
        '15',
        '8',
        '3',
        '8',
        '5',
        '5',
        '10',
        '6',
        '3',
      ],
      'Total work orders pending': [
        '47',
        '30',
        '69',
        '32',
        '45',
        '55',
        '58',
        '49',
        '38',
        '25',
        '30',
        '49',
        '60',
        '51',
        '62',
        '35',
        '60',
        '56',
        '64',
        '39',
        '33',
        '54',
        '42',
        '63',
        '56',
        '25',
        '52',
        '28',
        '39',
        '75',
        '25',
        '39',
        '37',
        '27',
        '53',
        '56',
        '48',
        '61',
        '61',
        '48',
        '36',
        '37',
        '46',
        '46',
        '68',
        '54',
        '27',
        '34',
        '55',
        '58',
        '44',
        '31',
        '27',
        '43',
        '73',
        '63',
        '62',
        '41',
        '45',
        '66',
      ],
      'Rent Collection (%)': [
        '95',
        '94',
        '93',
        '92',
        '92',
        '92',
        '91',
        '92',
        '92',
        '92',
        '94',
        '94',
        '95',
        '95',
        '95',
        '95',
        '96',
        '96',
        '96',
        '96',
        '96',
        '96',
        '96',
        '96',
        '96',
        '96',
        '97',
        '97',
        '97',
        '97',
        '97',
        '97',
        '97',
        '97',
        '97',
        '97',
        '97',
        '98',
        '98',
        '98',
        '98',
        '98',
        '98',
        '98',
        '98',
        '98',
        '98',
        '98',
        '99',
        '99',
        '99',
        '99',
        '99',
        '99',
        '99',
        '99',
        '99',
        '99',
        '99',
        '99',
      ],
      'Delay (%)': [
        '3',
        '4',
        '3',
        '5',
        '5',
        '6',
        '6',
        '6',
        '6',
        '6',
        '5',
        '5',
        '4',
        '4',
        '4',
        '4',
        '4',
        '4',
        '4',
        '3',
        '3',
        '3',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
      ],
      'Deliquent (over 60 days delay)': [
        '2',
        '2',
        '4',
        '3',
        '3',
        '2',
        '3',
        '2',
        '2',
        '2',
        '1',
        '1',
        '1',
        '1',
        '0',
        '0',
        '0',
        '0',
        '1',
        '1',
        '1',
        '1',
        '1',
        '2',
        '2',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '0',
        '1',
        '0',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
        '1',
      ],
      'Renewal Rate (%)': [
        '65',
        '68',
        '72',
        '72',
        '73',
        '74',
        '75',
        '75',
        '76',
        '77',
        '76',
        '75',
        '75',
        '74',
        '73',
        '72',
        '72',
        '71',
        '70',
        '69',
        '69',
        '68',
        '67',
        '67',
        '66',
        '65',
        '65',
        '64',
        '63',
        '64',
        '65',
        '65',
        '66',
        '67',
        '67',
        '68',
        '69',
        '69',
        '70',
        '71',
        '72',
        '72',
        '73',
        '74',
        '74',
        '75',
        '76',
        '77',
        '77',
        '78',
        '79',
        '81',
        '82',
        '83',
        '84',
        '85',
        '86',
        '86',
        '87',
        '88',
      ],
      'Avg. tenancy (in months)': [
        '19',
        '13',
        '13',
        '12',
        '18',
        '23',
        '31',
        '16',
        '36',
        '23',
        '28',
        '15',
        '35',
        '14',
        '20',
        '22',
        '36',
        '12',
        '31',
        '24',
        '21',
        '28',
        '35',
        '31',
        '30',
        '27',
        '30',
        '15',
        '33',
        '22',
        '26',
        '35',
        '34',
        '34',
        '27',
        '16',
        '16',
        '20',
        '27',
        '25',
        '31',
        '17',
        '25',
        '28',
        '20',
        '17',
        '27',
        '26',
        '34',
        '25',
        '22',
        '17',
        '27',
        '22',
        '33',
        '18',
        '29',
        '29',
        '28',
        '25',
      ],
      'NPS Score': [
        '62',
        '52',
        '51',
        '54',
        '70',
        '63',
        '73',
        '69',
        '50',
        '62',
        '55',
        '51',
        '76',
        '70',
        '69',
        '64',
        '66',
        '69',
        '55',
        '61',
        '50',
        '52',
        '71',
        '54',
        '61',
        '50',
        '52',
        '58',
        '70',
        '72',
        '51',
        '65',
        '51',
        '65',
        '60',
        '60',
        '62',
        '67',
        '72',
        '73',
        '71',
        '66',
        '60',
        '69',
        '51',
        '75',
        '76',
        '54',
        '62',
        '67',
        '71',
        '67',
        '67',
        '67',
        '67',
        '58',
        '64',
        '73',
        '63',
        '55',
      ],
    },
  },
};
