import { useEffect, useState } from "react";
import InsightsDrawer from 'containers/InsightsDrawer';
import { Whisper, Tooltip } from "rsuite";
import CreativeIcon from '@rsuite/icons/Creative';
import { useUser } from "contexts/UserContext";
import insightsData from "insights";
export default function InsightsButton({ origin }) {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [version, setVersion] = useState({});
  const [showInsights, setShowInsights] = useState(false);
  const user = useUser()

  useEffect(() => {
    if (insightsData.validUsers.includes(user.user.email)) {
      setShowInsights(true);
    }
  },[])

  useEffect(() => {
    setVersion(buttonStyleDict['active']);
  },[openDrawer])

  const buttonStyleDict = {
    inactive: {appearance: 'primary', color: '', fill: '#AAAAAA', outline: '#AAAAAA', message: 'No new insights'},
    active: openDrawer? {appearance: 'primary', color: '#FFB900', fill: '#FFFFFF', outline: '#FFB900', message: 'New insights'}
     : {appearance: 'primary', color: '', fill: '#FFB900', outline: '#FFB900', message: 'New insights'}
  }

  return (
    <>
      <InsightsDrawer origin={origin} openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} user={user}/>
      <div className="my-auto pl-4 pb-2">
        {!showInsights? (
          <Whisper placement="bottom" trigger="hover" speaker={<Tooltip>Coming Soon!</Tooltip>}>
            <div style={{backgroundColor: ``, borderColor: `#AAAAAA`}} className={`p-2 rounded-full cursor-pointer border-[2px] border-solid`}>
              <CreativeIcon
              style={{fontSize: '1.5em'}}
              className={''}
              fill={'#AAAAAA'}
              />
            </div>
          </Whisper>
        ) : (
        <Whisper placement="bottom" trigger="hover" speaker={<Tooltip>{version.message}</Tooltip>}>
          <div
          style={{backgroundColor: `${version.color}`, borderColor: `${version.outline}`}}
          className={`p-2 rounded-full cursor-pointer border-[2px] border-solid`}
          onClick={() => {
            setOpenDrawer(!openDrawer);
          }}
          >
            <CreativeIcon
            style={{fontSize: '1.5em'}}
            className={''}
            fill={version.fill}
            />
          </div>
        </Whisper>
        )}
      </div>
    </>
  );
}
