import React, { useState, useEffect } from 'react';
import { Button, Modal, Dropdown, Icon, Input, Alert,Tooltip,
  Whisper } from 'rsuite';
import { GptApi } from 'services/api/gpt';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
const FeedbackComponent = ({ user, questionId, noOutline , smallSize, monochrome}) => {
  const [showModal, setShowModal] = useState(false);
  const [feedbackOption, setFeedbackOption] = useState(null);
  const [comment, setComment] = useState('');

  const feedbackOptions = [
    'I know the answer is incorrect',
    'I feel the answer is wrong',
    'This doesn’t answer my question',
    'The answer is hard to understand',
    'other',
  ];

  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  useEffect(() => {
    setFeedbackSubmitted(false);
  }, []);
  const handlePositive = () => {
    GptApi.giveFeedback({ success: true, questionId, user }).then(res => {
      Alert.success('Your feedback has been submitted!');
      setShowModal(false);
      setFeedbackOption(null);
      setComment('');
      setFeedbackSubmitted(true);
    });
  };

  const handleNegative = () => {
    setShowModal(true);
  };

  const handleFeedbackSubmit = () => {
    if (feedbackOption) {
      console.log();
      GptApi.giveFeedback({ success: false, reason: feedbackOption, comment, questionId, user })
        .then(res => {
          Alert.success('Your feedback has been submitted!');
          setShowModal(false);
          setFeedbackOption(null);
          setComment('');
          setFeedbackSubmitted(true);
        })
        .catch(err => {
          errorTracker.error('Create feedback error', { feedbackData: feedbackData });
          Alert.error('Something went wrong while creating feedback.', 5000);
          console.error(err);
        });
    } else {
      Alert.error('Please select a feedback option.');
    }
  };

  const feedbackButtons = (
    <div className={`flex flex-row justify-center ${smallSize?'':'gap-4'}`}>
      <Whisper placement="top" trigger="hover" speaker={<Tooltip>Good</Tooltip>}>
      <Button
        disabled={feedbackSubmitted}
        onClick={handlePositive}
        className={`${!noOutline?'rounded-full':''} px-[0.2rem] pb-0 pt-[0.2rem]`}>
        <FontAwesomeIcon
          size={`${smallSize?'sm':'lg'}`}
          icon={faThumbsUp}
          className={`${monochrome?'':'hover:bg-[#67C03E]'} cursor-pointer  ${!noOutline?'rounded-full border-solid border-2 border-[#67C03E]':''} p-2 ${
            feedbackSubmitted ? 'border-gray-500 hover:bg-transparent' : ' '
          }`}
        />
      </Button>
      </Whisper>
      <Whisper placement="top" trigger="hover" speaker={<Tooltip>Bad</Tooltip>}>
      <Button
        disabled={feedbackSubmitted}
        onClick={handleNegative}
        className={`${!noOutline?'rounded-full':''} px-[0.2rem] pb-0 pt-[0.2rem]`}>
        <FontAwesomeIcon
          size={`${smallSize?'sm':'lg'}`}
          icon={faThumbsDown}
          className={`${monochrome?'':'hover:bg-[#E02C2C]'} cursor-pointer  ${!noOutline?'rounded-full border-solid border-2 border-[#E02C2C]':''} p-2 ${
            feedbackSubmitted ? 'border-gray-500 hover:bg-transparent' : ' '
          }`}
        />
      </Button>
      </Whisper>
    </div>
  );

  return (
    <div className="flex flex-col items-center">
      {feedbackButtons}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header>
          <Modal.Title>Let us know what went wrong</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mb-4">
          <Dropdown
            className="mb-6 border border-amber-200 rounded"
            title={feedbackOption || 'Select feedback option'}
            onSelect={key => setFeedbackOption(feedbackOptions[key])}
            renderMenu={menu => <div className="max-h-[200px] overflow-auto">{menu}</div>}>
            {feedbackOptions.map((option, index) => (
              <Dropdown.Item key={index} eventKey={index}>
                {option}
              </Dropdown.Item>
            ))}
          </Dropdown>
          <Input
            className="mb-6"
            componentClass="textarea"
            rows={5}
            placeholder="Add some more context"
            value={comment}
            onChange={value => setComment(value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleFeedbackSubmit} className="bg-yellow-400">
            Submit Feedback
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default FeedbackComponent;
