export default function Style(){
  return (
    <style>
      {`
       .back-btn{
    background-color: white !important;
    color: #ffb900 !important;
    border: 1px solid #ffb900;
}
.back-btn:hover {
  background-color: #ffb900 !important;
  color: white!important; 
}
@media (max-width: 410px) {
    .steps-dots{display: none;}
}
        `}
    </style>
  );
}
