import {
  Button,
  Col,
  Container,
  Drawer,
  FlexboxGrid,
  HelpBlock,
  Icon,
  IconButton,
  List,
  Nav,
  Input,
  Panel,
  PanelGroup,
  Placeholder,
  Popover,
  SelectPicker,
  Divider,
  Tooltip,
  Table,
  Column,
  HeaderCell,
  Cell,
  Loader,
  Whisper,
} from 'rsuite';
import React, { useEffect, useState, useRef } from 'react';
import { get, isEmpty, startCase, sum, truncate, union } from 'lodash';
import PropTypes from 'prop-types';
import { GptApi } from 'services/api/gpt';
import { useUser } from 'contexts/UserContext';
import { useErrorTracker } from 'utils/use-error-tracker';
import DefaultErrorBoundary from 'components/DefaultErrorBoundary';
import FeedbackComponent from 'components/ChatFeedback';
import SendIcon from '@rsuite/icons/Send';
import ShieldIcon from '@rsuite/icons/Shield';
import HelpOutlineIcon from '@rsuite/icons/HelpOutline';
import EditIcon from '@rsuite/icons/Edit';

export function ChatAssistantDrawer({ setOpenChatDrawer }) {
  const [openChat, setOpenChat] = useState(true);
  const [currentTab, setCurrentTab] = useState('chat');
  const [lastAskedQuestion, setLastAskedQuestion] = useState(null);
  const [questionAsked, setQuestionAsked] = useState(null);
  const [fetchedAnswer, setFetchedAnswer] = useState(null);
  const [summary, setSummary] = useState('');
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [settingSearchKeyword, setSettingSearchKeyword] = useState();
  const questionInputRef = useRef();
  const [question, setQuestion] = useState('');
  const { user: currentUser } = useUser();
  const [loading, setLoading] = useState(false);
  const [errorCaught, setErrorCaught] = useState(false);
  const [showData, setShowData] = useState(false);
  const [historyArray, setHistoryArray] = useState([]);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [questionId, setQuestionId] = useState(null);
  const [showChatOnboarding, setShowChatOnboarding] = useState(true);
  const getHistory = async () => {
    try {
      const { data } = await GptApi.getHistory(currentUser);
      // console.log(response);
      if (!data.result) setHistoryArray([]);
      // sort data.result by "createdAt" in descending order
      data.result.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

      setHistoryArray(data.result);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleKeyUp = event => {
    if (event.key === 'Enter') {
      // Get and set ref value
      onAskQuestionButtonClicked();
    }
  };

  useEffect(() => {
    getHistory();
  }, [fetchedAnswer]);

  const extractResponse = res => {
    if (res && res.data && res.data.completions && res.data.completions) {
      const keys = Object.keys(res.data.completions);
      for (let i = 0; i < keys.length; i++) {
        if (res.data.completions[keys[i]] && res.data.completions[keys[i]].result) {
          if (res.data.completions[keys[i]].result.length > 0) {
            setSummary(res.data.completions[keys[i]].summary?.data);
            setQuestionId(res.data.completions[keys[i]].questionId);
            return res.data.completions[keys[i]].result;
          }
          // if the array is empty, we return {data: null} so that the frontend does not crash
          return [{ data: null }];
        }
      }
    }
    return null;
  };

  const onAskQuestionButtonClicked = async (aQuestion) => {
    // if the question is empty, do not send a request to the backend
    if (questionInputRef.current.value === '' && !aQuestion) {
      return;
    }
    const questionToAsk =  aQuestion? aQuestion: questionInputRef.current.value;

    setQuestion(questionToAsk);
    setLoading(true);
    try {
      const res = await GptApi.runModels(questionToAsk, currentUser);
      const resultsArray = extractResponse(res);

      if (!resultsArray) {
        throw 'Something went wrong!';
      } else {
        setErrorCaught(null);
      }
      setFetchedAnswer(resultsArray);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrorCaught(error);
    }
  };

  const { Column, HeaderCell, Cell } = Table;

  function DynamicTable({ data }) {
    const [sortColumn, setSortColumn] = useState('');
    const [sortType, setSortType] = useState('');

    function handleSortColumn(sortColumn, sortType) {
      setSortColumn(sortColumn);
      setSortType(sortType);
    }

    // create a sorted copy of the data array based on the current sort order
    const sortedData = [...data].sort((a, b) => {
      if (sortColumn) {
        const aValue = a[sortColumn];
        const bValue = b[sortColumn];

        if (typeof aValue === 'string') {
          return aValue.localeCompare(bValue);
        }

        if (aValue < bValue) {
          return -1;
        }

        if (aValue > bValue) {
          return 1;
        }
      }

      return 0;
    });

    return (
      <div className="p-4 flex justify-center border border-gray-400 rounded-lg">
        <div className="w-full max-w-screen-lg mx-auto">
          <Table
            data={sortedData}
            sortColumn={sortColumn}
            sortType={sortType}
            autoHeight={true}
            onSortColumn={handleSortColumn}>
            {data &&
              data.length > 0 &&
              Object.keys(data[0]).map(key => (
                <Column key={key} sortable resizable>
                  <HeaderCell>{key}</HeaderCell>
                  <Cell dataKey={key} />
                </Column>
              ))}
          </Table>
        </div>
      </div>
    );
  }

  const AnswerSection = () => {
    return (
      <section>
        <div className="font-semibold italic text-stone-800 text-xl my-4"> Asked: {question}</div>

        {summary && (
          <>
            {' '}
            <div className=" text-gray-900 font-semibold mb-4 bg-amber-200 p-4 rounded-lg"> {summary}</div>
            {fetchedAnswer.length > 1 && (
              <button
                className="btn bg-[#ffb900] rounded-lg px-3 py-2 mb-4 text-white"
                onClick={() => setShowData(!showData)}>
                {!showData ? 'Show Data' : 'Hide Data'}
              </button>
            )}
          </>
        )}
        <div className="feedback-test">
          <FeedbackComponent user={currentUser} questionId={questionId} />
        </div>
        {fetchedAnswer.length > 1 && (!summary || showData) && <DynamicTable data={fetchedAnswer} />}
      </section>
    );
  };

  const QuestionHistoryItem = historyItem => {
    return (
      <Panel header={historyItem.question} collapsible>
        <div>
          <DynamicTable data={historyItem.answer} />
        </div>
      </Panel>
    );
  };

  const HistorySection = () => {
    return (
      <>
        <FlexboxGrid justify="space-around" style={{ padding: '0 10px' }}>
          {/* <FlexboxGrid.Item as={Col} colspan={24} xs={24} sm={24} md={12} lg={12}>
            <SearchInput
              onChange={k => {
                setSettingSearchKeyword(k);
              }}
              placeholder="Search..."
            />
          </FlexboxGrid.Item> */}

          {historyArray &&
            historyArray.map(history => {
              return (
                <Panel
                  shaded
                  bodyFill
                  className="shadow-sm w-full border-gray-100 border hover:shadow-xl m-[10px] overflow-visible"
                  key={history.id}
                  expanded={false}
                  onClick={() => {
                    setCurrentQuestion(history);
                  }}>
                  <FlexboxGrid className="m-6">
                    <FlexboxGrid.Item componentClass={Col} xs={18} sm={18} md={18} lg={18}>
                      <div style={{ textAlign: 'left' }} className="flex flex-col gap-1">
                        <div>{`${history.question}`}</div>
                      </div>
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item componentClass={Col} xs={4} sm={4} md={4} lg={4}>
                      {currentQuestion?.id === history.id && (
                        <div
                          className="bg bg-grey-100 py-1 px-3 rounded-lg font-bold text-center hover:bg-gray-200"
                          onClick={e => {
                            e.stopPropagation();

                            setCurrentQuestion(null);
                          }}>
                          close
                        </div>
                      )}
                    </FlexboxGrid.Item>
                  </FlexboxGrid>
                  <div
                    className="m-6 px-2"
                    onClick={e => {
                      e.stopPropagation();
                    }}>
                    {currentQuestion?.id === history.id && (
                      <div>
                        <div> Answer </div>
                        <div className="flex">
                          <div className="w-full">
                            <DynamicTable data={history.results.rows} />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </Panel>
              );
            })}
        </FlexboxGrid>
      </>
    );
  };

  // const handleQuestionChange = debounce(text => {
  //   setQuestion(text);
  // }, 300);

  const handleEnter = () => {
    console.log('handleEnter called');
  };

  const ShowError = () => {
    return (
      <div className={`flex flex-col max-w-xl w-full justify-center mx-auto items-center `}>
        <img src={'/static/images/Oops! 404 Error with a broken robot-cuate.png'} className="max-w-xs " />
        <h1 className="text-lg my-8 font-bold">Something went wrong retrieving your answer</h1>
      </div>
    );
  };

  const promptExamples = ["How many leads do I have?", "how many buildings do I have?", "how many no-show did I have last week?"];

  const renderPromptExmaples = ()=>{
    return promptExamples.map((d, i)=>{
        return (<div className="prompt_example my-2">
       <Button  key={i} appearance="ghost" onClick={()=>{onAskQuestionButtonClicked(d)}} className="text-black border-black bg-white" >
        {d}<SendIcon className="text-xl ml-4" />
        </Button>
    </div>)

  });
  }

  const renderInfoChatItem = ({icon, title, details}) =>{
    return (
      <div className="chat_info_intro_item flex flex-row my-8">
      <div className="icon_container px-4 w-20 text-xl items-center flex flex-col" style={{"flex": "0 0 auto"}}>
      {icon}
      </div>

      <div>
      <div className="font-bold">
      {title}
      </div>
       <div >
      {details}
      </div>
      </div>

      </div>
      );
  }

  const ChatOnboarding = () =>{
    return (
      <div className="" style={{}}>
      <section className="top_section py-8 px-4 rounded-t-xl" style={{background: "linear-gradient(rgb(225 213 181) 50%, rgb(249, 246, 237))"}}>

      <div className="intro_message">
       <span className="colorful_message font-bold">Welcome to Sage+</span> Now, you can ask anything about your portfolio on Leni.
      </div>

      <div className="try_out_message opacity-30 my-4 text-xs">
      <span className="inline mr-4">Try it out</span><img className="w-8 mt-2 inline rotate-[230deg]" src={"/static/icons/drawn-arrow.png"}/>
      </div>

      <div className="prompt_examples">
      {renderPromptExmaples()}
      </div>

      </section>


      <section className="bottom_section py-8 pr-8 rounded-b-xl" style={{background:"rgb(249 246 237)"}}>
      {renderInfoChatItem({icon:<ShieldIcon className="text-xl"/>, title:"Privacy first integration with LLMs", details:"Sage+ combines what Leni has captured with the power of LLMs. Only your question and text from relevant moments is sent to LLMs."})}
      {renderInfoChatItem({icon: <HelpOutlineIcon className="text-xl"/>, title:"Great questions get great answers", details:"Sage+ works well when you provide specific detailed questions."})}
      {renderInfoChatItem({icon: <EditIcon className="text-xl"/>, title:"Share your feedback", details:"Please share your feedback as you use Sage+ so we can keep improving it. "})}
      
      </section>
      </div>
      );
  }

  const ChatTabContent = () => (
    <div className="relative w-full m-4 flex flex-col">
      {/* <div class="flex flex-row overflow-auto border-2 border-gray-400 rounded-lg p-4 h-52"> */}

      {loading && (
        <div className="h-72">
          <Loader center speed="slow" size="lg" />
        </div>
      )}
      {!loading && !errorCaught && !fetchedAnswer && showChatOnboarding && <div className="h-full mb-12"><ChatOnboarding /></div>}
      {!loading && !errorCaught && fetchedAnswer && <div className="h-full mb-12">{fetchedAnswer && <AnswerSection />}</div>}
      {!loading && errorCaught && <ShowError />}
      {/* </div> */}
      <div className="fixed bottom-0 mb-4 w-full px-10 flex flex-row z-10 drop-shadow-lg">
        <input
          className="flex-auto p-2"
          type="text"
          ref={questionInputRef}
          placeholder="Ask a question about your data..."
          onKeyUp={handleKeyUp}
        />
        <Button
          appearance="primary"
          className="mx-2 "
          style={{ flex: '0 0 auto' }}
          onClick={() => onAskQuestionButtonClicked()}>
          Ask Question
        </Button>
      </div>
    </div>
  );

  const HistoryTabContent = () => (
    <div className="w-full py-2">
      <HistorySection />
    </div>
  );

  return (
    <>
      <Drawer
        backdrop={false}
        show={openChat}
        open={openChat}
        onHide={() => setOpenChatDrawer(false)}
        className="pt-16"
        onClose={() => {
          alert(1);
          setOpenChatDrawer(false);
        }}>
        <Drawer.Header>
          <Drawer.Title>Sage+</Drawer.Title>
        </Drawer.Header>
        <Divider style={{ marginBottom: 0 }}></Divider>
        <Drawer.Body>
          <div className="flex flex-col w-full items-center justify-center">
            <Nav
              appearance="subtle"
              onSelect={val => {
                setCurrentTab(val);
              }}
              activeKey={currentTab}>
              <Nav.Item style={{ marginLeft: '10px', marginRight: '10px' }} eventKey="chat">
                <span className={`font-bold ` + (currentTab === 'chat' ? `text-[#ffb900]` : ` `)}>Chat</span>
              </Nav.Item>
              <Nav.Item style={{ marginLeft: '10px', marginRight: '10px' }} eventKey="history">
                <span className={`font-bold ` + (currentTab === 'history' ? `text-[#ffb900]` : ` `)}>History</span>
              </Nav.Item>
            </Nav>

            {currentTab === 'chat' && <ChatTabContent />}
            {currentTab === 'history' && <HistoryTabContent />}
          </div>
        </Drawer.Body>
      </Drawer>
    </>
  );
}

ChatAssistantDrawer.propTypes = {
  showcasesData: PropTypes.object,
  getShowcases: PropTypes.func,
};

export default ChatAssistantDrawer;
