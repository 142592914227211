import React, { useContext, useEffect, useState } from 'react';
import { DashboardContext } from '../../../contexts/LayoutDashboardContext';
import { PropertiesContext } from 'contexts/PropertiesContext';
import useDimension from 'utils/use-dimension';
import {
  Alert,
  Button,
  CheckPicker,
  DatePicker,
  DateRangePicker,
  Drawer,
  Icon,
  IconButton,
  SelectPicker,
  Tooltip,
  Whisper,
} from 'rsuite';
import {
  addDays,
  endOfQuarter,
  startOfQuarter,
  startOfYear,
  subDays,
  subQuarters,
  differenceInWeeks,
  endOfYear,
  getDay,
  startOfWeek,
  endOfWeek,
  subWeeks,
  endOfDay,
  startOfMonth,
  endOfMonth,
  subMonths,
} from 'date-fns';
import styled from '@emotion/styled';
import { flatten, uniqBy } from 'lodash';
import Styles from './styles.js';
import insightsData from 'insights';
import { useRouter } from 'next/router';
import { useUser } from 'contexts/UserContext';
import { PriceApi } from 'services/api/price';
import { BuildingApi } from 'services/api/building';
import { UserApi } from 'services/api/user';
import SettingIcon from '@rsuite/icons/Setting';

import {
  dropDownOptions,
  analyticsMaker,
  dates,
  SectionData,
  useValues,
  weeklyByPeriod,
  lastNumberWeeks,
  valuesRanges,
} from '../../../containers/DashboardMarketing/index.js';
import managementData from '../../../containers/DashboardMarketing/managementData.js';
import marketingData from '../../../containers/DashboardMarketing/marketingData.js';
import tenantData from '../../../containers/DashboardMarketing/tenantData.js';
import leasingData from '../../../containers/DashboardMarketing/leasingData.js';
import reportingData from '../../../containers/DashboardMarketing/reportingData.js';
import lookerDashboards from 'data/lookerDashboards';

export default function DashboardFilters() {
  const { dashboardState, dashboardDispatch } = useContext(DashboardContext);
  const propertiesContext = useContext(PropertiesContext);
  // useDimension custom hook is not working on initial load, using state for now until this can be investigated further
  // const { width } = useDimension();
  const [show, setShow] = useState(false);
  const [insightsUser, setInsightsUser] = useState(false);
  const user = useUser();
  const useTestData = insightsUser;
  const previousYearHelper = new Date();
  previousYearHelper.setFullYear(previousYearHelper.getFullYear() - 1);
  const startOfPreviousYear = startOfYear(previousYearHelper);
  const endOfPreviousYear = endOfYear(previousYearHelper);
  const [neighborhoodData, setNeighborhoodData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [buildingGroup, setBuildingGroup] = useState({});

  //state to store buildings in one call
  const [priceBuildingsData, setPriceBuildingsData] = useState([]);
  const [savedComparables, setSavedComparables] = useState([]);

  //state to control whats on the list of buildings
  const [priceFilteredBuildingsData, setPriceFilteredBuildingsData] = useState([]);
  const router = useRouter();
  const [width, setWidth] = useState(null);

  useEffect(() => {
    // only execute all the code below in client side
    if (typeof window !== 'undefined') {
      // Handler to call on window resize

      const handleResize = () => {
        setWidth(window.innerWidth);
      };

      window.addEventListener('resize', handleResize);
      handleResize();

      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);

  // const fetchData = {
  //   portfolios: [],
  //   buildings: ['Bridge Condos', 'Parkway Apartments'],
  //   path: [
  //     'Net Rental Income',
  //     'Capital Expenditure',
  //     'Net Operating Income',
  //     'Occupancy',
  //     'Total expenses',
  //     'Total Sq.ft',
  //     'Total # of units',
  //     'Net Eff Rent/Sft',
  //   ],
  // };

  useEffect(() => {
    //if user is in the approved list
    if (insightsData.validUsers.includes(user.user.email)) {
      setInsightsUser(true);
    }
  }, []);

  useEffect(async () => {
    // await PriceApi.getPriceBuildings()
    await BuildingApi.getBuildingInstances()
      .then(res => setPriceBuildingsData(res.data))
      .catch(err => console.error(err));
  }, []);

  useEffect(() => {
    let neighbourhood = [];
    uniqBy(propertiesContext?.state?.data, 'building.id').forEach(p => {
      if (dashboardState.DashboardTab?.city.length > 0) {
        if (dashboardState.DashboardTab?.city.indexOf(p.building?.city) > -1) {
          neighbourhood.push({
            label: p.building.neighbourhood ? p.building.neighbourhood : 'Unknown',
            value: p.building.neighbourhood,
          });
        }
      } else {
        neighbourhood.push({
          label: p.building.neighbourhood ? p.building.neighbourhood : 'Unknown',
          value: p.building.neighbourhood,
        });
      }
    });
    const newNeighborhoodData = uniqBy(neighbourhood, 'value');
    setNeighborhoodData(newNeighborhoodData);
  }, [propertiesContext?.state?.data, dashboardState.DashboardTab?.city]);

  useEffect(() => {
    let city = [];
    const buildingDict = {};
    //set city data for dropwdown
    uniqBy(propertiesContext?.state?.data, 'building.id').forEach(p => {
      if (p.building?.city) {
        city.push({
          label: p.building.city,
          value: p.building.city,
        });
      }
      buildingDict[p.building.name]
        ? buildingDict[p.building.name].push(p.building.id)
        : (buildingDict[p.building.name] = [p.building.id]);
    });
    setBuildingGroup(buildingDict);
    if (Object.keys(buildingDict).length > 0) {
      dashboardDispatch({
        type: 'setPropertyGroup',
        payload: buildingDict,
      });
    }

    const newCityData = uniqBy(city, 'value');
    if (newCityData.length > 0) {
      dashboardDispatch({
        type: 'setCity',
        payload:
          user.user.id === 'a73cbbcb-3aa5-4d2b-a68c-ee5ff734961a'
            ? ['Toronto']
            : user.user.id === '6a532c61-7378-4f01-918e-56c8fde870d4'
            ? []
            : [newCityData[0].value],
      });
    }
    setCityData(newCityData);
  }, [propertiesContext?.state?.data]);

  useEffect(() => {
    const buildings = [];

    priceBuildingsData.forEach(b => {
      if (dashboardState.DashboardTab?.neighborhood.indexOf(b.neighbourhood) > -1) {
        buildings.push({ label: b.building, value: b.building });
      }
    });

    setPriceFilteredBuildingsData(buildings);
    if (dashboardState.DashboardTab?.neighborhood.length === 0) {
      dashboardDispatch({
        type: 'setPriceBuilding',
        payload: [],
      });
    }
  }, [propertiesContext?.state?.data, priceBuildingsData, dashboardState.DashboardTab?.neighborhood]);

  useEffect(() => {
    const val = dashboardState.DashboardTab?.filter;
    if (val === 'overview') {
      // reset building and user as the cards on the 'overview' tab are not designed to be filterable
      dashboardDispatch({
        type: 'setBuilding',
        payload: [],
      });
      dashboardDispatch({
        type: 'setUserSelected',
        payload: '',
      });
    }
    if (val === 'cgi_vp_exec') {
      dashboardDispatch({
        type: 'setDate',
        payload: endOfDay(startOfWeek(new Date())),
      });
    } else {
      dashboardDispatch({
        type: 'setDateRange',
        payload:
          val === 'reporting'
            ? // ? [startOfYear(new Date()), new Date()]
              [startOfYear(new Date()), new Date()]
            : val === 'unit_turn'
            ? [startOfPreviousYear, endOfPreviousYear]
            : val === 'cgi_vp_exec_sandbox'
            ? [startOfWeek(subWeeks(new Date(), 1)), endOfWeek(subWeeks(new Date(), 1))]
            : val === 'cgi_leasing' || val === 'cgi_operations' || val === 'asset_management'
            ? [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))]
            : val === 'standard_marketing' ||
              val === 'standard_occupancy_and_leasing_performance' ||
              val === 'standard_operations' // for these three, default to current month
            ? [startOfMonth(new Date()), endOfMonth(new Date())]
            : val === 'standard_financial' // for financial, default to last month
            ? [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))]
            : [startOfQuarter(new Date()), endOfQuarter(new Date())], // default to this quarter
      });
    }
  }, [dashboardState.DashboardTab?.filter]);

  //tailwind class "flex flex-row flex-wrap space-x-1 -mt-2"
  const FlexDiv = styled('div')`
    display: flex;
    flex-direction: row;
    --tw-space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
    margin-top: -0.5rem;
    flex-wrap: wrap;
  `;

  //tailwind class "grid grid-row-4 gap-2"
  const GridDiv = styled('div')`
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 0.5em 0.5em;
  `;

  const isUserAllowedToSeeLeasingReport = () => {
    const allowedOrganisationIds = ['6032c703-487f-4ac5-afae-752e6110522d', 'e5f8fcfe-18c7-4969-a224-0f8333a52aa8'];
    return user && user.user && user.user.organisationId && allowedOrganisationIds.includes(user.user.organisationId);
  };
  const isEntrata = () => {
    return user && user.user && user.user.organisationId === '97e009f8-545c-4b8e-97b8-730b2d56c554'; // simtob org
    // return user && user.user && (user.user.organisationId === '97e009f8-545c-4b8e-97b8-730b2d56c554' || user.user.organisationId === 'e5f8fcfe-18c7-4969-a224-0f8333a52aa8'); // simtob and dev orgs
  };

  const filterType = user?.user?.dashboardViews
    ?.filter(el => Object.keys(lookerDashboards).includes(el))
    .map(el => {
      return { label: lookerDashboards[el].label, value: el };
    });

  const close = () => {
    setShow(false);
  };
  const toggleDrawer = () => {
    setShow(!show);
  };

  const getDataObject = () => {
    if (dashboardState.DashboardTab?.filter === 'leasing') {
      return leasingData;
    }
    if (dashboardState.DashboardTab?.filter === 'tenants') {
      return tenantData;
    }
    if (dashboardState.DashboardTab?.filter === 'marketing') {
      return marketingData;
    }
    if (dashboardState.DashboardTab?.filter === 'management') {
      return managementData;
    }
    if (dashboardState.DashboardTab?.filter === 'overview') {
      return leasingData;
    }
    return reportingData;
  };

  const yearInfo = {};

  const getYearData = () => {
    const yearsArray = [];
    useValues.Years.forEach((y, i) => {
      if (!yearInfo[y]) {
        yearInfo[y] = { months: [], quarters: [] };
      }
      let month = useValues.Months[i];
      let quarter = useValues.DateQuarters[i];
      if (yearInfo[y]['months'].indexOf(month) == -1) {
        yearInfo[y]['months'].push(month);
      }
      if (yearInfo[y]['quarters'].indexOf(quarter) == -1) {
        yearInfo[y]['quarters'].push(quarter);
      }
      yearsArray.indexOf(y) > -1 ? null : yearsArray.push(y);
    });

    return yearsArray.map(y => {
      return { label: '20' + y, value: y };
    });
  };

  const graphTypes = ['Monthly', 'Quaterly'];
  const graphTypesData = graphTypes.map(t => {
    return { label: t, value: t };
  });
  const getTestPortfolioData = () => {
    return dropDownOptions(getDataObject()).portfolios.map(d => {
      return { label: d, value: d };
    });
  };

  const portfolioData = useTestData
    ? getTestPortfolioData()
    : [
        { label: 'Boston', value: 'boston' },
        { label: 'New York', value: 'newYork' },
        { label: 'Toronto', value: 'toronto' },
        { label: 'Philadelphia', value: 'philadelphia' },
      ];

  const getTestBuildingData = () => {
    return dropDownOptions(getDataObject())[
      dashboardState.DashboardTab?.portfolio ? dashboardState.DashboardTab?.portfolio : 'buildings'
    ]?.map(d => {
      return { label: d, value: d };
    });
  };

  const buildingData = useTestData
    ? getTestBuildingData()
    : insightsUser
    ? [
        {
          label: 'Troy Boston',
          value: 'Troy Boston',
        },
        {
          label: 'Parkway Apartments',
          value: 'Parkway Apartments',
        },
        {
          label: 'Bridge Condos',
          value: 'Bridge Condos',
        },
        {
          label: 'Liberty Towers',
          value: 'Liberty Towers',
        },
      ]
    : propertiesContext?.state?.data &&
      uniqBy(propertiesContext?.state?.data, 'building.id').map(p => {
        if (dashboardState.DashboardTab?.filter?.includes('cgi')) {
          return {
            label: p.building.name || p.address?.shortAddress,
            value: p.building.id || p.address?.shortAddress,
          };
        } else {
          return {
            label: p.building.name || p.address?.shortAddress,
            value: p.building.id || p.address?.shortAddress,
          };
        }
      });

  const userOptions = dashboardState.DashboardTab?.users.map(u => {
    return { label: `${u.firstName} ${u.lastName}`, value: u.id };
  });

  // console.log(buildingGroup, 'buildingGroup');

  return (
    <>
      {((dashboardState.DashboardTab?.filter === 'reporting' && width <= 1600) ||
        (dashboardState.DashboardTab?.filter !== 'reporting' && width <= 992)) && (
        <div className="-mt-1">
          <div className="my-auto pl-4 pb-2">
            <Whisper placement="bottom" trigger="hover" speaker={<Tooltip>{'Filters'}</Tooltip>}>
              <div
                style={{ backgroundColor: `${''}`, borderColor: `${'#AAAAAA'}` }}
                className={`p-2 rounded-full cursor-pointer border-[2px] border-none`}
                onClick={() => {
                  toggleDrawer();
                }}>
                <SettingIcon style={{ fontSize: '1.5em' }} className={''} fill={'#AAAAAA'} />
              </div>
            </Whisper>
          </div>
        </div>
      )}
      <div className="dashboard-filters">
        <div className="flex flex-wrap mt-[-0.5rem] ">
          {((dashboardState.DashboardTab?.filter === 'reporting' && width > 1600) ||
            (dashboardState.DashboardTab?.filter !== 'reporting' && width > 992)) && (
            <>
              {insightsUser && (
                <div style={{ paddingLeft: '0.5em' }}>
                  <SelectPicker
                    disabled={
                      dashboardState.DashboardTab?.filter === 'overview' ||
                      dashboardState.DashboardTab?.filter === 'reporting'
                    }
                    data={portfolioData}
                    onChange={val =>
                      dashboardDispatch({
                        type: 'setPortfolio',
                        payload: val,
                      })
                    }
                    appearance="default"
                    placeholder="Select Portfolio"
                    value={dashboardState.DashboardTab?.portfolio}
                    searchable={false}
                  />
                </div>
              )}
              {dashboardState.DashboardTab?.module === 'analytics' &&
                dashboardState.DashboardTab?.filter !== 'reporting' && (
                  <div style={{ paddingLeft: '0.5em' }}>
                    <CheckPicker
                      className="w-36"
                      preventOverflow
                      disabled={
                        dashboardState.DashboardTab?.filter === 'overview' ||
                        // dashboardState.DashboardTab?.filter === 'unit_turn' ||
                        dashboardState.DashboardTab?.filter === 'unit_turn_entrata' ||
                        dashboardState.DashboardTab?.filter === 'marketing_leasing_entrata' ||
                        dashboardState.DashboardTab?.filter === 'marketing_leasing_sandbox' ||
                        dashboardState.DashboardTab?.filter === 'management_sandbox' ||
                        dashboardState.DashboardTab?.filter === 'budgeted_vs_actual' ||
                        dashboardState.DashboardTab?.filter === 'reporting'
                      }
                      data={Object.keys(buildingGroup).map(key => ({ label: key, value: buildingGroup[key] }))}
                      onChange={val => {
                        // let filter
                        dashboardDispatch({
                          type: 'setBuilding',
                          payload: val,
                        });
                      }}
                      appearance="default"
                      placeholder="Select Building"
                      value={Array.from(dashboardState.DashboardTab?.buildingIds)}
                    />
                  </div>
                )}
              {dashboardState.DashboardTab?.module === 'analytics' &&
                dashboardState.DashboardTab?.filter === 'reporting' && (
                  <div style={{ paddingLeft: '0.5em' }}>
                    <CheckPicker
                      // className="w-36"
                      searchable={false}
                      preventOverflow
                      data={[
                        { label: '1 Bed', value: 1 },
                        { label: '2 Bed', value: 2 },
                        { label: '3 Bed', value: 3 },
                      ]}
                      onChange={val => {
                        dashboardDispatch({
                          type: 'setBedrooms',
                          payload: val,
                        });
                      }}
                      appearance="default"
                      placeholder="Select Bedrooms"
                      value={dashboardState.DashboardTab?.bedrooms}
                    />
                  </div>
                )}
              {dashboardState.DashboardTab?.module === 'analytics' &&
                dashboardState.DashboardTab?.filter === 'reporting' && (
                  <div style={{ paddingLeft: '0.5em' }}>
                    <CheckPicker
                      style={{ width: '200px' }}
                      preventOverflow
                      searchable={true}
                      data={cityData}
                      cleanable={false}
                      onChange={val => {
                        if (
                          user.user.id !== '6a532c61-7378-4f01-918e-56c8fde870d4' &&
                          val.length === 0 &&
                          savedComparables.length === 0
                        ) {
                          Alert.error('Minimum 1 city must be selected', 5000);
                          return;
                        }
                        dashboardDispatch({
                          type: 'setCity',
                          payload: val,
                        });
                        dashboardDispatch({
                          type: 'setNeighborhood',
                          payload: [],
                        });
                      }}
                      appearance="default"
                      placeholder="Select City"
                      value={dashboardState.DashboardTab?.city}
                    />
                  </div>
                )}
              {dashboardState.DashboardTab?.module === 'analytics' &&
                dashboardState.DashboardTab?.filter === 'reporting' && (
                  <div style={{ paddingLeft: '0.5em' }}>
                    <CheckPicker
                      style={{ width: '200px' }}
                      preventOverflow
                      searchable={true}
                      data={neighborhoodData}
                      onChange={val => {
                        dashboardDispatch({
                          type: 'setNeighborhood',
                          payload: val,
                        });
                      }}
                      appearance="default"
                      placeholder="Select Neighborhood"
                      value={dashboardState.DashboardTab?.neighborhood}
                    />
                  </div>
                )}
              {dashboardState.DashboardTab?.module === 'analytics' &&
                dashboardState.DashboardTab?.filter === 'reporting' && (
                  <div style={{ paddingLeft: '0.5em' }}>
                    <CheckPicker
                      style={{ width: '200px' }}
                      preventOverflow
                      searchable={true}
                      // disabled={dashboardState.DashboardTab?.neighborhood.length === 0}
                      data={buildingData}
                      onChange={async val => {
                        await UserApi.getPricingSettingByBuildingIds({
                          buildingIds: `{"${val.join('","')}"}`,
                        })
                          .then(res => {
                            dashboardDispatch({
                              type: 'setComparableBuilding',
                              payload: flatten(res.data.map(r => r.comparableBuildings.map(c => c.address))),
                            });
                            dashboardDispatch({
                              type: 'setBuilding',
                              payload: val,
                            });
                            dashboardDispatch({
                              type: 'setCity',
                              payload: [],
                            });
                          })
                          .catch(err => console.error(err));

                        dashboardDispatch({
                          type: 'setPriceBuilding',
                          payload: val,
                        });

                        if (val.length === 0) {
                          dashboardDispatch({
                            type: 'setCity',
                            payload: [cityData[0].value],
                          });
                        } //default it back to one city
                      }}
                      appearance="default"
                      placeholder="Select Building"
                      value={dashboardState.DashboardTab?.building}
                    />
                  </div>
                )}
              {dashboardState.DashboardTab?.module === 'analytics' &&
                !insightsUser &&
                (user.user.role === 'user' || user.user.role === 'manager') &&
                dashboardState.DashboardTab?.filter !== 'cgi_vp_exec' &&
                dashboardState.DashboardTab?.filter !== 'cgi_leasing' &&
                dashboardState.DashboardTab?.filter !== 'cgi_vp_exec_sandbox' &&
                dashboardState.DashboardTab?.filter !== 'cgi_operations' && (
                  <div style={{ paddingLeft: '0.5em' }}>
                    <SelectPicker
                      disabled={
                        dashboardState.DashboardTab?.filter === 'overview' ||
                        // dashboardState.DashboardTab?.filter === 'unit_turn' ||
                        dashboardState.DashboardTab?.filter === 'unit_turn_entrata' ||
                        dashboardState.DashboardTab?.filter === 'marketing_leasing_entrata' ||
                        dashboardState.DashboardTab?.filter === 'marketing_leasing_sandbox' ||
                        dashboardState.DashboardTab?.filter === 'management_sandbox' ||
                        dashboardState.DashboardTab?.filter === 'budgeted_vs_actual' ||
                        dashboardState.DashboardTab?.filter === 'reporting'
                      }
                      data={userOptions}
                      onChange={val =>
                        dashboardDispatch({
                          type: 'setUserSelected',
                          payload: val,
                        })
                      }
                      appearance="default"
                      placeholder="Select User"
                      value={dashboardState.DashboardTab?.userSelected}
                    />
                  </div>
                )}
              {/* {useTestData && (
                <div style={{ paddingLeft: '0.5em' }}>
                  <SelectPicker
                    data={getYearData()}
                    onChange={val =>
                      dashboardDispatch({
                        type: 'setYear',
                        payload: val,
                      })
                    }
                    appearance="default"
                    placeholder="Select Year"
                    value={dashboardState.DashboardTab?.year}
                    searchable={false}
                  />
                </div>
              )} */}
              {/* {useTestData && (
                <div style={{ paddingLeft: '0.5em' }}>
                  <SelectPicker
                    data={graphTypesData}
                    onChange={val =>
                      dashboardDispatch({
                        type: 'setGraphRange',
                        payload: val,
                      })
                    }
                    appearance="default"
                    placeholder="Select Graph Type"
                    value={dashboardState.DashboardTab?.graphRange}
                    searchable={false}
                  />
                </div>
              )} */}
              {dashboardState.DashboardTab?.filter === 'cgi_vp_exec' && (
                <div style={{ paddingLeft: '0.5em' }}>
                  <DatePicker
                    cleanable={false}
                    disabledDate={date => {
                      return getDay(date) !== 0;
                    }}
                    ranges={[]}
                    value={dashboardState.DashboardTab?.dateRange[1]}
                    placeholder="Select Ending Week"
                    showWeekNumbers={true}
                    onChange={val => {
                      dashboardDispatch({
                        type: 'setDate',
                        payload: val,
                      });
                    }}
                    placement="bottomRight"
                  />
                </div>
              )}
              {dashboardState.DashboardTab?.filter !== 'cgi_vp_exec' && (
                <div style={{ paddingLeft: '0.5em' }}>
                  <DateRangePicker
                    placeholder="Select Date Range"
                    disabled={
                      dashboardState.DashboardTab?.filter === 'overview' ||
                      // dashboardState.DashboardTab?.filter === 'unit_turn' ||
                      dashboardState.DashboardTab?.filter === 'unit_turn_entrata' ||
                      dashboardState.DashboardTab?.filter === 'marketing_leasing_entrata' ||
                      dashboardState.DashboardTab?.filter === 'marketing_leasing_sandbox' ||
                      dashboardState.DashboardTab?.filter === 'management_sandbox' ||
                      dashboardState.DashboardTab?.filter === 'budgeted_vs_actual'
                    }
                    showWeekNumbers={true}
                    ranges={
                      insightsData.validUsers.includes(user.user.email)
                        ? // Demo data
                          [
                            {
                              label: 'Last Quarter',
                              value: [
                                subQuarters(startOfQuarter(new Date()), 1),
                                subQuarters(endOfQuarter(new Date()), 1),
                              ],
                            },
                            {
                              label: 'This Quarter',
                              value: [startOfQuarter(new Date()), endOfQuarter(new Date())],
                            },
                            {
                              label: 'Year To Date',
                              value: [startOfYear(new Date()), new Date()],
                            },
                            {
                              label: 'Last Year',
                              value: [startOfPreviousYear, endOfPreviousYear],
                            },
                          ]
                        : // Real data
                        dashboardState.DashboardTab?.filter === 'standard_financial'
                        ? [
                            {
                              label: 'Last Quarter',
                              value: [
                                subQuarters(startOfQuarter(new Date()), 1),
                                subQuarters(endOfQuarter(new Date()), 1),
                              ],
                            },
                            {
                              label: 'This Quarter',
                              value: [startOfQuarter(new Date()), endOfQuarter(new Date())],
                            },
                            // 3 months ago (eg if its currently July 2024, the label will be April 2024)
                            {
                              label: `${subMonths(new Date(), 3).toLocaleString('default', {
                                year: 'numeric',
                                month: 'long',
                              })}`,
                              value: [startOfMonth(subMonths(new Date(), 3)), endOfMonth(subMonths(new Date(), 3))],
                            },
                            // 2 month ago
                            {
                              label: `${subMonths(new Date(), 2).toLocaleString('default', {
                                year: 'numeric',
                                month: 'long',
                              })}`,
                              value: [startOfMonth(subMonths(new Date(), 2)), endOfMonth(subMonths(new Date(), 2))],
                            },
                          ]
                        : dashboardState.DashboardTab?.filter === 'standard_occupancy_and_leasing_performance' ||
                          dashboardState.DashboardTab?.filter === 'standard_marketing' ||
                          dashboardState.DashboardTab?.filter === 'standard_operations'
                        ? [
                            {
                              label: 'Last Quarter',
                              value: [
                                subQuarters(startOfQuarter(new Date()), 1),
                                subQuarters(endOfQuarter(new Date()), 1),
                              ],
                            },
                            {
                              label: 'This Quarter',
                              value: [startOfQuarter(new Date()), endOfQuarter(new Date())],
                            },
                            // 2 months ago (eg if its currently July 2024, the label will be May 2024)
                            {
                              label: `${subMonths(new Date(), 2).toLocaleString('default', {
                                year: 'numeric',
                                month: 'long',
                              })}`,
                              value: [startOfMonth(subMonths(new Date(), 2)), endOfMonth(subMonths(new Date(), 2))],
                            },
                            // 1 month ago
                            {
                              label: `${subMonths(new Date(), 1).toLocaleString('default', {
                                year: 'numeric',
                                month: 'long',
                              })}`,
                              value: [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))],
                            },
                          ]
                        : [
                            {
                              label: 'Last Quarter',
                              value: [
                                subQuarters(startOfQuarter(new Date()), 1),
                                subQuarters(endOfQuarter(new Date()), 1),
                              ],
                            },
                            {
                              label: 'This Quarter',
                              value: [startOfQuarter(new Date()), endOfQuarter(new Date())],
                            },
                            {
                              label: 'Year To Date',
                              value: [startOfYear(new Date()), new Date()],
                            },
                            {
                              label: 'Last Year',
                              value: [startOfPreviousYear, endOfPreviousYear],
                            },
                          ]
                    }
                    onChange={val => {
                      dashboardDispatch({
                        type: 'setDateRange',
                        payload: val,
                      });
                    }}
                    value={dashboardState.DashboardTab?.dateRange}
                    placement="bottomRight"
                  />
                </div>
              )}
              {/* Analytics/Insights toggle for testing */}
              {/* {insightsUser && (
                <div style={{ paddingLeft: '0.5em' }}>
                  <SelectPicker
                    data={[
                      { label: 'Analytics', value: 'analytics' },
                      { label: 'Insights', value: 'insights' },
                    ]}
                    onChange={val => {
                      dashboardDispatch({
                        type: 'setModule',
                        payload: val,
                      });
                    }}
                    appearance="default"
                    placeholder="Overview"
                    value={dashboardState.DashboardTab?.module}
                    searchable={false}
                    cleanable={false}
                  />
                </div>
              )} */}
            </>
          )}
          <div style={{ paddingLeft: '0.5em' }}>
            <SelectPicker
              data={filterType}
              onChange={val => {
                if (router.query.sankey) {
                  router.replace('/dashboard', undefined, { shallow: true });
                }
                dashboardDispatch({
                  type: 'setFilter',
                  payload: val,
                });
                // date filter update logic moved into use effect above
              }}
              appearance="default"
              placeholder="Overview"
              value={dashboardState.DashboardTab?.filter}
              searchable={false}
              cleanable={false}
              style={{ width: '150px' }}
              menuStyle={{ width: '240px' }}
            />
          </div>
        </div>

        <Drawer placement={'top'} show={show} onHide={() => close()}>
          <Drawer.Header>
            <Drawer.Title>
              Dashboard:{' '}
              {
                filterType.find(t => {
                  return t.value === dashboardState.DashboardTab?.filter;
                })?.label
              }
            </Drawer.Title>
          </Drawer.Header>
          <Drawer.Body>
            <GridDiv>
              {insightsUser && (
                <div>
                  <SelectPicker
                    block
                    cleanable={false}
                    disabled={
                      dashboardState.DashboardTab?.filter === 'overview' ||
                      dashboardState.DashboardTab?.filter === 'reporting'
                    }
                    data={portfolioData}
                    onChange={val =>
                      dashboardDispatch({
                        type: 'setPortfolio',
                        payload: val,
                      })
                    }
                    appearance="default"
                    placeholder="Select Portfolio"
                    value={dashboardState.DashboardTab?.portfolio}
                    searchable={false}
                  />
                </div>
              )}
              <div>
                <CheckPicker
                  block
                  disabled={
                    dashboardState.DashboardTab?.filter === 'overview' ||
                    // dashboardState.DashboardTab?.filter === 'unit_turn' ||
                    dashboardState.DashboardTab?.filter === 'unit_turn_entrata' ||
                    dashboardState.DashboardTab?.filter === 'marketing_leasing_entrata' ||
                    dashboardState.DashboardTab?.filter === 'marketing_leasing_sandbox' ||
                    dashboardState.DashboardTab?.filter === 'management_sandbox' ||
                    dashboardState.DashboardTab?.filter === 'budgeted_vs_actual'
                    // ||
                    // dashboardState.DashboardTab?.filter === 'reporting'
                  }
                  data={buildingData}
                  onChange={async val => {
                    await UserApi.getPricingSettingByBuildingIds({
                      buildingIds: `{"${val.join('","')}"}`,
                    })
                      .then(res => {
                        dashboardDispatch({
                          type: 'setComparableBuilding',
                          payload: flatten(res.data.map(r => r.comparableBuildings.map(c => c.address))),
                        });
                        dashboardDispatch({
                          type: 'setBuilding',
                          payload: val,
                        });
                        dashboardDispatch({
                          type: 'setCity',
                          payload: [],
                        });
                      })
                      .catch(err => console.error(err));

                    dashboardDispatch({
                      type: 'setPriceBuilding',
                      payload: val,
                    });

                    if (val.length === 0) {
                      dashboardDispatch({
                        type: 'setCity',
                        payload: [cityData[0].value],
                      });
                    } //default it back to one city
                  }}
                  appearance="default"
                  placeholder="Select Building"
                  value={Array.from(dashboardState.DashboardTab?.building)}
                />
              </div>
              {dashboardState.DashboardTab?.module === 'analytics' &&
                dashboardState.DashboardTab?.filter === 'reporting' && (
                  <div>
                    <CheckPicker
                      block
                      searchable={false}
                      preventOverflow
                      data={[
                        { label: '1', value: 1 },
                        { label: '2', value: 2 },
                        { label: '3', value: 3 },
                      ]}
                      onChange={val => {
                        dashboardDispatch({
                          type: 'setBedrooms',
                          payload: val,
                        });
                      }}
                      appearance="default"
                      placeholder="Select Bedrooms"
                      value={dashboardState.DashboardTab?.bedrooms}
                    />
                  </div>
                )}
              {dashboardState.DashboardTab?.module === 'analytics' &&
                dashboardState.DashboardTab?.filter === 'reporting' && (
                  <div>
                    <CheckPicker
                      block
                      preventOverflow
                      searchable={true}
                      data={cityData}
                      cleanable={false}
                      onChange={val => {
                        if (
                          user.user.id !== '6a532c61-7378-4f01-918e-56c8fde870d4' &&
                          val.length === 0 &&
                          savedComparables.length === 0
                        ) {
                          Alert.error('Minimum 1 city must be selected', 5000);
                          return;
                        }
                        dashboardDispatch({
                          type: 'setCity',
                          payload: val,
                        });
                        dashboardDispatch({
                          type: 'setNeighborhood',
                          payload: [],
                        });
                      }}
                      appearance="default"
                      placeholder="Select City"
                      value={dashboardState.DashboardTab?.city}
                    />
                  </div>
                )}
              {dashboardState.DashboardTab?.module === 'analytics' &&
                dashboardState.DashboardTab?.filter === 'reporting' && (
                  <div>
                    <CheckPicker
                      block
                      preventOverflow
                      searchable={false}
                      data={neighborhoodData}
                      onChange={val => {
                        dashboardDispatch({
                          type: 'setNeighborhood',
                          payload: val,
                        });
                      }}
                      appearance="default"
                      placeholder="Select Neighborhood"
                      value={dashboardState.DashboardTab?.neighborhood}
                    />
                  </div>
                )}
              {dashboardState.DashboardTab?.module === 'analytics' &&
                !insightsUser &&
                (user.user.role === 'user' || user.user.role === 'manager') &&
                dashboardState.DashboardTab?.filter !== 'cgi_vp_exec' &&
                dashboardState.DashboardTab?.filter !== 'cgi_leasing' &&
                dashboardState.DashboardTab?.filter !== 'cgi_vp_exec_sandbox' &&
                dashboardState.DashboardTab?.filter !== 'cgi_operations' && (
                  <div>
                    <SelectPicker
                      block
                      disabled={
                        dashboardState.DashboardTab?.filter === 'overview' ||
                        // dashboardState.DashboardTab?.filter === 'unit_turn' ||
                        dashboardState.DashboardTab?.filter === 'unit_turn_entrata' ||
                        dashboardState.DashboardTab?.filter === 'marketing_leasing_entrata' ||
                        dashboardState.DashboardTab?.filter === 'marketing_leasing_sandbox' ||
                        dashboardState.DashboardTab?.filter === 'management_sandbox' ||
                        dashboardState.DashboardTab?.filter === 'budgeted_vs_actual' ||
                        dashboardState.DashboardTab?.filter === 'reporting'
                      }
                      data={userOptions}
                      onChange={val =>
                        dashboardDispatch({
                          type: 'setUserSelected',
                          payload: val,
                        })
                      }
                      appearance="default"
                      placeholder="Select User"
                      value={dashboardState.DashboardTab?.userSelected}
                    />
                  </div>
                )}

              <div>
                <DateRangePicker
                  block
                  placeholder="Select Date Range"
                  disabled={
                    dashboardState.DashboardTab?.filter === 'overview' ||
                    // dashboardState.DashboardTab?.filter === 'unit_turn' ||
                    dashboardState.DashboardTab?.filter === 'unit_turn_entrata' ||
                    dashboardState.DashboardTab?.filter === 'marketing_leasing_entrata' ||
                    dashboardState.DashboardTab?.filter === 'marketing_leasing_sandbox' ||
                    dashboardState.DashboardTab?.filter === 'management_sandbox' ||
                    dashboardState.DashboardTab?.filter === 'budgeted_vs_actual'
                  }
                  ranges={[
                    {
                      label: 'Yesterday',
                      value: [addDays(new Date(), -1), addDays(new Date(), -1)],
                    },
                    {
                      label: 'Today',
                      value: [new Date(), new Date()],
                    },
                    {
                      label: 'Last 7 days',
                      value: [subDays(new Date(), 6), new Date()],
                    },
                    {
                      label: 'Next 7 days',
                      value: [addDays(new Date(), 7), new Date()],
                    },
                  ]}
                  onChange={val =>
                    dashboardDispatch({
                      type: 'setDateRange',
                      payload: val,
                    })
                  }
                  value={dashboardState.DashboardTab?.dateRange}
                />
              </div>
              {/* Dashboard select has been moved out of the pop up and is always displayed even on narrower screens */}
              {/* <div>
                <SelectPicker
                  block
                  cleanable={false}
                  data={filterType}
                  onChange={val => {
                    dashboardDispatch({
                      type: 'setFilter',
                      payload: val,
                    });
                    dashboardDispatch({
                      type: 'setBuilding',
                      payload: '',
                    });
                    close();
                  }}
                  appearance="default"
                  placeholder="Overview"
                  value={dashboardState.DashboardTab?.filter}
                  searchable={false}
                />
              </div> */}
              {/* Analytics/Insights toggle for testing */}
              {/* {insightsUser && (
                <div>
                  <SelectPicker
                    block
                    cleanable={false}
                    data={[
                      { label: 'Analytics', value: 'analytics' },
                      { label: 'Insights', value: 'insights' },
                    ]}
                    onChange={val => {
                      dashboardDispatch({
                        type: 'setModule',
                        payload: val,
                      });
                      close();
                    }}
                    appearance="default"
                    placeholder=""
                    value={dashboardState.DashboardTab?.module}
                    searchable={false}
                  />
                </div>
              )} */}
              <div style={{ textAlign: 'center' }} className="justify-self-center">
                <Button onClick={() => close()} appearance="subtle">
                  Close
                </Button>
              </div>
            </GridDiv>
          </Drawer.Body>
        </Drawer>
      </div>
      <Styles />
    </>
  );
}
