import {
  Affix,
  Container,
  Content,
  Divider,
  Dropdown,
  Header,
  Icon,
  IconButton,
  Button,
  Nav,
  Navbar,
  Sidebar,
  Sidenav,
  Tooltip,
  Whisper,
  Carousel,
  Alert,
} from 'rsuite';
import { Link, Router } from 'utils/with-i18next';
import React, { useContext, useEffect, useState, useRef, useMemo } from 'react';
import { format } from 'date-fns';
import GearIcon from '@rsuite/icons/Gear';
import EmailIcon from '@rsuite/icons/Email';
import ArowBackIcon from '@rsuite/icons/ArowBack';
import AvatarComponent from 'components/Avatar';
import Footer from 'components/Footer';
import PropTypes from 'prop-types';
import SlackFeedback from 'components/SlackFeedback';
import WelcomeModal from 'components/Modals/Welcome';
import ChatbotHide from 'components/ChatbotHide';
import DashboardFilters from 'containers/DashboardLeads/DashboardFilters';
import { DashboardContext } from 'contexts/LayoutDashboardContext';
import { PriceContext } from 'contexts/PriceContext.js';
import Styles from './styles.js';
import InsightsButton from 'components/InsightsButton';
import { getDefaultRedirectPage } from '../../utils/getDefaultRedirectPage';
import { useRouter } from 'next/router';
import { ChatAssistantDrawer } from 'containers/ChatAssistantDrawer';
import { ChatAssistantPopover } from 'containers/ChatAssistantPopover';
import MessageIcon from '@rsuite/icons/Message';
import InnovationSvg from 'images/innovation.svg';
import useWindowSize from 'utils/detect-windows-size';
import PageIcon from '@rsuite/icons/Page';
// integrations
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlug } from '@fortawesome/free-solid-svg-icons';
import { UserApi } from 'services/api/user';
// Sales
import SalesBanner from 'components/SalesBanner';
import LinkToForm from 'components/Modals/LinkToForm';
import CreateCampaign from 'components/Modals/CreateCampaign';
import UpdateCampaign from 'components/Modals/UpdateCampaign';
import DropDownNotifications from 'components/DropDownNotifications';
import { TableauDashboardContext } from 'contexts/TableauDashboardContext.js';
import TableauDashboardFilters from '../../containers/TableauDashboardFilters/index.js';
function DashboardLayout({ children, pageId, handleLogout, currentUser }) {
  const [expand, setExpand] = useState(false);
  const [openWelcomeModal, setOpenWelcomeModal] = useState(false);
  const { dashboardState, dashboardDispatch } = useContext(DashboardContext);
  const {
    tableauDashboardState: { showTableau },
  } = useContext(TableauDashboardContext);
  const { priceState, priceDispatch } = useContext(PriceContext);
  const [navBarFixedStatusChange, setNavBarFixedStatusChange] = useState(false);
  const [openChatDrawer, setOpenChatDrawer] = useState(false);
  const [openChatPopup, setOpenChatPopup] = useState(false);
  const chatContainerRef = useRef(null);

  const width = useWindowSize()[0];
  // Sales Notifications
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [createCampaign, setCreateCampaign] = useState(false);
  const [updateCampaign, setUpdateCampaign] = useState(false);

  const [openSlackFeedback, setOpenSlackFeedback] = useState(false);

  // notifications
  const [notifications, setNotifications] = useState([]);
  const [bannerNotifications, setBannerNotifications] = useState([]);
  const [popupNotifications, setPopupNotifications] = useState([]);
  // const [dropdownNotifications, setDropdownNotifications] = useState([]);
  const [pendingPopups, setPendingPopups] = useState([]);
  const [updatedNotifications, setUpdatedNotifications] = useState(false);
  const [notificationsCount, setNotificationsCount] = useState(0);
  // toggle between convert settings
  const router = useRouter();
  const onSettings = router.query.settings;
  const onPriceSettings = router.query['price-settings'];

  const [visiblePopups, setVisiblePopups] = useState([]);

  const handleHidePopup = popupId => {
    setVisiblePopups(prev => [...prev, popupId]);
    UserApi.updateNotifications({ hide: true }, popupId);
  };

  const [currentPopupIndex, setCurrentPopupIndex] = useState(0);

  const [previousTab, setPreviousTab] = useState('');

  const getNextEligiblePopupIndex = startIndex => {
    for (let i = startIndex; i < popupNotifications.length; i++) {
      if (!popupNotifications[i].hide && !visiblePopups.includes(popupNotifications[i].nid)) {
        return i;
      }
    }
    return -1;
  };

  const handleClose = () => {
    const nextIndex = getNextEligiblePopupIndex(currentPopupIndex + 1);
    if (nextIndex !== -1) {
      setCurrentPopupIndex(nextIndex);
    } else {
      setPopupOpen(false);
    }
  };

  const activePopup = popupNotifications[currentPopupIndex];

  useEffect(() => {
    if (currentUser?.email) {
      if (!currentUser?.seenWelcomeModal) {
        setOpenWelcomeModal(false);
      }
    }
  }, [currentUser]);

  useEffect(() => {
    const fetchNotifications = async () => {
      const { data } = await UserApi.getNotifications();
      console.log(data);
      let banners = [];
      let popups = [];
      let dropdowns = [];
      const allNotifications = data;
      const viewNotifications = allNotifications.filter(x => !x.hide);
      viewNotifications.forEach(notification => {
        if (notification.campaign_type === 'Banner') {
          banners.push(notification);
        }
        if (notification.campaign_type === 'Popup') {
          popups.push(notification);
        }
        // if (notification.campaign_type === 'Dropdown') {
        //   dropdowns.push(notification);
        // }
      });
      setNotifications(allNotifications);
      setBannerNotifications(banners);
      setPopupNotifications(popups);
      if (popups.length > 0) {
        setPendingPopups(popups);
        setPopupOpen(true);
      }
      // setDropdownNotifications(dropdowns);
      setNotificationsCount(viewNotifications.length);
    };
    fetchNotifications();
  }, [router, updatedNotifications]);

  const navLinks = [
    {
      pageId: 'dashboard',
      title: 'Analytics',
      icon: '/static/icons/dashboard.png',
      link: '/dashboard',
      hide: false,
      dummy: false,
    },
    {
      pageId: 'properties',
      title: 'Properties',
      icon: '/static/icons/property-icon.png',
      link: '/dashboard/properties',
      hide: false,
      dummy: false,
    },
    {
      pageId: 'bookings',
      title: 'Convert',
      icon: '/static/icons/bookings-icon.png',
      link: '/dashboard/bookings',
      hide: false,
      dummy: false,
    },
    {
      pageId: 'tickets',
      title: 'Engage',
      icon: '/static/icons/tickets-icon.png',
      link: '/dashboard/tickets',
      hide: false,
      dummy: false,
    },
    {
      pageId: 'income-management',
      title: 'Income Management',
      icon: '/static/icons/payment-icon.png',
      link: '/dashboard/income-management',
      hide: false,
      dummy: false,
    },
    {
      pageId: 'price',
      title: 'Revenue Management',
      icon: '/static/icons/rent-calculator.png',
      link: '/dashboard/price',
      hide: false,
      dummy: false,
    },
    {
      pageId: 'sage+',
      title: 'Sage +',
      icon: '/static/icons/pilot.png',
      link: '/dashboard/sage-plus',
      hide: false,
      dummy: false,
    },
    {
      pageId: 'settings',
      title: 'Settings',
      icon: '/static/icons/rent-calculator.png',
      link: '/dashboard/settings',
      hide: false,
      dummy: true,
    },
    {
      pageId: 'import',
      title: 'Import Data',
      icon: '/static/icons/rent-calculator.png',
      link: '/dashboard/import',
      hide: false,
      dummy: true,
    },
  ];

  return (
    <>
      <ChatbotHide debug={true} show={false} />
      <main>
        <Container style={{ background: '#f8f8f8', height: 'auto', minHeight: '100vh' }}>
          {/* Sidebar for width < 780  */}
          <WelcomeModal show={openWelcomeModal} setClose={setOpenWelcomeModal} />

          <Sidenav
            className={`side-nav-${expand ? 'open' : 'close'}`}
            id="side-nav-779"
            // style={{ backgroundColor: 'white', zIndex: '100', width: '100%', maxWidth: '400px', position: 'absolute' }}
          >
            <Sidenav.Header className="db-logo" style={{ margin: '0.5em 0.4em 0 0', display: 'flex' }}>
              <Icon
                icon="close"
                style={{ float: 'left', fontSize: '2em', marginRight: '20px' }}
                onClick={() => setExpand(false)}
              />
              <Link href={getDefaultRedirectPage(currentUser)} passHref>
                <img src="/static/images/BigLogo.png"></img>
              </Link>
            </Sidenav.Header>
            <Sidenav activeKey={pageId} expanded={expand} appearance="subtle">
              <Sidenav.Body className="db-sidenav">
                <Nav activeKey={pageId}>
                  {/* !i.hide && !i.dummy is legacy code */}
                  {/* the subsequent boolean means that if views is null, we show all views (true) */}
                  {/* otherwise, we filter by the views present within currentUser.views */}
                  {navLinks
                    .filter(
                      i => !i.hide && !i.dummy && (currentUser.views ? currentUser.views.includes(i.pageId) : true)
                    )
                    .map((item, idx) => {
                      if (item.onClick) {
                        return (
                          <Nav.Item
                            key={idx}
                            eventKey={item.pageId}
                            onClick={item.onClick}
                            active={item.pageId === pageId}>
                            <div className="flex">
                              <span>{item?.title}</span>
                            </div>
                          </Nav.Item>
                        );
                      } else {
                        return (
                          <Link key={idx} href={item.link} passHref>
                            <Nav.Item
                              eventKey={item.pageId}
                              active={item.pageId === pageId}
                              onClick={() => {
                                setExpand(!expand);
                                // this helps to ensure that you can navigate back to the analytics dashboard after clicking into the insights page
                                if (dashboardState.DashboardTab.module === 'insights') {
                                  dashboardDispatch({
                                    type: 'setModule',
                                    payload: 'analytics',
                                  });
                                }
                                // reset variables used in price if user clicks on the price icon
                                if (item.pageId === 'price') {
                                  priceDispatch({
                                    type: 'resetVariables',
                                  });
                                }

                                if (item.pageId === 'sage+') {
                                  setOpenChatPopup(false);
                                }
                              }}>
                              <div className="flex">
                                <img src={item.icon} className="" />
                                <span>{item?.title}</span>
                              </div>
                            </Nav.Item>
                          </Link>
                        );
                      }
                    })}
                </Nav>
              </Sidenav.Body>
            </Sidenav>
          </Sidenav>

          {/* Sidebar for width > 780 */}
          <Sidebar id="side-bar-780" className="db-sidebar" width={expand ? 260 : 56}>
            <Affix style={{ background: '#fff' }}>
              <Sidenav.Header className="db-logo">
                <Link href={getDefaultRedirectPage(currentUser)} passHref>
                  {expand ? (
                    <img src="/static/images/BigLogo.png"></img>
                  ) : (
                    <img src="/static/images/SmallLogo.png"></img>
                  )}
                  {/*<img src="/static/images/BigLogo.png"></img>*/}
                </Link>
              </Sidenav.Header>
              <Sidenav activeKey={pageId} expanded={expand} appearance="subtle">
                <Sidenav.Body className="db-sidenav" style={{ width: expand ? '260px' : '56px' }}>
                  <Nav activeKey={pageId}>
                    {navLinks
                      .filter(
                        i => !i.hide && !i.dummy && (currentUser.views ? currentUser.views.includes(i.pageId) : true)
                      )
                      .map((item, idx) => {
                        if (item.onClick) {
                          return (
                            <li
                              className="rs-nav-item flex items-center justify-center pl-4"
                              key={idx}
                              style={{ height: '55px' }}
                              eventKey={item.pageId}
                              onClick={item.onClick}
                              active={item.pageId === pageId}>
                              <div className="flex">
                                <Whisper trigger={expand ? 'none' : 'hover'} speaker={<Tooltip>{item?.title}</Tooltip>}>
                                  <img src={item.icon} className="" />
                                </Whisper>
                                <span className={!expand ? 'hide' : ''}>{item?.title}</span>
                              </div>
                            </li>
                          );
                        } else {
                          return (
                            <Link key={idx} href={item.link} onClick={item.onClick} passHref>
                              <Nav.Item
                                eventKey={item.pageId}
                                active={dashboardState.DashboardTab.module !== 'insights' && item.pageId === pageId}
                                onClick={() => {
                                  // this helps to ensure that you can navigate back to the analytics dashboard after clicking into the insights page
                                  if (dashboardState.DashboardTab.module === 'insights') {
                                    dashboardDispatch({
                                      type: 'setModule',
                                      payload: 'analytics',
                                    });
                                  }
                                  // reset variables used in price if user clicks on the price icon
                                  if (item.pageId === 'price') {
                                    priceDispatch({
                                      type: 'resetVariables',
                                    });
                                  }
                                  // if user is on sage+, don't show chat popup
                                  if (item.pageId === 'sage+') {
                                    setOpenChatPopup(false);
                                  }
                                }}
                                style={{ height: '55px' }}>
                                <div className="flex">
                                  <Whisper
                                    trigger={expand ? 'none' : 'hover'}
                                    speaker={<Tooltip>{item?.title}</Tooltip>}>
                                    <img src={item.icon} className="" />
                                  </Whisper>
                                  <span className={!expand ? 'hide' : ''}>{item?.title}</span>
                                </div>
                              </Nav.Item>
                            </Link>
                          );
                        }
                      })}
                  </Nav>
                  {/*<Whisper trigger="hover" speaker={<Tooltip>{expand?"Collapse Menu":"Expand Menu"}</Tooltip>}>
                    <Sidenav.Toggle className="db-logo" expanded={expand} onToggle={(expanded) => setExpand(expanded)}/>
                  </Whisper>*/}
                </Sidenav.Body>
              </Sidenav>
              {/* <Navbar appearance="subtle" className="nav-toggle">
                <Navbar.Body>
                  <Nav pullRight>
                    <Nav.Item onClick={() => setExpand(!expand)}>
                      <Icon icon={expand ? 'angle-left' : 'angle-right'} />
                    </Nav.Item>
                  </Nav>
                </Navbar.Body>
              </Navbar> */}
            </Affix>
            <div className="fixed bottom-2 flex flex-col" style={{ width: expand ? 260 : 56 }}>
              <div className="justify-center mx-auto py-4" onClick={() => Router.push('/dashboard/integrations')}>
                <Whisper placement="right" trigger="hover" speaker={<Tooltip>Integrations</Tooltip>}>
                  <FontAwesomeIcon
                    size="xl"
                    icon={faPlug}
                    className={`hover:cursor-pointer ${
                      router.pathname === '/dashboard/integrations' ? 'text-[#ffb900]' : ''
                    }`}
                  />
                </Whisper>
              </div>
              <div
                className="mx-auto py-4"
                onClick={() => {
                  setOpenSlackFeedback(!openSlackFeedback);
                }}>
                <Whisper placement="right" trigger="hover" speaker={<Tooltip>Guide</Tooltip>}>
                  <PageIcon
                    onClick={() => {
                      window
                        .open(
                          'https://realsage.notion.site/960a0c48ab48455f8e024d324dceaf88?v=0562f53322224c55beda0018a621aff0',
                          '_blank'
                        )
                        .focus();
                    }}
                    className="text-2xl hover:cursor-pointer"
                  />
                </Whisper>
              </div>
              <div className="px-4 py-0"></div>
            </div>
          </Sidebar>

          <Container id="container-opacity" style={{ width: 'calc(100% - 86px)' }}>
            <SalesBanner
              notifications={bannerNotifications}
              setUpdatedNotifications={() => setUpdatedNotifications(!updatedNotifications)}
            />
            <Affix
              style={{ background: '#f8f8f8' }}
              className="affix_container_for_header"
              top={0}
              onChange={setNavBarFixedStatusChange}>
              <Header>
                <Navbar className="db-navbar">
                  <div
                    style={
                      navBarFixedStatusChange
                        ? { padding: '20px 0px', background: 'rgb(248, 248, 248)', width: '100%', height: '70px' }
                        : {}
                    }>
                    <Icon
                      id="icon-779"
                      icon="bars"
                      style={{ float: 'left', margin: '0.3em 1em 0 0em', fontSize: '2em' }}
                      onClick={() => setExpand(!expand)}></Icon>
                    <Navbar.Header>
                      <Nav>
                        {/* If on dashboard, checks context to either display insights or analytics accordingly as title */}
                        {pageId === 'dashboard'
                          ? dashboardState.DashboardTab.module === 'insights'
                            ? 'Insights'
                            : 'Analytics'
                          : navLinks.find(i => i.pageId === pageId)?.title}
                      </Nav>
                      {navLinks.find(i => i.pageId === pageId)?.title === 'Convert' && (
                        <IconButton
                          icon={onSettings ? <ArowBackIcon color="#ffb900" /> : <EmailIcon color="#ffb900" />}
                          circle
                          className="ml-3 !bg-transparent mt-[-4px]"
                          onClick={() => {
                            if (onSettings) {
                              Router.push('/dashboard/bookings');
                            } else {
                              Router.push('/dashboard/bookings?settings=true');
                            }
                          }}></IconButton>
                      )}
                      {navLinks.find(i => i.pageId === pageId)?.title === 'Revenue Management' && (
                        <>
                          <IconButton
                            icon={
                              onSettings || priceState.frontend.currentTab === 'settings' ? (
                                <ArowBackIcon color="#ffb900" />
                              ) : (
                                <EmailIcon color="#ffb900" />
                              )
                            }
                            circle
                            className="ml-3 !bg-transparent mt-[-4px]"
                            onClick={() => {
                              if (onSettings) {
                                Router.push('/dashboard/price');
                              } else if (priceState.frontend.currentTab === 'settings') {
                                priceDispatch({
                                  type: 'setFrontend',
                                  payload: { currentTab: previousTab },
                                });
                              } else {
                                Router.push('/dashboard/price?settings=true');
                              }
                            }}></IconButton>
                          {!onSettings &&
                            priceState.frontend.currentTab !== 'settings' &&
                            currentUser.views.includes('price+') &&
                            ['dev.prod.soulrooms@gmail.com', 'rentmanager.realsage@gmail.com'].includes(
                              currentUser.email
                            ) && (
                              <IconButton
                                icon={onSettings ? <ArowBackIcon color="#ffb900" /> : <GearIcon color="#ffb900" />}
                                circle
                                className="ml-3 !bg-transparent mt-[-4px]"
                                onClick={() => {
                                  if (onSettings) {
                                    Router.push('/dashboard/price');
                                  } else {
                                    setPreviousTab(priceState.frontend.currentTab);
                                    priceDispatch({
                                      type: 'setFrontend',
                                      payload: { currentTab: 'settings' },
                                    });
                                  }
                                }}></IconButton>
                            )}
                        </>
                      )}
                    </Navbar.Header>

                    {/* zIndex is set to 3 as any value lower will cause the dropdown to go behind the header of the subuser table */}
                    <Nav pullRight justified className="z-10">
                      {pageId === 'dashboard' && !showTableau && <DashboardFilters />}
                      {pageId === 'dashboard' && showTableau && <TableauDashboardFilters />}

                      <div className="-mt-1">
                        <InsightsButton origin={pageId} />
                      </div>
                      <span id="notifuse-bell" className="relative" style={{ userSelect: 'none' }}>
                        <div
                          className={`animated headShake -mt-2 cursor-pointer ${
                            dropdownOpen ? 'text-gray-800' : 'text-gray-500'
                          }`}
                          style={{ padding: '10px 0px', fontSize: '22px' }}
                          onClick={() => setDropdownOpen(!dropdownOpen)}>
                          <Icon
                            className="animated headShake -mt-2"
                            style={{ padding: '10px 20px', fontSize: '26px', color: '#c5c6c7', cursor: 'pointer' }}
                            size="3x"
                            icon="bell-o">
                            {notificationsCount > 0 && (
                              <span className="absolute top-0 right-0 bg-red-500 text-white rounded-full text-xs w-5 h-5 flex items-center justify-center">
                                {notificationsCount}
                              </span>
                            )}
                          </Icon>
                        </div>
                        {dropdownOpen && (
                          <DropDownNotifications
                            notifications={notifications}
                            setDropdownOpen={setDropdownOpen}
                            setUpdatedNotifications={() => setUpdatedNotifications(!updatedNotifications)}
                          />
                        )}
                      </span>

                      <Dropdown
                        placement="bottomEnd"
                        className="animated fadeIn"
                        renderTitle={() => {
                          return (
                            currentUser?.displayName && <AvatarComponent size="md" name={currentUser?.displayName} />
                          );
                        }}>
                        <Dropdown.Item panel style={{ padding: 10, width: 160 }}>
                          <p>Signed in as</p>
                          <strong>{currentUser?.displayName}</strong>
                        </Dropdown.Item>
                        {/* <Dropdown.Item divider /> */}
                        {/* <Dropdown.Item>Personality Profile</Dropdown.Item> */}
                        <Dropdown.Item divider />
                        <Dropdown.Item onSelect={() => Router.push('/dashboard/settings?tab=personal')}>
                          Account Settings
                        </Dropdown.Item>
                        {/* {currentUser?.email === 'dev.prod.soulrooms@gmail.com' && (
                          <Dropdown.Item onSelect={() => Router.push('/dashboard/integrations')}>
                            Integrations
                          </Dropdown.Item>
                        )} */}
                        {/* <Dropdown.Item onSelect={() => Router.push('/dashboard/import')}>Import</Dropdown.Item> */}
                        {/* {currentUser.views && currentUser.views.includes('analytics-plus') && ( */}
                        <Dropdown.Item onSelect={() => Router.push('/dashboard/reports')}>Imports</Dropdown.Item>
                        {/* )} */}
                        {currentUser.views && currentUser.views.includes('sales-forms') && (
                          <Dropdown.Item onSelect={() => setCreateCampaign(true)}>Build Campaign</Dropdown.Item>
                        )}
                        {currentUser.views && currentUser.views.includes('sales-forms') && (
                          <Dropdown.Item onSelect={() => setUpdateCampaign(true)}>Update Campaign</Dropdown.Item>
                        )}
                        {/* <Dropdown.Item onSelect={() => Router.push('/dashboard/settings?tab=plans')}>
                        Plans & Bilings
                      </Dropdown.Item> */}
                        <Dropdown.Item onSelect={handleLogout}>Sign Out</Dropdown.Item>
                      </Dropdown>
                    </Nav>
                  </div>

                  <Navbar.Body></Navbar.Body>
                  <br></br>
                  <Divider style={{ marginBottom: 0 }}></Divider>
                  {openChatDrawer && (
                    <ChatAssistantDrawer promptContext={currentContext} setOpenChatDrawer={setOpenChatDrawer} />
                  )}
                </Navbar>
              </Header>
            </Affix>
            <Content className="db-content">{children}</Content>
            <Footer />
          </Container>
        </Container>
        {currentUser && currentUser.views?.includes('sage+') && pageId !== 'sage+' && (
          <div
            ref={chatContainerRef}
            className="fixed right-28 bottom-6 z-50 chatPopupContainer"
            style={{
              height: `${openChatPopup ? '650px' : '50px'}`,
              width: `${openChatPopup ? (width < 991 ? 'calc(100% - 7rem)' : '430px') : '50px'}`,
            }}>
            <Whisper
              preventOverflow
              trigger="click"
              open={openChatPopup}
              onClick={() => setOpenChatPopup(!openChatPopup)}
              container={chatContainerRef.current}
              controlId="control-id-container"
              speaker={
                <ChatAssistantPopover
                  pageId={pageId}
                  onCloseButtonClicked={() => {
                    setOpenChatPopup(false);
                  }}
                />
              }
              placement="top">
              <Button
                size="lg"
                circle
                appearance="primary"
                className="bottom-0 right-0 absolute rounded-full p-2 animate-[pulse-yellow_2s_linear_infinite]"
                style={{ position: 'absolute' }}>
                <img src={'/static/icons/pilot.png'} className="w-8 h-8 " />
              </Button>
            </Whisper>
          </div>
        )}
        {popupOpen && activePopup && (
          <LinkToForm
            show={true}
            onClose={() => {
              handleHidePopup(activePopup.nid);
              handleClose();
              setUpdatedNotifications(!updatedNotifications);
            }}
            title={activePopup.title}
            description={activePopup.message}
            buttonLabel={activePopup.cta}
            buttonLink={activePopup.url}
          />
        )}
        {createCampaign && <CreateCampaign show={true} onClose={() => setCreateCampaign(false)} />}
        {updateCampaign && <UpdateCampaign show={true} onClose={() => setUpdateCampaign(false)} />}

        {/* {!openChatDrawer && <SlackFeedback user={currentUser}></SlackFeedback>} */}
      </main>
      <Styles />
    </>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.node,
  bgImage: PropTypes.bool,
  currentUser: PropTypes.object,
  handleLogout: PropTypes.func,
};

export default DashboardLayout;
