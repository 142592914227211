export default function Styles () {
  return (
    <style>
      {`
       .fontWeight {
    font-weight: 200px !important;
    line-height: 34px;
}
 
 .fs-14-16 {
   font-size: 14px !important;
   line-height: 21px !important;
   font-weight: 400;
 }
 


@media (max-width: 768px) {
  .responsiveT {
    font-size: 28px !important;
    line-height: 30px !important;
    font-weight: 400;
  }
 
}
@media (min-width: 768px) {
  .responsiveT {
    font-size: 30px !important;
    line-height: 38px !important;
    font-weight: 400;
  }

}


@media (min-width: 1024px) {
  .responsiveT {
    font-size: 30px !important;
    line-height: 38px !important;
    font-weight: 400;
  }

}
@media (min-width: 1280px) {
  .responsiveT {
    font-size: 32px !important;
    line-height: 40px !important;
    font-weight: 400;
  }
 
}

@media (min-width: 1534px) {
  .responsiveT {
    font-size: 36px !important;
    line-height: 45px !important;
    font-weight: 400;
  }
  .fs-14-16{
    font-size: 16px !important;
    line-height: 24px !important;
    font-weight: 400;
  }
  .size {
    font-size: 24px !important;
    line-height: 34px !important;
    font-weight: 400;
  }
}
        `}
    </style>
  );
}
