import React, { useState, useEffect } from 'react';
import { Icon, IconButton } from 'rsuite';
import { UserApi } from 'services/api/user';

function Banner({ notifications, setUpdatedNotifications }) {
  const [visibleBanners, setVisibleBanners] = useState([]);

  const handleHideBanner = bannerId => {
    setVisibleBanners(prev => [...prev, bannerId]);
    UserApi.updateNotifications({ hide: true }, bannerId);
  };
  return (
    <div>
      {notifications.map(
        banner =>
          !banner.hide &&
          !visibleBanners.includes(banner.nid) && (
            <div
              key={banner.nid}
              id="banner"
              tabIndex="-1"
              className="flex justify-between items-start py-1 px-4 border border-b bg-[#fCB000] bg-opacity-20 border-white">
              <div className={'property-details-description resident-description-wrapper'}>
                <p className={'desc-styles'}>
                  <div dangerouslySetInnerHTML={{ __html: banner.message }} />
                </p>
              </div>
              <IconButton
                size="xs"
                icon={<Icon icon="close" size="xs" />}
                className="text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={() => {
                  handleHideBanner(banner.nid);
                  setUpdatedNotifications();
                }}
              />
            </div>
          )
      )}
    </div>
  );
}

export default Banner;
