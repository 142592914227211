export default function Styles(){
  return (
    <style>
      {`
       @media (max-width: 410px) {
    .xxs-size{
        width: 95% !important;
    }
    .closeBtn{
        display: none;
    }
}
        `}
    </style>
  );
}
