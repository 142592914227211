import { BuildingApi } from 'services/api/building';
import { UserApi } from 'services';

export const fetchSubUsersData = async (currentUser, setSubUsersData, setIsLoading) => {
  setIsLoading(true);
  try {
    const results = await UserApi.getUsersForOrganisation(currentUser.organisationId).catch(err => {
      errorTracker.error(err);
      console.error(err);
    });

    const userArrayWithoutCurrentUser = results?.data || [];

    let userBuildingIds = [];
    // retrieve all buildingIds from all users so that we only need to make a single fetch to retreive all required buildings
    for (const user of userArrayWithoutCurrentUser) {
      userBuildingIds = userBuildingIds.concat(user.buildingIds);
    }
    // fetch all required building data
    const buildings = await BuildingApi.getBuildingsById({ buildingIds: userBuildingIds }).catch(err => {
      console.error(err);
    });
    let adminManagers = [];
    let buildingNamesDict = {};
    // add relevant buildingNames to the respective users
    for (const user of userArrayWithoutCurrentUser) {
      if (user.role === 'manager') {
        adminManagers.push(user);
      }
      buildingNamesDict[user.id] = [];
      user.buildingNamesString = '';
      // find buildings associated with user's buildingIds
      for (const building of buildings.data) {
        if (user.buildingIds && user.buildingIds.includes(building.id)) {
          buildingNamesDict[user.id].push(building.name);
        }
      }
      user.buildingNamesString = buildingNamesDict[user.id].join(', ');
    }

    // if the user is an admin manager,
    // find all users that are managed by the admin manager
    // and add the building names of those users to the admin manager's building names
    for (const user of adminManagers) {
      let buildingNamesArray = buildingNamesDict[user.id] || [];
      for (const uid of user.usersManaged) {
        buildingNamesArray = buildingNamesArray.concat(buildingNamesDict[uid]);
      }
      // remove duplicates
      buildingNamesArray = [...new Set(buildingNamesArray)];
      user.buildingNamesString = buildingNamesArray.join(', ');
    }
    // change role to owner if role is user and add (You) next to role
    for (const user of userArrayWithoutCurrentUser) {
      if (user.role === 'user') {
        user.role = 'owner';
      }

      if (user.id === currentUser.id) {
        user.role = `${user.role} (You)`;
      }
    }
    const mappedSubUserData = userArrayWithoutCurrentUser
      .filter(user => user.id !== currentUser.id)
      .map(user => {
        return {
          label: `${user.firstName} ${user.lastName}`,
          value: user.id,
        };
      });
    setSubUsersData(mappedSubUserData);
  } catch (err) {
    console.log({ err });
  } finally {
    setIsLoading(false);
  }
};
