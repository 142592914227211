import Styles from './styles.js';

import { React, useState, useEffect, useRef } from 'react';
import { Container, Header, Navbar, Nav, Content, Button, Dropdown, Alert } from 'rsuite';
import { Link } from 'utils/with-i18next';
import FormModal from 'components/LandingPageModal/FormModal';
import Popup from '../../containers/Popup/LoginPopup';
import HamburgerButton from 'components/HambergerButton';
import AvatarComponent from 'components/Avatar';
import { AuthApi } from 'services/api';
import { cache } from 'swr';
import { Router } from 'utils/with-i18next';
import { createErrorStatusCodeHandler } from 'utils/default-http-error-handler';
import { BookingApi } from 'services/api/booking';
import { startCase } from 'lodash';
import AddRoomieProfileModal from 'components/Modals/RoomieProfile';
import { useUser } from 'contexts/UserContext';
import { useErrorTracker } from 'utils/use-error-tracker';

function ResidentsPropertyLayout({ children, currentUser }) {
  const errorTracker = useErrorTracker();
  const [show] = useState('true');
  const [openPremiumModal, setOpenPremiumModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  // const [premium, setPremium] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [roommateProfileModal, setRoommateProfileModal] = useState(false);
  const { user, isUserLoggedIn, clearUser } = useUser();

  const dataModal = {
    roomSliderPremium: [
      {
        src: '/static/images/landingPages/s1.png',
      },
    ],
    roomSliderSupreme: [
      {
        src: '/static/images/landingPages/SingleSupreme.png',
      },
    ],
    roomSliderAccessable: [
      {
        src: '/static/images/landingPages/s2.png',
      },
    ],
  };

  const togglePopup = () => {
    !isOpen
      ? (document.getElementById('app-root').style.filter = 'blur(0px)')
      : (document.getElementById('app-root').style.filter = 'blur(10px)');
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    AuthApi.logout()
      .then(() => {
        cache.clear();
        clearUser();
        setIsOpen(true);
        document.getElementById('app-root').style.filter = 'blur(10px)';
      })
      .catch(createErrorStatusCodeHandler('logout', err => {
        Alert.error(`Something went wrong while logging out.`, 5000);
      }, null, errorTracker))
  };

  useEffect(() => {
    if (!isUserLoggedIn()) {
      const timer = setTimeout(() => {
        setIsOpen(true);
        document.getElementById('app-root').style.filter = 'blur(10px)';
      }, 10000);
      return () => {
        document.getElementById('app-root').style.filter = 'blur(0px)';
        clearTimeout(timer);
      };
    } else {
      setIsOpen(false);
      document.getElementById('app-root').style.filter = 'blur(0px)';
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      BookingApi.fetchBookings('tenant')
        .then(res => setBookings(res.data))
        .catch(err => {
          errorTracker.error('Fetch bookings error', { fetchBookingsParam: 'tenant' });
          Alert.error('Something went wrong while fetching bookings.', 5000);
          console.error(err);
        });
    }
  }, [currentUser]);

  const [urlPathname, setUrlPathname] = useState('');
  useEffect(() => {
    setUrlPathname(window.location.pathname);
  });

  return (
    <>
      <main className="layout-page-main-container">
        <Container id="app-root" style={{ height: '100%' }}>
          <Container id="container-opacity" style={{ height: '100%' }}>
            <div style={{ display: show }}>
              <FormModal
                dataModal={dataModal.roomSliderPremium}
                show={openPremiumModal}
                setClose={setOpenPremiumModal}
              />
            </div>

            <Header className="">
              <Navbar className="db-navbar cus-db-nav">
                <Navbar.Header>
                  <Link href={'/'} passHref>
                    <img className="ob-logo " src="/static/images/BigLogo.png"></img>
                  </Link>
                </Navbar.Header>
                <Navbar.Body>
                  <div className="hamburger-btn">
                    <HamburgerButton
                      setRoommateProfileModal={setRoommateProfileModal}
                      currentUser={currentUser}
                      roommateProfileModal={roommateProfileModal}
                      togglePopup={togglePopup}
                      handleLogout={handleLogout}
                      bookings={bookings}
                    />
                  </div>
                  <Nav className="cus-nav-resident" pullRight>
                    {/* <Link href={'/'}>
                      <a className="res-navitem link">Refer & Earn</a>
                    </Link> */}

                    {urlPathname.split('/')[1] === 'my-bookings' && (
                      <Link href={'/properties'}>
                        <a className="res-navitem go-to-prop"> &larr; Go to Properties</a>
                      </Link>
                    )}
                    {/* {currentUser?.role && (
                      <Button
                        className='py-2'
                        appearance="ghost"
                        onClick={() => {
                          setRoommateProfileModal(!roommateProfileModal);
                        }}>
                        Create Roommate Profile
                      </Button>
                    )} */}
                    {!isUserLoggedIn() ? (
                      <Button className=" " onClick={togglePopup}>
                        Login
                      </Button>
                    ) : (
                      <Dropdown
                        toggleClassName="ava-header"
                        placement="bottomEnd"
                        className="animated fadeIn mr-2"
                        renderTitle={() => {
                          return (
                            currentUser?.displayName && <AvatarComponent size="md" name={currentUser?.displayName} />
                          );
                        }}>
                        <Dropdown.Item panel style={{ padding: 10, width: 160 }}>
                          <p>Signed in as</p>
                          <strong>{currentUser?.displayName}</strong>
                        </Dropdown.Item>
                        {/* <Dropdown.Item divider /> */}
                        {/* <Dropdown.Item>Personality Profile</Dropdown.Item> */}
                        <Dropdown.Item divider />
                        <Dropdown.Menu title="My Rentals" pullLeft={true}>
                          {bookings.length > 0 ? (
                            bookings
                              .filter(
                                item =>
                                  item.status === 'awaiting_application' ||
                                  item.status === 'paid' ||
                                  item.status === 'approved' ||
                                  item.status === 'documents-uploaded'
                              )
                              .map(booking => {
                                return (
                                  <Dropdown.Item
                                    key={booking.id}
                                    onSelect={() => Router.push(`/my-bookings/${booking.id}`)}>
                                    {booking.room
                                      ? `${startCase(booking.room.type)} room in ${booking.propertyAddress}`
                                      : `Entire Unit in ${booking.propertyAddress}`}
                                  </Dropdown.Item>
                                );
                              })
                          ) : (
                            <Dropdown.Item disabled>No Rentals</Dropdown.Item>
                          )}
                        </Dropdown.Menu>

                        <Dropdown.Item divider />
                        {/* <Dropdown.Item onSelect={() => Router.push('/dashboard/settings?tab=personal')}>
                          Account Settings
                        </Dropdown.Item> */}
                        {/* <Dropdown.Item onSelect={() => Router.push('/dashboard/settings?tab=plans')}>
                        Plans & Bilings
                      </Dropdown.Item> */}
                        <Dropdown.Item onSelect={handleLogout}>Sign Out</Dropdown.Item>
                      </Dropdown>
                    )}
                  </Nav>
                </Navbar.Body>
              </Navbar>
            </Header>

            <Content className="db-content">{children}</Content>
          </Container>
        </Container>
        <div id="dialog-target" style={{ width: '60%' }}>
          {isOpen && <Popup setIsOpen={setIsOpen} handleClose={togglePopup} />}
        </div>
        <AddRoomieProfileModal show={roommateProfileModal} setClose={() => setRoommateProfileModal(false)} />
      </main>
      <Styles />
    </>
  );
}

export default ResidentsPropertyLayout;
