import React from 'react';
import HeadingSection from 'components/LandingPageHeadingSection/HeadingSection';

const ReservationForm = ({ heading, spacing, children }) => {
  return (
    <>
      <HeadingSection spacing={spacing} title={heading} bgcolor="white" />
      <div className="lg:container px-6 lg:px-5 mx-auto pb-4">
        <div className="embed-responsive embed-responsive-21by9 text-center">{children}</div>
      </div>
    </>
  );
};

export default ReservationForm;
