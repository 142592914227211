export default function Style(){
  return (
    <style>
      {`
       
@media (max-width: 475px) {
  .hamburger-wrapper .rs-btn.rs-btn-default{
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
}
  
  
}
@media (max-width: 400px) {
  .rs-drawer-left {
    width: 100% !important;
  }
}
@media (min-width: 490px) {
.hamburger-wrapper .rs-btn.rs-btn-default {
  padding-left: 26px !important;
  padding-right: 26px !important;
}
  

}



.hamburger-wrapper .menu-links .rs-btn.rs-btn-ghost {
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 30px;
  display: inline;
  border-radius: 30px;
  border-color: #FFB900;
  color: #FFB900;
}
.hamburger-wrapper .menu-links .rs-btn.rs-btn-default {
  margin-top: 30px;
  background-color: transparent;
}

.hamburger-wrapper .refer-link {
  color: black;
  margin: auto;
  margin-top: 30px;
}
.hamburger-wrapper .sm-logo {
  /* // width: 48px;
  // height: 35px; */
  position: relative;
  left: 25px;
  top: 5px;
  width: 1.75rem;
}
.hamburger-wrapper  .rs-icon-size-2x,
.rs-icon-size-2x.rs-icon {
    position: relative;
    top: 12px;
}
.hamburger-wrapper .rs-btn.rs-btn-default.ham-btn {
  font-size: 1.5rem;
  /* // position: fixed; */
  left: 83vw;
  top: -5px;
  background: #FAFAFA;
  position: absolute;
}

/* #resident-property-hamburger-drawer { */
  #resident-property-hamburger-drawer .rs-drawer-content {
      background: #FAFAFA;
  }
  #resident-property-hamburger-drawer .rs-drawer-header {
      margin: 15px;
  }
  #resident-property-hamburger-drawer .rs-panel {
    background: none;
  }
  #resident-property-hamburger-drawer .rs-panel-title {
    padding-right: 1em;
  }
  #resident-property-hamburger-drawer .rs-panel {
    background: none;
  }
  #resident-property-hamburger-drawer .rs-panel-title {
    padding-right: 1em;
  }
/* } */

        `}
    </style>
  );
}