export default function Style(){
  return (
    <style>
      {`
      *{
        font-family:"Josefin Sans";
        }
       .db-logo {
    padding: 0px;
}

.db-logo img {
    height: 30px;
    cursor: pointer;
    margin-left: calc((100% - 30px)/2);
    margin-top: 20px;
}

.db-footer {
    bottom: 0;
    width: 100%;
    text-align: center;
    font-size: 14px;
}

.db-navbar {
    margin: 25px;
}


.db-navbar .rs-navbar-header {
    font-size: 21px;
}
.db-sidebar {
    background: rgba(255, 255, 255, 0.98);
    /*box-shadow: 0 6px 20px rgba(0, 0, 0, 0.06);*/
    display: 'flex';
    flex-direction: 'column';
}

.db-content {
    margin: 0 25px;
}

.db-sidenav {
    margin-top: 20px;
}

.db-sidenav .rs-nav-item-content {
   
    font-weight: 500;
    // color: #ffb900;

}
.db-sidenav .rs-nav-item-content:active {
    // padding-left: 30px !important;
    // font-weight: 500;
    color: #ffb900 !important;

}
.db-sidenav .rs-nav-item-content:focus-visible {
    // padding-left: 30px !important;
    // font-weight: 500;
    color: #ffb900 !important;

}


// new work sk
input.rs-input.rs-input-sm {
    padding: 9px 11px !important;
  }
.rs-input-sm{
    
}
.rs-nav-item-active>.rs-nav-item-content{
    color: #ffb900 !important;
}
.rs-nav-subtle.rs-nav-horizontal .rs-nav-item>.rs-nav-item-content:before{
    background:#e0ac00;
}
// new work end
.db-sidenav .rs-sidenav { 
    width: auto;
}

@keyframes pulse-yellow {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 201, 1, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 201, 1, 0);
  }
  
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 201, 1, 0);
  }
}

.db-sidenav img {
    height: 25px;
    width: 25px;
    margin-right: 20px;
}

.db-sidebar .rs-sidenav-collapse-out .rs-nav-item .rs-nav-item-content{
    padding-left: 18px;
    margin: 0px;

}
.db-sidebar .rs-sidenav-collapse-out .rs-nav-item .rs-nav-item-content img{
    width: 20px;
    height: 20px;
    object-fit:contain;
    margin: 0px;
}

.db-sidenav .rs-nav-item-active img{
     
        filter: invert(61%) sepia(99%) saturate(857%) hue-rotate(1deg) brightness(106%) contrast(102%);
    
}
.affix_container_for_header .rs-affix{
    margin-left: 85px;
    left: 0px!important;
    background-color:rgb(248, 248, 248);
}

@media (max-width: 500px) {
    .affix_container_for_header .rs-affix{
        margin-left: 0px;
        
    }
}
.affix_container_for_header .rs-affix br,
.affix_container_for_header .rs-affix .rs-divider{
    display:none;
}
.affix_container_for_header .rs-affix .db-navbar{
    margin-top:0px;
    margin-bottom:0px;
}

.chatPopupContainer .rs-popover-content{
    height:100%;
}



@media (max-width: 500px) {
    #notifuse-dropdown .main-container-1vUV_{
        width :auto;
        overflow-x:scroll ;
    }
   
}
@media (max-width: 416px) {
    #notifuse-dropdown .main-container-1vUV_{
        width :314px;
       
        overflow-x:scroll ;
    }
   
}
@media (max-width: 390px) {
    #notifuse-dropdown .main-container-1vUV_{
        width :240px;
       
        overflow-x:scroll ;
    }
   
}

        `}
    </style>
  );
}
