import { Schema, FormControl, Panel, FormGroup, Radio, RadioGroup, Form } from 'rsuite';
import styled from '@emotion/styled';
import { Grid, Row, Col } from 'rsuite';
import React from 'react';
export const Model = Schema.Model({
  youTakeTobacco: Schema.Types.StringType().isRequired('This field is required'),
  youTakeCannabis: Schema.Types.StringType().isRequired('This field is required'),
  roomiesTakeTobacco: Schema.Types.StringType().isRequired('This field is required'),
  roomiesTakeCannabis: Schema.Types.StringType().isRequired('This field is required'),
});

const Step6Form = ({ roomieProfileData, setroomieProfileData, formErrors, setFormErrors, shadow, space }) => {
  const FormTagline = styled('h4')`
    font-size: 18px;
    font-weight: 400;

    margin-bottom: 30px;
  `;

  return (
    <div>
      {space ? (
        <FormTagline>Do you smoke at home...</FormTagline>
      ) : (
        <FormTagline className="mb-0">Do you smoke at home...</FormTagline>
      )}

      <Form
        checkTrigger="change"
        formValue={roomieProfileData}
        onCheck={setFormErrors}
        formError={formErrors}
        onChange={setroomieProfileData}
        model={Model}
        fluid>
        <Panel className="mt-3" shaded={shadow ? false : true} style={{ overflow: 'inherit' }}>
          <Grid fluid>
            <Row className="show-grid  ">
              <FormTagline className="mb-2">Nicotine / Tobacco?</FormTagline>
              <Col md={24}>
                <FormGroup controlId="radioList" className="mb-2">
                  <FormControl
                    inline
                    name="youTakeTobacco"
                    accepter={RadioGroup}
                    onChange={val => setroomieProfileData({ ...roomieProfileData, youTakeTobacco: val })}>
                    <Radio value="Never">Never</Radio>
                    <Radio value="On occasions">On occasions</Radio>
                    <Radio value="Once a day">Once a day</Radio>
                    <Radio value="Multiple times a day">Multiple times a day</Radio>
                  </FormControl>
                </FormGroup>
              </Col>
              <FormTagline className="mb-2  ">Cannabis?</FormTagline>
              <Col md={24}>
                <FormGroup controlId="radioList">
                  <FormControl
                    inline
                    // value="youTakeCannabis"
                    name="youTakeCannabis"
                    accepter={RadioGroup}
                    onChange={val => setroomieProfileData({ ...roomieProfileData, youTakeCannabis: val })}>
                    <Radio value="Never">Never</Radio>
                    <Radio value="On occasions">On occasions</Radio>
                    <Radio value="Once a day">Once a day</Radio>
                    <Radio value="Multiple times a day">Multiple times a day</Radio>
                  </FormControl>
                </FormGroup>
              </Col>
            </Row>
          </Grid>
        </Panel>

        <FormTagline className="mt-4 mb-0">Is it okay if your roomies smoke at home...</FormTagline>
        <Panel className="mt-3" shaded={shadow ? false : true} style={{ overflow: 'inherit' }}>
          <Grid fluid>
            <Row className="show-grid  ">
              <FormTagline className="mb-2">Nicotine / Tobacco?</FormTagline>
              <Col md={24}>
                <FormGroup controlId="radioList" className="mb-2">
                  <FormControl
                    inline
                    // value="roomiesTakeTobacco"
                    name="roomiesTakeTobacco"
                    accepter={RadioGroup}
                    onChange={val => setroomieProfileData({ ...roomieProfileData, roomiesTakeTobacco: val })}>
                    <Radio value="No">No</Radio>
                    <Radio value="Yes">Yes (always outside)</Radio>
                  </FormControl>
                </FormGroup>
              </Col>
              <FormTagline className="mb-2  ">Cannabis?</FormTagline>
              <Col md={24}>
                <FormGroup controlId="radioList">
                  <FormControl
                    inline
                    // value="roomiesTakeCannabis"
                    name="roomiesTakeCannabis"
                    accepter={RadioGroup}
                    onChange={val => setroomieProfileData({ ...roomieProfileData, roomiesTakeCannabis: val })}>
                    <Radio value="No">No</Radio>
                    <Radio value="Yes ">Yes (always outside)</Radio>
                  </FormControl>
                </FormGroup>
              </Col>
            </Row>
          </Grid>
        </Panel>
      </Form>
    </div>
  );
};

export default Step6Form;
