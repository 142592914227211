import React, { useState } from 'react';
import { Modal, Button } from 'rsuite';

const modalStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
};

const titleStyles = {
  fontWeight: 'bold',
  marginBottom: '16px',
};

export default function CustomModal({ show, onClose, title, description, buttonLabel, buttonLink }) {
  const [loading, setLoading] = useState(false);

  const openThirdPartyForm = () => {
    if (buttonLink) {
      window.open(buttonLink, '_blank');
    }
    onClose();
  };

  return (
    <Modal show={show} onHide={onClose} style={modalStyles}>
      <Modal.Header>
        <Modal.Title style={titleStyles}>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={'property-details-description resident-description-wrapper'}>
          <p className={'desc-styles'}>
            <div dangerouslySetInnerHTML={{ __html: description.replaceAll('\n', '<br>') }} />
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
        {buttonLink && (
          <Button onClick={openThirdPartyForm} color="yellow">
            {buttonLabel || 'Go to Third-Party Form'}
          </Button>
        )}
        <Button onClick={onClose} appearance="subtle">
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
