import React, { createContext, useReducer, useEffect } from 'react';
import { UserApi } from '../services/api/user';
import { useUser } from './UserContext';
import { useErrorTracker } from 'utils/use-error-tracker.js';
import { TABLEAU_DASHBOARDS } from 'data/tableauDashboards';

// Tableau Dashboard Context initial state
let initialState = {
  showTableau: false,
  currentDashboard: TABLEAU_DASHBOARDS[0], // one of jonathan's 6 standard dashboards, default to exec summary
  dashboardTabIndex: 0,
  userSelected: '', // user selected in the dropdown
  token: null,
  allDashboards: TABLEAU_DASHBOARDS, // all the various tableau dashboards and sub-dashboards
};

export const TableauDashboardContext = createContext(initialState);

export const TableauDashboardContextProvider = ({ children }) => {
  const getUser = useUser();
  const errorTracker = useErrorTracker();

  const getUsersData = async () => {
    try {
      const results = await UserApi.getUsersManaged().catch(err => {
        errorTracker.error('Error when getting users managed', { currentUserId: getUser.user.id });
        console.error(err);
      });
      tableauDashboardDispatch({
        type: 'setUsers',
        payload: results.data,
      });
    } catch (err) {
      console.log({ err });
    }
  };

  const getTableauJWT = async () => {
    try {
      const hasAccess = await UserApi.getHasTableauAccess();
      if (hasAccess.data || getUser.user.email === 'dev.prod.soulrooms@gmail.com') {
        const res = await UserApi.getTableauJWT();
        tableauDashboardDispatch({
          type: 'setToken',
          payload: res.data,
        });
      }
    } catch (err) {
      console.log({ err });
    }
  };

  useEffect(() => {
    // only fetch users data if organisationId is set
    // if the organisationId is not set, it is guaranteed that no users will be returned
    // hence, there is no need to fetch users data
    if (getUser.user.organisationId) {
      getUsersData();
      getTableauJWT();
    }
  }, [getUser]);

  const [tableauDashboardState, tableauDashboardDispatch] = useReducer((dashboardState, action) => {
    switch (action.type) {
      case 'setCurrentDashboard':
        return { ...dashboardState, currentDashboard: action.payload };
      case 'setDashboardTabIndex':
        return { ...dashboardState, dashboardTabIndex: action.payload };
      case 'setUserSelected':
        return {
          ...dashboardState,
          userSelected: action.payload,
        };
      case 'setShowTableau':
        return {
          ...dashboardState,
          showTableau: action.payload,
        };
      case 'setToken':
        return {
          ...dashboardState,
          token: action.payload,
        };
      default:
        return dashboardState;
    }
  }, initialState);

  return (
    <TableauDashboardContext.Provider
      value={{
        tableauDashboardState,
        tableauDashboardDispatch,
      }}>
      {children}
    </TableauDashboardContext.Provider>
  );
};
