import { Button, Form, FormControl, Input, Modal, Schema, Alert, SelectPicker } from 'rsuite';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { pickBy } from 'lodash';

function GetFeedback({ show, setClose, handleSuccess, origin = 'price' }) {
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const model = Schema.Model({
    details: Schema.Types.StringType()
      .isRequired('This field is required')
      .maxLength(250, 'Max length is 250 characters.'),
    category: Schema.Types.StringType().isRequired('This field is required'),
  });

  const validate = () => {
    const c = pickBy(model.check(formData), v => v.hasError);
    Object.keys(c).forEach(k => {
      c[k] = c[k].errorMessage;
    });
    return c;
  };

  const sendFeedback = () => {
    setLoading(true);
    const errors = validate();

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      setLoading(false);
      return;
    }

    setClose(false);
    handleSuccess(formData);
  };

  const feedbackCategories = {
    price: [
      { label: 'Inaccurate - Price Too High', value: 'priceTooHigh' },
      { label: 'Inaccurate - Price Too Low', value: 'priceTooLow' },
      { label: 'Inaccurate - Percentiles', value: 'percentiles' },
      { label: 'Not Enough Options', value: 'notEnoughOptions' },
      { label: 'Other', value: 'other' },
    ],
    insights: [
      { label: 'Call', value: 'call' },
      { label: 'Meeting', value: 'meeting' },
      { label: 'Task', value: 'task' },
      { label: 'Email', value: 'email' },
      { label: 'Text', value: 'text' },
    ],
  };

  return (
    <Modal
      className="tenants-instruction-modal"
      show={show}
      backdrop="static"
      onHide={() => setClose(false)}
      size={'xs'}>
      <Modal.Header style={{ textAlign: 'center' }}>
        <Modal.Title>Send Feedback</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          formValue={formData}
          formError={formErrors}
          style={{ textAlign: 'center' }}
          layout="horizontal"
          model={model}
          onChange={setFormData}
          checkTrigger="change">
          <FormControl
            size="sm"
            name="category"
            placeholder="Select A Category"
            accepter={SelectPicker}
            searchable={false}
            style={{ width: '300px', marginLeft: 'auto', marginRight: 'auto', marginBottom: '20px' }}
            preventOverflow={true}
            data={feedbackCategories[origin]}
          />
          <FormControl
            style={{ resize: 'none', marginLeft: 'auto', marginRight: 'auto' }}
            accepter={Input}
            Placeholder={'Your feedback...'}
            name="details"
            rows={4}
            componentClass={'textarea'}
            size="sm"
          />
        </Form>
      </Modal.Body>
      <Modal.Footer style={{ textAlign: 'center' }}>
        <Button
          style={{ width: '75px', color: '#b40000', border: '1px solid #b40000', marginRight: '20px' }}
          size="sm"
          appearance={'ghost'}
          onClick={sendFeedback}>
          Send
        </Button>
        <Button style={{ width: '75px' }} size="sm" appearance={'ghost'} onClick={() => setClose(false)}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

GetFeedback.propTypes = {
  show: PropTypes.bool.isRequired,
  setClose: PropTypes.func.isRequired,
};

export default GetFeedback;
