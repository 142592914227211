export default function Styles() {
  return (
    <style>
      {`
        .questionFeedback .rs-btn-default.rs-btn-disabled{
            background-color:#feebc1;
        }
        .Typewriter__wrapper{
          font-size: 14px
        }

              `}
    </style>
  );
}
