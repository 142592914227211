import { useContext, useEffect, useState } from 'react';
import { Drawer, Panel, Loader, Button, Alert, Form, FormControl, Schema, Input } from 'rsuite';
import Styles from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import { DashboardContext } from '../../contexts/LayoutDashboardContext';
import { confirmAlert } from 'react-confirm-alert';
import insightsData from 'insights';
import router from 'next/router';
import GetFeedback from 'components/Modals/GetFeedback';

export function InsightsDrawer({ origin, openDrawer, setOpenDrawer, user }) {
  const { dashboardState, dashboardDispatch } = useContext(DashboardContext);
  const [insights, setInsights] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openGetFeedback, setOpenGetFeedback] = useState(false);

  useEffect(() => {
    loadInsights();
  }, [openDrawer]);

  const loadInsights = () => {
    setLoading(true);
    setTimeout(() => {
      setInsights(insightsData.insights);
      setLoading(false);
    }, 1000);
  };

  const versionDict = {
    Positive: '#67C03E',
    Negative: '#E02C2C',
    Neutral: '#D9D9D9',
  };

  const dashboardDict = {
    overview: 'Management',
    leasing: 'Leasing',
    tenants: 'Tenants',
    management: 'Management',
    marketing: 'Marketing',
  };

  const categoryDict = {
    dashboard: dashboardDict[dashboardState?.DashboardTab.filter] || 'Management',
    properties: 'Leasing',
    bookings: 'Leasing',
    tickets: 'Tenants',
    'income-management': 'Marketing',
    settings: 'Management',
  };

  const handleNegativeFeedback = () => {
    setOpenGetFeedback(true);
  };

  const handlePositiveFeedback = () => {
    Alert.success('Your feedback has been submitted!');
    // This feedback needs to be recorded somewhere
  };

  return (
    <>
      {openGetFeedback && (
        <GetFeedback
          show={openGetFeedback}
          setClose={setOpenGetFeedback}
          handleSuccess={() => {
            console.log('CLOSED MODAL');
          }}
        />
      )}
      <Drawer size="xs" show={openDrawer} placement="right" onHide={() => setOpenDrawer(false)}>
        <Drawer.Header>
          <Drawer.Title>
            <div className="flex flex-row">
              <img className="self-start mt-3 mr-2" src="/static/icons/sparks.png" />
              <h1 className="text-[24px]">Top Insights</h1>
            </div>
          </Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          {loading ? (
            <Loader center speed="slow" size="lg" />
          ) : (
            <>
              <div className="mb-10 flex flex-col items-center mx-12">
                <p className="text-[24px] text-center">Good Day, {user?.user.firstName}</p>
                <p className="text-[20px] text-center">Here are your insights for the last week.</p>
                <hr className="w-full border-2 color-[#D9D9D9]" />
              </div>
              {origin && (
                <div className="mb-10">
                  <Panel header={`${categoryDict[origin]} Insights`} bordered bodyFill>
                    <hr className="w-full border-2 color-[#D9D9D9] mb-4" />
                    <div className="px-4 grid gap-4 mb-4">
                      {/* {insights.filter((insight) => insight.category === categoryDict[origin]).slice(0,3).map((el) => (
                      <div className={`p-4 border-2 border-[${versionDict[el.version]}] rounded-lg cursor-pointer hover:shadow-md`}>{el.message}</div>
                    ))} */}
                      {insights && insights[categoryDict[origin]]?.card1 && (
                        <div
                          className={`p-4 border-2 border-[${
                            versionDict[insights[categoryDict[origin]].card1.version]
                          }] rounded-lg cursor-pointer hover:shadow-md`}
                          onClick={() => {
                            dashboardDispatch({
                              type: 'setModule',
                              payload: 'insights',
                            });
                            dashboardDispatch({
                              type: 'setFilter',
                              payload: categoryDict[origin].toLowerCase(),
                            });
                            setOpenDrawer(false);
                            router.push('/dashboard');
                          }}>
                          {insights[categoryDict[origin]].card1.insight}
                        </div>
                      )}
                      {insights && insights[categoryDict[origin]]?.card2 && (
                        <div
                          className={`p-4 border-2 border-[${
                            versionDict[insights[categoryDict[origin]].card2.version]
                          }] rounded-lg cursor-pointer hover:shadow-md`}
                          onClick={() => {
                            dashboardDispatch({
                              type: 'setModule',
                              payload: 'insights',
                            });
                            dashboardDispatch({
                              type: 'setFilter',
                              payload: categoryDict[origin].toLowerCase(),
                            });
                            setOpenDrawer(false);
                            router.push('/dashboard');
                          }}>
                          {insights[categoryDict[origin]].card2.insight}
                        </div>
                      )}
                      {insights && insights[categoryDict[origin]]?.card3 && (
                        <div
                          className={`p-4 border-2 border-[${
                            versionDict[insights[categoryDict[origin]].card3.version]
                          }] rounded-lg cursor-pointer hover:shadow-md`}
                          onClick={() => {
                            dashboardDispatch({
                              type: 'setModule',
                              payload: 'insights',
                            });
                            dashboardDispatch({
                              type: 'setFilter',
                              payload: categoryDict[origin].toLowerCase(),
                            });
                            setOpenDrawer(false);
                            router.push('/dashboard');
                          }}>
                          {insights[categoryDict[origin]].card3.insight}
                        </div>
                      )}
                    </div>
                    <hr className="w-full border-2 color-[#D9D9D9]" />
                    <div
                      className="cursor-pointer hover:bg-[#E9E9E9] py-2 text-[20px] text-center text-yellow-500"
                      onClick={() => {
                        dashboardDispatch({
                          type: 'setModule',
                          payload: 'insights',
                        });
                        dashboardDispatch({
                          type: 'setFilter',
                          payload: categoryDict[origin].toLowerCase(),
                        });
                        setOpenDrawer(false);
                        router.push('/dashboard');
                      }}>
                      View Insights
                    </div>
                  </Panel>
                </div>
              )}
              <div className="flex flex-col items-center">
                <p className="mb-8 text-[20px] text-[#7D7D7D]">Were these insights helpful?</p>
                <div className="flex flex-row justify-center gap-10">
                  <Button onClick={handleNegativeFeedback} className="rounded-full px-[0.2rem] pb-0 pt-[0.2rem]">
                    <FontAwesomeIcon
                      size="lg"
                      icon={faThumbsDown}
                      className="hover:bg-[#E02C2C] cursor-pointer border-solid border-2 border-[#E02C2C] rounded-full p-2"
                    />
                  </Button>
                  <Button onClick={handlePositiveFeedback} className="rounded-full px-[0.2rem] pb-0 pt-[0.2rem]">
                    <FontAwesomeIcon
                      size="lg"
                      icon={faThumbsUp}
                      className="hover:bg-[#67C03E] cursor-pointer border-solid border-2 border-[#67C03E] rounded-full p-2"
                    />
                  </Button>
                </div>
              </div>
            </>
          )}
        </Drawer.Body>
        {/* <Drawer.Footer>
      </Drawer.Footer> */}
      </Drawer>
      {openDrawer && <Styles />}
    </>
  );
}

export default InsightsDrawer;
