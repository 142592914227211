export default function Style(){
  return (
    <style>
      {`
       .auth-bg {
  height: 100vh;
  position: relative;
  clip-path: polygon(125px 0%, 1600px 0%, 1600px 100%, 0 100%);
}

@media screen and (max-width: 990px) {
  .auth-bg {
    display: none;
  }
  .logo1 {
    height: 50%;
    margin: 0 5% !important;
    cursor: pointer;
  }
}

.auth-bg-slider .auth-quote {
  width: 40vw;
  position: absolute;
  bottom: 60px;
  height: auto;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  margin: 20px 1.5%;
  box-shadow: 0 2px 9px 0 rgba(34, 12, 43, 0.1);
}
.auth-bg-slider .auth-quote h6 {
  margin-bottom: 5px;
}
.auth-bg-slider .auth-quote p {
  margin-bottom: 0;
}

.auth-container {
  background-color: white;
}
  .rs-col {
    padding: 0 !important;
  }

  .rs-footer {
    text-align: center;
    font-size: 14px;
  }


.logo1 {
  height: 50%;
  margin: 0 30px;
  cursor: pointer;
}

.auth-content {
  background-image: url('/static/images/auth-layout-bg.png');
  background-color: #fff;
  background-position: right;
  background-repeat: no-repeat;
  /* // height: 80vh; */
  background-position-y: bottom;
  background-position-x: RIGHT;
  background-size: 400px;
  margin-bottom: 20px;
}

        `}
    </style>
  );
}
