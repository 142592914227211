import { Alert, Button, Modal } from 'rsuite';
import PropTypes from 'prop-types';
import React from 'react';
import { UserApi } from 'services/api';
import styled from '@emotion/styled';
import { useErrorTracker } from 'utils/use-error-tracker';

const Heading = styled('p')`
  font-size: 36px;
  text-align: center;
  font-weight: 300;
  margin: 3% 10%;
  color: #ffc900;
`;
const Description = styled('p')`
  font-size: 18px;
  text-align: center;
  font-weight: 400;
  margin: 5% 15% 0%;
  line-height: 24px;
`;

function WelcomeModal({ show, setClose }) {
  const errorTracker = useErrorTracker();
  const onCancelClick = () => {
    UserApi.updateCurrentUser({ seenWelcomeModal: true }).catch(err => {
      errorTracker.error('Update current user error', { updateCurrentUserData: { seenWelcomeModal: true } });
      Alert.error('Something went wrong while updating user data.', 5000);
      console.error(err);
    });
  };
  return (
    <Modal backdrop={'static'} show={show} onHide={() => setClose(false)} size="md">
      <Modal.Body>
        <Heading>
          Welcome to <br></br> Soulrooms&apos; Owner Portal
        </Heading>
        <Description>If you have any issues or concern, feel free to reach out to us through support</Description>
      </Modal.Body>
      <Modal.Footer style={{ textAlign: 'center', marginTop: '1em' }}>
        <Button
          onClick={() => {
            onCancelClick();
            setClose(false);
          }}
          size="xs"
          appearance="ghost">
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

WelcomeModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setClose: PropTypes.func.isRequired,
};

export default WelcomeModal;
