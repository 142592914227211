export const getTableauUrl = (allDashboards, currentDashboard, dashboardTab) => {
  if (!currentDashboard) return process.env.NEXT_PUBLIC_TABLEAU_EXEC_SUMMARY_URL;

  const dashboard = allDashboards.find(dashboard => dashboard.value === currentDashboard);

  if (!dashboard) return process.env.NEXT_PUBLIC_TABLEAU_EXEC_SUMMARY_URL;

  // If no dashboardTab selected, return the 0th indexed tab
  // Otherwise, return the indexed sub-dashboard tab
  return dashboard.dashboardTabs[dashboardTab].url;
};
