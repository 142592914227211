import React from 'react';
import styled from '@emotion/styled';

import Step7Form from './StepsForms/Step7Form';

const Step7 = ({ roomieProfileData, setroomieProfileData, formErrors, setFormErrors }) => {
  const FormTitle = styled('h3')`
    font-size: 24px;
    font-weight: 600;
  `;

  return (
    <>
      <FormTitle>Bringing guests home</FormTitle>
      <Step7Form
        space={true}
        roomieProfileData={roomieProfileData}
        setroomieProfileData={setroomieProfileData}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
      />
    </>
  );
};

export default Step7;
