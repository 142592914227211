import { useEffect, useState } from 'react';

export default function useDimension() {
  const [width, setWidth] = useState(1440);
  const [height, setHeight] = useState(900);

  const updateDimensions = () => {
    setWidth(window?.innerWidth);
    setHeight(window?.innerHeight);
  };
  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  return { width, height };
}
