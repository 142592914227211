import http from '../http';

/**
 * Property Api Service
 */
export class PriceService {
  urlPrefix = 'api/listing';
  /**
   * For Price
   */
  getPrice(data) {
    return http.post(`${this.urlPrefix}/get-price`, data);
  }
  getPriceCollection(data) {
    return http.post(`${this.urlPrefix}/get-price-collection`, data);
  }

  getPriceReport(data) {
    return http.post(`${this.urlPrefix}/get-price-report`, data);
  }

  getPropertiesByRegion(data) {
    return http.post(`${this.urlPrefix}/properties-by-region`, data);
  }

  createReport(data) {
    return http.post(`${this.urlPrefix}/create-report`, data);
  }

  createAdjustment(data) {
    return http.post(`${this.urlPrefix}/create-adjustment`, data);
  }

  createUpdateRenewals(data) {
    return http.post(`${this.urlPrefix}/create-update-renewals`, data);
  }

  renewalStatusUpdate(renewalId, data) {
    return http.post(`${this.urlPrefix}/renewal/${renewalId}`, data);
  }

  uploadCSV(file) {
    const form = new FormData();
    form.append('xlsx', file);

    return http.post(`${this.urlPrefix}/report-csv/upload`, form);
  }

  uploadAdjustment(file) {
    const form = new FormData();
    form.append('xlsx', file);

    return http.post(`${this.urlPrefix}/adjustment-csv/upload`, form);
  }

  uploadRenewal(file) {
    const form = new FormData();
    form.append('xlsx', file);

    return http.post(`${this.urlPrefix}/renewal-csv/upload`, form);
  }

  getReports() {
    return http.get(`${this.urlPrefix}/get-reports`);
  }

  getNewReports(lastestReportDate) {
    return http.post(`${this.urlPrefix}/get-new-reports`, { lastestReportDate });
  }

  generateEmailPriceReport(data) {
    return http.post(`${this.urlPrefix}/generate-email-price-report`, data);
  }

  getPriceNeighbourhoods() {
    return http.get(`${this.urlPrefix}/get-price-neighbourhoods`);
  }

  getPriceBuildings() {
    return http.get(`${this.urlPrefix}/get-price-buildings`);
  }

  getAdjustments() {
    return http.get(`${this.urlPrefix}/get-adjustments`);
  }

  getRenewals() {
    return http.get(`${this.urlPrefix}/get-renewals`);
  }
}

export const PriceApi = new PriceService();
