import styled from '@emotion/styled';
import React from 'react';
import { Grid, Row, Col } from 'rsuite';
import {Schema, Form, FormControl, Panel, FormGroup, Radio, RadioGroup } from 'rsuite';

export const Model = Schema.Model({
  dishesInSink: Schema.Types.StringType().isRequired('This field is required'),
  dirtyClothes: Schema.Types.StringType().isRequired('This field is required'),
  crumbs: Schema.Types.StringType().isRequired('This field is required'),
  garbageBins: Schema.Types.StringType().isRequired('This field is required'),
  foodInFridge: Schema.Types.StringType().isRequired('This field is required'),
  messFromRoommates: Schema.Types.StringType().isRequired('This field is required'),
  expectFromARoomie: Schema.Types.StringType().isRequired('This field is required'),
  isCleaner: Schema.Types.StringType().isRequired('This field is required'),
});
const Step4Form = ({ roomieProfileData, setroomieProfileData, formErrors, setFormErrors, shadow, space }) => {
  const FormTagline = styled('h4')`
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 30px;
  `;

  return (
    <div>
      {space ? (
        <FormTagline>How long do you leave dishes in the sink?.</FormTagline>
      ) : (
        <FormTagline className="mb-0">How long do you leave dishes in the sink?.</FormTagline>
      )}
      <Form
        checkTrigger="change"
        formValue={roomieProfileData}
        onCheck={setFormErrors}
        formError={formErrors}
        onChange={setroomieProfileData}
        model={Model}
        fluid>
        <Panel className="mt-3" shaded={shadow ? false : true} style={{ overflow: 'inherit' }}>
          <Grid fluid>
            <Row className="show-grid  ">
              <Col md={24}>
                <FormGroup controlId="radioList">
                  <FormControl
                    inline
                    name="dishesInSink"
                    accepter={RadioGroup}
                    onChange={val => setroomieProfileData({ ...roomieProfileData, dishesInSink: val })}>
                    <Radio value="Never">Never</Radio>
                    <Radio value="A few hours">A few hours</Radio>
                    <Radio value="About a day">About a day</Radio>
                    <Radio value="More then a day">More then a day</Radio>
                  </FormControl>
                </FormGroup>
              </Col>
            </Row>
          </Grid>
        </Panel>

        <FormTagline className="mt-4 mb-0">Do dirty clothes go on the floor/furniture?</FormTagline>
        <Panel className="mt-3" shaded={shadow ? false : true} style={{ overflow: 'inherit' }}>
          <Grid fluid>
            <Row className="show-grid  ">
              <Col md={24}>
                <FormGroup controlId="radioList">
                  <FormControl
                    inline
                    name="dirtyClothes"
                    accepter={RadioGroup}
                    onChange={val => setroomieProfileData({ ...roomieProfileData, dirtyClothes: val })}>
                    <Radio value="Never">Never</Radio>
                    <Radio value="Only Sometimes">Only Sometimes</Radio>
                    <Radio value="Most of the time">Most of the time</Radio>
                    <Radio value="All the time">All the time</Radio>
                  </FormControl>
                </FormGroup>
              </Col>
            </Row>
          </Grid>
        </Panel>

        <FormTagline className="mt-4 mb-0">If you cook, how long do you leave crumbs and spills?</FormTagline>
        <Panel className="mt-3" shaded={shadow ? false : true} style={{ overflow: 'inherit' }}>
          <Grid fluid>
            <Row className="show-grid  ">
              <Col md={24}>
                <FormGroup controlId="radioList">
                  <FormControl
                    inline
                    name="crumbs"
                    accepter={RadioGroup}
                    onChange={val => setroomieProfileData({ ...roomieProfileData, crumbs: val })}>
                    <Radio value="I clean up right away">I clean up right away</Radio>
                    <Radio value="Less than a day">Less than a day</Radio>
                    <Radio value="More than a day">More than a day</Radio>
                    <Radio value="I don’t mind it">I don’t mind it</Radio>
                  </FormControl>
                </FormGroup>
              </Col>
            </Row>
          </Grid>
        </Panel>
        <FormTagline className="mt-4 mb-0">How often do you empty garbage bins?</FormTagline>
        <Panel className="mt-3" shaded={shadow ? false : true} style={{ overflow: 'inherit' }}>
          <Grid fluid>
            <Row className="show-grid  ">
              <Col md={24}>
                <FormGroup controlId="radioList">
                  <FormControl
                    inline
                    name="garbageBins"
                    accepter={RadioGroup}
                    onChange={val => setroomieProfileData({ ...roomieProfileData, garbageBins: val })}>
                    <Radio value="Daily">Daily</Radio>
                    <Radio value="Every few days">Every few days</Radio>
                    <Radio value="Weekly">Weekly</Radio>
                    <Radio value="When it overflows">When it overflows</Radio>
                  </FormControl>
                </FormGroup>
              </Col>
            </Row>
          </Grid>
        </Panel>
        <FormTagline className="mt-4 mb-0">Do you ever have food go bad in the fridge?</FormTagline>
        <Panel className="mt-3" shaded={shadow ? false : true} style={{ overflow: 'inherit' }}>
          <Grid fluid>
            <Row className="show-grid  ">
              <Col md={24}>
                <FormGroup controlId="radioList">
                  <FormControl
                    inline
                    name="foodInFridge"
                    accepter={RadioGroup}
                    onChange={val => setroomieProfileData({ ...roomieProfileData, foodInFridge: val })}>
                    <Radio value="Never">Never</Radio>
                    <Radio value="Rarely">Rarely</Radio>
                    <Radio value="Sometimes">Sometimes</Radio>
                    <Radio value="Sadly,yes">Sadly,yes</Radio>
                  </FormControl>
                </FormGroup>
              </Col>
            </Row>
          </Grid>
        </Panel>
        <FormTagline className="mt-4 mb-0">
          How much mess from roommates are you okay with in shared spaces?
        </FormTagline>
        <Panel className="mt-3" shaded={shadow ? false : true} style={{ overflow: 'inherit' }}>
          <Grid fluid>
            <Row className="show-grid  ">
              <Col md={24}>
                <FormGroup controlId="radioList">
                  <FormControl
                    inline
                    name="messFromRoommates"
                    accepter={RadioGroup}
                    onChange={val => setroomieProfileData({ ...roomieProfileData, messFromRoommates: val })}>
                    <Row>
                      <Col md={12}>
                        <Radio value="Don't care" className="h6">
                          Don&apos;t care <br />
                          <span style={{ fontSize: '12px', color: '#8e8e93' }}>I&apos;m fine with messy roomates</span>
                        </Radio>
                      </Col>
                      <Col md={12}>
                        <Radio value="Some mess" className="h6">
                          Some mess
                          <br />{' '}
                          <span style={{ fontSize: '12px', color: '#8e8e93' }}>Dirty dishes are fine for a day</span>
                        </Radio>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <Radio value="Almost perfect" className="h6">
                          Almost perfect <br />{' '}
                          <span style={{ fontSize: '12px', color: '#8e8e93' }}>They should be generally tidy</span>
                        </Radio>
                      </Col>
                      <Col md={12}>
                        <Radio value="None" className="h6">
                          None <br />{' '}
                          <span style={{ fontSize: '12px', color: '#8e8e93' }}>It should look like a magazine</span>
                        </Radio>
                      </Col>
                    </Row>
                  </FormControl>
                </FormGroup>
              </Col>
            </Row>
          </Grid>
        </Panel>
        <FormTagline className="mt-4 mb-0">
          What would you expect from a roomie who&apos;s messier than you?
        </FormTagline>
        <Panel className="mt-3" shaded={shadow ? false : true} style={{ overflow: 'inherit' }}>
          <Grid fluid>
            <Row className="show-grid  ">
              <Col md={24}>
                <FormGroup controlId="radioList">
                  <FormControl
                    inline
                    name="expectFromARoomie"
                    accepter={RadioGroup}
                    onChange={val => setroomieProfileData({ ...roomieProfileData, expectFromARoomie: val })}>
                    <Radio value="They were fine the way they are">They&apos;re fine the way they are</Radio>
                    <Radio value="They would have to adapt to my standardsB">
                      They would have to adapt to my standards
                    </Radio>
                  </FormControl>
                </FormGroup>
              </Col>
            </Row>
          </Grid>
        </Panel>
        <FormTagline className="mt-4 mb-0">Would you live with a roomie who expects you to be cleaner?</FormTagline>
        <Panel className="mt-3" shaded={shadow ? false : true} style={{ overflow: 'inherit' }}>
          <Grid fluid>
            <Row className="show-grid  ">
              <Col md={24}>
                <FormGroup controlId="radioList">
                  <FormControl
                    inline
                    name="isCleaner"
                    accepter={RadioGroup}
                    onChange={val => setroomieProfileData({ ...roomieProfileData, isCleaner: val })}>
                    <Radio value="Yes, I'm willing to adapt to their standards">
                      Yes, I&apos;m willing to adapt to their standards
                    </Radio>
                    <Radio value="No, they would have to be ok with my standards">
                      No, they would have to be ok with my standards
                    </Radio>
                  </FormControl>
                </FormGroup>
              </Col>
            </Row>
          </Grid>
        </Panel>
      </Form>
    </div>
  );
};

export default Step4Form;
