import React from 'react';
import { Col, Grid, Row } from 'rsuite';
import { Container, Content, FlexboxGrid, Header, Navbar } from 'rsuite';
import Styles from './styles.js';
// import Footer from 'components/Footer';
import { Link } from 'utils/with-i18next';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import ChatbotHide from 'components/ChatbotHide';

const images = [
  {
    img: '/static/images/landingPages/analytics_sage_plus_bg.png',
    title: 'Analytics Dashboards & AI Insights',
    text: `Analytics Dashboards & AI Insights`,
  },
  {
    img: '/static/images/landingPages/report_builder_bg.png',
    title: 'Report Builder',
    text: `Report Builder`,
  },
];

function AuthLayout({ children }) {
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    dots: true,
    dotsClass: 'd-flex slick-dots',
  };

  const NavbarStyles = {
    padding: 18,
    fontSize: 16,
    background: '#fff',
    color: ' #fff',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  };

  return (
    <>
      <ChatbotHide show={false} debug={true} />
      <main>
        <Grid fluid>
          <Row className="auth-container">
            <Col xs={24} sm={24} md={10}>
              <Container>
                <Header>
                  <Navbar style={NavbarStyles}>
                    <Navbar.Header>
                      <Link href={'https://www.leni.co'} passHref>
                        <img className="logo1" src="/static/images/BigLogo.png"></img>
                      </Link>
                    </Navbar.Header>
                  </Navbar>
                </Header>
                <Content className="auth-content">
                  <FlexboxGrid justify="space-around">
                    <FlexboxGrid.Item componentClass={Col} xs={20} sm={18} md={15}>
                      <Content>{children}</Content>
                    </FlexboxGrid.Item>
                  </FlexboxGrid>
                </Content>
                {/* <Footer /> */}
              </Container>
            </Col>
            <Col xs={24} sm={24} md={14}>
              <div className="auth-bg">
                <Slider className="auth-bg-slider cs-bg-slider" {...settings}>
                  {images.map((item, i) => (
                    <div key={i}>
                      <div
                        className="cs-bg-item bg-cover bg-center"
                        style={{
                          backgroundImage: 'url(' + item.img + ')',
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                        }}></div>
                    </div>
                  ))}
                </Slider>
              </div>
            </Col>
          </Row>
        </Grid>
      </main>
      <Styles />
    </>
  );
}

AuthLayout.propTypes = {
  children: PropTypes.node,
};

export default AuthLayout;
