import React, { useState, useEffect, useContext } from 'react';
import {
  Modal,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  SelectPicker,
  Input,
  InputNumber,
  DatePicker,
  Button,
} from 'rsuite';
import { format } from 'date-fns';
import { addDays, isBefore } from 'date-fns'; // Import date-fns functions
import { UserApi } from 'services/api/user';
import { set } from 'lodash';
import Editor from 'containers/List-Property/steps/EditorCus';
import Ajv from 'ajv';
import addFormats from 'ajv-formats';

// Initialize AJV
const ajv = new Ajv.default({ allErrors: true });
addFormats(ajv);

const CampaignUpdateModal = ({ show, onClose }) => {
  const [campaignData, setCampaignData] = useState({
    campaign_manager: '',
    campaign_type: '',
    start_date: null,
    end_date: null,
    form_type: '',
    form_id: '',
    url: '',
    message: '',
    title: '',
    cta: '',
  });
  const [allCampaignsData, setAllCampaignsData] = useState(null); // [ { campaign_manager: '', campaign_type: '', start_date: '', end_date: '', form_type: '', form_id: '', url: '', message: '', title: '', cta: '' }
  const [searchByStatus, setSearchByStatus] = useState('upcoming');
  const [searchByManager, setSearchByManager] = useState(null);
  const [selectedCampaignId, setSelectedCampaignId] = useState(null);

  const [loading, setLoading] = useState(false);
  const [loadingCampaign, setLoadingCampaign] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [displayedCampaigns, setDisplayedCampaigns] = useState([]);

  // Validation
  const validate = () => {
    const validateSchema = ajv.compile({
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      properties: {
        campaign_type: {
          type: 'string',
        },
        // Add minLength property to other fields that should not be empty
        start_date: {
          type: 'string',
          format: 'date',
        },
        end_date: {
          type: 'string',
          format: 'date-time',
        },
        url: {
          anyOf: [
            { type: 'string', format: 'uri' },
            { type: 'null' }, // Allow null for "url"
          ],
        },
        message: {
          type: 'string',
        },
        title: {
          type: 'string',
        },
        cta: {
          type: 'string',
        },
        audience_type: {
          type: 'string',
        },
        audience: {
          type: 'array',
          items: {
            type: 'string',
          },
        },
        manager: {
          type: 'string',
        },
      },
    });

    // Rest of your validation logic remains the same

    const valid = validateSchema(campaignData);
    if (!valid) {
      const errors = validateSchema.errors;
      console.log(errors);
      setFormErrors(errors);
      setLoading(false);
      return false;
    }
    return true;
  };

  // Get all data on mount
  useEffect(() => {
    loadCampaigns();
  }, []);

  const loadCampaigns = () => {
    // Load campaigns here
    UserApi.getCampaign().then(res => {
      // console.log(res.data);
      setAllCampaignsData(res.data);
    });
  };

  // Filter campaigns by status and manager once manager is clicked
  useEffect(() => {
    if (allCampaignsData) {
      setDisplayedCampaigns(allCampaignsData[searchByStatus].filter(campaign => campaign.manager === searchByManager));
      // let filteredCampaigns = allCampaignsData.searchByStatus.filter(campaign => campaign.manager === searchByManager);
      // setDisplayedCampaigns(filteredCampaigns);
    }
  }, [searchByManager, searchByStatus]);

  // Reflect assignment of default values when campaign is selected
  useEffect(() => {
    setLoadingCampaign(true);
    if (selectedCampaignId) {
      const selectedCampaign = displayedCampaigns.find(campaign => campaign.id === selectedCampaignId);
      if (selectedCampaign) {
        assignDefaultValues(selectedCampaign);
      }
    }
  }, [selectedCampaignId]);

  // Assign default values after campaign is selected
  const assignDefaultValues = selectedCampaign => {
    setCampaignData({
      ...campaignData,
      campaign_manager: selectedCampaign.campaign_manager,
      title: selectedCampaign.title,
      url: selectedCampaign.url,
      campaign_type: selectedCampaign.campaign_type,
      start_date: selectedCampaign.start_date,
      end_date: selectedCampaign.end_date,
      cta: selectedCampaign.cta,
      message: selectedCampaign.message,
    });
    setLoadingCampaign(false);
  };

  const handleFieldChange = (name, value) => {
    if (name === 'start_date') {
      value = format(value, 'yyyy-MM-dd');
    }
    if (name === 'end_date') {
      value = format(value, 'yyyy-MM-dd');
      value = `${value}T23:59:59`;
    }
    setCampaignData({ ...campaignData, [name]: value });
  };

  const handleUpdate = () => {
    // Handle updating campaign data here
    // You can send the campaignData to your backend or perform any other actions.
    setLoading(true);
    // console.log('Not validated!');
    // console.log('cd', campaignData);
    // console.log('cdid', selectedCampaignId);
    UserApi.updateCampaign(campaignData, selectedCampaignId).then(res => {
      // console.log(res.data);
      setLoading(false);
      onClose();
    });
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header>
        <Modal.Title>Update Campaign</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <FormGroup>
            <ControlLabel>Campaign Manager</ControlLabel>
            <SelectPicker
              name="manager"
              searchable={true}
              data={[
                { label: 'Gaurav', value: 'Gaurav' },
                { label: 'Mike', value: 'Mike' },
                { label: 'Kinda', value: 'Kinda' },
                { label: 'Arunabh', value: 'Arunabh' },
                { label: 'Maithili', value: 'Maithili' },
                { label: 'Leon', value: 'Leon' },
                { label: 'Dev Team', value: 'Dev Team' },
              ]}
              style={{ width: 224 }}
              placeholder="Select your name"
              labelKey="label"
              valueKey="value"
              onChange={value => setSearchByManager(value)}
            />
          </FormGroup>

          <FormGroup>
            <ControlLabel>Campaign Status</ControlLabel>
            <div
              className="bg-white text-sm text-gray-500 leading-none rounded-full inline-flex"
              style={{ border: '1px solid #e5e5ea', borderColor: '#e5e5ea' }}>
              <button
                onClick={e => {
                  e.preventDefault();
                  setSearchByStatus('upcoming');
                  setSelectedCampaignId(null);
                }}
                className="inline-flex items-center focus:outline-none hover:text-blue-400 focus:text-blue-400 rounded-r-full px-4 py-2 active"
                style={{
                  background: searchByStatus === 'upcoming' ? '#ffb900' : '#fff',
                  borderRadius: '9999px',
                  color: searchByStatus === 'upcoming' ? '#fff' : '#8e8e93',
                }}>
                <span className="font-semibold">Upcoming</span>
              </button>
              <button
                onClick={e => {
                  e.preventDefault();
                  setSearchByStatus('active');
                  setSelectedCampaignId(null);
                }}
                className="inline-flex items-center focus:outline-none hover:text-blue-400 focus:text-blue-400 rounded-r-full px-4 py-2"
                style={{
                  background: searchByStatus === 'active' && '#50b350',
                  borderRadius: '9999px',
                  color: searchByStatus === 'active' ? '#fff' : '#8e8e93',
                }}>
                <span className="font-semibold">Active</span>
              </button>
              <button
                onClick={e => {
                  e.preventDefault();
                  setSearchByStatus('completed');
                  setSelectedCampaignId(null);
                }}
                className="inline-flex items-center focus:outline-none hover:text-blue-400 focus:text-blue-400 rounded-r-full px-4 py-2"
                style={{
                  background: searchByStatus === 'completed' && '#e6645b',
                  borderRadius: '9999px',
                  color: searchByStatus === 'completed' ? '#fff' : '#8e8e93',
                }}>
                <span className="font-semibold">Completed</span>
              </button>
            </div>
          </FormGroup>

          <FormGroup>
            <ControlLabel>Select Campaign by Title</ControlLabel>
            <FormControl
              name="title"
              searchable={true}
              accepter={SelectPicker}
              data={
                displayedCampaigns
                  ? displayedCampaigns.map(campaign => ({
                      label: campaign.title,
                      value: campaign.id,
                    }))
                  : []
              }
              style={{ width: 224 }}
              placeholder="Select campaign"
              onChange={value => {
                setSelectedCampaignId(value); // Assuming setSelectedCampaignId updates the selected campaign ID.
                setLoadingCampaign(true);
              }}
            />
          </FormGroup>
          {!loadingCampaign && (
            <FormGroup>
              <ControlLabel>Start Date</ControlLabel>
              <FormControl
                size="sm"
                oneTap
                name="start_date"
                style={{ width: '300px' }}
                value={campaignData.start_date ? campaignData.start_date : ''}
                format="YYYY-MM-DD"
                accepter={DatePicker}
                disabledDate={date => isBefore(date, addDays(new Date(), -1))}
                onChange={value => handleFieldChange('start_date', value)}
                cleanable={false}></FormControl>
            </FormGroup>
          )}
          {!loadingCampaign && (
            <FormGroup>
              <ControlLabel>End Date</ControlLabel>
              <FormControl
                size="sm"
                oneTap
                name="end_date"
                style={{ width: '300px' }}
                format="YYYY-MM-DD"
                value={campaignData.end_date ? campaignData.end_date : ''}
                accepter={DatePicker}
                disabledDate={date => isBefore(date, addDays(new Date(), 0))}
                onChange={value => handleFieldChange('end_date', value)}
                cleanable={false}></FormControl>
            </FormGroup>
          )}
          {!loadingCampaign && (
            <FormGroup>
              <ControlLabel>Button Text</ControlLabel>
              <Input name="cta" value={campaignData.cta} onChange={value => handleFieldChange('cta', value)} />
            </FormGroup>
          )}
          {!loadingCampaign && (
            <FormGroup>
              <ControlLabel>Campaign Message</ControlLabel>
              <Editor
                currentValue={campaignData.message ? campaignData.message : ''}
                onChange={value => handleFieldChange('message', value)}
              />
            </FormGroup>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose}>Cancel</Button>
        {!loadingCampaign && (
          <Button onClick={handleUpdate} appearance="primary">
            Update
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default CampaignUpdateModal;
