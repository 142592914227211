import { Alert, Button, Modal } from 'rsuite';
import ReservationForm from 'components/LandingPageReservationForm';
import '../WinnipegModal/styles.js';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { UserApi } from 'services/api';
import { useErrorTracker } from 'utils/use-error-tracker';

function FormModal({ show, setClose, dataModal }) {
  const errorTracker = useErrorTracker();
  const [width, setWidth] = useState(null);

  useEffect(() => {
    // only execute all the code below in client side
    if (typeof window !== 'undefined') {
      // Handler to call on window resize

      const handleResize = () => {
        setWidth(window.innerWidth);
      };

      window.addEventListener('resize', handleResize);
      handleResize();

      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);
  const onCancelClick = () => {
    UserApi.updateCurrentUser({ seenWelcomeModal: true }).catch(err => {
      errorTracker.error('Update current user error', { updateUserData: { seenWelcomeModal: true } });
      Alert.error('Something went wrong while updating user data.', 5000);
      console.error(err);
    });
  };

  return (
    <>
      <Modal
        dialogClassName="roomModal"
        backdropClassName="roomModalMobile"
        sss
        backdrop={'static'}
        show={show}
        onHide={() => setClose(false)}
        size={width < 600 ? 'xs' : width < 800 ? 'sm' : width < 1024 ? 'md' : 'lg'}>
        <Modal.Body>
          {/* RESERVATION FORM  */}
          <ReservationForm heading="TELL US ABOUT YOUR REQUIREMENTS & LET US FIND YOU A SOULROOM">
            <iframe
              id="JotFormIFrame-91797058303261"
              title="Find a SoulRoom"
              onLoad={() => {
                window.parent.scrollTo(0, 0);
              }}
              allowTransparency="true"
              allowFullScreen="true"
              allow="geolocation; microphone; camera"
              src="https://form.jotform.com/91797058303261"
              frameBorder="0"
              style={{ minWidth: '100%', height: '640px', border: 'none' }}
              scrolling="no"></iframe>
          </ReservationForm>
        </Modal.Body>
        <Modal.Footer style={{ textAlign: 'center', marginTop: '1em' }}>
          <Button
            onClick={() => {
              onCancelClick();
              setClose(false);
            }}
            size="xs"
            appearance="ghost">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

FormModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setClose: PropTypes.func.isRequired,
};

export default FormModal;
