import React, { useState } from 'react';
import {
  Button,
  Input,
  FlexboxGrid,
  FormGroup,
  FormControl,
  ControlLabel,
  Checkbox,
  CheckboxGroup,
  Grid,
  Col,
  Row,
  Panel,
} from 'rsuite';
import PropTypes from 'prop-types';
import slugify from 'slugify';

function CheckboxComponent({ options, shadow, label, canAddMore = true, inPanel = true, ...props }) {
  const [newOption, setNewOption] = useState('');
  const [curValue, setCurValue] = useState('');
  const allCheckboxOptions = options.map(option => {
    return (
      <Col key={option} xs={12}>
        <Checkbox value={slugify(`${option || ''}`, { lower: true })}>{option}</Checkbox>
      </Col>
    );
  });
  const InputValue = val => {
    setNewOption(val);
    setCurValue(val);
    console.log('onchange', curValue);
  };
  const AddInputValue = () => {
    props.addCustomOption(newOption);
    setCurValue('');

    console.log('after added value: ', curValue);
  };
  return (
    <>
      {inPanel ? (
        <Panel shaded={shadow ? false : true}>
          <FormGroup>
            <ControlLabel>{label}</ControlLabel>
            <FormControl {...props} accepter={CheckboxGroup}>
              <Grid fluid>
                <Row>{allCheckboxOptions}</Row>
              </Grid>
              <FlexboxGrid style={{ marginLeft: '13px' }}>
                {canAddMore && (
                  <>
                    <FlexboxGrid.Item colspan={12}>
                      <Input
                        defaultValue=""
                        value={curValue}
                        id="cus-input"
                        size="xs"
                        onChange={val => InputValue(val)}
                        placeholder="Add other..."
                      />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item style={{ marginLeft: '10px' }} colspan={5}>
                      <Button size="sm" appearance="ghost" onClick={() => AddInputValue()}>
                        Add
                      </Button>
                    </FlexboxGrid.Item>
                  </>
                )}
              </FlexboxGrid>
            </FormControl>
          </FormGroup>
        </Panel>
      ) : (
        <FormGroup>
          <ControlLabel>{label}</ControlLabel>
          <FormControl {...props} accepter={CheckboxGroup}>
            <Grid fluid>
              <Row>{allCheckboxOptions}</Row>
            </Grid>
            <FlexboxGrid style={{ marginLeft: '13px' }}>
              {canAddMore && (
                <>
                  <FlexboxGrid.Item colspan={12}>
                    <Input
                      defaultValue=""
                      value={curValue}
                      id="cus-input"
                      size="xs"
                      onChange={val => InputValue(val)}
                      placeholder="Add other..."
                    />
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item style={{ marginLeft: '10px' }} colspan={5}>
                    <Button size="sm" appearance="ghost" onClick={() => AddInputValue()}>
                      Add
                    </Button>
                  </FlexboxGrid.Item>
                </>
              )}
            </FlexboxGrid>
          </FormControl>
        </FormGroup>
      )}
    </>
  );
}

Checkbox.prototypes = {
  options: PropTypes.array,
  label: PropTypes.string,
};

export default CheckboxComponent;
