import React from 'react';
import styled from '@emotion/styled';
import Step6Form from './StepsForms/Step6Form';

const Step6 = ({ roomieProfileData, setroomieProfileData, formErrors, setFormErrors }) => {
  const FormTitle = styled('h3')`
    font-size: 24px;
    font-weight: 600;
  `;

  return (
    <>
      <FormTitle>Smoking</FormTitle>
      <Step6Form
        space={true}
        roomieProfileData={roomieProfileData}
        setroomieProfileData={setroomieProfileData}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
      />
    </>
  );
};

export default Step6;
