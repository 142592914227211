import { Button, Popover, Divider } from 'rsuite';
import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@rsuite/icons/Close';
import useWindowSize from 'utils/detect-windows-size';
import Styles from './styles.js';
import SagePlus from 'containers/SagePlus';

export function ChatAssistantPopover ({ ...props }) {
  const width = useWindowSize()[0];
  const chatContainerRef = useRef(null);

  return (
    <div>
      <style>
        {`
        .rs-popover-arrow{
          left: 94% !important;
        }
        `}
      </style>
      <Popover
        {...props}
        style={{ width: `${width > 991 ? '100%' : 'calc(100% + 7rem)'}` }}>
        <div className='flex flex-row items-center'>
          <div className='flex-auto text-xl font-bold flex justify-center flex-col text-[#ffb900]'>Sage+</div>
          <Button appearance='subtle' style={{ flex: '0 0 auto' }} onClick={props.onCloseButtonClicked}>
            <CloseIcon />
          </Button>
        </div>
        <Divider className='m-0'></Divider>
        <div  ref={chatContainerRef}>
          <SagePlus maxHeight={600} height={600} containerRef={chatContainerRef} />
        </div>
      </Popover>
      <Styles />
    </div>
  );
}

ChatAssistantPopover.propTypes = {
  showcasesData: PropTypes.object,
  getShowcases: PropTypes.func,
};

export default ChatAssistantPopover;
