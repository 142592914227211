export default function Style(){
  return (
    <style>
      {`
  
          .questionFeedback .rs-btn-default.rs-btn-disabled{
            background-color:#feebc1;
          }
        `}
    </style>
  );
}
