import Styles from './styles.js';

import { Button, Container, Content, Dropdown, FlexboxGrid, Header, Nav, Navbar } from 'rsuite';
import { Col, Grid, Icon, Row } from 'rsuite';
import React, { useState, useEffect } from 'react';

import Footer from 'components/Footer';
import { Link } from 'utils/with-i18next';
import PropTypes from 'prop-types';
// import SlackFeedback from 'components/SlackFeedback';
import AvatarComponent from 'components/Avatar';
import { isEmpty } from 'lodash';
import ChatbotHide from 'components/ChatbotHide';
import detectWindowScreenSize from '../../utils/detect-windows-size';
import Popup from '../../containers/Popup/LoginPopup';
import { getDefaultRedirectPage } from '../../utils/getDefaultRedirectPage';

function GenericLayout({ children, currentUser, handleLogout }) {
  const [width] = detectWindowScreenSize();
  const NavbarStyles = {
    padding: 18,
    fontSize: 16,
    background: '#f8f8f8',
    whiteSpace: 'nowrap',
  };
  const [isOpen, setIsOpen] = useState(false);
  // Manage log in of current client base
  const togglePopup = () => {
    !isOpen
      ? (document.getElementById('app-root').style.filter = 'blur(0px)')
      : (document.getElementById('app-root').style.filter = 'blur(10px)');
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (currentUser && isOpen) {
      setIsOpen(false);
      document.getElementById('app-root').style.filter = 'blur(0px)';
    }
  }, [currentUser]);

  return (
    <>
      <ChatbotHide show={false} />
      <main id="app-root">
        <Grid fluid>
          <Row className="generic-container">
            <Col md={24}>
              <Container style={{ minHeight: '100vh' }}>
                <Header>
                  <Navbar style={NavbarStyles}>
                    <Navbar.Header>
                      <Link href={'/'} passHref>
                        <img className="cursor-pointer h-1/2 mx-0 sm:mx-[30px]" src="/static/images/BigLogo.png"></img>
                      </Link>
                    </Navbar.Header>
                    {!isEmpty(currentUser?.email) && (
                      <Navbar.Body>
                        <Nav pullRight>
                          <Dropdown
                            placement="bottomEnd"
                            renderTitle={() => {
                              return (
                                currentUser?.displayName && (
                                  <AvatarComponent
                                    style={{
                                      margin: '3px',
                                      backgroundColor: '#fff',
                                    }}
                                    name={currentUser?.displayName}
                                  />
                                )
                              );
                            }}>
                            <Dropdown.Item
                              panel
                              style={{
                                padding: 10,
                                width: 160,
                              }}>
                              <p>Signed in as</p>
                              <strong>{currentUser?.displayName}</strong>
                            </Dropdown.Item>
                            <Dropdown.Item divider />
                            <Dropdown.Item onSelect={handleLogout}>Sign Out</Dropdown.Item>
                          </Dropdown>
                        </Nav>

                        <Nav pullRight style={{ zIndex: 100 }}>
                          <Link href={getDefaultRedirectPage(currentUser)} passHref>
                            <a
                              className="go-to-dashboard"
                              style={{
                                padding: width >= 500 ? '30px' : '15px',
                                lineHeight: '40px',
                                textDecoration: 'none',
                              }}>
                              <Icon icon="angle-double-left go-to-dashboard"></Icon>{' '}
                              {width >= 500 ? 'Go to Dashboard' : 'Dashboard'}
                            </a>
                          </Link>
                        </Nav>
                      </Navbar.Body>
                    )}
                    {isEmpty(currentUser?.email) && (
                      <Navbar.Body>
                        <Nav pullRight>
                          <Button className="stay-on-page" onClick={togglePopup}>
                            Log in
                          </Button>
                        </Nav>
                      </Navbar.Body>
                    )}
                    <div id="dialog-target" style={{ width: '60%' }}>
                      {isOpen && <Popup setIsOpen={setIsOpen} handleClose={togglePopup} />}
                    </div>
                  </Navbar>
                </Header>
                <Content className="generic-content">
                  <FlexboxGrid justify="center">
                    <FlexboxGrid.Item colspan={18}>
                      <Content>{children}</Content>
                    </FlexboxGrid.Item>
                  </FlexboxGrid>
                </Content>
                <Footer />
              </Container>
            </Col>
          </Row>
        </Grid>
        {/* <SlackFeedback user={currentUser}></SlackFeedback> */}
      </main>
      <Styles />
    </>
  );
}

GenericLayout.propTypes = {
  children: PropTypes.node,
};

export default GenericLayout;
