
export default function Styles() {
  return (
    <style>
        {`
            .dashboard-filters span.rs-picker-toggle-clean {
                right: 34px !important;
               
            }
            .dashboard-filters .rs-picker-default .rs-picker-toggle.rs-btn {
                padding-top: 12px !important;
                padding-bottom: 12px;
                px: ;
            }
             .dashboard-filters .rs-picker-default:not(.rs-picker-disabled) .rs-btn.active, .rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle.active, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover {
                border-color: #e0ac00 !important;
            }
            .dashboard-filters .rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-caret, .rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
                top: 12px !important;
            }
            .dashboard-filters .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
                right: 18px !important;
            }
            .dashboard-filters .rs-picker-select-menu-item.rs-picker-select-menu-item-active, .rs-picker-select-menu-item.rs-picker-select-menu-item-active:hover {
                color: #e0ac00;
                background-color: #fffbf2 !important;
            }
            .rs-picker-select-menu-item.rs-picker-select-menu-item-active, .rs-picker-select-menu-item.rs-picker-select-menu-item-active:hover {
                color: #e0ac00 !important;
                background-color: #fffbf2 !important;
            }
            .dashboard-filters .rs-picker-select-menu-item.rs-picker-select-menu-item-focus, .rs-picker-select-menu-item:not(.rs-picker-select-menu-item-disabled):hover {
                color: #575757;
                background-color: #fffbf2 !important;
                text-decoration: none;
            }
            .dashboard-filters .rs-btn-link:focus, .rs-btn-link:hover {
                color: #e0ac00 !important;
                
            }
             .rs-nav.rs-nav-default.rs-navbar-nav.rs-navbar-right.rs-nav-horizontal {
                color: inherit !important;
            }
            .dashboard-filters .rs-dropdown-menu>.rs-dropdown-item>.rs-dropdown-item-content:focus, .rs-dropdown-menu>.rs-dropdown-item>.rs-dropdown-item-content:hover {
                text-decoration: none;
                color: #575757;
                background-color: #fffbf2 !important;
            }
             .rs-calendar-table-cell-content:hover {
                background-color: #fffbf2  ;
            }
            .rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
                border: 1px solid #e0ac00;
                padding: 4px;
            }
            .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
                color: #fff;
                border-color: #e0ac00;
                background-color: #ffb900 !important;
            }
            .rs-calendar-table-cell-in-range:before {
                background-color: #fffbf2;
            }
             a.rs-picker-toolbar-option {
                color: #c9a100 !important;
            }
            a.rs-picker-toolbar-option:hover{
                color: #b39200 !important;
            }
             button.rs-picker-toolbar-right-btn-ok.rs-picker-toolbar-btn-disabled {
                background-color: #ffb900 !important;
            }
            button.rs-picker-toolbar-right-btn-ok {
                background-color: #ffb900;
            }
            button.rs-picker-toolbar-right-btn-ok:hover {
                background-color: #e0ac00 !important;
            }
            // date picker modal ends here

            // select building starts modal
            .dashboard-filters input.rs-picker-search-bar-input {
                padding: 5px 12px !important;
            }
            .dashboard-filters .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:focus, .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:hover {
                border-color: #e0ac00;
                outline: 0;
            }
             .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:focus, .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:hover {
                border-color: #e0ac00;
                outline: 0;
            }
             .rs-picker-menu .rs-picker-search-bar:after{
                top:19px !important;
            }
            .rs-picker-toggle-value{
                margin-right: 8px;
            }
        `}
    </style>
  )
}
// 

