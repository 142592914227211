export default {
  'Portfolio-Boston': {
    'Troy Boston': {
      'Net Rental Income': {
        Actual: [
          '$1,084,899.20 ',
          '$1,090,879.98 ',
          '$1,074,636.91 ',
          '$1,092,021.69 ',
          '$1,097,481.80 ',
          '$1,114,319.15 ',
          '$1,109,376.24 ',
          '$1,128,800.47 ',
          '$1,134,444.47 ',
          '$1,136,078.52 ',
          '$1,156,625.70 ',
          '$1,152,756.37 ',
          '$1,154,975.45 ',
          '$1,165,791.36 ',
          '$1,176,719.55 ',
          '$1,185,597.12 ',
          '$1,188,542.42 ',
          '$1,187,996.44 ',
          '$1,186,527.58 ',
          '$1,182,065.25 ',
          '$1,178,053.06 ',
          '$1,177,889.71 ',
          '$1,178,071.21 ',
          '$1,175,137.67 ',
          '$1,174,849.36 ',
          '$1,177,988.58 ',
          '$1,170,858.59 ',
          '$1,170,580.45 ',
          '$1,170,305.36 ',
          '$1,173,454.42 ',
          '$1,173,184.53 ',
          '$1,176,337.17 ',
          '$1,176,072.39 ',
          '$1,175,810.49 ',
          '$1,175,551.47 ',
          '$1,174,367.94 ',
          '$1,176,655.25 ',
          '$1,178,993.54 ',
          '$1,177,975.81 ',
          '$1,180,411.63 ',
          '$1,179,492.20 ',
          '$1,178,619.55 ',
          '$1,177,791.30 ',
          '$1,177,005.20 ',
          '$1,178,613.96 ',
          '$1,180,262.55 ',
          '$1,181,949.26 ',
          '$1,182,501.90 ',
          '$1,186,623.83 ',
          '$1,189,612.35 ',
          '$1,192,636.76 ',
          '$1,195,695.92 ',
          '$1,198,788.72 ',
          '$1,201,914.10 ',
          '$1,201,598.24 ',
          '$1,208,258.66 ',
          '$1,211,475.98 ',
          '$1,214,722.16 ',
          '$1,217,996.39 ',
          '$1,221,297.88 ',
        ],
        Budgeted: [
          '$1,103,263.04 ',
          '$1,103,151.03 ',
          '$1,114,323.25 ',
          '$1,108,525.38 ',
          '$1,114,068.00 ',
          '$1,113,896.61 ',
          '$1,119,466.09 ',
          '$1,127,025.74 ',
          '$1,132,660.87 ',
          '$1,136,693.63 ',
          '$1,145,873.81 ',
          '$1,152,751.83 ',
          '$1,151,225.39 ',
          '$1,157,237.57 ',
          '$1,163,278.51 ',
          '$1,172,096.54 ',
          '$1,173,921.73 ',
          '$1,172,996.90 ',
          '$1,177,034.30 ',
          '$1,178,077.41 ',
          '$1,178,895.46 ',
          '$1,182,263.93 ',
          '$1,185,452.73 ',
          '$1,185,724.87 ',
          '$1,184,670.20 ',
          '$1,186,364.53 ',
          '$1,179,812.76 ',
          '$1,178,759.44 ',
          '$1,177,705.77 ',
          '$1,179,391.37 ',
          '$1,178,334.62 ',
          '$1,180,012.26 ',
          '$1,178,952.49 ',
          '$1,177,892.46 ',
          '$1,176,832.18 ',
          '$1,176,236.95 ',
          '$1,178,337.16 ',
          '$1,180,406.86 ',
          '$1,179,725.91 ',
          '$1,181,739.97 ',
          '$1,181,009.14 ',
          '$1,180,256.06 ',
          '$1,179,481.93 ',
          '$1,178,687.88 ',
          '$1,180,233.09 ',
          '$1,178,702.08 ',
          '$1,180,211.38 ',
          '$1,181,705.97 ',
          '$1,185,550.64 ',
          '$1,189,392.73 ',
          '$1,193,232.88 ',
          '$1,197,071.73 ',
          '$1,200,909.88 ',
          '$1,207,875.31 ',
          '$1,208,934.93 ',
          '$1,215,573.06 ',
          '$1,219,423.86 ',
          '$1,223,276.59 ',
          '$1,227,131.71 ',
          '$1,230,989.65 ',
        ],
        Variance: [
          '($18,363.84)',
          '($12,271.05)',
          '($39,686.34)',
          '($16,503.69)',
          '($16,586.20)',
          '$422.54 ',
          '($10,089.85)',
          '$1,774.72 ',
          '$1,783.60 ',
          '($615.11)',
          '$10,751.89 ',
          '$4.54 ',
          '$3,750.05 ',
          '$8,553.79 ',
          '$13,441.04 ',
          '$13,500.58 ',
          '$14,620.69 ',
          '$14,999.54 ',
          '$9,493.27 ',
          '$3,987.84 ',
          '($842.40)',
          '($4,374.23)',
          '($7,381.52)',
          '($10,587.20)',
          '($9,820.84)',
          '($8,375.95)',
          '($8,954.17)',
          '($8,178.98)',
          '($7,400.41)',
          '($5,936.94)',
          '($5,150.09)',
          '($3,675.08)',
          '($2,880.10)',
          '($2,081.96)',
          '($1,280.71)',
          '($1,869.01)',
          '($1,681.91)',
          '($1,413.32)',
          '($1,750.11)',
          '($1,328.34)',
          '($1,516.94)',
          '($1,636.51)',
          '($1,690.62)',
          '($1,682.68)',
          '($1,619.13)',
          '$1,560.47 ',
          '$1,737.88 ',
          '$795.94 ',
          '$1,073.19 ',
          '$219.62 ',
          '($596.12)',
          '($1,375.81)',
          '($2,121.16)',
          '($5,961.20)',
          '($7,336.69)',
          '($7,314.40)',
          '($7,947.88)',
          '($8,554.43)',
          '($9,135.32)',
          '($9,691.77)',
        ],
      },
      'Capital Expenditure': {
        'Net Operating Income': [
          '$599,678.03 ',
          '$602,983.91 ',
          '$594,005.55 ',
          '$603,614.99 ',
          '$606,633.07 ',
          '$615,939.91 ',
          '$613,207.72 ',
          '$623,944.46 ',
          '$627,064.18 ',
          '$627,967.40 ',
          '$639,324.86 ',
          '$637,186.08 ',
          '$638,412.68 ',
          '$644,391.17 ',
          '$650,431.73 ',
          '$655,338.81 ',
          '$656,966.82 ',
          '$656,665.03 ',
          '$655,853.12 ',
          '$653,386.57 ',
          '$651,168.83 ',
          '$651,078.54 ',
          '$651,178.86 ',
          '$649,557.35 ',
          '$649,397.98 ',
          '$651,133.19 ',
          '$647,192.09 ',
          '$647,038.35 ',
          '$646,886.29 ',
          '$648,626.93 ',
          '$648,477.75 ',
          '$650,220.37 ',
          '$650,074.01 ',
          '$649,929.25 ',
          '$649,786.07 ',
          '$649,131.88 ',
          '$650,396.19 ',
          '$651,688.68 ',
          '$651,126.13 ',
          '$593,156.84 ',
          '$592,694.83 ',
          '$592,256.32 ',
          '$591,840.13 ',
          '$591,445.11 ',
          '$592,253.51 ',
          '$593,081.93 ',
          '$593,929.50 ',
          '$594,207.21 ',
          '$596,278.48 ',
          '$597,780.20 ',
          '$659,229.97 ',
          '$660,920.92 ',
          '$662,630.47 ',
          '$664,358.02 ',
          '$664,183.43 ',
          '$667,864.97 ',
          '$669,643.35 ',
          '$671,437.68 ',
          '$673,247.50 ',
          '$675,072.40 ',
        ],
        Occupancy: [
          '97.14%',
          '97.14%',
          '97.14%',
          '97.14%',
          '97.14%',
          '97.14%',
          '97.14%',
          '97.43%',
          '97.43%',
          '97.14%',
          '97.14%',
          '96.86%',
          '96.57%',
          '96.57%',
          '96.57%',
          '96.86%',
          '97.14%',
          '97.14%',
          '97.43%',
          '97.43%',
          '97.43%',
          '97.71%',
          '98.00%',
          '98.00%',
          '98.00%',
          '98.29%',
          '97.71%',
          '97.71%',
          '97.71%',
          '98.00%',
          '98.00%',
          '98.29%',
          '98.29%',
          '98.29%',
          '98.29%',
          '98.29%',
          '98.57%',
          '98.86%',
          '98.86%',
          '99.14%',
          '99.14%',
          '99.14%',
          '99.14%',
          '99.14%',
          '99.14%',
          '99.14%',
          '99.14%',
          '99.14%',
          '99.14%',
          '99.14%',
          '99.14%',
          '99.14%',
          '99.14%',
          '99.14%',
          '98.86%',
          '99.14%',
          '99.14%',
          '99.14%',
          '99.14%',
          '99.14%',
        ],
        'Total expenses': [
          '$485,221.17 ',
          '$487,896.07 ',
          '$480,631.36 ',
          '$488,406.70 ',
          '$490,848.74 ',
          '$498,379.24 ',
          '$496,168.52 ',
          '$504,856.01 ',
          '$507,380.29 ',
          '$508,111.12 ',
          '$517,300.84 ',
          '$515,570.29 ',
          '$516,562.77 ',
          '$521,400.18 ',
          '$526,287.82 ',
          '$530,258.31 ',
          '$531,575.60 ',
          '$531,331.41 ',
          '$530,674.46 ',
          '$528,678.68 ',
          '$526,884.23 ',
          '$526,811.17 ',
          '$526,892.35 ',
          '$525,580.32 ',
          '$525,451.37 ',
          '$526,855.39 ',
          '$523,666.51 ',
          '$523,542.11 ',
          '$523,419.07 ',
          '$524,827.49 ',
          '$524,706.78 ',
          '$526,116.80 ',
          '$525,998.37 ',
          '$525,881.24 ',
          '$525,765.39 ',
          '$525,236.06 ',
          '$526,259.06 ',
          '$527,304.86 ',
          '$526,849.68 ',
          '$587,254.79 ',
          '$586,797.37 ',
          '$586,363.23 ',
          '$585,951.17 ',
          '$585,560.09 ',
          '$586,360.44 ',
          '$587,180.62 ',
          '$588,019.76 ',
          '$588,294.70 ',
          '$590,345.36 ',
          '$591,832.14 ',
          '$533,406.79 ',
          '$534,775.00 ',
          '$536,158.26 ',
          '$537,556.08 ',
          '$537,414.81 ',
          '$540,393.69 ',
          '$541,832.63 ',
          '$543,284.49 ',
          '$544,748.89 ',
          '$546,225.48 ',
        ],
        'Total Sq.ft': [
          '283850',
          '283850',
          '283850',
          '283850',
          '283850',
          '283850',
          '283850',
          '283850',
          '283850',
          '283850',
          '283850',
          '283850',
          '283850',
          '283850',
          '283850',
          '283850',
          '283850',
          '283850',
          '283850',
          '283850',
          '283850',
          '283850',
          '283850',
          '283850',
          '283850',
          '283850',
          '283850',
          '283850',
          '283850',
          '283850',
          '283850',
          '283850',
          '283850',
          '283850',
          '283850',
          '283850',
          '283850',
          '283850',
          '283850',
          '283850',
          '283850',
          '283850',
          '283850',
          '283850',
          '283850',
          '283850',
          '283850',
          '283850',
          '283850',
          '283850',
          '283850',
          '283850',
          '283850',
          '283850',
          '283850',
          '283850',
          '283850',
          '283850',
          '283850',
          '283850',
        ],
        'Total # of units': [
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
          '350',
        ],
        'Net Eff Rent/Sft': [
          '$4.01 ',
          '$4.03 ',
          '$4.06 ',
          '$4.08 ',
          '$4.10 ',
          '$4.12 ',
          '$4.14 ',
          '$4.16 ',
          '$4.18 ',
          '$4.20 ',
          '$4.22 ',
          '$4.24 ',
          '$4.26 ',
          '$4.28 ',
          '$4.31 ',
          '$4.33 ',
          '$4.32 ',
          '$4.32 ',
          '$4.31 ',
          '$4.31 ',
          '$4.31 ',
          '$4.30 ',
          '$4.30 ',
          '$4.29 ',
          '$4.29 ',
          '$4.28 ',
          '$4.28 ',
          '$4.28 ',
          '$4.27 ',
          '$4.27 ',
          '$4.26 ',
          '$4.26 ',
          '$4.25 ',
          '$4.25 ',
          '$4.25 ',
          '$4.24 ',
          '$4.24 ',
          '$4.23 ',
          '$4.23 ',
          '$4.22 ',
          '$4.22 ',
          '$4.22 ',
          '$4.21 ',
          '$4.21 ',
          '$4.21 ',
          '$4.22 ',
          '$4.22 ',
          '$4.22 ',
          '$4.24 ',
          '$4.25 ',
          '$4.26 ',
          '$4.27 ',
          '$4.29 ',
          '$4.30 ',
          '$4.31 ',
          '$4.33 ',
          '$4.34 ',
          '$4.35 ',
          '$4.37 ',
          '$4.38 ',
        ],
      },
    },
    'Parkway Apartments': {
      'Net Rental Income': {
        Actual: [
          '$1,292,306.40 ',
          '$1,293,362.84 ',
          '$1,268,155.24 ',
          '$1,282,652.91 ',
          '$1,286,214.50 ',
          '$1,299,848.70 ',
          '$1,288,039.46 ',
          '$1,300,645.91 ',
          '$1,304,249.06 ',
          '$1,297,444.20 ',
          '$1,314,740.71 ',
          '$1,304,839.98 ',
          '$1,301,865.17 ',
          '$1,304,673.69 ',
          '$1,317,274.01 ',
          '$1,317,116.00 ',
          '$1,321,488.16 ',
          '$1,325,873.11 ',
          '$1,325,332.21 ',
          '$1,322,073.23 ',
          '$1,322,548.98 ',
          '$1,320,221.72 ',
          '$1,321,526.70 ',
          '$1,323,185.32 ',
          '$1,324,588.70 ',
          '$1,329,231.07 ',
          '$1,330,647.58 ',
          '$1,332,068.89 ',
          '$1,336,739.50 ',
          '$1,341,421.84 ',
          '$1,342,864.44 ',
          '$1,344,311.77 ',
          '$1,345,763.82 ',
          '$1,350,482.60 ',
          '$1,351,947.56 ',
          '$1,352,349.30 ',
          '$1,352,819.13 ',
          '$1,353,353.76 ',
          '$1,353,950.09 ',
          '$1,357,877.15 ',
          '$1,358,589.86 ',
          '$1,359,355.95 ',
          '$1,360,172.88 ',
          '$1,361,038.23 ',
          '$1,365,231.52 ',
          '$1,366,189.24 ',
          '$1,367,188.93 ',
          '$1,366,875.55 ',
          '$1,367,951.58 ',
          '$1,367,708.71 ',
          '$1,367,498.30 ',
          '$1,367,318.73 ',
          '$1,367,168.48 ',
          '$1,363,759.91 ',
          '$1,360,378.27 ',
          '$1,363,593.59 ',
          '$1,363,546.83 ',
          '$1,366,808.32 ',
          '$1,366,805.74 ',
          '$1,366,823.68 ',
        ],
        Budgeted: [
          '$1,318,142.76 ',
          '$1,311,854.46 ',
          '$1,318,952.43 ',
          '$1,305,962.78 ',
          '$1,308,270.60 ',
          '$1,301,960.77 ',
          '$1,302,360.02 ',
          '$1,302,759.28 ',
          '$1,305,058.18 ',
          '$1,302,060.12 ',
          '$1,306,445.63 ',
          '$1,308,521.73 ',
          '$1,310,230.41 ',
          '$1,309,002.03 ',
          '$1,313,530.00 ',
          '$1,314,216.56 ',
          '$1,316,822.81 ',
          '$1,319,428.41 ',
          '$1,324,534.21 ',
          '$1,327,440.40 ',
          '$1,332,033.97 ',
          '$1,334,471.07 ',
          '$1,338,649.19 ',
          '$1,342,651.54 ',
          '$1,343,209.60 ',
          '$1,345,713.75 ',
          '$1,346,269.84 ',
          '$1,346,824.56 ',
          '$1,349,327.81 ',
          '$1,351,831.32 ',
          '$1,352,383.59 ',
          '$1,352,934.55 ',
          '$1,353,484.19 ',
          '$1,355,986.41 ',
          '$1,356,534.26 ',
          '$1,357,617.87 ',
          '$1,358,668.32 ',
          '$1,359,687.24 ',
          '$1,360,676.22 ',
          '$1,363,598.74 ',
          '$1,364,533.55 ',
          '$1,365,442.62 ',
          '$1,366,327.23 ',
          '$1,367,188.59 ',
          '$1,369,996.25 ',
          '$1,370,815.71 ',
          '$1,371,615.20 ',
          '$1,372,395.70 ',
          '$1,373,158.16 ',
          '$1,373,903.47 ',
          '$1,374,632.50 ',
          '$1,375,346.04 ',
          '$1,376,044.88 ',
          '$1,374,751.67 ',
          '$1,373,443.25 ',
          '$1,376,080.27 ',
          '$1,376,726.28 ',
          '$1,379,342.73 ',
          '$1,379,967.43 ',
          '$1,380,581.85 ',
        ],
        Variance: [
          '($25,836.36)',
          '($18,491.62)',
          '($50,797.19)',
          '($23,309.87)',
          '($22,056.10)',
          '($2,112.07)',
          '($14,320.57)',
          '($2,113.37)',
          '($809.12)',
          '($4,615.93)',
          '$8,295.08 ',
          '($3,681.75)',
          '($8,365.24)',
          '($4,328.33)',
          '$3,744.01 ',
          '$2,899.44 ',
          '$4,665.35 ',
          '$6,444.70 ',
          '$798.00 ',
          '($5,367.17)',
          '($9,484.98)',
          '($14,249.35)',
          '($17,122.50)',
          '($19,466.22)',
          '($18,620.90)',
          '($16,482.68)',
          '($15,622.26)',
          '($14,755.67)',
          '($12,588.31)',
          '($10,409.47)',
          '($9,519.16)',
          '($8,622.78)',
          '($7,720.37)',
          '($5,503.81)',
          '($4,586.70)',
          '($5,268.57)',
          '($5,849.19)',
          '($6,333.48)',
          '($6,726.13)',
          '($5,721.59)',
          '($5,943.69)',
          '($6,086.67)',
          '($6,154.35)',
          '($6,150.36)',
          '($4,764.74)',
          '($4,626.48)',
          '($4,426.27)',
          '($5,520.15)',
          '($5,206.58)',
          '($6,194.76)',
          '($7,134.20)',
          '($8,027.31)',
          '($8,876.40)',
          '($10,991.76)',
          '($13,064.98)',
          '($12,486.67)',
          '($13,179.45)',
          '($12,534.41)',
          '($13,161.69)',
          '($13,758.17)',
        ],
      },
      'Capital Expenditure': {
        'Net Operating Income': [
          '$714,322.36 ',
          '$714,906.31 ',
          '$700,972.81 ',
          '$708,986.40 ',
          '$710,955.06 ',
          '$718,491.37 ',
          '$711,963.81 ',
          '$718,932.03 ',
          '$720,923.67 ',
          '$717,162.28 ',
          '$726,722.93 ',
          '$721,250.30 ',
          '$719,605.97 ',
          '$721,158.38 ',
          '$728,123.21 ',
          '$728,035.87 ',
          '$730,452.58 ',
          '$732,876.36 ',
          '$732,577.38 ',
          '$730,775.98 ',
          '$731,038.95 ',
          '$729,752.56 ',
          '$730,473.88 ',
          '$731,390.69 ',
          '$732,166.41 ',
          '$734,732.48 ',
          '$735,515.45 ',
          '$736,301.08 ',
          '$738,882.76 ',
          '$741,470.92 ',
          '$742,268.32 ',
          '$743,068.33 ',
          '$743,870.95 ',
          '$746,479.26 ',
          '$747,289.01 ',
          '$747,511.08 ',
          '$747,770.77 ',
          '$748,066.29 ',
          '$748,395.91 ',
          '$750,566.60 ',
          '$750,960.54 ',
          '$683,076.37 ',
          '$751,835.56 ',
          '$752,313.88 ',
          '$754,631.72 ',
          '$755,161.10 ',
          '$755,713.68 ',
          '$755,540.46 ',
          '$756,135.24 ',
          '$756,000.99 ',
          '$755,884.69 ',
          '$755,785.43 ',
          '$687,002.16 ',
          '$685,289.35 ',
          '$683,590.08 ',
          '$685,205.78 ',
          '$685,182.28 ',
          '$686,821.18 ',
          '$686,819.89 ',
          '$686,828.90 ',
        ],
        Occupancy: [
          '96.43%',
          '96.43%',
          '96.43%',
          '96.43%',
          '96.67%',
          '96.67%',
          '96.67%',
          '96.67%',
          '96.90%',
          '96.43%',
          '96.43%',
          '96.19%',
          '95.95%',
          '95.71%',
          '96.19%',
          '96.19%',
          '96.43%',
          '96.67%',
          '96.90%',
          '96.90%',
          '97.14%',
          '97.14%',
          '97.38%',
          '97.62%',
          '97.62%',
          '97.86%',
          '97.86%',
          '97.86%',
          '98.10%',
          '98.33%',
          '98.33%',
          '98.33%',
          '98.33%',
          '98.57%',
          '98.57%',
          '98.57%',
          '98.57%',
          '98.57%',
          '98.57%',
          '98.81%',
          '98.81%',
          '98.81%',
          '98.81%',
          '98.81%',
          '99.05%',
          '99.05%',
          '99.05%',
          '99.05%',
          '99.05%',
          '99.05%',
          '99.05%',
          '99.05%',
          '99.05%',
          '98.81%',
          '98.57%',
          '98.81%',
          '98.81%',
          '99.05%',
          '99.05%',
          '99.05%',
        ],
        'Total expenses': [
          '$577,984.04 ',
          '$578,456.53 ',
          '$567,182.43 ',
          '$573,666.51 ',
          '$575,259.43 ',
          '$581,357.33 ',
          '$576,075.65 ',
          '$581,713.88 ',
          '$583,325.39 ',
          '$580,281.92 ',
          '$588,017.78 ',
          '$583,589.68 ',
          '$582,259.20 ',
          '$583,515.31 ',
          '$589,150.80 ',
          '$589,080.13 ',
          '$591,035.58 ',
          '$592,996.75 ',
          '$592,754.83 ',
          '$591,297.25 ',
          '$591,510.03 ',
          '$590,469.17 ',
          '$591,052.81 ',
          '$591,794.64 ',
          '$592,422.30 ',
          '$594,498.60 ',
          '$595,132.13 ',
          '$595,767.81 ',
          '$597,856.74 ',
          '$599,950.92 ',
          '$600,596.12 ',
          '$601,243.44 ',
          '$601,892.87 ',
          '$604,003.34 ',
          '$604,658.55 ',
          '$604,838.23 ',
          '$605,048.36 ',
          '$605,287.47 ',
          '$605,554.18 ',
          '$607,310.56 ',
          '$607,629.31 ',
          '$676,279.59 ',
          '$608,337.32 ',
          '$608,724.35 ',
          '$610,599.80 ',
          '$611,028.14 ',
          '$611,475.25 ',
          '$611,335.09 ',
          '$611,816.34 ',
          '$611,707.72 ',
          '$611,613.62 ',
          '$611,533.30 ',
          '$680,166.32 ',
          '$678,470.55 ',
          '$676,788.19 ',
          '$678,387.81 ',
          '$678,364.55 ',
          '$679,987.14 ',
          '$679,985.86 ',
          '$679,994.78 ',
        ],
        'Total Sq.ft': [
          '340620',
          '340620',
          '340620',
          '340620',
          '340620',
          '340620',
          '340620',
          '340620',
          '340620',
          '340620',
          '340620',
          '340620',
          '340620',
          '340620',
          '340620',
          '340620',
          '340620',
          '340620',
          '340620',
          '340620',
          '340620',
          '340620',
          '340620',
          '340620',
          '340620',
          '340620',
          '340620',
          '340620',
          '340620',
          '340620',
          '340620',
          '340620',
          '340620',
          '340620',
          '340620',
          '340620',
          '340620',
          '340620',
          '340620',
          '340620',
          '340620',
          '340620',
          '340620',
          '340620',
          '340620',
          '340620',
          '340620',
          '340620',
          '340620',
          '340620',
          '340620',
          '340620',
          '340620',
          '340620',
          '340620',
          '340620',
          '340620',
          '340620',
          '340620',
          '340620',
        ],
        'Total # of units': [
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
          '420',
        ],
        'Net Eff Rent/Sft': [
          '$4.01 ',
          '$4.02 ',
          '$4.02 ',
          '$4.02 ',
          '$4.02 ',
          '$4.02 ',
          '$4.02 ',
          '$4.02 ',
          '$4.02 ',
          '$4.03 ',
          '$4.03 ',
          '$4.03 ',
          '$4.03 ',
          '$4.03 ',
          '$4.03 ',
          '$4.03 ',
          '$4.03 ',
          '$4.04 ',
          '$4.04 ',
          '$4.04 ',
          '$4.04 ',
          '$4.04 ',
          '$4.04 ',
          '$4.04 ',
          '$4.04 ',
          '$4.05 ',
          '$4.05 ',
          '$4.05 ',
          '$4.05 ',
          '$4.05 ',
          '$4.05 ',
          '$4.05 ',
          '$4.05 ',
          '$4.06 ',
          '$4.06 ',
          '$4.06 ',
          '$4.06 ',
          '$4.06 ',
          '$4.06 ',
          '$4.06 ',
          '$4.06 ',
          '$4.07 ',
          '$4.07 ',
          '$4.07 ',
          '$4.07 ',
          '$4.07 ',
          '$4.07 ',
          '$4.07 ',
          '$4.07 ',
          '$4.08 ',
          '$4.08 ',
          '$4.08 ',
          '$4.08 ',
          '$4.08 ',
          '$4.08 ',
          '$4.08 ',
          '$4.08 ',
          '$4.09 ',
          '$4.09 ',
          '$4.09 ',
        ],
      },
    },
  },
  'Portfolio-Toronto': {
    'Bridge Condos': {
      'Net Rental Income': {
        Actual: [
          '$507,395.00 ',
          '$510,192.14 ',
          '$502,595.45 ',
          '$510,726.11 ',
          '$513,279.74 ',
          '$521,154.38 ',
          '$518,842.63 ',
          '$526,378.95 ',
          '$531,437.50 ',
          '$533,768.28 ',
          '$543,422.05 ',
          '$543,201.76 ',
          '$548,350.13 ',
          '$553,485.22 ',
          '$558,673.62 ',
          '$563,779.05 ',
          '$563,517.31 ',
          '$563,258.45 ',
          '$563,450.34 ',
          '$558,802.79 ',
          '$556,906.09 ',
          '$555,200.71 ',
          '$551,162.07 ',
          '$549,789.61 ',
          '$549,654.72 ',
          '$547,023.48 ',
          '$546,892.13 ',
          '$546,762.22 ',
          '$546,633.72 ',
          '$546,506.64 ',
          '$546,380.94 ',
          '$546,256.62 ',
          '$546,133.66 ',
          '$546,012.04 ',
          '$545,891.76 ',
          '$540,361.87 ',
          '$539,845.01 ',
          '$544,325.47 ',
          '$543,855.60 ',
          '$543,409.64 ',
          '$542,986.37 ',
          '$542,584.64 ',
          '$542,203.36 ',
          '$544,315.63 ',
          '$545,059.61 ',
          '$545,822.02 ',
          '$546,602.05 ',
          '$549,343.34 ',
          '$551,258.23 ',
          '$555,147.23 ',
          '$556,558.62 ',
          '$557,986.21 ',
          '$559,429.50 ',
          '$563,414.52 ',
          '$564,894.39 ',
          '$568,928.48 ',
          '$570,443.41 ',
          '$571,971.93 ',
          '$573,513.65 ',
          '$575,068.22 ',
        ],
        Budgeted: [
          '$519,809.13 ',
          '$519,756.35 ',
          '$525,020.21 ',
          '$522,288.51 ',
          '$524,899.95 ',
          '$524,819.20 ',
          '$527,443.29 ',
          '$530,080.51 ',
          '$533,689.92 ',
          '$536,524.26 ',
          '$540,857.34 ',
          '$545,054.47 ',
          '$550,116.78 ',
          '$552,989.71 ',
          '$555,876.40 ',
          '$559,779.19 ',
          '$552,328.89 ',
          '$551,893.76 ',
          '$555,255.94 ',
          '$552,990.59 ',
          '$553,374.58 ',
          '$553,663.65 ',
          '$551,105.62 ',
          '$551,232.14 ',
          '$550,741.83 ',
          '$547,493.82 ',
          '$547,005.62 ',
          '$546,517.26 ',
          '$546,028.74 ',
          '$545,540.07 ',
          '$545,051.26 ',
          '$544,562.31 ',
          '$544,073.24 ',
          '$548,064.14 ',
          '$547,570.80 ',
          '$543,317.14 ',
          '$543,028.79 ',
          '$546,700.12 ',
          '$546,384.74 ',
          '$546,057.90 ',
          '$545,720.19 ',
          '$545,372.21 ',
          '$547,984.60 ',
          '$549,099.74 ',
          '$549,819.59 ',
          '$550,531.79 ',
          '$551,236.73 ',
          '$553,426.52 ',
          '$555,227.09 ',
          '$554,774.32 ',
          '$556,565.50 ',
          '$558,356.07 ',
          '$560,146.32 ',
          '$564,724.64 ',
          '$566,523.91 ',
          '$571,129.52 ',
          '$572,938.80 ',
          '$574,748.98 ',
          '$576,560.28 ',
          '$578,372.91 ',
        ],
        Variance: [
          '($12,414.13)',
          '($9,564.21)',
          '($22,424.77)',
          '($11,562.40)',
          '($11,620.21)',
          '($3,664.82)',
          '($8,600.66)',
          '($3,701.56)',
          '($2,252.42)',
          '($2,755.97)',
          '$2,564.71 ',
          '($1,852.72)',
          '($1,766.65)',
          '$495.51 ',
          '$2,797.22 ',
          '$3,999.86 ',
          '$11,188.42 ',
          '$11,364.69 ',
          '$8,194.40 ',
          '$5,812.20 ',
          '$3,531.50 ',
          '$1,537.06 ',
          '$56.45 ',
          '($1,442.53)',
          '($1,087.11)',
          '($470.33)',
          '($113.49)',
          '$244.96 ',
          '$604.99 ',
          '$966.57 ',
          '$1,329.68 ',
          '$1,694.30 ',
          '$2,060.42 ',
          '($2,052.09)',
          '($1,679.04)',
          '($2,955.27)',
          '($3,183.77)',
          '($2,374.64)',
          '($2,529.14)',
          '($2,648.26)',
          '($2,733.82)',
          '($2,787.56)',
          '($5,781.25)',
          '($4,784.11)',
          '($4,759.98)',
          '($4,709.77)',
          '($4,634.68)',
          '($4,083.18)',
          '($3,968.86)',
          '$372.92 ',
          '($6.88)',
          '($369.86)',
          '($716.82)',
          '($1,310.12)',
          '($1,629.51)',
          '($2,201.04)',
          '($2,495.38)',
          '($2,777.05)',
          '($3,046.63)',
          '($3,304.70)',
        ],
      },
      'Capital Expenditure': {
        'Net Operating Income': [
          '$280,462.59 ',
          '$282,008.71 ',
          '$277,809.63 ',
          '$282,303.86 ',
          '$283,715.38 ',
          '$288,068.08 ',
          '$286,790.27 ',
          '$290,955.96 ',
          '$293,752.08 ',
          '$295,040.42 ',
          '$300,376.54 ',
          '$300,254.77 ',
          '$303,100.53 ',
          '$305,938.96 ',
          '$308,806.84 ',
          '$311,628.87 ',
          '$311,484.19 ',
          '$311,341.11 ',
          '$311,447.18 ',
          '$308,878.24 ',
          '$307,829.84 ',
          '$306,887.19 ',
          '$304,654.83 ',
          '$303,896.21 ',
          '$303,821.65 ',
          '$302,367.23 ',
          '$302,294.63 ',
          '$302,222.81 ',
          '$302,151.79 ',
          '$302,081.54 ',
          '$302,012.06 ',
          '$301,943.35 ',
          '$301,875.38 ',
          '$301,808.16 ',
          '$301,741.67 ',
          '$298,685.02 ',
          '$298,399.33 ',
          '$300,875.91 ',
          '$300,616.18 ',
          '$300,369.68 ',
          '$300,135.72 ',
          '$299,913.66 ',
          '$299,702.90 ',
          '$300,870.46 ',
          '$301,281.70 ',
          '$301,703.12 ',
          '$302,134.28 ',
          '$303,649.53 ',
          '$304,707.98 ',
          '$306,857.63 ',
          '$307,637.77 ',
          '$308,426.88 ',
          '$309,224.66 ',
          '$311,427.38 ',
          '$312,245.38 ',
          '$314,475.22 ',
          '$315,312.60 ',
          '$316,157.48 ',
          '$317,009.67 ',
          '$317,868.96 ',
        ],
        Occupancy: [
          '96.89%',
          '96.89%',
          '96.89%',
          '96.89%',
          '96.89%',
          '96.89%',
          '96.89%',
          '96.89%',
          '97.33%',
          '97.33%',
          '97.33%',
          '97.33%',
          '97.78%',
          '97.78%',
          '97.78%',
          '98.22%',
          '98.22%',
          '98.22%',
          '98.67%',
          '98.22%',
          '98.22%',
          '98.22%',
          '97.78%',
          '97.78%',
          '97.78%',
          '97.33%',
          '97.33%',
          '97.33%',
          '97.33%',
          '97.33%',
          '97.33%',
          '97.33%',
          '97.33%',
          '97.33%',
          '97.33%',
          '96.44%',
          '96.44%',
          '97.33%',
          '97.33%',
          '97.33%',
          '97.33%',
          '97.33%',
          '97.33%',
          '97.78%',
          '97.78%',
          '97.78%',
          '97.78%',
          '98.22%',
          '98.22%',
          '98.67%',
          '98.67%',
          '98.67%',
          '98.67%',
          '99.11%',
          '99.11%',
          '99.56%',
          '99.56%',
          '99.56%',
          '99.56%',
          '99.56%',
        ],
        'Total expenses': [
          '$226,932.41 ',
          '$228,183.44 ',
          '$224,785.81 ',
          '$228,422.25 ',
          '$229,564.36 ',
          '$233,086.29 ',
          '$232,052.37 ',
          '$235,422.98 ',
          '$237,685.42 ',
          '$238,727.86 ',
          '$243,045.51 ',
          '$242,946.98 ',
          '$245,249.60 ',
          '$247,546.27 ',
          '$249,866.78 ',
          '$252,150.18 ',
          '$252,033.12 ',
          '$251,917.34 ',
          '$252,003.17 ',
          '$249,924.55 ',
          '$249,076.25 ',
          '$248,313.52 ',
          '$246,507.24 ',
          '$245,893.40 ',
          '$245,833.07 ',
          '$244,656.25 ',
          '$244,597.51 ',
          '$244,539.40 ',
          '$244,481.93 ',
          '$244,425.09 ',
          '$244,368.87 ',
          '$244,313.27 ',
          '$244,258.28 ',
          '$244,203.89 ',
          '$244,150.09 ',
          '$241,676.85 ',
          '$241,445.68 ',
          '$243,449.57 ',
          '$243,239.42 ',
          '$243,039.96 ',
          '$242,850.65 ',
          '$242,670.98 ',
          '$242,500.45 ',
          '$243,445.17 ',
          '$243,777.91 ',
          '$244,118.90 ',
          '$244,467.77 ',
          '$245,693.81 ',
          '$246,550.24 ',
          '$248,289.60 ',
          '$248,920.84 ',
          '$249,559.33 ',
          '$250,204.84 ',
          '$251,987.14 ',
          '$252,649.02 ',
          '$254,453.26 ',
          '$255,130.82 ',
          '$255,814.45 ',
          '$256,503.98 ',
          '$257,199.26 ',
        ],
        'Total Sq.ft': [
          '169200',
          '169200',
          '169200',
          '169200',
          '169200',
          '169200',
          '169200',
          '169200',
          '169200',
          '169200',
          '169200',
          '169200',
          '169200',
          '169200',
          '169200',
          '169200',
          '169200',
          '169200',
          '169200',
          '169200',
          '169200',
          '169200',
          '169200',
          '169200',
          '169200',
          '169200',
          '169200',
          '169200',
          '169200',
          '169200',
          '169200',
          '169200',
          '169200',
          '169200',
          '169200',
          '169200',
          '169200',
          '169200',
          '169200',
          '169200',
          '169200',
          '169200',
          '169200',
          '169200',
          '169200',
          '169200',
          '169200',
          '169200',
          '169200',
          '169200',
          '169200',
          '169200',
          '169200',
          '169200',
          '169200',
          '169200',
          '169200',
          '169200',
          '169200',
          '169200',
        ],
        'Total # of units': [
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
          '225',
        ],
        'Net Eff Rent/Sft': [
          '$3.16 ',
          '$3.17 ',
          '$3.19 ',
          '$3.21 ',
          '$3.22 ',
          '$3.24 ',
          '$3.25 ',
          '$3.27 ',
          '$3.29 ',
          '$3.30 ',
          '$3.32 ',
          '$3.34 ',
          '$3.35 ',
          '$3.37 ',
          '$3.39 ',
          '$3.40 ',
          '$3.40 ',
          '$3.40 ',
          '$3.39 ',
          '$3.39 ',
          '$3.39 ',
          '$3.38 ',
          '$3.38 ',
          '$3.38 ',
          '$3.37 ',
          '$3.37 ',
          '$3.37 ',
          '$3.36 ',
          '$3.36 ',
          '$3.36 ',
          '$3.35 ',
          '$3.35 ',
          '$3.35 ',
          '$3.34 ',
          '$3.34 ',
          '$3.34 ',
          '$3.33 ',
          '$3.33 ',
          '$3.33 ',
          '$3.32 ',
          '$3.32 ',
          '$3.32 ',
          '$3.31 ',
          '$3.31 ',
          '$3.31 ',
          '$3.32 ',
          '$3.32 ',
          '$3.32 ',
          '$3.33 ',
          '$3.34 ',
          '$3.35 ',
          '$3.36 ',
          '$3.37 ',
          '$3.38 ',
          '$3.39 ',
          '$3.40 ',
          '$3.41 ',
          '$3.42 ',
          '$3.43 ',
          '$3.44 ',
        ],
      },
    },
    'Liberty Towers': {
      'Net Rental Income': {
        Actual: [
          '$673,696.10 ',
          '$677,410.02 ',
          '$667,323.47 ',
          '$678,118.99 ',
          '$681,509.59 ',
          '$691,965.18 ',
          '$688,895.75 ',
          '$698,902.13 ',
          '$704,598.51 ',
          '$709,900.27 ',
          '$722,739.57 ',
          '$722,446.59 ',
          '$728,240.46 ',
          '$737,342.95 ',
          '$744,254.84 ',
          '$747,657.75 ',
          '$747,310.65 ',
          '$749,279.95 ',
          '$746,158.94 ',
          '$743,352.76 ',
          '$740,829.66 ',
          '$738,561.07 ',
          '$734,248.09 ',
          '$732,419.72 ',
          '$732,240.02 ',
          '$729,795.85 ',
          '$729,620.61 ',
          '$727,181.92 ',
          '$727,011.03 ',
          '$726,842.00 ',
          '$726,674.83 ',
          '$726,509.49 ',
          '$726,345.95 ',
          '$723,921.95 ',
          '$723,762.47 ',
          '$723,033.80 ',
          '$722,342.22 ',
          '$721,685.84 ',
          '$721,062.86 ',
          '$720,471.59 ',
          '$719,910.41 ',
          '$719,377.78 ',
          '$718,872.26 ',
          '$718,392.46 ',
          '$721,622.42 ',
          '$722,631.79 ',
          '$723,664.50 ',
          '$726,258.33 ',
          '$731,053.22 ',
          '$735,163.40 ',
          '$737,032.44 ',
          '$741,203.59 ',
          '$745,407.32 ',
          '$747,350.68 ',
          '$749,313.68 ',
          '$753,600.32 ',
          '$755,606.98 ',
          '$757,631.65 ',
          '$761,996.98 ',
          '$764,062.44 ',
        ],
        Budgeted: [
          '$688,808.04 ',
          '$688,738.11 ',
          '$695,713.34 ',
          '$692,093.51 ',
          '$695,553.98 ',
          '$695,446.97 ',
          '$698,924.20 ',
          '$702,418.82 ',
          '$707,018.64 ',
          '$711,866.99 ',
          '$717,616.18 ',
          '$723,184.98 ',
          '$729,711.66 ',
          '$734,647.55 ',
          '$738,482.51 ',
          '$740,744.30 ',
          '$729,030.39 ',
          '$731,407.10 ',
          '$729,484.75 ',
          '$730,131.23 ',
          '$730,638.23 ',
          '$731,019.90 ',
          '$727,871.71 ',
          '$728,038.80 ',
          '$727,391.23 ',
          '$726,970.88 ',
          '$726,322.64 ',
          '$722,722.45 ',
          '$727,180.64 ',
          '$726,529.85 ',
          '$735,050.01 ',
          '$734,390.63 ',
          '$733,731.07 ',
          '$731,716.32 ',
          '$731,057.67 ',
          '$730,687.91 ',
          '$730,300.11 ',
          '$729,895.23 ',
          '$729,474.17 ',
          '$729,037.80 ',
          '$728,586.93 ',
          '$728,122.34 ',
          '$727,644.77 ',
          '$727,154.90 ',
          '$729,456.53 ',
          '$730,401.41 ',
          '$731,336.67 ',
          '$733,616.35 ',
          '$737,361.11 ',
          '$741,113.06 ',
          '$743,505.86 ',
          '$747,269.00 ',
          '$751,040.48 ',
          '$753,440.75 ',
          '$755,841.28 ',
          '$759,631.13 ',
          '$762,037.56 ',
          '$764,445.19 ',
          '$768,256.24 ',
          '$770,671.54 ',
        ],
        Variance: [
          '($15,111.94)',
          '($11,328.09)',
          '($28,389.87)',
          '($13,974.52)',
          '($14,044.39)',
          '($3,481.79)',
          '($10,028.46)',
          '($3,516.70)',
          '($2,420.13)',
          '($1,966.72)',
          '$5,123.39 ',
          '($738.40)',
          '($1,471.21)',
          '$2,695.40 ',
          '$5,772.33 ',
          '$6,913.45 ',
          '$18,280.26 ',
          '$17,872.85 ',
          '$16,674.19 ',
          '$13,221.53 ',
          '$10,191.43 ',
          '$7,541.17 ',
          '$6,376.38 ',
          '$4,380.92 ',
          '$4,848.79 ',
          '$2,824.96 ',
          '$3,297.96 ',
          '$4,459.47 ',
          '($169.61)',
          '$312.16 ',
          '($8,375.18)',
          '($7,881.14)',
          '($7,385.12)',
          '($7,794.37)',
          '($7,295.19)',
          '($7,654.10)',
          '($7,957.89)',
          '($8,209.39)',
          '($8,411.31)',
          '($8,566.21)',
          '($8,676.52)',
          '($8,744.56)',
          '($8,772.51)',
          '($8,762.45)',
          '($7,834.11)',
          '($7,769.62)',
          '($7,672.17)',
          '($7,358.03)',
          '($6,307.89)',
          '($5,949.66)',
          '($6,473.42)',
          '($6,065.41)',
          '($5,633.16)',
          '($6,090.07)',
          '($6,527.60)',
          '($6,030.81)',
          '($6,430.57)',
          '($6,813.53)',
          '($6,259.26)',
          '($6,609.10)',
        ],
      },
      'Capital Expenditure': {
        'Net Operating Income': [
          '$372,385.52 ',
          '$374,438.39 ',
          '$368,863.05 ',
          '$374,830.27 ',
          '$376,704.43 ',
          '$382,483.75 ',
          '$380,787.13 ',
          '$386,318.15 ',
          '$389,466.83 ',
          '$392,397.38 ',
          '$399,494.30 ',
          '$399,332.35 ',
          '$402,534.91 ',
          '$407,566.31 ',
          '$411,386.86 ',
          '$413,267.82 ',
          '$413,075.96 ',
          '$414,164.49 ',
          '$412,439.35 ',
          '$410,888.24 ',
          '$409,493.60 ',
          '$408,239.63 ',
          '$405,855.63 ',
          '$404,845.00 ',
          '$404,745.67 ',
          '$403,394.65 ',
          '$403,297.79 ',
          '$401,949.81 ',
          '$401,855.35 ',
          '$401,761.92 ',
          '$401,669.51 ',
          '$401,578.12 ',
          '$401,487.73 ',
          '$400,147.86 ',
          '$400,059.71 ',
          '$399,656.93 ',
          '$399,274.66 ',
          '$398,911.85 ',
          '$398,567.50 ',
          '$398,240.67 ',
          '$397,930.48 ',
          '$397,636.07 ',
          '$397,356.64 ',
          '$397,091.43 ',
          '$398,876.79 ',
          '$399,434.72 ',
          '$400,005.55 ',
          '$401,439.29 ',
          '$404,089.67 ',
          '$406,361.57 ',
          '$407,394.68 ',
          '$409,700.28 ',
          '$412,023.89 ',
          '$413,098.09 ',
          '$414,183.14 ',
          '$416,552.58 ',
          '$417,661.76 ',
          '$418,780.90 ',
          '$421,193.83 ',
          '$422,335.51 ',
        ],
        Occupancy: [
          '96.67%',
          '96.67%',
          '96.67%',
          '96.67%',
          '96.67%',
          '96.67%',
          '96.67%',
          '96.67%',
          '96.97%',
          '97.27%',
          '97.27%',
          '97.27%',
          '97.58%',
          '97.88%',
          '97.88%',
          '97.88%',
          '97.88%',
          '98.18%',
          '98.18%',
          '98.18%',
          '98.18%',
          '98.18%',
          '97.88%',
          '97.88%',
          '97.88%',
          '97.58%',
          '97.58%',
          '97.27%',
          '97.27%',
          '97.27%',
          '97.27%',
          '97.27%',
          '97.27%',
          '96.97%',
          '96.97%',
          '96.97%',
          '96.97%',
          '96.97%',
          '96.97%',
          '96.97%',
          '96.97%',
          '96.97%',
          '96.97%',
          '96.97%',
          '97.27%',
          '97.27%',
          '97.27%',
          '97.58%',
          '97.88%',
          '98.18%',
          '98.18%',
          '98.48%',
          '98.79%',
          '98.79%',
          '98.79%',
          '99.09%',
          '99.09%',
          '99.09%',
          '99.39%',
          '99.39%',
        ],
        'Total expenses': [
          '$301,310.58 ',
          '$302,971.63 ',
          '$298,460.42 ',
          '$303,288.72 ',
          '$304,805.16 ',
          '$309,481.42 ',
          '$308,108.62 ',
          '$312,583.98 ',
          '$315,131.68 ',
          '$317,502.90 ',
          '$323,245.27 ',
          '$323,114.24 ',
          '$325,705.54 ',
          '$329,776.63 ',
          '$332,867.98 ',
          '$334,389.93 ',
          '$334,234.69 ',
          '$335,115.46 ',
          '$333,719.58 ',
          '$332,464.52 ',
          '$331,336.07 ',
          '$330,321.44 ',
          '$328,392.46 ',
          '$327,574.72 ',
          '$327,494.35 ',
          '$326,401.19 ',
          '$326,322.82 ',
          '$325,232.11 ',
          '$325,155.68 ',
          '$325,080.09 ',
          '$325,005.32 ',
          '$324,931.37 ',
          '$324,858.23 ',
          '$323,774.09 ',
          '$323,702.77 ',
          '$323,376.87 ',
          '$323,067.56 ',
          '$322,773.99 ',
          '$322,495.37 ',
          '$322,230.92 ',
          '$321,979.93 ',
          '$321,741.71 ',
          '$321,515.62 ',
          '$321,301.03 ',
          '$322,745.63 ',
          '$323,197.07 ',
          '$323,658.95 ',
          '$324,819.04 ',
          '$326,963.55 ',
          '$328,801.83 ',
          '$329,637.76 ',
          '$331,503.30 ',
          '$333,383.42 ',
          '$334,252.59 ',
          '$335,130.54 ',
          '$337,047.74 ',
          '$337,945.22 ',
          '$338,850.76 ',
          '$340,803.15 ',
          '$341,726.93 ',
        ],
        'Total Sq.ft': [
          '248160',
          '248160',
          '248160',
          '248160',
          '248160',
          '248160',
          '248160',
          '248160',
          '248160',
          '248160',
          '248160',
          '248160',
          '248160',
          '248160',
          '248160',
          '248160',
          '248160',
          '248160',
          '248160',
          '248160',
          '248160',
          '248160',
          '248160',
          '248160',
          '248160',
          '248160',
          '248160',
          '248160',
          '248160',
          '248160',
          '248160',
          '248160',
          '248160',
          '248160',
          '248160',
          '248160',
          '248160',
          '248160',
          '248160',
          '248160',
          '248160',
          '248160',
          '248160',
          '248160',
          '248160',
          '248160',
          '248160',
          '248160',
          '248160',
          '248160',
          '248160',
          '248160',
          '248160',
          '248160',
          '248160',
          '248160',
          '248160',
          '248160',
          '248160',
          '248160',
        ],
        'Total # of units': [
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
          '330',
        ],
        'Net Eff Rent/Sft': [
          '$2.87 ',
          '$2.88 ',
          '$2.89 ',
          '$2.91 ',
          '$2.92 ',
          '$2.94 ',
          '$2.95 ',
          '$2.97 ',
          '$2.98 ',
          '$3.00 ',
          '$3.01 ',
          '$3.03 ',
          '$3.04 ',
          '$3.06 ',
          '$3.07 ',
          '$3.09 ',
          '$3.09 ',
          '$3.08 ',
          '$3.08 ',
          '$3.08 ',
          '$3.07 ',
          '$3.07 ',
          '$3.07 ',
          '$3.06 ',
          '$3.06 ',
          '$3.06 ',
          '$3.05 ',
          '$3.05 ',
          '$3.05 ',
          '$3.05 ',
          '$3.04 ',
          '$3.04 ',
          '$3.04 ',
          '$3.03 ',
          '$3.03 ',
          '$3.03 ',
          '$3.02 ',
          '$3.02 ',
          '$3.02 ',
          '$3.02 ',
          '$3.01 ',
          '$3.01 ',
          '$3.01 ',
          '$3.00 ',
          '$3.01 ',
          '$3.01 ',
          '$3.01 ',
          '$3.02 ',
          '$3.02 ',
          '$3.03 ',
          '$3.04 ',
          '$3.05 ',
          '$3.06 ',
          '$3.07 ',
          '$3.08 ',
          '$3.09 ',
          '$3.10 ',
          '$3.11 ',
          '$3.12 ',
          '$3.13 ',
        ],
      },
    },
  },
};
