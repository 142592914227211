import { BookingApi } from 'services/api/booking';
import { Router } from 'utils/with-i18next';
import { Alert } from 'rsuite';
import { useErrorTracker } from './use-error-tracker';

export default function isUserAuthorize(user, pageId) {
  const errorTracker = useErrorTracker();
  if (!user) {
    return;
  }

  if (user?.status) {
    return false;
  }

  // && (user.views ? user.views.include())
  const restrictedPages = new Set(['dashboard', 'properties', 'bookings', 'tickets', 'income-management', 'price']);

  if (user?.userType === 'landlord') {
    // if the user is not allowed to see the current view, show 403 page
    if (restrictedPages.has(pageId) && user.views && !user.views.includes(pageId)) {
      Router.push(`/403`);
    }
    return true;
  }

  //If user is a renter, check to see if they have any bookings that are either paid, approved, or documents-uploaded.
  //If there are any, redirect them to the first one. Otherwise, redirect them to /properties
  if (user?.userType === 'renter') {
    BookingApi.fetchBookings('tenant')
      .then(res => {
        if (Array.isArray(res.data) && res.data.length > 0) {
          const userBooking = res.data.filter(
            item => item.status === 'paid' || item.status === 'approved' || item.status === 'awaiting_application' || item.status === 'documents-uploaded'
          );
          if (userBooking.length > 0) {
            Router.push(`/my-bookings/${userBooking[0].id}`);
          } else {
            Router.push('/properties');
          }
        } else {
          Router.push('/properties');
        }
      })
      .catch(err => {
        errorTracker.error('Fetch bookings error', { fetchBookingsParam: 'tenant' });
        Alert.error('Something went wrong while fetching bookings.', 5000);
        console.error(err);
      });
  }

  return false;
}
