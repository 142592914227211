import React, { useState, useEffect } from 'react';
import { Drawer, Button, FlexboxGrid, Panel, Divider } from 'rsuite';
import { Link } from 'utils/with-i18next';
import { startCase, filter } from 'lodash';
import AvatarComponent from 'components/Avatar';
import Styles from './styles.js';
import { useUser } from 'contexts/UserContext';

function HamburgerButton({
  currentUser,
  togglePopup,
  handleLogout,
  setRoommateProfileModal,
  roommateProfileModal,
  bookings,
}) {
  const [show, setShow] = useState(false);
  const { user, isUserLoggedIn } = useUser();
  const close = () => {
    setShow(false);
  };
  const toggleDrawer = () => {
    setShow(true);
  };

  const [urlPathname, setUrlPathname] = useState('');
  useEffect(() => {
    setUrlPathname(window.location.pathname);
  });

  return (
    <div className="hamburger-wrapper">
      <Button className="ham-btn " onClick={() => toggleDrawer()}>
        ☰
      </Button>
      <Link href={'/'} passHref>
        <img className="sm-logo" src="/static/images/SmallLogo.png"></img>
      </Link>
      <Drawer
        id="resident-property-hamburger-drawer"
        className="hamburger-wrapper"
        size={'sm'}
        placement={'left'}
        show={show}
        onHide={() => close()}
        full={true}>
        <Drawer.Header>{isUserLoggedIn() ? <p>Signed in as</p> : <p>Please login for more options</p>}</Drawer.Header>
        <Drawer.Body>
          <div className="menu-links">
            <FlexboxGrid justify="space-around">
              {isUserLoggedIn() && (
                <>
                  <FlexboxGrid.Item
                    colspan={24}
                    style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: '1em' }}>
                    {currentUser?.displayName && <AvatarComponent size="lg" name={currentUser?.displayName} />}
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item
                    colspan={24}
                    style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                    <strong>{currentUser?.displayName}</strong>
                  </FlexboxGrid.Item>
                  {currentUser?.role && (
                    <FlexboxGrid.Item
                      colspan={24}
                      style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: '1em' }}>
                      <Button
                        appearance="ghost"
                        className="py-2"
                        onClick={() => {
                          setRoommateProfileModal(!roommateProfileModal);
                          close();
                        }}
                        style={{ marginTop: '0.5em' }}>
                        Create Roommate Profile
                      </Button>
                    </FlexboxGrid.Item>
                  )}
                  <FlexboxGrid.Item
                    colspan={24}
                    style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: '1em' }}>
                    <Panel header={'My Rentals'} collapsible>
                      <FlexboxGrid>
                        {bookings.length > 0 ? (
                          bookings
                            .filter(
                              item =>
                                item.status === 'awaiting_application' ||
                                item.status === 'paid' ||
                                item.status === 'approved' ||
                                item.status === 'documents-uploaded'
                            )
                            .map(booking => {
                              return (
                                <FlexboxGrid.Item key={booking.id}>
                                  <Divider />
                                  <Link href={`/my-bookings/${booking.id}`}>
                                    <a
                                      onClick={() => {
                                        close();
                                      }}>
                                      {booking.room ? (
                                        <span>
                                          <p>
                                            <strong>{startCase(booking.room.type)} Room </strong>
                                            in
                                          </p>
                                          <p>{booking.propertyAddress}</p>
                                        </span>
                                      ) : (
                                        <p>Entire Unit</p> in <p>{booking.propertyAddress}</p>
                                      )}
                                    </a>
                                  </Link>
                                  <Divider />
                                </FlexboxGrid.Item>
                              );
                            })
                        ) : (
                          <FlexboxGrid.Item>
                            <Divider />
                            <p>No Rental Found</p>
                            <Divider />
                          </FlexboxGrid.Item>
                        )}
                      </FlexboxGrid>
                    </Panel>
                  </FlexboxGrid.Item>
                  {urlPathname.split('/')[1] === 'my-bookings' && (
                    <FlexboxGrid.Item
                      colspan={24}
                      style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: '1em' }}>
                      <Link href={'/properties'}>
                        <a
                          onClick={() => {
                            close();
                          }}>
                          <strong>&larr; Go to Properties</strong>
                        </a>
                      </Link>
                    </FlexboxGrid.Item>
                  )}
                </>
              )}
              <FlexboxGrid.Item
                colspan={24}
                style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: '1em' }}>
                {!isUserLoggedIn() ? (
                  <>
                    <Button
                      className=" "
                      onClick={() => {
                        togglePopup();
                        close();
                      }}
                      style={{ marginTop: '1em' }}>
                      <Divider />
                      Login
                      <Divider />
                    </Button>
                  </>
                ) : (
                  <Button
                    className=" "
                    onClick={() => {
                      handleLogout();
                      close();
                    }}
                    style={{ marginTop: '1em' }}>
                    Sign Out
                  </Button>
                )}
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </div>
        </Drawer.Body>
      </Drawer>
      <Styles />
    </div>
  );
}
export default HamburgerButton;
