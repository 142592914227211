export default function Styles() {
  return (
    <style>
      {`
        .rs-picker-default .rs-picker-toggle.rs-btn-sm {
          padding-top: 4px !important;
          padding-bottom: 4px;  
        }

        .rs-picker-toggle-caret {
          right: 8px !important;
          top: 5px !important;
        }

        .rs-drawer {
          margin-top: 71px;
        }

        .rs-drawer-backdrop.in {
          opacity: 0;
          pointer-events: none;
        }
    `}
    </style>
  );
}
