import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { compact, get, uniq } from 'lodash';
import { Grid, Row, Col } from 'rsuite';
import CheckboxComponent from 'components/Checkbox';
import { Schema, FormControl, Panel, FormGroup, Radio, RadioGroup, Form } from 'rsuite';

export const Model = Schema.Model({
  cookAtHome: Schema.Types.StringType().isRequired('This field is required'),
  favouritFoods: Schema.Types.ArrayType().minLength(1, 'At least one required'),
  dietaryRestrictions: Schema.Types.ArrayType().minLength(1, 'At least one required'),
  isSmellBothered: Schema.Types.StringType().isRequired('This field is required'),
});
const Step5Form = ({
  roomieProfileData,
  setroomieProfileData,
  formErrors,
  setFormErrors,
  shadow,
  chkBoxShadow,
  space,
}) => {
  const [options, setOptions] = useState({
    favouritFoodsOptions: [],
  });
  useEffect(() => {
    setOptions({
      favouritFoodsOptions: uniq([
        'Italian',
        'Japanese',
        'Chinese',
        'Mexican',
        'Health foods',
        'Indian',
        'Thai',
        'American',
      ]),
      dietaryRestrictions: uniq([
        'Lactose Intolerant',
        'Gluten Free',
        'No Peanuts',
        'No Shellfish',
        'Vegan',
        'Vegetarian',
        'Halal',
        'Kosher',
      ]),
    });
  }, []);
  const getData = type => {
    return get(roomieProfileData, 'favouritFoods', [])
      .filter(a => a.type === type)
      .map(a => a.slug);
  };
  const addCustomOption = (type, newOption) => {
    return setOptions({ ...options, [type]: [...options[type], newOption] });
  };
  const FormTagline = styled('h4')`
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 30px;
  `;

  return (
    <div>
      {space ? (
        <FormTagline>How often do you cook at home?</FormTagline>
      ) : (
        <FormTagline className="mb-0">How often do you cook at home?</FormTagline>
      )}

      <Form
        checkTrigger="change"
        formValue={roomieProfileData}
        onCheck={setFormErrors}
        formError={formErrors}
        onChange={setroomieProfileData}
        model={Model}
        fluid>
        <Panel className="mt-3" shaded={shadow ? false : true} style={{ overflow: 'inherit' }}>
          <Grid fluid>
            <Row className="show-grid  ">
              <Col md={24}>
                <FormGroup controlId="radioList">
                  <FormControl
                    inline
                    name="cookAtHome"
                    accepter={RadioGroup}
                    onChange={val => setroomieProfileData({ ...roomieProfileData, cookAtHome: val })}>
                    <Radio value="Almost never">Almost never</Radio>
                    <Radio value="1-2 times a week">1-2 times a week</Radio>
                    <Radio value="3-4 times a week">3-4 times a week</Radio>
                    <Radio value="Almost daily">Almost daily</Radio>
                  </FormControl>
                </FormGroup>
              </Col>
            </Row>
          </Grid>
        </Panel>
        <FormTagline className="mt-4 mb-3">What are your favourite foods?</FormTagline>
        <CheckboxComponent
          shadow={chkBoxShadow ? true : false}
          name="favouritFoods"
          onChange={val => setroomieProfileData({ ...roomieProfileData, favouritFoods: val })}
          options={compact(options.favouritFoodsOptions)}
          addCustomOption={newOption => addCustomOption('favouritFoodsOptions', newOption)}
        />

        <FormTagline className="mt-4 mb-0">
          Would you be bothered if your roomies regularly cooked food with strong smells?
        </FormTagline>
        <Panel className="mt-3" shaded={shadow ? false : true} style={{ overflow: 'inherit' }}>
          <Grid fluid>
            <Row className="show-grid  ">
              <Col md={24}>
                <FormGroup controlId="radioList">
                  <FormControl
                    inline
                    name="isSmellBothered"
                    accepter={RadioGroup}
                    onChange={val => setroomieProfileData({ ...roomieProfileData, isSmellBothered: val })}>
                    <Radio value="Yes">Yes</Radio>
                    <Radio value="No">No</Radio>
                  </FormControl>
                </FormGroup>
              </Col>
            </Row>
          </Grid>
        </Panel>
        <FormTagline className="mt-4 mb-3">Any dietary restrictions?</FormTagline>
        <CheckboxComponent
          shadow={chkBoxShadow ? true : false}
          name="dietaryRestrictions"
          options={compact(options.dietaryRestrictions)}
          onChange={val => setroomieProfileData({ ...roomieProfileData, dietaryRestrictions: val })}
          addCustomOption={newOption => addCustomOption('dietaryRestrictions', newOption)}
        />
      </Form>
    </div>
  );
};

export default Step5Form;
