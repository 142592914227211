export default function Style(){
  return (
    <style>
      {`
       .popup-main {
  position: fixed;
  background: #ffffff12;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 114;
}
.popup-main .popup-box {
  margin: 0 auto;
  min-width: 309px;
  max-height: 90vh;
  background: #fff;
  border-radius: 40px;
  padding: 5px;
  border: 1px solid #fff;
  overflow: auto;
  background-image: url(/static/images/auth-layout-bg.png);
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 288px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 2px 0px 22px -5px rgba(0, 0, 0, 0.21);
  -webkit-box-shadow: 2px 0px 22px -5px rgba(0, 0, 0, 0.21);
  -moz-box-shadow: 2px 0px 22px -5px rgba(0, 0, 0, 0.21);
}

.popup-main .logo {
  width: 165px;
  margin: auto;
  display: block;
  margin-top: 20px;
}

.popup-main .popup-login-form {
  margin: 55px 50px 20px 50px;
}

@media (max-width: 750px) {
  .popup-main .popup-login-form {
    margin-left: 25px;
    margin-right: 25px;
    margin-bottom: 10px;
    margin-top: 27px;
  }
}

        `}
    </style>
  );
}
