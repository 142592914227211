import React, { useContext, useEffect, useMemo, useState } from 'react';
import { SelectPicker } from 'rsuite';
import Styles from './styles.js';
import { useRouter } from 'next/router';
import { useUser } from 'contexts/UserContext';
import { TableauDashboardContext } from 'contexts/TableauDashboardContext';
import { fetchSubUsersData } from 'utils/getSubusersData.js';
import { getTableauTitle } from 'utils/getTableauTitle.js';

export default function TableauDashboardFilters() {
  const {
    tableauDashboardState: { currentDashboard, userSelected, allDashboards, dashboardTabIndex },
    tableauDashboardDispatch,
  } = useContext(TableauDashboardContext);
  const { user: currentUser } = useUser();
  const router = useRouter();
  const [subUsersData, setSubUsersData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (currentUser) {
      fetchSubUsersData(currentUser, setSubUsersData, setIsLoading);
    }
  }, [currentUser]);

  const tabDropdownDisabled = useMemo(() => currentDashboard.dashboardTabs.length <= 1, [currentDashboard]);

  return (
    <>
      <div className="dashboard-filters">
        <div className="flex flex-wrap mt-[-0.5rem] ">
          <div style={{ paddingLeft: '0.5em' }}>
            <SelectPicker
              data={subUsersData}
              onChange={val => {
                tableauDashboardDispatch({
                  type: 'setUserSelected',
                  payload: val ? val : currentUser.id,
                });
              }}
              appearance="default"
              placeholder="Select User"
              value={userSelected}
            />
          </div>
          <div style={{ paddingLeft: '0.5em' }}>
            <SelectPicker
              data={allDashboards}
              onChange={val => {
                if (router.query.sankey) {
                  router.replace('/dashboard', undefined, { shallow: true });
                }
                const selectedDashboard = allDashboards.find(dashboard => dashboard.value === val);
                tableauDashboardDispatch({
                  type: 'setCurrentDashboard',
                  payload: selectedDashboard ? selectedDashboard : allDashboards[0],
                });
                tableauDashboardDispatch({
                  type: 'setDashboardTabIndex',
                  payload: 0,
                });
                if (val === 'overview') {
                  // reset building and user as the cards on the 'overview' tab are not designed to be filterable
                  tableauDashboardDispatch({
                    type: 'setUserSelected',
                    payload: '',
                  });
                }
              }}
              appearance="default"
              value={currentDashboard.value}
              searchable={false}
              cleanable={false}
              style={{ width: '150px' }}
              menuStyle={{ width: '240px' }}
            />
          </div>
          <div style={{ paddingLeft: '0.5em' }}>
            <SelectPicker
              data={currentDashboard.dashboardTabs}
              onChange={val => {
                const selectedTab = currentDashboard.dashboardTabs.find(tab => tab.value === val);
                tableauDashboardDispatch({
                  type: 'setDashboardTabIndex',
                  payload: selectedTab.value,
                });
              }}
              appearance="default"
              searchable={false}
              cleanable={false}
              style={{
                width: '150px',
                pointerEvents: tabDropdownDisabled ? 'none' : 'auto',
                transition: tabDropdownDisabled ? 'none' : 'all 0.3s ease', // Assuming 0.3s for hover animations; adjust as necessary
              }}
              value={dashboardTabIndex}
              menuStyle={{ width: '240px' }}
              disabled={tabDropdownDisabled}
            />
          </div>
        </div>
      </div>
      <Styles />
    </>
  );
}
